import "./OtherConsiderationsModal.scss";

import Icon from "../Global/Icon/Icon";
import { AnimatePresence, motion } from "framer-motion";

export type OtherConsiderationsModalProps = {
  className?: string;
  showConsiderations?: boolean;
  onClick?: (() => void) | ((e: any) => void);
};

const otherConsiderations = [
  {
    icon: (
      <Icon name="pet-friendly" color="#FFFFFF" className="pet-friendly-size" />
    ),
    text:
      "Pet Friendly - Our furry companions need vacations too. As long as they adhere to the same policies our human guests do;" +
      " No noise complaints and don’t chew the furniture. Pets up to 25 lb are permitted. Pet guests are subject to a one time $50 cleaning fee.",
  },
  {
    icon: (
      <Icon name="no-elevator" color="#FFFFFF" className="no-elevator-size" />
    ),
    text: "No elevator - We’re a 100-year old, 3-storey building. You understand.",
  },
  {
    icon: <Icon name="parking" color="#FFFFFF" className="parking-size" />,
    text: "Park it and walk - We have city parking lots close by. Toronto transit is cheap and easy. Uber’s a thing here too. ",
  },
  {
    icon: (
      <Icon name="room-service" color="#FFFFFF" className="room-service-size" />
    ),
    text: "Room Service - A beautiful latte and croissant are just a text message away. You don’t have to get out of bed",
  },
  {
    icon: (
      <Icon name="check-in-out" color="#FFFFFF" className="check-in-out-size" />
    ),
    text: "Check in is at 3pm, check out is at 11am. If you need to sleep off  last night’s party late check out is possible for a small fee.",
  },
  {
    icon: <Icon name="cancel" color="#FFFFFF" className="cancel-size" />,
    text: "Cancel anytime - Shit happens, it’s cool. Cancel your stay up until 3pm on the day prior to check-in for no charge.",
  },
];

const showModal = {
  hidden: {
    opacity: 0,
    left: "100%",
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    left: 0,
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
};

const OtherConsiderationsModal = ({
  className,
  showConsiderations,
  onClick,
  ...props
}: OtherConsiderationsModalProps) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {showConsiderations && (
        <motion.div
          className="other-considerations-modal-container"
          variants={showModal}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Icon
            name="menu-close"
            size={21}
            className="other-considerations-modal-close"
            onClick={onClick}
          ></Icon>
          <div className="other-considerations-modal-inner-container">
            <Icon
              name="annex-icon"
              className="other-considerations-annex-icon"
            />
            <div className="other-considerations-modal">
              <div className="other-considerations-modal__title-container">
                <h1 className="other-considerations-modal__title-top">
                  Other Considerations
                </h1>
                {/* <h1 className="other-considerations-modal__title-bottom">Considerations</h1> */}
              </div>
              <div className="considerations-container">
                {otherConsiderations.map((item, index) => {
                  return (
                    <div className="consideration" key={index}>
                      <div className="consideration__icon">{item.icon}</div>
                      <p className="consideration__text">{item.text}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OtherConsiderationsModal;
