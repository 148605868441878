import { collection } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

const useUserReservations = (email: string) => {
  const reservationsRef = collection(useFirestore(), `customers/${email}/reservations`);

  const {
    data: reservations
  } = useFirestoreCollectionData(reservationsRef, {idField: 'id'});

  return {
    reservations,
    reservationsRef
  }
};

export default useUserReservations;
