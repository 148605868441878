import { collection } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

const useVouchers = () => {
  const vouchersCollectionRef = collection(useFirestore(), 'vouchers');

  const {
    data: vouchers
  } = useFirestoreCollectionData(vouchersCollectionRef, {idField: 'id'});

  return {
    vouchers,
    vouchersCollectionRef
  }
};

export default useVouchers;