import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Global/Button/Button";
import MetaDecorator from "../../../MetaDecorator";
// import { mockMonths, mockYears } from "../mockData";
import AuthTwoColumnLayout from "../AuthTwoColumnLayout";
import "./AuthPaymentInformation.scss";

import * as yup from "yup";
import { useFormik } from "formik";

import { setDoc } from "firebase/firestore";

import { useUserData } from "../../../hooks/useUserData";

import useMews from "../../../hooks/useMews";
import axios from "axios";

let secureFields: any;

const AuthPaymentInformation = () => {
  const { userRef, userData } = useUserData();

  // const [cardNumber, setCardNumber] = useState("");
  // const [cardNumberError, setCardNumberError] = useState<boolean>(false);

  // const [expiryMonth, setExpiryMonth] = useState("");
  // const [expiryMonthError, setExpiryMonthError] = useState<boolean>(false);

  // const [expiryYear, setExpiryYear] = useState("");
  // const [expiryYearError, setExpiryYearError] = useState<boolean>(false);

  const [showPaymentMethod, setShowPaymentMethod] = useState<boolean>(false);
  const payment = useMews().payment;
  var styles = {
    // set style to all elements, JSON accepted too
    // setting the font-family to both fields
    "*": "height: 74px; font-size: 16px; font-weight: normal; caret-color: #004C45; border: 1px solid #000000; padding: 18px 28px 0px;",
    // ::hover on all elements
    "*::hover":
      "-webkit-box-shadow: none; -ms-box-shadow: none; -moz-appearance: none; ",
    // pseudoselectors
    "*:focus": "border: 2px solid #004C45",
    "*::placeholder": "color: #999999",
    "*:-ms-input-placeholder": "color: #999999",
  };

  const createSecureFields = () => {
    setTimeout(() => {
      secureFields = new (window as any).SecureFields();
      secureFields.initTokenize(
        payment?.mechantId,
        {
          cardNumber: {
            placeholderElementId: "card-number-placeholder",
            inputType: "tel",
          },
          // cvv: {
          //   placeholderElementId: "cvv-placeholder",
          //   inputType: "tel",
          // },
        },
        {
          styles: styles,
          // focus: "cardNumber"
        }
      );
      secureFields.on("change", function (event: any) {
        // put a red border around the fields if they are not valid
        secureFields.setStyle("cardNumber.invalid", "color: red");
        // secureFields.setStyle("cvv.invalid", "color: red");
        // console.log("on change event", event);

        if (!event.fields.cardNumber.valid) {
          formikProps.setFieldError(
            "creditCardNumber",
            "Invalid credit card number"
          );
        } else formikProps.setFieldError("creditCardNumber", "");

        // if (!event.fields.cvv.valid) {
        //   formikProps.setFieldError("cvc", "Invalid CVC");
        // } else formikProps.setFieldError("cvc", "");
      });
      secureFields.on("validate", function (event: any) {
        // put a red border around the fields if they are not valid
        secureFields.setStyle("cardNumber.invalid", "color: red");
        // secureFields.setStyle("cvv.invalid", "color: red");

        if (!event.fields.cardNumber.valid) {
          formikProps.setFieldError(
            "creditCardNumber",
            "Invalid credit card number"
          );
        } else formikProps.setFieldError("creditCardNumber", "");

        // if (!event.fields.cvv.valid) {
        //   formikProps.setFieldError("cvc", "Invalid CVC");
        // } else formikProps.setFieldError("cvc", "");
      });
    }, 100);
  };

  const getPaymentUrl = useCallback((production: boolean) => {
    // TODO: environment variables
    if (production) {
      return "https://pay.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js";
    } else {
      return "https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js";
    }
  }, []);

  useEffect(() => {
    //initalize payment scripts
    const scriptSource = getPaymentUrl(false);

    if (document.querySelector('script[src="' + scriptSource + '"]')) {
      return;
    }

    const script = document.createElement("script");
    script.src = scriptSource;
    document.body.appendChild(script);

    return () => {};
  }, [getPaymentUrl]);

  useEffect(() => {
    createSecureFields();
  }, []);

  // const [months, setMonths] = useState<any[]>(mockMonths);
  // const [years, setYears] = useState<any[]>(mockYears);

  const [formSuccess, setFormSuccess] = useState(false);

  const history = useHistory();

  // const savePaymentMethod = (e: any) => {
  //   e.preventDefault();

  //   if (!cardNumber) setCardNumberError(true);
  //   if (!expiryMonth) setExpiryMonthError(true);
  //   if (!expiryYear) setExpiryYearError(true);

  //   if (cardNumber) setCardNumberError(false);
  //   if (expiryMonth) setExpiryMonthError(false);
  //   if (expiryYear) setExpiryYearError(false);

  //   if (cardNumber && expiryMonth && expiryYear) {
  //     setShowPaymentMethod(true);
  //   }
  // };

  const addPaymentMethod = (e: any) => {
    e.preventDefault();
    setShowPaymentMethod(false);
  };

  const removePaymentMethod = (e: any) => {
    e.preventDefault();
    setShowPaymentMethod(false);
  };

  const fullNameCCRegExp = /^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/;
  const creditCardRegExp = /^[0-9]{16}$/;
  const expiryMonthYearRegExp = /([0-9]{2})\/([0-9]{2})/;

  // const currentMonthNumber = new Date().getMonth() + 1;
  const currentMonthNumber = ("0" + new Date().getMonth() + 1).slice(-2);
  const currentMonth = currentMonthNumber.toString();
  const currentYearNumber = new Date().getFullYear();
  const currentYear = currentYearNumber.toString().substr(-2);

  const paymentInfoSchema = yup.object().shape({
    fullNameCC: yup
      .string()
      .matches(
        fullNameCCRegExp,
        "Enter your full name as it appears on your credit card"
      )
      .required("Full name is required"),
    // cardNumber: yup
    //   .string()
    //   .matches(creditCardRegExp, "Credit card number must be 16 digits")
    //   .required("Card number is required"),
    // expiryMonth: yup
    //   .string()
    //   .test(
    //     "test-credit-card-expiration-month",
    //     "Expiry month is invalid",
    //     function (value: any) {
    //       if (value < currentMonth && this.parent.expiryYear === currentYear) {
    //         return false;
    //       } else {
    //         return true;
    //       }
    //     }
    //   )
    //   .required("Expiry month is required"),
    // expiryYear: yup.string().required("Expiry year is required"),
    expiryMonthYear: yup
      .string()
      .typeError("Not a valid expiration date. Example: MM/YY")
      .max(5, "Not a valid expiration date. Example: MM/YY")
      .matches(
        expiryMonthYearRegExp,
        "Not a valid expiration date. Example: MM/YY"
      )
      .test(
        "test-credit-card-expiration-date",
        "Invalid. Expiration date has passed.",
        (expiryMonthYear) => {
          if (!expiryMonthYear) {
            return false;
          }

          const [expMonth, expYear] = expiryMonthYear.split("/");

          if (Number(expYear) < Number(currentYear)) {
            return false;
          } else if (
            Number(expMonth) < Number(currentMonth) &&
            Number(expYear) <= Number(currentYear)
          ) {
            return false;
          }

          return true;
        }
      )
      .test(
        "test-credit-card-expiration-date",
        "Invalid expiration month",
        (expiryMonthYear) => {
          if (!expiryMonthYear) {
            return false;
          }
          // const today = new Date().getFullYear().toString().substr(-2);

          const [expMonth] = expiryMonthYear.split("/");

          if (Number(expMonth) > 12) {
            return false;
          }

          return true;
        }
      )
      .required("Expiration date is required"),
  });

  const initialValues = {
    fullNameCC: userData?.fullNameCC || "",
    creditCardNumber: "",
    // expiryMonth: currentMonth,
    // expiryYear: currentYear,
    expiryMonthYear:
      userData?.expiryMonthYear || currentMonth + "/" + currentYear,
    cvc: "",
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema: paymentInfoSchema,
    onSubmit: async (values, { resetForm }) => {
      const apiBaseUrl = process.env.REACT_APP_FIREBASE_API_BASE;
      // var data = JSON.stringify({
      //   CreditCardIds: [],
      //   CustomerIds: [],
      // });
      var config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        //getting 404 error with this call, not sure why
        const response = await axios.post(
          `${apiBaseUrl}/creditCards/getAll`,
          // data,
          config
        );
        // console.log("cc response", response);
      } catch (err: any) {
        // console.log("fetching cc data error", err.message);
        alert(err);
      }

      // await setDoc(
      //   userRef,
      //   {
      //     fullNameCC: values.fullNameCC,
      //     // creditCardNumber: values.creditCardNumber,
      //     expiryMonth: values.expiryMonth,
      //     expiryYear: values.expiryYear,
      //     expiryMonthYear: values.expiryMonthYear,
      //   },
      //   { merge: true }
      // )
      //   .then(() => {
      //     setFormSuccess(true);
      //     setTimeout(() => {
      //       history.push("/mybookings");
      //       setFormSuccess(false);
      //       resetForm();
      //     }, 4000);
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
    },
  });

  return (
    <>
      <MetaDecorator
        title="Payment Information | Annex"
        description=" Sample description"
      />
      <AuthTwoColumnLayout
        rightColumnChildren={
          <>
            <div className="annex-auth-two-column-layout__right-column-container">
              <h2 className="annex-auth-two-column-layout__title">
                Payment Information
              </h2>

              {!showPaymentMethod && (
                <form
                  className="annex-form"
                  // onSubmit={savePaymentMethod}
                  onSubmit={formikProps.handleSubmit}
                >
                  <fieldset>Payment Method</fieldset>

                  <div className="annex-form-group-container">
                    <div className="annex-form-group">
                      <label
                        className={`annex-form__label ${
                          formikProps.touched.fullNameCC &&
                          formikProps.errors.fullNameCC
                            ? "annex-form__label--error"
                            : ""
                        }`}
                      >
                        Name On Credit Card
                      </label>
                      <input
                        className="annex-form__input"
                        id="fullNameCC"
                        name="fullNameCC"
                        type="text"
                        value={formikProps.values.fullNameCC}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                      />
                    </div>
                    {formikProps.touched.fullNameCC &&
                      formikProps.errors.fullNameCC && (
                        <p className="annex-form__error-message">
                          {formikProps.errors.fullNameCC}
                        </p>
                      )}
                  </div>

                  <div className="annex-form-group-container-payment">
                    <div className="annex-form-group-payment">
                      <label
                        htmlFor="card-number-placeholder"
                        className={`annex-form-payment__label ${
                          formikProps.touched.creditCardNumber &&
                          formikProps.errors.creditCardNumber
                            ? "annex-form-payment__label--error"
                            : ""
                        }`}
                      >
                        Credit Card Number
                      </label>
                      <div
                        id="card-number-placeholder"
                        className="annex-form-payment__input"
                        style={{
                          height: "74px",
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          width: "100%",
                        }}
                      ></div>
                      <input
                        className="hidden-input"
                        id="creditCardNumber"
                        name="creditCardNumber"
                        // type="text"
                        // value={formikProps.values.cardNumber}
                        // onChange={formikProps.handleChange}
                        // onBlur={formikProps.handleBlur}
                      />
                    </div>
                    {formikProps.errors.creditCardNumber && (
                      <p className="annex-form__error-message">
                        {formikProps.errors.creditCardNumber}
                      </p>
                    )}
                  </div>

                  {/* <div className="annex-form__two-column">
                  <div className="annex-form-group-container">
                    <div className="annex-form-group">
                      <label className={`annex-form__label ${formikProps.touched.expiryMonth && formikProps.errors.expiryMonth ? 'annex-form__label--error' : ''}`}>Expiry Month</label>
                      <select 
                        className="annex-form__select" 
                        name="expiryMonth" 
                        id="expiryMonth" 
                        value={formikProps.values.expiryMonth}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                      >
                        <option value="Default" disabled>Month</option>
                        {months.map((month, index) => <option value={month} key={index}>{month}</option>)}
                      </select>
                    </div>
                    {formikProps.touched.expiryMonth && formikProps.errors.expiryMonth && <p className="annex-form__error-message">{formikProps.errors.expiryMonth}</p>}
                  </div>

                  <div className="annex-form-group-container">
                    <div className="annex-form-group">
                      <label className={`annex-form__label ${formikProps.touched.expiryYear && formikProps.errors.expiryYear ? 'annex-form__label--error' : ''}`}>Expiry Year</label>
                      <select 
                        className="annex-form__select" 
                        name="expiryYear" 
                        id="expiryYear" 
                        value={formikProps.values.expiryYear}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                      >
                        <option value="Default" disabled>Year</option>
                        {years.map((year, index) => <option value={year} key={index}>{year}</option>)}
                      </select>
                    </div>
                    {formikProps.touched.expiryYear && formikProps.errors.expiryYear && <p className="annex-form__error-message">{formikProps.errors.expiryYear}</p>}
                  </div>
                </div> */}
                  <div className="annex-form-group-container">
                    <div className="annex-form-group">
                      <label
                        className={`annex-form__label ${
                          formikProps.touched.expiryMonthYear &&
                          formikProps.errors.expiryMonthYear
                            ? "annex-form__label--error"
                            : ""
                        }`}
                      >
                        MM/YY
                      </label>
                      <input
                        className="annex-form__input"
                        id="expiryMonthYear"
                        name="expiryMonthYear"
                        type="text"
                        value={formikProps.values.expiryMonthYear}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                      />
                    </div>
                    {formikProps.touched.expiryMonthYear &&
                      formikProps.errors.expiryMonthYear && (
                        <p className="annex-form__error-message">
                          {formikProps.errors.expiryMonthYear}
                        </p>
                      )}
                  </div>
                  {/* <div className="annex-form-group-container-payment">
                    <div className="annex-form-group-payment">
                      <label
                        htmlFor="cvv-placeholder"
                        className={`annex-form-payment__label ${
                          formikProps.touched.cvc && formikProps.errors.cvc
                            ? "annex-form-payment__label--error"
                            : ""
                        }`}
                      >
                        CVC
                      </label>

                      <div
                        id="cvv-placeholder"
                        className="annex-form-payment__input"
                        style={{
                          height: "74px",
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          width: "100%",
                        }}
                      ></div>
                      <input className="hidden-input" id="cvc" name="cvc" />
                    </div>

                    {formikProps.errors.cvc && (
                      <p className="annex-form__error-message">
                        {formikProps.errors.cvc}
                      </p>
                    )}
                  </div> */}
                  <Button
                    buttontype="primary--inverse"
                    children="Add a Payment Method"
                    // cname={
                    //   !(formikProps.dirty && formikProps.isValid)
                    //     ? "disabled-btn-black"
                    //     : ""
                    // }
                    // disabled={!(formikProps.dirty && formikProps.isValid)}
                    type="submit"
                    linkTo=""
                  />
                  {formSuccess && (
                    <p className="annex-form__success-message">
                      You've successfully saved your payment information and
                      will be re-directed shortly!
                    </p>
                  )}
                </form>
              )}

              {showPaymentMethod && (
                <form className="annex-form">
                  <fieldset>Payment Method</fieldset>

                  <div className="annex-payment-method">
                    <div className="annex-payment-method__info">
                      <p className="annex-payment-method__text annex-payment-method__text--mr">
                        Visa
                      </p>
                      <p className="annex-payment-method__text">
                        <span>**** **** **** 9302</span>
                        <br />
                        <span>Expiration: 02/2025</span>
                      </p>
                    </div>
                    <button
                      className="annex-payment-method__button"
                      onClick={removePaymentMethod}
                    >
                      Remove
                    </button>
                  </div>

                  <Button
                    buttontype="primary--inverse"
                    children="Add a Payment Method"
                    onClick={addPaymentMethod}
                    linkTo=""
                  />
                </form>
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default AuthPaymentInformation;
