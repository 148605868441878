import { ReactNode } from "react"
import Icon from "../../components/Global/Icon/Icon"
import './AuthSideMenuMobileList.scss';

type AuthSideMenuMobileListProps = {
  children?: ReactNode;
  isSelectOpen?: boolean;
  onClick?: () => void;
  label?: string;
}

type AuthSideMenuMobileListItemProps = {
  label?: string;
  onClick?: () => void;
}

const AuthSideMenuMobileList = (props: AuthSideMenuMobileListProps) => {
  return (
    <div className="annex-auth-side-menu-mobile-list">
      <button className={`annex-auth-side-menu-mobile-list__button ${ props.isSelectOpen ? 'annex-auth-side-menu-mobile-list__button--active' : ''}`} onClick={props.onClick}>
        {props.label}
        <Icon name="dropdown-arrow" size={15} />
      </button>
      <div className={`annex-auth-side-menu-mobile-list__list ${props.isSelectOpen ? 'annex-auth-side-menu-mobile-list__list--acitve' : ''}`}>
        {props.children}
      </div>
    </div>
  )
}

const AuthSideMenuMobileListItem = (props: AuthSideMenuMobileListItemProps) => {
  return (
    <button className="annex-auth-side-menu-mobile-list__button" onClick={props.onClick}>{props.label}</button>
  )
}

export { AuthSideMenuMobileListItem };
export default AuthSideMenuMobileList;
