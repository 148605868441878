import { AnnexSpacesDetails } from "../EventBookingAccordion/EventBookingAccordion";

export const yourDetailsArray = [{
    className:"your-details-form-input your-details-form-input--name",
    label: "First Name",
    for: "first-name",
    labelClassName: "your-details-form-input__label",
    id:"first-name",
    inputClassName: "your-details-form-input__input",
    type: "text"
}, {
    className:"your-details-form-input your-details-form-input--name",
    label: "Last Name",
    for: "last-name",
    labelClassName: "your-details-form-input__label",
    id:"last-name",
    inputClassName: "your-details-form-input__input",
    type: "text"
}, {
    className:"your-details-form-input",
    label: "Email",
    for: "email",
    labelClassName: "your-details-form-input__label",
    id:"email",
    inputClassName: "your-details-form-input__input",
    type: "email"
}, {
    className:"your-details-form-input",
    label: "Phone Number",
    for: "phone-number",
    labelClassName: "your-details-form-input__label",
    id:"phone-number",
    inputClassName: "your-details-form-input__input",
    type: "tel",
}];

export const willThereBeFoodArray = ["yes", "no"];

export const occasionArray = ["Birthday", "Fundraiser", "Holiday Party", "Work Event"];

export const ourSpacesDescriptionsArray: AnnexSpacesDetails[] = [{
    title: "Wine Bar",
    body: "An intimate space with a secluded patio that can accommodate up to 75 guests. Cocktail tasting menu? Wine flights? Charcuterie boards? Anything you can dream up we can put together. We'll do the dishes after."
}, 
// {
//     title: "The Commons",
//     body: "The larger of our two spaces and homebase to Aunty Lucy's. The space can be booked privately for parties, corporate events, product launches and weddings. We offer a full service bar, and a stage fit for a ted talk (or karaoke.)"
// }
]
