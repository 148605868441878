import "./InfoImageGroupCarousel.scss";

import { Carousel } from "react-bootstrap";
import { useState } from "react";

// import Icon from '../Icon/Icon';

import InfoImageGroup from "../../../components/Global/InfoImageGroup/InfoImageGroup";
import InfoImageCard from "../../../components/Global/InfoImageCard/InfoImageCard";

export type InfoImageGroupCarouselProps = {
  title?: string;
  items?: any;
  experience?: boolean;
};

const InfoImageGroupCarousel = ({
  title,
  items,
  experience,
  ...props
}: InfoImageGroupCarouselProps) => {
  // const { title, items } = props;

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  // const directionButtons = (direction: string) => {
  //   return (
  //     <span
  //       aria-hidden="true"
  //       className={direction === "Next" ? "button-next-iigc" : "button-prev-iigc"}
  //     >
  //       {direction === "Next" ? (
  //         <Icon name='next-arrow' className="button-next-icon-iigc" color='black'/>
  //       ) : (
  //         <Icon name='back-arrow' className="button-prev-icon-iigc" color='black'/>
  //       )}
  //     </span>
  //   );
  // };

  return (
    <div
      className={`info-image-group-carousel-container ${
        experience && "info-image-group-carousel-container--experience"
      }`}
    >
      {title && <h4 className="info-image-group-carousel-title">{title}</h4>}
      {items && (
        <Carousel
          //  ref={ref}
          interval={null}
          // controls={true}
          indicators={false}
          nextLabel={""}
          prevLabel={""}
          activeIndex={index}
          onSelect={handleSelect}
          // nextIcon={directionButtons("Next")}
          // prevIcon={directionButtons("Previous")}
          className="iig-carousel"
        >
          {items.map((item: any, index: number) => {
            return (
              <Carousel.Item key={index}>
                <InfoImageGroup
                  leftImageSrc={item.leftImageSrc}
                  leftImageAlt={item.leftImageAlt}
                  leftImageTitle={item.leftImageTitle}
                  imageCardPosition={experience ? "bottom" : "top"}
                  rightImageSrc={item.rightImageSrc}
                  rightImageAlt={item.rightImageAlt}
                  rightImageTitle={item.rightImageTitle}
                  bottomTitle={experience ? "" : item.bottomTitle}
                  cardChildren={
                    <InfoImageCard
                      imageSrc={item.imageSrc}
                      imageAlt={item.imageAlt}
                      imageTitle={item.imageTitle}
                      mobileImageSrc={item.mobileImageSrc}
                      mobileImageAlt={item.mobileImageAlt}
                      mobileImageTitle={item.mobileImageTitle}
                      title={experience ? "" : item.title}
                      name={item.name}
                      subName={item.subName}
                      paragraph={item.paragraph}
                      buttonText={item.buttonText}
                      buttonLinkTo={item.buttonLinkTo}
                    />
                  }
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

export default InfoImageGroupCarousel;
