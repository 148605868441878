import "./JoinMailingListModal.scss";
// import { Children, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Icon from "../Global/Icon/Icon";

import JoinCommunityForm from "../JoinCommunity/JoinCommunityForm";
// import { child } from "@firebase/database";

export type JoinMailingListModalProps = {
  className?: string;
  title?: string;
  body?: string;
  showMailingListModal?: boolean;
  setShowMailingListModal: (i: boolean) => void;
  joinMailingList?: boolean;
  subscribeToMailingList?: boolean;
  subscribeToMailingListMobile?: boolean;
  children?: string;
  onClick?: (() => void) | ((e: any) => void);
};

const showJoinMailingListModal = {
  hidden: {
    opacity: 0,
    top: "-100%",
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    top: 0,
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
};

const showSubscribeModal = {
  hidden: {
    opacity: 0,
    left: "100%",
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    left: 0,
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
};

const showSubscribeMobileModal = {
  hidden: {
    opacity: 0,
    left: "-100%",
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    left: 0,
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
};

const JoinMailingListModal = ({
  className,
  title,
  body,
  showMailingListModal,
  setShowMailingListModal,
  joinMailingList,
  subscribeToMailingList,
  subscribeToMailingListMobile,
  children,
  onClick,
  ...props
}: JoinMailingListModalProps) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {showMailingListModal && (
        <motion.div
          className="join-mailing-list-modal-container"
          variants={
            joinMailingList
              ? showJoinMailingListModal
              : subscribeToMailingListMobile
                ? showSubscribeMobileModal
                : showSubscribeModal
          }
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Icon
            name="menu-close"
            size={21}
            className="join-mailing-list-modal-close"
            onClick={onClick}
          >
          </Icon>

          <JoinCommunityForm
            title={title}
            body={body}
            showMailingListModal={showMailingListModal}
            setShowMailingListModal={setShowMailingListModal}
            joinMailingList={joinMailingList}
            subscribeToMailingList={subscribeToMailingList}
            subscribeToMailingListMobile={subscribeToMailingListMobile}
            children={children}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default JoinMailingListModal;
