import contactImg from "../../assets/images/annex-contact_2.jpg";
import "./ContactUs.scss";
import MetaDecorator from "../../MetaDecorator";
import React from "react";
import GotQuestions from "../../components/GotQuestions/GotQuestions";

const ContactUs = () => {
  return (
    <div className="annex-contact-wrapper">
      <MetaDecorator title="Contact us" description="Sample description" />
      <div className="annex-contact-wrapper__wrapper-container">
        <img
          src={contactImg}
          className="annex-contact-wrapper__image-container"
          alt="contact_image"
          loading="lazy"
        />
      </div>
      <div className="annex-contact-wrapper__info">
        <p className="annex-contact-wrapper__desc-text">
          Send us a text, follow us or drop in when you are in town next. We
          love hearing from our guests, both old and new.{" "}
        </p>
        <h2 className="annex-contact-wrapper__contact-heading">Get in touch</h2>
        <p className="annex-contact-wrapper__contact-heading-text">
          + 1 647 694-9868
        </p>
        <p className="annex-contact-wrapper__contact-heading-text">
          hello@theannex.com
        </p>
        <div className="annex-contact-wrapper__got-questionsBtn">
          <GotQuestions
            children="Text Us"
            buttonHoverText="+1 647 694 9868"
            hasATag={true}
            linkTo={""}
          />
        </div>
        <h2 className="annex-contact-wrapper__contact-heading">Find us</h2>
        <p className="annex-contact-wrapper__contact-heading-text">
          296 Brunswick, Toronto
          <br />
          M5S 2M7
        </p>
      </div>
    </div>
  );
};
export default ContactUs;
