import "./NotFoundPage.scss";
import MetaDecorator from "../../MetaDecorator";
import Button from "../../components/Global/Button/Button";
import { Link } from "react-router-dom";
import ErrorIMG from "../../assets/images/404Error.svg";

const NotFoundPage = () => {
  return (
    <>
      <MetaDecorator
        title="404 Page Not Found"
        description="404 Page Not Found"
      />
      <div className="NotFoundPage">
        <h2 className="NotFoundPage__title">- 404 - </h2>
        <h2 className="NotFoundPage__title2">Uh Oh.</h2>
        <h2 className="NotFoundPage__title3">This page could not be found!</h2>
        <Link to="/">
          <Button buttontype="primary--inverse" children="Go Back" linkTo="" />
        </Link>
        <img
          className="NotFoundPage__image"
          src={ErrorIMG}
          alt="Page Not Found"
          loading="lazy"
        />
      </div>
    </>
  );
};

export default NotFoundPage;
