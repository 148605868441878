// import "bootstrap/dist/css/bootstrap.min.css";

import "./InstagramCarousel.scss";

import { Carousel } from "react-bootstrap";
import { ReactNode, useState } from "react";

import DiscoverCard from "../DiscoverCard/DiscoverCard";

import Icon from "../Global/Icon/Icon";

type InstagramCarouselProps = {
  items?: any[];
  itemsDesktop?: any[];
  selectDropDownChildren?: ReactNode;
  experience?: boolean;
};

const InstagramCarousel = (props: InstagramCarouselProps) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const directionButtons = (direction: string) => {
    return (
      <span
        aria-hidden="true"
        className={
          direction === "Next"
            ? "button-next-instagram"
            : "button-prev-instagram"
        }
      >
        {direction === "Next" ? (
          <Icon
            name="next-arrow"
            className="button-next-icon-instagram"
            color="black"
          />
        ) : (
          <Icon
            name="back-arrow"
            className="button-prev-icon-instagram"
            color="black"
          />
        )}
      </span>
    );
  };

  return (
    <>
      {props.selectDropDownChildren && <>{props.selectDropDownChildren}</>}
      <div className="instagram-carousel-container">
        {props.items && (
          <Carousel
            //  ref={ref}
            interval={null}
            controls={true}
            indicators={false}
            nextLabel={""}
            prevLabel={""}
            activeIndex={index}
            onSelect={handleSelect}
            nextIcon={directionButtons("Next")}
            prevIcon={directionButtons("Previous")}
            className="instagram-carousel mobile-only"
          >
            {props.items.map((item, index) => {
              return (
                <Carousel.Item key={index} className="instagram-carousel-item">
                  <div className="instagram-carousel-item-wrapper">
                    <DiscoverCard
                      imageSrc={item.imageSrc}
                      imageAlt={item.imageAlt}
                      imageTitle={item.imageTitle}
                      title={item.title}
                      address={item.address}
                      paragraph={item.paragraph}
                      linkTo={item.linkTo}
                      url={item.url}
                      isExternal={true}
                      experience={props.experience}
                      instagram={true}
                    />
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        )}
        {props.itemsDesktop && (
          <Carousel
            //  ref={ref}
            interval={null}
            controls={true}
            indicators={false}
            nextLabel={""}
            prevLabel={""}
            activeIndex={index}
            onSelect={handleSelect}
            nextIcon={directionButtons("Next")}
            prevIcon={directionButtons("Previous")}
            className="instagram-carousel desktop-only"
          >
            {props.itemsDesktop.map((item, index) => {
              return (
                <Carousel.Item key={index} className="instagram-carousel-item">
                  <div className="instagram-discover-cards">
                    {item.imageSrc && (
                      <DiscoverCard
                        imageSrc={item.imageSrc}
                        imageAlt={item.imageAlt}
                        imageTitle={item.imageTitle}
                        title={item.title}
                        address={item.address}
                        paragraph={item.paragraph}
                        linkTo={item.linkTo}
                        url={item.url}
                        isExternal={true}
                        experience={props.experience}
                        instagram={true}
                      />
                    )}
                    {item.imageSrc2 && (
                      <DiscoverCard
                        imageSrc={item.imageSrc2}
                        imageAlt={item.imageAlt2}
                        imageTitle={item.imageTitle2}
                        title={item.title2}
                        address={item.address2}
                        paragraph={item.paragraph2}
                        linkTo={item.linkTo2}
                        url={item.url2}
                        isExternal={true}
                        experience={props.experience}
                        instagram={true}
                      />
                    )}
                    {item.imageSrc3 && (
                      <DiscoverCard
                        imageSrc={item.imageSrc3}
                        imageAlt={item.imageAlt3}
                        imageTitle={item.imageTitle3}
                        title={item.title3}
                        address={item.address3}
                        paragraph={item.paragraph3}
                        linkTo={item.linkTo3}
                        url={item.url3}
                        isExternal={true}
                        experience={props.experience}
                        instagram={true}
                      />
                    )}
                    {/* {item.imageSrc4 && (
                      <DiscoverCard
                        imageSrc={item.imageSrc4}
                        imageAlt={item.imageAlt4}
                        imageTitle={item.imageTitle4}
                        title={item.title4}
                        address={item.address4}
                        paragraph={item.paragraph4}
                        linkTo={item.linkTo4}
                        url={item.url4}
                        isExternal={true}
                      />
                    )} */}
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        )}
      </div>
    </>
  );
};

export default InstagramCarousel;
