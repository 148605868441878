import "./Events.scss";
import EventCarousel from "../../components/EventCarousel/EventCarousel";
import BookingMenuAccordion, {
  BookingMenuAccordionItem,
} from "../../components/BookingMenu/BookingMenuAccordion";
import {
  MockBudget,
  MockType,
  eventCarouselDataDesktop,
  eventCarouselDataMobile,
} from "./EventsMockData";
import NeighbourhoodNew from "../../components/NeighbourhoodNew/NeighbourhoodNew";
// import eventsHeader from "../../assets/images/events-header.png";
import eventsHeroImage from "../../assets/images/events-page/hero-events.jpg";
import Button from "../../components/Global/Button/Button";
import CallToActionBanner from "../../components/CallToActionBanner/CallToActionBanner";
import GotQuestions from "../../components/GotQuestions/GotQuestions";
import AccordionBottomMenuMobile from "../../components/AccordionBottomMenuMobile/AccordionBottomMenuMobile";
import OutsideClickHandler from "react-outside-click-handler";
import EventBookingModal from "../../components/EventBookingModal/EventBookingModal";
import MetaDecorator from "../../MetaDecorator";
import { useState, useEffect, useRef, useContext, useMemo } from "react";

import AppContext from "../../context";

import axios from "axios";
import PageBanner from "../../components/Global/PageBanner/PageBanner";

import JoinMailingListModal from "../../components/JoinMailingListModal/JoinMailingListModal";

const Events = () => {
  const eventbriteBaseUrl = "https://www.eventbriteapi.com/v3";
  const eventbritePrivateToken = process.env.REACT_APP_EVENTBRITE_PRIVATE_TOKEN;
  const eventbriteOrganizationId =
    process.env.REACT_APP_EVENTBRITE_ORGANIZATION_ID;

  const [openMenu, setOpenMenu] = useState(false);
  const [openEventsModal, setOpenEventsModal] = useState(false);

  const [allPrivateEvents, setAllPrivateEvents] = useState<any[]>([]);
  const [privateEvents, setPrivateEvents] = useState<any[]>([]);

  const [freeEvents, setFreeEvents] = useState<any[]>([]);
  const [paidEvents, setPaidEvents] = useState<any[]>([]);

  const [showMore, setShowMore] = useState(false);

  const [heightState, setHeightState] = useState("0px");
  const [setActive, setActiveState] = useState("");
  const [typeSubTitle, setTypeSubTitle] = useState(MockType[0]);
  const [budgetSubTitle, setBudgetSubTitle] = useState(MockBudget[0]);

  const eventListRef = useRef<HTMLDivElement>(null);

  const handleToggleEventsModal = () => {
    setOpenEventsModal(!openEventsModal);
  };
  const handleCloseEventsModal = () => {
    setOpenEventsModal(false);
  };

  const [showMailingListModal, setShowMailingListModal] = useState(false);

  const handleShowMailingListModal = () => {
    setShowMailingListModal(!showMailingListModal);
  };

  const handleCloseMailingListModal = () => {
    setShowMailingListModal(false);
  };

  const fetchPrivateEvents = () => {
    let upcomingFreeEvents: any[] = [];
    let upcomingPaidEvents: any[] = [];
    let upcomingEvents: any[] = [];
    let passedEvents: any[] = [];

    // const sortByYear = (b: any, a: any) => {
    //   return new Date(a.start.local).getFullYear() - new Date(b.start.local).getFullYear();
    // }

    // const sortByMonthAndDay = (b: any, a: any) => {
    //   if(a.start.local.substr(-4) === b.start.local.substr(-4)) {
    //     return new Date(a.start.local).getFullYear() - new Date(b.start.local).getFullYear();
    //   }
    //   return new Date(a.start.local).getMonth() - new Date(b.start.local).getMonth();
    // }
    // order by chronological order
    const sortByDateAndPushToEnd = (b: any, a: any) => {
      if (new Date(a.start.local).getTime() > new Date().getTime()) {
        return 1;
      }
      return (
        new Date(a.start.local).getTime() - new Date(b.start.local).getTime()
      );
    };

    axios
      .get(
        // `${eventbriteBaseUrl}/organizations/${eventbriteOrganizationId}/events/?page_size=81&token=${eventbritePrivateToken}`
        `${eventbriteBaseUrl}/organizations/${eventbriteOrganizationId}/events/?status=live&token=${eventbritePrivateToken}`
      )

      .then((response) => {
        // setPrivateEvents(response.data.events.sort(sortByMonthAndDay));
        // setPrivateEvents(response.data.events.sort(sortByDateAndPushToEnd));
        // show only current upcoming events and hide passedEvents
        response.data.events.forEach((event: any) => {
          if (new Date(event.start.local).getTime() > new Date().getTime()) {
            upcomingEvents.push(event);
          }

          /* -------------------------------------------------------------------------- */
          /*      if privateEvents == grape juice redirect- Temporary implentation      */
          /* -------------------------------------------------------------------------- */
          if (
            event.url ===
            "https://www.eventbrite.ca/e/grape-juice-vol-6-tickets-709665276947"
          ) {
            event.url =
              "https://www.opentable.com/booking/experiences-availability?rid=1032961&restref=1032961&experienceId=195102&utm_source=external&utm_medium=referral&utm_campaign=shared";
          } else {
            passedEvents.push(event);
            // console.log("passedEvents", passedEvents);
          }
        });
        setPrivateEvents(upcomingEvents.sort(sortByDateAndPushToEnd));
        setAllPrivateEvents(upcomingEvents.sort(sortByDateAndPushToEnd));
        // console.log(response.data.events); // show data in console
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log(response.data.events);

  // response.data.events.forEach((event: any, index: number) => {
  //   if (event.is_free) {
  //     upcomingFreeEvents.push(event);
  //   }

  //   if (!event.is_free) {
  //     upcomingPaidEvents.push(event);
  //   }
  //   if (event.is_free && event.status === "live") {
  //     upcomingFreeEvents.push(event);
  //   }

  //   if (!event.is_free && event.status === "live") {
  //     upcomingPaidEvents.push(event);
  //   }
  //   if (event.status === "live") {
  //     upcomingEvents.push(event);
  //   }
  // });
  // setFreeEvents(upcomingFreeEvents);
  // setPaidEvents(upcomingPaidEvents);
  // setPrivateEvents(response.data.events);
  // setAllPrivateEvents(response.data.events);
  // setPrivateEvents(upcomingEvents);
  // setAllPrivateEvents(upcomingEvents);
  // console.log("private events", privateEvents);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const date = (date: any) => {
    const eventDate = new Date(date).toLocaleDateString("default", {
      month: "short",
      day: "numeric",
    });
    return eventDate;
  };
  const time = (date: any) => {
    const eventTime = new Date(date).toLocaleTimeString("default", {
      timeStyle: "short",
    });
    return eventTime;
  };

  const selectType = (e: any) => {
    setTypeSubTitle(e);
    setHeightState("0px");
    setActiveState("");
  };

  const selectBudget = (e: any) => {
    setBudgetSubTitle(e);
    setHeightState("0px");
    setActiveState("");
    if (e === "Free") {
      setPrivateEvents(freeEvents);
    }
    if (e === "Paid") {
      setPrivateEvents(paidEvents);
    }
    if (e === "All") {
      setPrivateEvents(allPrivateEvents);
    }
  };

  useEffect(() => {
    fetchPrivateEvents();
    console.log(privateEvents);
  }, []);

  const onClearFilter = () => {
    // setTypeSubTitle(MockType[0]);
    setBudgetSubTitle(MockBudget[0]);
    setPrivateEvents(allPrivateEvents);
  };

  const {
    size: { width },
  } = useContext(AppContext);

  const eventCarouselData = useMemo(() => {
    if (width && width < 1366) {
      return eventCarouselDataMobile;
    } else {
      return eventCarouselDataDesktop;
    }
  }, [width]);

  return (
    <>
      <MetaDecorator title="Events" description="Sample description" />

      <div className="events-container">
        <PageBanner
          title="What's Happening"
          type="Events"
          imgSrc={eventsHeroImage}
          showHeroMask={true}
        />

        <h4 className="events-container__header">The recurring things</h4>
        <EventCarousel arrayOfObjectsSrc={eventCarouselData} />
      </div>
      <div className="events-neighbourhood-container">
        <div className="events-neighbourhood-container__header-container">
          <h4
            className="events-neighbourhood-container__header"
            ref={eventListRef}
          >
            More good times are coming
          </h4>
          {/* hiding the filter */}
          {/* {privateEvents.length > 0 && (
            <div className="events-neighbourhood-container__filter-container desktop-only">
              <div className="events-filter events-filter--mobile-active events-filter events-filter__type">
              <BookingMenuAccordion
                headerTitle={"Type"}
                headerSubTitle={typeSubTitle}
                headerWidth={"type"}
                heightState={heightState}
                activeState={setActive}
                itemsChildren={
                  <>
                    {MockType.map((type, index) => 
                      <BookingMenuAccordionItem 
                        key={index} 
                        label={type} 
                        onClick={() => selectType(type)}
                      />
                    )}                       
                  </>
                }
              />
            </div>
              <div className="events-filter events-filter--mobile-active events-filter">
                <BookingMenuAccordion
                  headerTitle={"Budget"}
                  headerSubTitle={budgetSubTitle}
                  headerWidth={"budget"}
                  heightState={heightState}
                  activeState={setActive}
                  itemsChildren={
                    <>
                      {MockBudget.map((budget, index) => (
                        <BookingMenuAccordionItem
                          key={index}
                          label={budget}
                          onClick={() => {
                            selectBudget(budget);
                          }}
                        />
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          )} */}
        </div>
      </div>

      <div className="neighbourhood-items">
        {showMore &&
          privateEvents.map((event, index) => {
            return (
              <div key={index}>
                <NeighbourhoodNew
                  imgTitle={event.name.text}
                  imgSrc={event.logo?.url}
                  imgAlt={event.name.text}
                  name={event.name.text}
                  date={date(event.start.local)}
                  time={time(event.start.local)}
                  budget={event.is_free ? "Free" : "Paid"}
                  address={"296 Brunswick Ave"}
                  onClick={() => window.open(event.url)}
                />
              </div>
            );
          })}
        {!showMore &&
          privateEvents.slice(0, 3).map((event, index) => {
            return (
              <div key={index}>
                <NeighbourhoodNew
                  imgTitle={event.name.text}
                  imgSrc={event.logo.url}
                  imgAlt={event.name.text}
                  name={event.name.text}
                  date={date(event.start.local)}
                  time={time(event.start.local)}
                  budget={
                    event.is_free && event.name.text !== "Grape Juice | Vol 6"
                      ? "Free"
                      : event.name.text === "Grape Juice | Vol 6"
                      ? "$50.00"
                      : "Paid"
                  }
                  address={"296 Brunswick Ave"}
                  onClick={() => window.open(event.url)}
                />
              </div>
            );
          })}

        {privateEvents.length === 0 && (
          <div className="no-upcoming-events">
            <p className="no-upcoming-events__title">
              More events at The Annex are coming soon!
            </p>
            <p className="no-upcoming-events__text">
              Join our mailing list to find out when.
            </p>
            <Button
              buttontype="secondary"
              children={"Join"}
              onClick={handleShowMailingListModal}
              linkTo=""
            />
            <OutsideClickHandler onOutsideClick={handleCloseMailingListModal}>
              <JoinMailingListModal
                className="hide-modal"
                title={"Join the Community"}
                body={
                  "Annex is an unrivalled local community experience for creative, lifestyle, and original hospitality experiences. Join our mailing list for first access to new content, discounts and events. You may opt out at any time."
                }
                onClick={handleShowMailingListModal}
                showMailingListModal={showMailingListModal}
                setShowMailingListModal={setShowMailingListModal}
                children={"Join the Community"}
                joinMailingList={true}
              />
            </OutsideClickHandler>
          </div>
        )}
      </div>

      {privateEvents.length > 3 && (
        <div className="events-neighbourhood-container__button">
          <Button
            children={showMore ? "Show me less" : "Show me more"}
            onClick={() => {
              setShowMore(!showMore);
              showMore &&
                eventListRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
            linkTo=""
          />
        </div>
      )}

      <div className="events-action-banner">
        <CallToActionBanner
          title="Your event, our spaces."
          body="Whether it’s a birthday bash Vegas style, a quiet group dinner or the launch of your industry changing product, we got you covered. Our events coordinator will get back to you in 24 hours or less to hammer out the details. See you at The Annex."
          buttonText="Book our Space"
          onClick={handleToggleEventsModal}
        />
        <OutsideClickHandler onOutsideClick={handleCloseEventsModal}>
          <EventBookingModal
            // onClick={handleToggleEventsModal}
            openEventsModal={openEventsModal}
            setOpenEventsModal={setOpenEventsModal}
            showEventsModal={openEventsModal}
            bookOnly={false}
            setBookOnly={() => {}}
          />
        </OutsideClickHandler>
      </div>

      <div className="events-got-questions">
        <GotQuestions
          title="Get answers to your questions."
          children="Text Us"
          buttonHoverText="+1 647 694 9868"
          hasATag={true}
          linkTo=""
        />
      </div>

      {privateEvents.length > 0 && (
        <AccordionBottomMenuMobile
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          menuTitle={"Refine my choices"}
          onClearFilter={onClearFilter}
          menuOptions={[
            // <BookingMenuAccordion
            //   headerTitle={"Type"}
            //   headerSubTitle={typeSubTitle}
            //   headerWidth={"mobile-menu"}
            //   mobileActive={openMenu}
            //   // bookRoom={true}
            //   heightState={heightState}
            //   activeState={setActive}
            //   itemsChildren={
            //     <>
            //       {MockType.map((type, index) =>
            //         <BookingMenuAccordionItem
            //           key={index}
            //           label={type}
            //           onClick={() => selectType(type)}
            //         />
            //       )}
            //     </>
            //   }
            // />,

            <BookingMenuAccordion
              headerTitle={"Budget"}
              headerSubTitle={budgetSubTitle}
              headerWidth={"mobile-menu"}
              mobileActive={openMenu}
              // bookRoom={true}
              heightState={heightState}
              activeState={setActive}
              itemsChildren={
                <>
                  {MockBudget.map((budget, index) => (
                    <BookingMenuAccordionItem
                      key={index}
                      label={budget}
                      onClick={() => selectBudget(budget)}
                    />
                  ))}
                </>
              }
            />,
          ]}
        />
      )}
    </>
  );
};

export default Events;
