import './RoomAmenitiesSection.scss';
import ButtonArrow from '../../components/Global/ButtonArrow/ButtonArrow';

import RoomAmenitiesModal from '../../components/RoomAmenitiesModal/RoomAmenitiesModal';
import OtherConsiderationsModal from '../../components/OtherConsiderationsModal/OtherConsiderationsModal';

import OutsideClickHandler from 'react-outside-click-handler';

import { useState } from 'react';


export type RoomAmenitiesSectionProps = {
	imgSrc1?: string;
	imgAlt1?: string;
	imgSrc2?: string;
	imgAlt2?: string;
	imgSrc3?: string;
	imgAlt3?: string;
}

const RoomAmenitiesSection = (props: RoomAmenitiesSectionProps) => {

	const {imgSrc1, imgAlt1, imgSrc2, imgAlt2, imgSrc3, imgAlt3} = props;

	const roomAmenityImages = [{
		src: imgSrc1,
		alt: imgAlt1,
		className: "room-amenities__image-1"
	}, {
		src: imgSrc2,
		alt: imgAlt2,
		className: "room-amenities__image-2"
	}, {
		src: imgSrc3,
		alt: imgAlt3,
		className: "room-amenities__image-3 desktop-only"
	},
	]

	const [showAmenities, setShowAmenities] = useState(false);
	const [showConsiderations, setShowConsiderations] = useState(false);

	const handleShowAmenities = () => {
		setShowAmenities(!showAmenities);
	}
	const handleShowConsiderations = () => {
		setShowConsiderations(!showConsiderations);
	}
	const handleCloseAmenities = () => {
		setShowAmenities(false);
	}
	const handleCloseConsiderations = () => {
		setShowConsiderations(false);
	}

	return (
		<div className='room-amenities'>
			<OutsideClickHandler onOutsideClick={handleCloseAmenities}>
				<RoomAmenitiesModal className="hide-modal" onClick={handleShowAmenities} 
			showAmenities={showAmenities}/>
			</OutsideClickHandler>
			<OutsideClickHandler onOutsideClick={handleCloseConsiderations}>
				<OtherConsiderationsModal  className="hide-modal" onClick={handleShowConsiderations} showConsiderations={showConsiderations}/>
			</OutsideClickHandler>
			<div className="see-amenities">
				<h4 className="see-amenities__text">Our rooms come with everything you need and nothing you don’t</h4>
				<ButtonArrow buttontype="inverse" children="See what matters" iconColor="#FFFFFF" linkTo="#" onClick={handleShowAmenities}/>
			</div>
			{roomAmenityImages.map((image, index) => {
				return (
					<div className={image.className} key={index}>
						<img src={image.src} alt={image.alt} className="room-amenities__image" key={index} loading="lazy"/>
					</div>
				)
			})}
			<div className="considerations">
				<h4 className="considerations__text">Other Considerations</h4>
				<ButtonArrow buttontype="main" children="Learn More" iconColor="#000000" linkTo="#" onClick={handleShowConsiderations}/>
			</div>

		</div>
	)
}

export default RoomAmenitiesSection;
