import "./RoomAmenitiesModal.scss";

import { motion, AnimatePresence } from "framer-motion";

import Icon from "../Global/Icon/Icon";

export type RoomAmenitiesModalProps = {
  className?: string;
  showAmenities?: boolean;
  onClick?: (() => void) | ((e: any) => void);
};

const roomAmenities = [
  {
    icon: <Icon name="e-checkin" color="#FFFFFF" className="e-checkin-size" />,
    text: "Mobile check-in - that means no front desk.",
  },
  {
    icon: <Icon name="wifi" color="#FFFFFF" className="wifi-size" />,
    text: "Mega-super-ultra fast wifi.",
  },
  {
    icon: <Icon name="ipad" color="#FFFFFF" className="ipad-size" />,
    text: "iPad Pro - binge in bed, just like at home.",
  },
  {
    icon: <Icon name="binge" color="#FFFFFF" className="binge-size" />,
    text: "Netflix accounts ready to watch.",
  },
  {
    icon: (
      <Icon
        name="selfServeClosets"
        color="#FFFFFF"
        className="self-serve-closets-size"
      />
    ),
    text: "Self serve closets (unlimited pillows).",
  },
  {
    icon: <Icon name="bath" color="#FFFFFF" className="bath-size" />,
    text: "Best in the biz bath products from Grown Alchemist.",
  },
  {
    icon: <Icon name="bedding" color="#FFFFFF" className="bedding-size" />,
    text: "The best mattresses. (Don’t tell the 5-star hotels we have the same beds)",
  },
  {
    icon: <Icon name="ac-heat" color="#FFFFFF" className="ac-heat-size" />,
    text: "Temperature control - Toronto can be temperamental, we’ve got the power to match.",
  },
  {
    icon: <Icon name="records" color="#FFFFFF" className="records-size" />,
    text: "Record player - because you can’t beat the sound of vinyl. Curated record selections by local legends Sonic Boom.",
  },
  {
    icon: <Icon name="bff" color="#FFFFFF" className="bff-size" />,
    text: "24:7 BFF: Anything else you need is just a text away with our host team.",
  },
];

const showModal = {
  hidden: {
    opacity: 0,
    left: "-100%",
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    left: 0,
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
};

const RoomAmenitiesModal = ({
  className,
  showAmenities,
  onClick,
  ...props
}: RoomAmenitiesModalProps) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {showAmenities && (
        <motion.div
          className="room-amenities-modal-container"
          variants={showModal}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Icon
            name="menu-close"
            size={21}
            className="room-amenities-modal-close"
            onClick={onClick}
          ></Icon>
          <div className="room-amenities-modal-inner-container">
            <Icon name="annex-icon" className="room-amenities-annex-icon" />
            <div className="room-amenities-modal">
              <div className="room-amenities-modal__title-container">
                <h1 className="room-amenities-modal__title-top">
                  Every room comes with
                </h1>
                {/* <h1 className="room-amenities-modal__title-bottom">comes with</h1> */}
              </div>
              <div className="amenities-container">
                {roomAmenities.map((item, index) => {
                  return (
                    <div className="amenity" key={index}>
                      <div className="amenity__icon">{item.icon}</div>
                      <p className="amenity__text">{item.text}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RoomAmenitiesModal;
