import "./TheBigOne.scss";
import { useContext, useMemo, useState } from "react";
import HeroImage from "../../../assets/images/the-big-one-room-page/hero-image.jpg";
import CabinetSink from "../../../assets/images/the-big-one-room-page/cabinet-sink.png";
import BookStack from "../../../assets/images/the-big-one-room-page/book-stack.png";
import DeskLamp from "../../../assets/images/the-big-one-room-page/desk-lamp.png";
import RecordPlayerOpen from "../../../assets/images/the-big-one-room-page/record-player-open.png";
import VaseDesk from "../../../assets/images/the-big-one-room-page/vase-desk.png";
import Shower from "../../../assets/images/the-big-one-room-page/shower.png";
// import PicturesOnHeadboard from '../../../assets/images/the-big-one-room-page/pictures-on-headboard.png';
// import RoomServiceTag from '../../../assets/images/the-big-one-room-page/room-service-tag.png';

import RecordPlayer from "../../../assets/images/the-big-one-room-page/amenities-section/record-player.jpg";
import Door from "../../../assets/images/the-big-one-room-page/amenities-section/door.jpg";
import HandWash from "../../../assets/images/the-big-one-room-page/amenities-section/hand-wash.jpg";

// import OpenCupboard from '../../../assets/images/the-big-one-room-page/open-cupboard.png';
import LadyWithDog from "../../../assets/images/the-big-one-room-page/lady-with-dog.jpg";
// import LadyRecord from '../../../assets/images/the-big-one-room-page/lady-record.png';
import WomanPainting from "../../../assets/images/the-big-one-room-page/woman-painting.jpg";

import RoomHeroSection from "../RoomHeroSection";
import RoomFeatureBar from "../RoomFeatureBar";
import RoomImagesSection, { RoomImage } from "../RoomImagesSection";
import RoomAmenitiesSection from "../RoomAmenitiesSection";
import GuestQuote from "../GuestQuote";
import RoomTwoImageSection from "../RoomTwoImageSection";
import AnnexDiscoverCardCarousel from "../AnnexDiscoverCardCarousel";
import CallToActionBanner from "../../../components/CallToActionBanner/CallToActionBanner";
import RoomBookingMenu from "../../../components/BookingMenu/RoomBookingMenu";
import RoomBookingMenuMobile from "../../../components/BookingMenuMobile/RoomBookingMenuMobile";
import AppContext from "../../../context";
import MetaDecorator from "../../../MetaDecorator";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import { doc } from "@firebase/firestore";
import { useParams } from "react-router-dom";
import { IconsType } from "../../../assets/icons";

// const RoomImages: RoomImage[] = [
//   {
//     src: CabinetSink,
//     alt: "Cabinet and bathroom sink",
//     width: "100%",
//     height: 809,
//   },
//   {
//     src: BookStack,
//     alt: "Stack of books",
//     width: "100%",
//     height: 476,
//   },
//   {
//     src: DeskLamp,
//     alt: "Desk with lamp",
//     width: "100%",
//     height: 573,
//   },
//   {
//     src: RecordPlayerOpen,
//     alt: "Record player",
//     width: "100%",
//     height: 405,
//     observe: true,
//   },
//   {
//     src: VaseDesk,
//     alt: "Vase on desk",
//     width: "100%",
//     height: 809,
//   },
//   {
//     src: Shower,
//     alt: "shower",
//     width: "100%",
//     height: 476,
//   },
// ];

// const RoomImagesTablet: RoomImage[] = [
//   {
//     src: CabinetSink,
//     alt: "Cabinet and bathroom sink",
//     width: "100%",
//     height: 557.5,
//   },
//   {
//     src: BookStack,
//     alt: "Stack of books",
//     width: "100%",
//     height: 487.425287,
//   },
//   {
//     src: DeskLamp,
//     alt: "Desk with lamp",
//     width: "100%",
//     height: 386.5,
//   },
//   {
//     src: RecordPlayerOpen,
//     alt: "Record player",
//     width: "100%",
//     height: 269,
//     observe: true,
//   },
//   {
//     src: VaseDesk,
//     alt: "Vase on desk",
//     width: "100%",
//     height: 560.5,
//   },
//   {
//     src: Shower,
//     alt: "shower",
//     width: "100%",
//     height: 325.5,
//   },
// ];

// const RoomImagesMobile: RoomImage[] = [
//   {
//     src: CabinetSink,
//     alt: "Cabinet and bathroom sink",
//     width: "100%",
//     height: 306,
//   },
//   {
//     src: BookStack,
//     alt: "Stack of books",
//     width: "100%",
//     height: 238,
//   },
//   {
//     src: DeskLamp,
//     alt: "Desk with lamp",
//     width: "100%",
//     height: 200,
//   },
//   {
//     src: RecordPlayerOpen,
//     alt: "Record player",
//     width: "100%",
//     height: 133,
//     observe: true,
//   },
//   {
//     src: VaseDesk,
//     alt: "Vase on desk",
//     width: "100%",
//     height: 312,
//   },
//   {
//     src: Shower,
//     alt: "shower",
//     width: "100%",
//     height: 175,
//   },
// ];

export default function TheBigOne() {
  const { id } = useParams<{ id: string }>();

  const roomRef = doc(useFirestore(), `rooms/${id}`);
  const { data: room } = useFirestoreDocDataOnce(roomRef, { idField: "id" });

  const RoomImages: RoomImage[] = [
    {
      src: room?.imageUrls[0] || CabinetSink,
      alt: "Cabinet and bathroom sink",
      width: "100%",
      height: 809,
    },
    {
      src: room?.imageUrls[1] || BookStack,
      alt: "Stack of books",
      width: "100%",
      height: 476,
    },
    {
      src: room?.imageUrls[2] || DeskLamp,
      alt: "Desk with lamp",
      width: "100%",
      height: 573,
    },
    {
      src: room?.imageUrls[3] || RecordPlayerOpen,
      alt: "Record player",
      width: "100%",
      height: 405,
      observe: true,
    },
    {
      src: room?.imageUrls[4] || VaseDesk,
      alt: "Vase on desk",
      width: "100%",
      height: 809,
    },
    {
      src: room?.imageUrls[5] || Shower,
      alt: "shower",
      width: "100%",
      height: 476,
    },
  ];

  const RoomImagesTablet: RoomImage[] = [
    {
      src: room?.imageUrls[0] || CabinetSink,
      alt: "Cabinet and bathroom sink",
      width: "100%",
      height: 557.5,
    },
    {
      src: room?.imageUrls[1] || BookStack,
      alt: "Stack of books",
      width: "100%",
      height: 487.425287,
    },
    {
      src: room?.imageUrls[2] || DeskLamp,
      alt: "Desk with lamp",
      width: "100%",
      height: 386.5,
    },
    {
      src: room?.imageUrls[3] || RecordPlayerOpen,
      alt: "Record player",
      width: "100%",
      height: 269,
      observe: true,
    },
    {
      src: room?.imageUrls[4] || VaseDesk,
      alt: "Vase on desk",
      width: "100%",
      height: 560.5,
    },
    {
      src: room?.imageUrls[5] || Shower,
      alt: "shower",
      width: "100%",
      height: 325.5,
    },
  ];

  const RoomImagesMobile: RoomImage[] = [
    {
      src: room?.imageUrls[0] || CabinetSink,
      alt: "Cabinet and bathroom sink",
      width: "100%",
      height: 306,
    },
    {
      src: room?.imageUrls[1] || BookStack,
      alt: "Stack of books",
      width: "100%",
      height: 238,
    },
    {
      src: room?.imageUrls[2] || DeskLamp,
      alt: "Desk with lamp",
      width: "100%",
      height: 200,
    },
    {
      src: room?.imageUrls[3] || RecordPlayerOpen,
      alt: "Record player",
      width: "100%",
      height: 133,
      observe: true,
    },
    {
      src: room?.imageUrls[4] || VaseDesk,
      alt: "Vase on desk",
      width: "100%",
      height: 312,
    },
    {
      src: room?.imageUrls[5] || Shower,
      alt: "shower",
      width: "100%",
      height: 175,
    },
  ];

  const {
    size: { width },
  } = useContext(AppContext);
  const [showBookingMenu, setShowBookingMenu] = useState<boolean>(false);
  const onImageSectionInView = (inView: boolean) => {
    if (inView) {
      return setShowBookingMenu(true);
    }

    setShowBookingMenu(false);
  };

  const roomImagesArray = useMemo(() => {
    if (width && width < 768) {
      return room && RoomImagesMobile;
    } else if (width && width >= 768 && width < 1366) {
      return room && RoomImagesTablet;
    } else {
      return room && RoomImages;
    }
  }, [width, room]);

  const guestsIconName: IconsType = useMemo(() => {
    if (room && room.bedCount === 2) {
      return "twoGuestsPerson";
    }

    return "fourGuestsPerson";
  }, [room]);

  return (
    <>
      {room && (
        <div className="the-big-one">
          <MetaDecorator title="The Big One" description="Sample description" />
          <RoomHeroSection
            imgSrc={room.mainImageUrl}
            title={room.name}
            description={room.description}
          />

          <RoomFeatureBar
            guestsIconName={guestsIconName}
            guestIconNumber={room.bedCount}
            guestsText={`Up to ${room.bedCount} guests`}
            sqftIconName="330sqft"
            sqftIconNumber={330}
            sqftText={`${room.sqft} sqft`}
            bedIconName="queen"
            bedIconNumber={"two"}
            bedText={room.bedInfo}
          />
          <RoomImagesSection
            onObserverImageInView={onImageSectionInView}
            imgs={roomImagesArray}
          />
          {/* <RoomAmenitiesSection
				imgSrc1={PicturesOnHeadboard}
				imgAlt1="Pictures on headboard"
				imgSrc2={RoomServiceTag}
				imgAlt2="Room service tag on door handle"
				imgSrc3={OpenCupboard}
				imgAlt3="Cupboard open to show fridge"
			/> */}
          <RoomAmenitiesSection
            imgSrc1={Door}
            imgAlt1="Room door"
            imgSrc2={HandWash}
            imgAlt2="Hand wash bottle"
            imgSrc3={RecordPlayer}
            imgAlt3="Record player"
          />
          <GuestQuote
            text="Don’t waste your “you up?” text on someone who won’t respond. Text us. We’re always up and we have wine."
            // name=""
          />
          <RoomTwoImageSection
            leftImgSrc={LadyWithDog}
            leftImgAlt="Lady with dog"
            rightImgSrc={WomanPainting}
            rightImgAlt="Woman painting"
          />
          <AnnexDiscoverCardCarousel
            title="Life at the Annex"
            infoHeight={"310px"}
          />
          <CallToActionBanner
            title="You’ve got questions and we’ve got cocktails. Oh and answers."
            body="Text us 24/7 with your best knock knock joke or if you need anything for your stay. Our host team is your annex BFF, and we won’t leave you on read."
            buttonText="Send us a message"
            buttonHoverText="+1 647 694 9868"
            buttonClassName="sms-button"
            hasATag={true}
          />
          {/* Commenting out booking menus for now until after launch - starting here */}

          {/* {showBookingMenu && (
            <RoomBookingMenu
              className={showBookingMenu && "room-booking-menu--reveal"}
            />
          )}
          {showBookingMenu && (
            <RoomBookingMenuMobile
              className={showBookingMenu && "room-booking-menu--reveal"}
            />
          )} */}

          {/* Commenting out booking menus for now until after launch - ending here */}
        </div>
      )}
    </>
  );
}
