import BookingImage from '../../assets/images/mybooking.jpg';

const mockUpcomingItems: any[] = [
  {
    imgSrc: BookingImage,
    imgAlt: "My Booking",
    imgTitle: "My Booking",
    name: "The Big One",
    date: "July 31, 2021 - Aug 2, 2021",
    linkTo: "/",
    linkText: "Booking Details"
  },
  {
    imgSrc: BookingImage,
    imgAlt: "My Booking",
    imgTitle: "My Booking",
    name: "The Big One",
    date: "July 31, 2021 - Aug 2, 2021",
    linkTo: "/",
    linkText: "Booking Details"
  },
  {
    imgSrc: BookingImage,
    imgAlt: "My Booking",
    imgTitle: "My Booking",
    name: "The Big One",
    date: "July 31, 2021 - Aug 2, 2021",
    linkTo: "/",
    linkText: "Booking Details"
  }
];

const mockPastItems: any[] = [
  {
    imgSrc: BookingImage,
    imgAlt: "My Booking",
    imgTitle: "My Booking",
    name: "The Big One",
    date: "July 31, 2021 - Aug 2, 2021",
    linkTo: "/",
    linkText: "Booking Details"
  },
  {
    imgSrc: BookingImage,
    imgAlt: "My Booking",
    imgTitle: "My Booking",
    name: "The Big One",
    date: "July 31, 2021 - Aug 2, 2021",
    linkTo: "/",
    linkText: "Booking Details"
  },
];

const mockCancelledItems: any[] = [
  {
    imgSrc: BookingImage,
    imgAlt: "My Booking",
    imgTitle: "My Booking",
    name: "The Big One",
    date: "July 31, 2021 - Aug 2, 2021",
    linkTo: "/",
    linkText: "Booking Details"
  },
];

const mockMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const mockYears  = ['2021', '2022', '2023', '2024', '2025', '2026'];

export { mockUpcomingItems, mockPastItems, mockCancelledItems, mockMonths, mockYears };
