import './BookingCalendar.scss';
import Calendar, { 
    CalendarProps, 
    OnChangeDateCallback, 
    OnChangeDateRangeCallback 
} from 'react-calendar';
import Icon from '../Icon/Icon';

export type BookingCalendarProps = CalendarProps & {
    cname?: string;
    isActive: boolean;
    bookRoom?: boolean;
    bookEvent?: boolean;
    whiteText?: boolean;
    validationProps?: any;
    onBlur?: any;
    name?: string;
    onChange?: OnChangeDateCallback | OnChangeDateRangeCallback | Date[] | undefined;
}

const BookingCalendar = (props: BookingCalendarProps) => {
    const { onChange, value, cname, isActive, bookEvent, whiteText, name } = props;
    return (
        <Calendar
            onChange={onChange}
            value={value}
            minDate={new Date()}
            // className={isActive ? 'calendar calendar--active' : 'calendar'}
            // className={bookRoom ? (isActive ? 'calendar-book-room calendar-book-room--active' : 'calendar-book-room') : (isActive ? `${whiteText && "white-text"} calendar calendar--active` : `${whiteText && "white-text"} calendar`)}
            className={ isActive ? `${cname} ${cname}--active ${whiteText && "white-text"}` : `${cname} ${whiteText && "white-text"}`}
            selectRange={!bookEvent && true}
            tileClassName={bookEvent ? 'calendar__tile-event' : 'calendar__tile'}
            showNeighboringMonth={true}
            returnValue='range'
            nextLabel={<Icon name='dropdown-arrow' className='calendar-next'/>}
            prevLabel={<Icon name='dropdown-arrow' className='calendar-prev'/>}
            next2Label=''
            prev2Label=''
            name={name}
            formatMonth={(locale, date) => date.toLocaleString(locale, { month: 'short' })}
            // onClickDay={(value, event) => console.log("date value", value)}
            {...props}
        />
    )
};

export default BookingCalendar;
