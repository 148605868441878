import {ReactNode} from 'react';
import './ImageTextColumn.scss';

type ImageTextColumnProps = {
	title?: string;
	ImageSrc?: string;
	ImageAlt?: string;
	ImageTitle?: string;
	paragraphChildren?: ReactNode;
	imagePosition?: 'left' | 'right';
};

const ImageTextColumn = (props: ImageTextColumnProps) => {
	return (
		<div className={`annex-image-text-column-info annex-image-text-column-info--image-position-${props.imagePosition}`}>
			<div className="annex-image-text-column-info__info">
				<div className="annex-image-text-column-info__info-container">
					{props.title && <h2 className="annex-image-text-column-info__name">{props.title}</h2>}
					{props.paragraphChildren && <>{props.paragraphChildren}</>}
				</div>
			</div>
			{
				props.ImageSrc &&
				<div className="annex-image-text-column-info__image-container">
					<img
						className="annex-image-text-column-info__image"
						src={props.ImageSrc}
						alt={props.ImageAlt}
						title={props.ImageTitle}
						loading="lazy"
					/>
				</div>
			}
		</div>
	)
}

ImageTextColumn.defaultProps = {
	imagePosition: 'right'
}

export default ImageTextColumn;
