import "./App.scss";

import { Route, Switch } from "react-router-dom";
import { getFirestore } from "firebase/firestore";
import {
  AuthProvider,
  DatabaseProvider,
  FirestoreProvider,
  useFirebaseApp,
} from "reactfire";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

import LandingPage from "./pages/LandingPage/LandingPage";
import LiveLocal from "./pages/LiveLocal/LiveLocal";
import Experience from "./pages/Experience/Experience";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import AuthMenuMobile from "./pages/Auth/AuthMenuMobile";

import Moments from "./pages/Moments/Moments";
import Rooms from "./pages/Rooms/Rooms";
import TheBigOne from "./pages/RoomsPages/TheBigOne/TheBigOne";
import AuthCreate from "./pages/Auth/AuthCreate/AuthCreate";
import AuthLogin from "./pages/Auth/AuthLogin/AuthLogin";
import AuthLoginCreate from "./pages/Auth/AuthLoginCreate/AuthLoginCreate";
import Careers from "./pages/Careers/Careers";
import ContactUs from "./pages/ContactUs/ContactUs";
import Events from "./pages/Events/Events";
import FAQs from "./pages/FAQs/FAQs";
import FoodAndDrinks from "./pages/FoodAndDrinks/FoodAndDrinks";
import Neighbourhood from "./pages/Neighbourhood/Neighbourhood";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import AuthAccount from "./pages/Auth/AuthAccount/AuthAccount";
import AuthLoginSecurity from "./pages/Auth/AuthLoginSecurity/AuthLoginSecurity";
import AuthMyBookings from "./pages/Auth/AuthMyBookings/AuthMyBookings";
import AuthPaymentInformation from "./pages/Auth/AuthPaymentInformation/AuthPaymentInformation";
import AuthSpecialOffers from "./pages/Auth/AuthSpecialOffers/AuthSpecialOffers";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import BookingStay from "./pages/Booking/BookingStay/BookingStay";
import AppContext from "./context";
import { useWindowSize } from "./hooks/useWindowSize";
import ScrollToTop from "./ScrollToTop";
import { useEffect, useState } from "react";
import Blog from "./pages/Blog/Blog";
import BlogDetail from "./pages/Blog/BlogDetail";

function App() {
  const windowSize = useWindowSize();
  const firestoreInstance = getFirestore(useFirebaseApp());
  const app = useFirebaseApp();
  const database = getDatabase(app);
  const auth = getAuth(app);

  const [initializedBooking, setInitializedBooking] = useState<boolean>(false);
  const [mewsAPI, setMewsAPI] = useState<any>(false);
  useEffect(() => {
    if (!initializedBooking) {
      //@ts-ignore
      Mews.Distributor({
        configurationIds: [
          process.env.REACT_APP_MEWS_CONFIG_ID,
        ],
      }, (api: any) => setMewsAPI(api));
      setInitializedBooking(true);
    }
  }, [setInitializedBooking, initializedBooking]);
  
  const openBookingEngine = (startDate?: Date, endDate?: Date) => {
    if (mewsAPI) {
      mewsAPI.setStartDate(startDate);
      mewsAPI.setEndDate(endDate);
      mewsAPI.setLanguageCode("en-US")
      mewsAPI.setCurrencyCode("CAD")
      mewsAPI.open();
    }
  }

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <AuthProvider sdk={auth}>
        <DatabaseProvider sdk={database}>
          <AppContext.Provider
            value={{
              size: windowSize,
              bookingAPI: mewsAPI,
              openBookingEngine,
            }}
          >
            {/* <BrowserRouter> */}
            <Header />
            <AuthMenuMobile />
            <ScrollToTop />
            <Switch>
              <Route path="/" exact component={LandingPage} />
              <Route path="/live-local" exact component={LiveLocal} />
              <Route path="/experience" exact component={Experience} />
              <Route path="/create" exact component={AuthCreate} />
              <Route path="/login" exact component={AuthLogin} />
              <Route path="/login-create" exact component={AuthLoginCreate} />
              <Route path="/mybookings" exact component={AuthMyBookings} />
              <Route
                path="/specialoffers"
                exact
                component={AuthSpecialOffers}
              />
              <Route path="/account" exact component={AuthAccount} />
              <Route
                path="/loginsecurity"
                exact
                component={AuthLoginSecurity}
              />
              <Route
                path="/paymentinformation"
                exact
                component={AuthPaymentInformation}
              />
              <Route path="/camera-roll" exact component={Moments} />
              <Route path="/rooms" exact component={Rooms} />
              <Route path="/rooms/:id" exact component={TheBigOne} />
              <Route path="/careers" exact component={Careers} />
              <Route path="/contact-us" exact component={ContactUs} />
              <Route path="/events" exact component={Events} />
              <Route path="/faqs" exact component={FAQs} />
              <Route path="/eat-and-drink" exact component={FoodAndDrinks} />
              <Route path="/neighbourhood" exact component={Neighbourhood} />
              <Route path="/privacy-policy" exact component={PrivacyPolicy} />
              <Route path="/the-lobby" exact component={Blog} />
              <Route path="/the-lobby/:id" exact component={BlogDetail} />
              <Route
                path="/terms-and-conditions"
                exact
                component={TermsAndConditions}
              />
              <Route path="/booking-stay" exact component={BookingStay} />
              <Route
                path="/booking-stay/:id/:numberOfGuests/:startUtc/:endUtc"
                exact
                component={BookingStay}
              />
              <Route component={NotFoundPage} />
            </Switch>
            <Footer />
            {/* </BrowserRouter> */}
          </AppContext.Provider>
        </DatabaseProvider>
      </AuthProvider>
    </FirestoreProvider>
  );
}

export default App;
