import { useHistory } from "react-router-dom";
import Button from "../../../components/Global/Button/Button";
import "./AuthLoginCreate.scss";

import * as yup from "yup";
import { useFormik } from "formik";

import MetaDecorator from "../../../MetaDecorator";
import { collection, getDocs } from "@firebase/firestore";
import { useFirestore } from "reactfire";

const AuthLoginCreate = () => {
  const history = useHistory();
  const db = useFirestore();

  const loginCreateSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const initialValues = {
    email: "",
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema: loginCreateSchema,
    onSubmit: async (values, { resetForm }) => {
      const querySnapshot = await getDocs(collection(db, "customers"));
      querySnapshot.forEach((doc) => {
        if (doc.data().email === values.email) {
          history.push("/login");
        } else {
          history.push("/create");
        }
        resetForm();
      });
    },
  });

  return (
    <>
      <MetaDecorator
        title="Log In or Create Account | Annex"
        description=" Sample description"
      />
      <div className="auth-container">
        <h2 className="annex-form-title">Log In or Create Account</h2>
        <form className="annex-form" onSubmit={formikProps.handleSubmit}>
          <div className="annex-form-group">
            <label
              className={`annex-form__label ${
                formikProps.touched.email && formikProps.errors.email
                  ? "annex-form__label--error"
                  : ""
              }`}
            >
              Email
            </label>
            <input
              className="annex-form__input"
              id="email"
              name="email"
              type="text"
              value={formikProps.values.email}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
            />
          </div>
          {formikProps.touched.email && formikProps.errors.email && (
            <p className="annex-form__error-message">
              {formikProps.errors.email}
            </p>
          )}
          <Button
            buttontype="primary--inverse"
            children="Continue"
            cname={
              !(formikProps.dirty && formikProps.isValid)
                ? "disabled-btn-black"
                : ""
            }
            disabled={!(formikProps.dirty && formikProps.isValid)}
            type="submit"
            linkTo=""
          />
        </form>
      </div>
    </>
  );
};

export default AuthLoginCreate;
