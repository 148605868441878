import "./NavMenu.scss";

import { useState } from "react";

import { Link } from "react-router-dom";

import Button from "../Global/Button/Button";
import Icon from "../Global/Icon/Icon";

import { NavMenuItems } from "./NavMenuItems";

import OutsideClickHandler from "react-outside-click-handler";
import EventBookingModal from "../../components/EventBookingModal/EventBookingModal";
import JoinMailingListModal from "../JoinMailingListModal/JoinMailingListModal";

export type NavMenuProps = {
  isActive?: boolean;
  onClick?: (() => void) | ((e: any) => void);
};

const NavMenu = ({ isActive, onClick, ...props }: NavMenuProps) => {
  const [bookOnly, setBookOnly] = useState(false);

  const [openEventsModal, setOpenEventsModal] = useState(false);

  const handleToggleEventsModal = () => {
    setOpenEventsModal(!openEventsModal);
  };
  const handleCloseEventsModal = () => {
    setOpenEventsModal(false);
  };

  const [showMailingListModal, setShowMailingListModal] = useState(false);

  const handleShowMailingListModal = () => {
    setShowMailingListModal(!showMailingListModal);
  };

  const handleCloseMailingListModal = () => {
    setShowMailingListModal(false);
  };

  return (
    <nav className={isActive ? "nav-menu nav-menu-active" : "nav-menu"}>
      <div className="menu-middle">
        {NavMenuItems.map((item, index) => {
          return (
            <Link
              to={item.link}
              className="menu-middle__item"
              onClick={onClick}
              key={index}
            >
              {item.name}
            </Link>
          );
        })}
      </div>
      <div className="menu-bottom desktop-only">
        <div className="menu-location">
          <p className="menu-bottom-title">Visit</p>
          <p className="menu-bottom-text">296 Brunswick, Toronto</p>
          <p className="menu-bottom-text">M5S 2M7</p>
        </div>
        <div className="menu-contact">
          <p className="menu-bottom-title">Get in Touch </p>
          <p className="menu-bottom-text">
            <a href="tel:+ 1 647 694-9868">+ 1 647 694-9868</a>
          </p>
          <p className="menu-bottom-text">hello@theannex.com</p>
          <p className="menu-contact-link">
            <Link
              to="/contact-us"
              className="menu-contact-link__link"
              onClick={onClick}
            >
              Contact Us
            </Link>
          </p>
        </div>
        <div className="menu-contact">
          <p className="menu-bottom-title">Event Booking</p>
          <p
            className="menu-bottom-booking"
            onClick={() => {
              setBookOnly(true);
              handleToggleEventsModal();
            }}
          >
            Book Our Space
          </p>
        </div>
        <div className="menu-social">
          <p className="menu-bottom-title">Follow Us</p>
          <div className="menu-social-links">
            <a
              href="https://www.instagram.com/annexhotels/"
              target="_blank"
              rel="noreferrer"
              className="menu-social-links__icon"
              onClick={onClick}
            >
              <Icon
                name="instagram"
                className="menu-social-links__icon--instagram"
              />
            </a>
            <a
              href="https://www.facebook.com/annexhotels/"
              target="_blank"
              rel="noreferrer"
              className="menu-social-links__icon"
              onClick={onClick}
            >
              <Icon
                name="facebook"
                className="menu-social-links__icon--facebook"
              />
            </a>
            <a
              href="https://medium.com/the-annex"
              target="_blank"
              rel="noreferrer"
              className="menu-social-links__icon"
              onClick={onClick}
            >
              <Icon name="medium" className="menu-social-links__icon--medium" />
            </a>
          </div>
        </div>
        <div
          className="menu-contact"
          style={{
            marginRight: 0,
          }}
        >
          <p className="menu-bottom-title">Catch up in</p>
          <p className="menu-contact-link">
            <Link
              to="/the-lobby"
              className="menu-contact-link__link"
              onClick={onClick}
            >
              The Lobby
            </Link>
          </p>
        </div>
      </div>
      <div className="menu-footer-mobile-container mobile-only">
        <div className="footer-mobile-contact">
          <div className="footer-mobile-contact-address">
            <div className="footer-mobile-contact-address__text">
              <a href="tel:+ 1 647 694-9868">+ 1 647 694-9868</a>
            </div>
            <div className="footer-mobile-contact-address__text">
              <a href="mailto:hello@theannex.com">hello@theannex.com</a>
            </div>
            <div className="footer-mobile-contact-address__text">
              296 Brunswick, Toronto M5S 2M7
            </div>
            <p
              className="menu-bottom-booking"
              onClick={() => {
                setBookOnly(true);
                handleToggleEventsModal();
              }}
            >
              Book Our Space
            </p>
          </div>
          <div className="footer-mobile-contact-social">
            <a
              href="https://www.instagram.com/annexhotels/"
              target="_blank"
              rel="noreferrer"
              className="menu-social-links__icon"
              onClick={onClick}
            >
              <Icon
                name="instagram"
                color="#000000"
                className="footer-mobile-contact-social__instagram"
              />
            </a>
            <a
              href="https://www.facebook.com/annexhotels/"
              target="_blank"
              rel="noreferrer"
              className="menu-social-links__icon"
              onClick={onClick}
            >
              <Icon
                name="facebook"
                color="#000000"
                className="footer-mobile-contact-social__facebook"
              />
            </a>
            <a
              href="https://medium.com/the-annex"
              target="_blank"
              rel="noreferrer"
              className="menu-social-links__icon"
              onClick={onClick}
            >
              <Icon
                name="medium"
                color="#000000"
                className="footer-mobile-contact-social__medium"
              />
            </a>
          </div>
        </div>
        <div className="footer-mobile-links-container">
          <div className="footer-mobile-links footer-mobile-links-left">
            <Link
              to="/terms-and-conditions"
              className="footer-mobile-links__text"
              onClick={onClick}
            >
              Terms & Conditions
            </Link>
            <Link
              to="/faqs"
              className="footer-mobile-links__text"
              onClick={onClick}
            >
              FAQs
            </Link>
            <Link
              to="/contact-us"
              className="footer-mobile-links__text"
              onClick={onClick}
            >
              Contact Us
            </Link>
          </div>
          <div className="footer-mobile-links">
            <Link
              to="#"
              className="footer-mobile-links__text"
              onClick={onClick}
            >
              Powered by The Annex
            </Link>
            <Link
              to="/careers"
              className="footer-mobile-links__text"
              onClick={onClick}
            >
              Careers
            </Link>
          </div>
        </div>
        <div className="footer-mobile-subscribe">
          {/* hide the newsletter */}
          {/* <p className="footer-mobile-subscribe__title">Subscribe</p>
          <p className="footer-mobile-subscribe__body">
            Sign up to learn about events, news and updates from Annex and the
            experience
          </p>
          <Button
            buttontype="primary--inverse"
            children="Subscribe"
            cname="footer-mobile-subscribe__button"
            onClick={handleShowMailingListModal}
            linkTo=""
          /> */}
          <OutsideClickHandler onOutsideClick={handleCloseMailingListModal}>
            <JoinMailingListModal
              className="hide-modal"
              title={"Subscribe"}
              body={
                "Provide your email, and we will provide you with our deepest, darkest secrets. And some discounts. Probably some events too."
              }
              onClick={handleShowMailingListModal}
              showMailingListModal={showMailingListModal}
              setShowMailingListModal={setShowMailingListModal}
              children={"Subscribe"}
              subscribeToMailingListMobile={true}
            />
          </OutsideClickHandler>
        </div>

        <p className="footer-mobile-the-lobby-text">
          Catch up in{" "}
          <Link
            to="/the-lobby"
            className="footer-mobile-the-lobby-link"
            onClick={onClick}
          >
            The Lobby
          </Link>
        </p>

        <p className="footer-mobile-copyright-text">
          &copy; The Annex. All Rights Reserved.{" "}
          <Link
            to="/privacy-policy"
            className="footer-mobile-p-policy-link"
            onClick={onClick}
          >
            Privacy Policy
          </Link>
        </p>
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          handleCloseEventsModal();
          setBookOnly(false);
        }}
      >
        <EventBookingModal
          openEventsModal={openEventsModal}
          setOpenEventsModal={setOpenEventsModal}
          showEventsModal={openEventsModal}
          bookOnly={bookOnly}
          setBookOnly={setBookOnly}
        />
      </OutsideClickHandler>
    </nav>
  );
};

export default NavMenu;
