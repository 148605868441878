import { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthSideMenuDesktopList, { AuthSideMenuDesktopListItem } from './AuthSideMenuDesktopList';
// import AuthSideMenuMobileList, { AuthSideMenuMobileListItem } from './AuthSideMenuMobileList';
import './AuthTwoColumnLayout.scss';

type AuthTwoColumnLayoutProps = {
  leftColumnChildren?: ReactNode;
  rightColumnChildren?: ReactNode;
};

const AuthTwoColumnLayout = (props: AuthTwoColumnLayoutProps) => {
  // const [currentListValue, setCurrentListValue] = useState('');
  // const [isSelectOpen, setSelectOpen] = useState<boolean>(false);
  const [booking, setBooking] = useState<boolean>(false);
  const [specialOffers, setSpecialOffers] = useState<boolean>(false);
  const [account, setAccount] = useState<boolean>(false);
  const [loginSecurity, setLoginSecurity] = useState<boolean>(false);
  const [paymentInformation, setPaymentInformation] = useState<boolean>(false);  

  const history = useHistory();
  
  // const toggleSelect = () => {
  //   let newValue = isSelectOpen;
  //   newValue = !newValue;
  //   setSelectOpen(newValue);
  // }

  // const closeMenu = () => {
  //   setSelectOpen(false);
  // }

  const resetMenus = () => {
    setBooking(false);
    setSpecialOffers(false);
    setAccount(false);
    setLoginSecurity(false);
    setPaymentInformation(false);
  }

  const checkCurrentPage = () => {
    resetMenus();

    if (window.location.pathname === '/mybookings') {
      // setCurrentListValue('My Bookings'); 
      setBooking(true);
    }

    if (window.location.pathname === '/specialoffers') {
      // setCurrentListValue('My Special Offers'); 
      setSpecialOffers(true);
    }

    if (window.location.pathname === '/account') {
      // setCurrentListValue('My Account'); 
      setAccount(true);
    }

    if (window.location.pathname === '/loginsecurity') {
      // setCurrentListValue('My Login & Security'); 
      setLoginSecurity(true);
    }

    if (window.location.pathname === '/paymentinformation') {
      // setCurrentListValue('My Payments Information'); 
      setPaymentInformation(true);
    }
  }

  const redirectPage = (url: string) => {
    history.push(url);
  }

  useEffect(() => {
    checkCurrentPage();
    
    return () => {}
  });

  return (
    <div className="annex-auth-two-column-layout">
      <div className="annex-auth-two-column-layout__container">
        <div className="annex-auth-two-column-layout__left-column">

        {/* <OutsideClickHandler onOutsideClick={closeMenu}>
          <AuthSideMenuMobileList
            label={currentListValue}
            isSelectOpen={isSelectOpen}
            onClick={toggleSelect}
          >
            <AuthSideMenuMobileListItem 
              label="My Bookings" 
              onClick={() => [toggleSelect(), redirectPage('/mybookings')]}
            ></AuthSideMenuMobileListItem>
            <AuthSideMenuMobileListItem 
              label="Special Offers" 
              onClick={() => [toggleSelect(), redirectPage('/specialoffers')]}
            ></AuthSideMenuMobileListItem>
            <AuthSideMenuMobileListItem 
              label="Account" 
              onClick={() => [toggleSelect(), redirectPage('/account')]}
            ></AuthSideMenuMobileListItem>
            <AuthSideMenuMobileListItem 
              label="Login & Security" 
              onClick={() => [toggleSelect(), redirectPage('/loginsecurity')]}
            ></AuthSideMenuMobileListItem>
            <AuthSideMenuMobileListItem 
              label="Payment Information" 
              onClick={() => [toggleSelect(), redirectPage('/paymentinformation')]}
            ></AuthSideMenuMobileListItem>
          </AuthSideMenuMobileList>
          </OutsideClickHandler> */}

          <AuthSideMenuDesktopList>
            <AuthSideMenuDesktopListItem 
              label="My Bookings" 
              isActive={booking} 
              onClick={() => redirectPage('/mybookings')}
            ></AuthSideMenuDesktopListItem>
            <AuthSideMenuDesktopListItem 
              label="Special Offers" 
              isActive={specialOffers} 
              onClick={() => redirectPage('/specialoffers')}
            ></AuthSideMenuDesktopListItem>
            <AuthSideMenuDesktopListItem 
              label="Account"
              isActive={account}
              onClick={() => redirectPage('/account')}
            ></AuthSideMenuDesktopListItem>
            <AuthSideMenuDesktopListItem 
              label="Login & Security" 
              isActive={loginSecurity} 
              onClick={() => redirectPage('/loginsecurity')}
            ></AuthSideMenuDesktopListItem>
            <AuthSideMenuDesktopListItem 
              label="Payment Information" 
              isActive={paymentInformation} 
              onClick={() => redirectPage('/paymentinformation')}
            ></AuthSideMenuDesktopListItem>
          </AuthSideMenuDesktopList>

        </div>
        <div className="annex-auth-two-column-layout__right-column">
          {props.rightColumnChildren && <>{props.rightColumnChildren}</>}
        </div>
      </div>
    </div>
  )
}

export default AuthTwoColumnLayout;
