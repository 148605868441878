import { IconsType } from '../../assets/icons';
import './BookingRoomInfo.scss';
import Icon from '../../components/Global/Icon/Icon';

type BookingRoomInfoProps = {
  imgSrc?: string;
  imgAlt?: string;
  imgTitle?: string;
  title?: string;
  bedSizeIcon: IconsType;
  bedSize?: string;
  roomSizeIcon: IconsType;
  roomSize?: string;
  guestIcon: IconsType;
  guest?: string;
};

const BookingRoomInfo = (props: BookingRoomInfoProps) => {
  return (
    <div className="booking-room-info">
      <img 
        className="booking-room-info__image" 
        src={props.imgSrc} 
        alt={props.imgAlt} 
        title={props.imgTitle} 
        loading="lazy"
      />
      <div className="booking-room-info__content">
        <div className="booking-room-info__info">
          <p className="booking-room-info__title">{props.title}</p>
        </div>
        <div className="booking-room-info__icon-text-container">
        <div className="booking-room-info__info">
          <div className="booking-room-info__icon--container">
          <Icon 
            className="booking-room-info__icon booking-room-info__icon--bed" 
            name={props.bedSizeIcon} 
            color="#004C45" 
          />
          </div>
          <p className="booking-room-info__text">{props.bedSize}</p>
        </div>
        <div className="booking-room-info__info">
         <div className="booking-room-info__icon--container">
          <Icon 
            className="booking-room-info__icon booking-room-info__icon--room" 
            name={props.roomSizeIcon} 
            color="#004C45" 
          />
          </div>
          <p className="booking-room-info__text">{props.roomSize}</p>
        </div>
        <div className="booking-room-info__info">
         <div className="booking-room-info__icon--container">
          <Icon 
            className="booking-room-info__icon booking-room-info__icon--guest" 
            name={props.guestIcon} 
            color="#004C45" 
          />
          </div>
          <p className="booking-room-info__text">{props.guest}</p>
        </div>
        </div>
      </div>
    </div>
  )
}

export default BookingRoomInfo;
