import MILOU from '../../assets/images/neighbourhood/Milou.jpg';
import IMANISHI from '../../assets/images/neighbourhood/Imanishi.jpg';
import PIANO from '../../assets/images/neighbourhood/Piano.jpg';
import SUGO from '../../assets/images/neighbourhood/Sugo.jpg';
import LAMBOS from '../../assets/images/neighbourhood/Lambos.jpg';
import HANMOTO from '../../assets/images/neighbourhood/Hanmoto.jpg';
import MANITA from '../../assets/images/neighbourhood/Manita.jpg';
import GRACE from '../../assets/images/neighbourhood/Grace.jpg';
import FOURTHMAN from '../../assets/images/neighbourhood/Fourth-man.jpg';
import QUETZAL from '../../assets/images/neighbourhood/Quetzal.jpg';
import GIULIETTA from '../../assets/images/neighbourhood/Giulietta.jpg';
import MALLO from '../../assets/images/neighbourhood/Malo.jpg';
import ROBINSON from '../../assets/images/neighbourhood/Robinson.jpg';
import RAVAL from '../../assets/images/neighbourhood/Bar-Raval.jpg';
import COLLECTIVEARTS from '../../assets/images/neighbourhood/Collective-arts.jpg';
import BARMORDECHAI from '../../assets/images/neighbourhood/Mordechai.jpg';
import MILKYS from '../../assets/images/neighbourhood/Milkys.jpg';
import PILOT from '../../assets/images/neighbourhood/Pilot.jpg';
import OLIVIAS from '../../assets/images/neighbourhood/Olivias.jpg';
import SAM from '../../assets/images/neighbourhood/Sam.jpg';
import LOST_FOUND from '../../assets/images/neighbourhood/Lost_Found.jpg';
import SCOOP from '../../assets/images/neighbourhood/Scoop.jpg';
import BLUE_BUTTON_SHOP from '../../assets/images/neighbourhood/Blue_Button_Shop.jpg';
import SIZE from '../../assets/images/neighbourhood/Size.jpg';
import MONOCLE from '../../assets/images/neighbourhood/Monocle.jpg';
import SUPERETTE from '../../assets/images/neighbourhood/Superette.jpg';
import FAWN from '../../assets/images/neighbourhood/Fawn.jpg';
import MEJURI from '../../assets/images/neighbourhood/Mejuri.jpg';
import BMV_BOOKS from '../../assets/images/neighbourhood/Bmv_Books.jpg';
import REIGNING_CHAMP from '../../assets/images/neighbourhood/Reigning.jpg';
import UNCLE_OTIS from '../../assets/images/neighbourhood/Uncle_Otis.jpg';
import TOWN_BARBER from '../../assets/images/neighbourhood/Town_barber.jpg';

import { NeighbourhoodCarouselDesktopType, NeighbourhoodCarouselType } from '../../models/NeighbourhoodCarousel';
import { NeighbourhoodMapMarker } from '../../models/NeighbourhoodMapMarker';

const mockBudgets  = ['5 - 10 $', '10 - 20 $', '20 - 30 $', '30 - 40 $', '40 - 50 $', '50 - 100 $'];

// Carousels
const NeighbourhoodFoods: NeighbourhoodCarouselType[] = [
  {
    imageSrc: MILOU,
    imageAlt: "Milou Neighbourhood image",
    imageTitle: "Milou Neighbourhood image",
    title: "MILOU",
    address: "1375 Dundas st w.",
    paragraph: "Airdrop yourself to a Parisian cafe in a short Uber ride. Don’t forget to buy a tote.",
    linkTo: "https://www.instagram.com/milou.to/?hl=en",
  },
  {
    imageSrc: IMANISHI,
    imageAlt: "Imanishi Neighbourhood image",
    imageTitle: "Imanishi Neighbourhood image",
    title: "IMANISHI",
    address: "1330 Dundas",
    paragraph: "Our favourite izakaya style eatery in the city. Their food is Tokyo homestyle cooking in the best way. Don’t forget the Sake.",
    linkTo: "https://www.imanishi.ca/",
  },
  {
    imageSrc: PIANO,
    imageAlt: "Piano Neighbourhood image",
    imageTitle: " Piano Neighbourhood image",
    title: "Piano",
    address: "88 Harbord St",
    paragraph: "Two things that go excellently together: Pizza and wine. Piano Piano has the best of the both.",
    linkTo: "https://www.pianopianotherestaurant.com/",
  },
  {
    imageSrc: SUGO,
    imageAlt: "Sugo Neighbourhood image",
    imageTitle: "Sugo Neighbourhood image",
    title: "SUGO",
    address: "1281 Bloor St W",
    paragraph: " Homestyle italian cooking surrounded by vintage boxing posters and tattoo clad servers. Big portions and even bigger hearts. Don’t sleep on Sugo!",
    linkTo: 'https://www.instagram.com/sugotoronto/?hl=en',
  },
  {
    imageSrc: LAMBOS,
    imageAlt: "Lambos Neighbourhood image",
    imageTitle: "Lambos Neighbourhood image",
    title: "LAMBO'S",
    address: "176 Bellwoods",
    paragraph: "Deli sandwiches good enough to write home about. Call ahead to skip the line. These guys know what they’re doing. Turkey Pesto for all!",
    linkTo: "https://www.lambosdeli.ca/",
  },
  {
    imageSrc: HANMOTO,
    imageAlt: "Hanmoto Neighbourhood image",
    imageTitle: "Hanmoto Neighbourhood image",
    title: "HANMOTO ",
    address: "2 Lakeview Ave",
    paragraph: "A tiny izakaya style restaurant that let’s their food do the talking. A certified cool kids establishment." +
        " Don’t be shocked if you catch your favourite Nas tune while chomping a Dino Wing",
    linkTo: "https://www.instagram.com/hanm0t0/?hl=en",
  },
  {
    imageSrc: MANITA,
    imageAlt: "Manita Neighbourhood image",
    imageTitle: "Manita Neighbourhood image",
    title: "MANITA",
    address: "210 Ossington",
    paragraph: "A quaint spot to try wines you’ve never heard of. Manita on Ossington is cafe, grocery and bar. And does all of them well.",
    linkTo: "https://www.manita.ca/",
  },
  {
    imageSrc: GRACE,
    imageAlt: "Grace Neighbourhood image",
    imageTitle: "Grace Neighbourhood image",
    title: "SAVING GRACE",
    address: "907 Dundas St W",
    paragraph: "Saving Grace has brunch on lock. A rotating menu based on what’s fresh.Your hangover is no match for them.",
    linkTo: 'https://www.instagram.com/savinggraceto/?hl=en',
  },
  {
    imageSrc: FOURTHMAN,
    imageAlt: "Fourth man Neighbourhood image",
    imageTitle: "Fourth man Neighbourhood image",
    title: "FOURTH MAN IN THE FIRE",
    address: "823 Dundas St W",
    paragraph: "Pizza. Done like it should be. Fourth Man in the Fire is a must stop for pizza lovers in our city.",
    linkTo: 'https://www.thefourthmaninthefirepizzeria.com/',
  },
  {
    imageSrc: QUETZAL,
    imageAlt: "Quetzel Neighbourhood image",
    imageTitle: "Quetzel Neighbourhood image",
    title: "QUETZAL",
    address: "419 college St. ",
    paragraph: "Luxurious Mexican food cooked over open flame. A beautiful interior, rich with a smokey smell and complemented with " +
        "a cheek tequila cocktail. Take an uber and have a couple. ",
    linkTo: 'https://www.quetzaltoronto.com/info',
  },
  {
    imageSrc: GIULIETTA,
    imageAlt: "Giulietta Neighbourhood image",
    imageTitle: "Giulietta Neighbourhood image",
    title: "GIULIETTA",
    address: "972 College St",
    paragraph: "New World Italian. Skip lunch if you have a reservation here, you’ll be happy you left space." +
        " Giulietta’s menu is full of classics you know and new age meals you’d never expect.",
    linkTo: 'https://giu.ca/',
  },
  {
    imageSrc: MALLO,
    imageAlt: "Mallo Neighbourhood image",
    imageTitle: "Mallo Neighbourhood image",
    title: "MALLO",
    address: "785 Bathurst St",
    paragraph: "After you’ve exhausted your free coffee at the hotel, take a stroll down to Mallo for your next fix." +
        " You’ll catch local artists playing in the evening and the neighbourhood folks working all day.",
    linkTo: 'https://www.mallo.ca/',
  },
];

const NeighbourhoodFoodsDesktop: NeighbourhoodCarouselDesktopType[] = [
  {
    imageSrc: NeighbourhoodFoods[0].imageSrc,
    imageAlt: NeighbourhoodFoods[0].imageAlt,
    imageTitle: NeighbourhoodFoods[0].imageTitle,
    title: NeighbourhoodFoods[0].title,
    address: NeighbourhoodFoods[0].address,
    paragraph: NeighbourhoodFoods[0].paragraph,
    linkTo: NeighbourhoodFoods[0].linkTo,

    imageSrc2: NeighbourhoodFoods[1].imageSrc,
    imageAlt2: NeighbourhoodFoods[1].imageAlt,
    imageTitle2: NeighbourhoodFoods[1].imageTitle,
    title2: NeighbourhoodFoods[1].title,
    address2: NeighbourhoodFoods[1].address,
    paragraph2: NeighbourhoodFoods[1].paragraph,
    linkTo2: NeighbourhoodFoods[1].linkTo,

    imageSrc3: NeighbourhoodFoods[2].imageSrc,
    imageAlt3: NeighbourhoodFoods[2].imageAlt,
    imageTitle3: NeighbourhoodFoods[2].imageTitle,
    title3: NeighbourhoodFoods[2].title,
    address3: NeighbourhoodFoods[2].address,
    paragraph3: NeighbourhoodFoods[2].paragraph,
    linkTo3: NeighbourhoodFoods[2].linkTo,

    imageSrc4: NeighbourhoodFoods[3].imageSrc,
    imageAlt4: NeighbourhoodFoods[3].imageAlt,
    imageTitle4: NeighbourhoodFoods[3].imageTitle,
    title4: NeighbourhoodFoods[3].title,
    address4: NeighbourhoodFoods[3].address,
    paragraph4: NeighbourhoodFoods[3].paragraph,
    linkTo4: NeighbourhoodFoods[3].linkTo,
  },
  {
    imageSrc: NeighbourhoodFoods[4].imageSrc,
    imageAlt: NeighbourhoodFoods[4].imageAlt,
    imageTitle: NeighbourhoodFoods[4].imageTitle,
    title: NeighbourhoodFoods[4].title,
    address: NeighbourhoodFoods[4].address,
    paragraph: NeighbourhoodFoods[4].paragraph,
    linkTo: NeighbourhoodFoods[4].linkTo,

    imageSrc2: NeighbourhoodFoods[5].imageSrc,
    imageAlt2: NeighbourhoodFoods[5].imageAlt,
    imageTitle2: NeighbourhoodFoods[5].imageTitle,
    title2: NeighbourhoodFoods[5].title,
    address2: NeighbourhoodFoods[5].address,
    paragraph2: NeighbourhoodFoods[5].paragraph,
    linkTo2: NeighbourhoodFoods[5].linkTo,

    imageSrc3: NeighbourhoodFoods[6].imageSrc,
    imageAlt3: NeighbourhoodFoods[6].imageAlt,
    imageTitle3: NeighbourhoodFoods[6].imageTitle,
    title3: NeighbourhoodFoods[6].title,
    address3: NeighbourhoodFoods[6].address,
    paragraph3: NeighbourhoodFoods[6].paragraph,
    linkTo3: NeighbourhoodFoods[6].linkTo,

    imageSrc4: NeighbourhoodFoods[7].imageSrc,
    imageAlt4: NeighbourhoodFoods[7].imageAlt,
    imageTitle4: NeighbourhoodFoods[7].imageTitle,
    title4: NeighbourhoodFoods[7].title,
    address4: NeighbourhoodFoods[7].address,
    paragraph4: NeighbourhoodFoods[7].paragraph,
    linkTo4: NeighbourhoodFoods[7].linkTo,
  },
  {
    imageSrc: NeighbourhoodFoods[8].imageSrc,
    imageAlt: NeighbourhoodFoods[8].imageAlt,
    imageTitle: NeighbourhoodFoods[8].imageTitle,
    title: NeighbourhoodFoods[8].title,
    address: NeighbourhoodFoods[8].address,
    paragraph: NeighbourhoodFoods[8].paragraph,
    linkTo: NeighbourhoodFoods[8].linkTo,

    imageSrc2: NeighbourhoodFoods[9].imageSrc,
    imageAlt2: NeighbourhoodFoods[9].imageAlt,
    imageTitle2: NeighbourhoodFoods[9].imageTitle,
    title2: NeighbourhoodFoods[9].title,
    address2: NeighbourhoodFoods[9].address,
    paragraph2: NeighbourhoodFoods[9].paragraph,
    linkTo2: NeighbourhoodFoods[9].linkTo,

    imageSrc3: NeighbourhoodFoods[10].imageSrc,
    imageAlt3: NeighbourhoodFoods[10].imageAlt,
    imageTitle3: NeighbourhoodFoods[10].imageTitle,
    title3: NeighbourhoodFoods[10].title,
    address3: NeighbourhoodFoods[10].address,
    paragraph3: NeighbourhoodFoods[10].paragraph,
    linkTo3: NeighbourhoodFoods[10].linkTo,

    imageSrc4: NeighbourhoodFoods[11].imageSrc,
    imageAlt4: NeighbourhoodFoods[11].imageAlt,
    imageTitle4: NeighbourhoodFoods[11].imageTitle,
    title4: NeighbourhoodFoods[11].title,
    address4: NeighbourhoodFoods[11].address,
    paragraph4: NeighbourhoodFoods[11].paragraph,
    linkTo4: NeighbourhoodFoods[11].linkTo,
  },
];

const NeighbourhoodCocktails: NeighbourhoodCarouselType[] = [
  {
    imageSrc: ROBINSON,
    imageAlt: "Robinson Neighbourhood image",
    imageTitle: "Robinson Neighbourhood image",
    title: "MRS. ROBINSON",
    address: "574 College st. ",
    paragraph: "Our favourite cocktail bar spinning vinyl classics all night. The vibes are right at Mrs Robinson.",
    linkTo: "https://www.mrsrobinsontoronto.com/",
  },
  {
    imageSrc: RAVAL,
    imageAlt: " Bar Raval Neighbourhood image",
    imageTitle: "Bar Raval Neighbourhood image",
    title: "Bar Raval",
    address: "505 College St",
    paragraph: "Don’t come for the exterior because the inside of Bar Raval will blow you away. Transport yourself into a hand carved, all encompassing room with share plates that just don’t quit.",
    linkTo: "https://www.thisisbarraval.com/",
  },
  {
    imageSrc: COLLECTIVEARTS,
    imageAlt: "Collective arts Neighbourhood image",
    imageTitle: "Collective arts Neighbourhood image",
    title: "COLLECTIVE ARTS",
    address: "77 Dundas st W",
    paragraph: "Originally from Hamilton, Collective Art’s Brewery newest outpost features custom beers you can’t find anywhere else. Fill up your mini fridge at the annex with their bottle shop.",
    linkTo: "https://collectiveartsbrewing.com/breweries/toronto-brewery/",
  },
  {
    imageSrc: PIANO,
    imageAlt: "Piano Neighbourhood image",
    imageTitle: " Piano Neighbourhood image",
    title: "Piano",
    address: "88 Harbord st ",
    paragraph: "Two things that go excellently together: Pizza and wine. Piano Piano has the best of the both.",
    linkTo: "https://www.pianopianotherestaurant.com/",
  },
  {
    imageSrc: BARMORDECHAI,
    imageAlt: "Bar Mordechai Neighbourhood image",
    imageTitle: "Bar Mordechai Neighbourhood image",
    title: "BAR MORDECHAI",
    address: "1272 Dundas St W",
    paragraph: "Inspired by the works of Wes Anderson, Bar Mordechai is a moody cocktail bar you won’t be in a rush to leave.",
    linkTo: "https://www.barmordecai.com/",
  },
  {
    imageSrc: MANITA,
    imageAlt: "Manita Neighbourhood image",
    imageTitle: "Manita Neighbourhood image",
    title: "MANITA",
    address: "210 Ossington",
    paragraph: "A quaint spot to try wines you’ve never heard of. Manita on Ossington is cafe, grocery and bar. And does all of them well.",
    linkTo: "https://www.manita.ca/",
  },
];

const NeighbourhoodCocktailsDesktop: NeighbourhoodCarouselDesktopType[] = [
  {
    imageSrc: NeighbourhoodCocktails[0].imageSrc,
    imageAlt: NeighbourhoodCocktails[0].imageAlt,
    imageTitle: NeighbourhoodCocktails[0].imageTitle,
    title: NeighbourhoodCocktails[0].title,
    address: NeighbourhoodCocktails[0].address,
    paragraph: NeighbourhoodCocktails[0].paragraph,
    linkTo: NeighbourhoodCocktails[0].linkTo,

    imageSrc2: NeighbourhoodCocktails[1].imageSrc,
    imageAlt2: NeighbourhoodCocktails[1].imageAlt,
    imageTitle2: NeighbourhoodCocktails[1].imageTitle,
    title2: NeighbourhoodCocktails[1].title,
    address2: NeighbourhoodCocktails[1].address,
    paragraph2: NeighbourhoodCocktails[1].paragraph,
    linkTo2: NeighbourhoodCocktails[1].linkTo,

    imageSrc3: NeighbourhoodCocktails[2].imageSrc,
    imageAlt3: NeighbourhoodCocktails[2].imageAlt,
    imageTitle3: NeighbourhoodCocktails[2].imageTitle,
    title3: NeighbourhoodCocktails[2].title,
    address3: NeighbourhoodCocktails[2].address,
    paragraph3: NeighbourhoodCocktails[2].paragraph,
    linkTo3: NeighbourhoodCocktails[2].linkTo,

    imageSrc4: NeighbourhoodCocktails[3].imageSrc,
    imageAlt4: NeighbourhoodCocktails[3].imageAlt,
    imageTitle4: NeighbourhoodCocktails[3].imageTitle,
    title4: NeighbourhoodCocktails[3].title,
    address4: NeighbourhoodCocktails[3].address,
    paragraph4: NeighbourhoodCocktails[3].paragraph,
    linkTo4: NeighbourhoodCocktails[3].linkTo,
  },
  {
    imageSrc: NeighbourhoodCocktails[4].imageSrc,
    imageAlt: NeighbourhoodCocktails[4].imageAlt,
    imageTitle: NeighbourhoodCocktails[4].imageTitle,
    title: NeighbourhoodCocktails[4].title,
    address: NeighbourhoodCocktails[4].address,
    paragraph: NeighbourhoodCocktails[4].paragraph,
    linkTo: NeighbourhoodCocktails[4].linkTo,

    imageSrc2: NeighbourhoodCocktails[5].imageSrc,
    imageAlt2: NeighbourhoodCocktails[5].imageAlt,
    imageTitle2: NeighbourhoodCocktails[5].imageTitle,
    title2: NeighbourhoodCocktails[5].title,
    address2: NeighbourhoodCocktails[5].address,
    paragraph2: NeighbourhoodCocktails[5].paragraph,
    linkTo2: NeighbourhoodCocktails[5].linkTo,
  },
];

const NeighbourhoodCoffees: NeighbourhoodCarouselType[] = [
  {
    imageSrc: MILKYS,
    imageAlt: "Milky's Neighbourhood image",
    imageTitle: "Milky's Neighbourhood image",
    title: "MILKY’S",
    address: "760 Dundas st W",
    paragraph: "Come for the coffee, stay for the Instagram. Our friends at Milky’s have one of the most beautiful shops in the city.",
    linkTo: "https://milkys.ca/",
  },
  {
    imageSrc: PILOT,
    imageAlt:"Pilot Neighbourhood image",
    imageTitle: "Pilot Neighbourhood image",
    title:"PILOT COFFEE ROASTERS",
    address:"117 Ossington (and more)",
    paragraph: "A Toronto staple, Pilot coffee is locally roasted and served out of this aesthetic shop on Ossington. ",
    linkTo: "https://www.pilotcoffeeroasters.com/locations/ossington-ave/",
  },
  {
    imageSrc: OLIVIAS,
    imageAlt: "Olivia's Neighbourhood image",
    imageTitle: "Olivia's Neighbourhood image",
    title: "OLIVIA’S GARDEN",
    address:"569 Crawford St",
    paragraph: "A beautiful shop that’s one part coffee, one part flowers. Just the ideal spot to start your day.",
    linkTo: "https://www.oliviasgarden.ca/le-cafe",
  },
  {
    imageSrc: SAM,
    imageAlt: "Sam James Neighbourhood image",
    imageTitle: "Sam James Neighbourhood image",
    title: "SAM JAMES COFFEE BAR",
    address: "297 Harbord St (& more)",
    paragraph:"A Toronto Coffee staple. Locations all over the city, you’ll catch the who’s who in our city sipping cappuccinos on the SJCB benches. ",
    linkTo: 'https://www.samjamescoffeebar.com/pages/harbord-shop',
  },
];

const NeighbourhoodCoffeesDesktop: NeighbourhoodCarouselDesktopType[] = [
  {
    imageSrc: NeighbourhoodCoffees[0].imageSrc,
    imageAlt: NeighbourhoodCoffees[0].imageAlt,
    imageTitle: NeighbourhoodCoffees[0].imageTitle,
    title: NeighbourhoodCoffees[0].title,
    address: NeighbourhoodCoffees[0].address,
    paragraph: NeighbourhoodCoffees[0].paragraph,
    linkTo: NeighbourhoodCoffees[0].linkTo,

    imageSrc2: NeighbourhoodCoffees[1].imageSrc,
    imageAlt2: NeighbourhoodCoffees[1].imageAlt,
    imageTitle2: NeighbourhoodCoffees[1].imageTitle,
    title2: NeighbourhoodCoffees[1].title,
    address2: NeighbourhoodCoffees[1].address,
    paragraph2: NeighbourhoodCoffees[1].paragraph,
    linkTo2: NeighbourhoodCoffees[1].linkTo,

    imageSrc3: NeighbourhoodCoffees[2].imageSrc,
    imageAlt3: NeighbourhoodCoffees[2].imageAlt,
    imageTitle3: NeighbourhoodCoffees[2].imageTitle,
    title3: NeighbourhoodCoffees[2].title,
    address3: NeighbourhoodCoffees[2].address,
    paragraph3: NeighbourhoodCoffees[2].paragraph,
    linkTo3: NeighbourhoodCoffees[2].linkTo,

    imageSrc4: NeighbourhoodCoffees[3].imageSrc,
    imageAlt4: NeighbourhoodCoffees[3].imageAlt,
    imageTitle4: NeighbourhoodCoffees[3].imageTitle,
    title4: NeighbourhoodCoffees[3].title,
    address4: NeighbourhoodCoffees[3].address,
    paragraph4: NeighbourhoodCoffees[3].paragraph,
    linkTo4: NeighbourhoodCoffees[3].linkTo,
  },
];

const NeighbourhoodShopping: NeighbourhoodCarouselType[] = [
  {
    imageSrc: LOST_FOUND,
    imageAlt: "Lost and Found Neighbourhood image",
    imageTitle: "Lost and Found Neighbourhood image",
    title: "LOST & FOUND",
    address: "12 Ossington Ave",
    paragraph: "A menswear shop that carries our favourite brands and keeps The annex team looking sharp." +
        " Their sneaker collection is the city’s best kept secret. SHH!",
    linkTo: "https://shoplostfound.com/",
  },
  {
    imageSrc: SCOOP,
    imageAlt: "Latest Scoop Neighbourhood image",
    imageTitle: "Latest Scoop Neighbourhood image",
    title: "The Latest Scoop",
    address: "115 Ossington",
    paragraph: "Their name says it all, and they aren’t lying. Best in women’s trends and a staple of the Ossington strip. ",
    linkTo: "https://thelatestscoop.ca/",
  },
  {
    imageSrc: BLUE_BUTTON_SHOP,
    imageAlt: "Blue Button Neighbourhood image",
    imageTitle: "Blue Button Neighbourhood image",
    title: "Blue Button Shop",
    address: "1499 Dundas St",
    paragraph: "The best men’s and women’s clothing from Japan and the world brought right here to Toronto. Premium denim, sneakers and homewares.",
    linkTo: "https://www.bluebuttonshop.com/",
  },
  {
    imageSrc: SIZE,
    imageAlt: "Size Neighbourhood image",
    imageTitle: "Size Neighbourhood image",
    title: "SIZE",
    address: "1000 Queen St W",
    paragraph: "A new addition to the Toronto Sneaker scene courtesy of the UK. It’s modelled after a bank because you’ll be leaving some money behind. ",
    linkTo: "https://size.ca/pages/toronto-1000-queen-st-w?utm_source=google&utm_medium=PaidSearch&utm_campaign=bl_search_brand_ca&gclid=Cj0KCQiAkNiMBhCxARIsAIDDKNXI3Rofav32nhnE8YkTFX396yiYHgQtpMGotaLXi_XXkS5YpCn5c6oaAi9lEALw_wcB",
  },
  {
    imageSrc: MONOCLE,
    imageAlt: "Monocle Neighbourhood image",
    imageTitle: "Monocle Neighbourhood image",
    title: "MONOCLE",
    address: "776 College S",
    paragraph: "Outpost for the international magazine, Monocle is a good stop for cool homewares and city guides for your stops after Toronto.",
    linkTo: 'https://monocle.com/about/contacts/monocle-shop-toronto/',
  },
  {
    imageSrc: SUPERETTE,
    imageAlt: "SUPERETTE Neighbourhood image",
    imageTitle: "SUPERETTE Neighbourhood image",
    title: "SUPERETTE SIP N SMOKE",
    address: "994 Dundas St W",
    paragraph: "Weed’s legal here and the best shop in the city is Superette. They have an annex location soon," +
        " but until then the Sip ‘n’ Smoke on Dundas is here. No you still can’t smoke in our rooms.",
    linkTo: "https://superetteshop.com/pages/bellwoods-location",
  },
  {
    imageSrc: FAWN,
    imageAlt: "Fawn Neighbourhood image",
    imageTitle: "Fawn Neighbourhood image",
    title: "FAWN",
    address: "967 Queen St W",
    paragraph: "A women’s boutique shop like no other. You’ll discover more than a couple brands.",
    linkTo: "https://shopfawn.com/",
  },
  {
    imageSrc: MEJURI,
    imageAlt: "Mejuri Neighbourhood image",
    imageTitle: "Mejuri Ahimsa Yoga",
    title: "MEJURI",
    address: "906 Dundas St W",
    paragraph: "We look up to Mejuri, a Toronto brand with global reach. Shop their jewelry collection at their first location. ",
    linkTo: 'https://mejuri.com/locations/toronto?utm_source=google&utm_medium=cpc&utm_campaign=branded_search_ca_5_retail_toronto_exact_all_pro&gclid=Cj0KCQiAkNiMBhCxARIsAIDDKNVQFhT6IBqiaPJo4B9-K5PfyuMfdvM_Usjyxbz92eyVB8g6MwQe2g0aAiiXEALw_wcB',
  },
  {
    imageSrc: BMV_BOOKS,
    imageAlt: "BMV Books Neighbourhood image",
    imageTitle: "BMV Books Ahimsa Yoga",
    title: "BMV BOOKS",
    address: "417 Bloor St W",
    paragraph: "Curator of our in room book selections, BMV is a store you can get lost in. " +
        "Find that book you’ve been looking for years and discover new ones alike. We like the comic book part upstairs. ",
    linkTo: 'https://www.bmvbooks.com/',
  },
  {
    imageSrc: REIGNING_CHAMP,
    imageAlt: "Reigning Champ Neighbourhood image",
    imageTitle: "Reigning Champ Neighbourhood image",
    title: "REIGNING CHAMP",
    address: "41 Ossington Ave",
    paragraph: "The best in athletic wear for men and women. Hailing from Vancouver BC, Reigning Champ’s gear is good enough for the elite athlete and for drinking wine in bed. Your choice.",
    linkTo: 'https://reigningchamp.com/stores/',
  },
  {
    imageSrc: UNCLE_OTIS,
    imageAlt: "UNCLE OTIS Neighbourhood image",
    imageTitle: "UNCLE OTIS Neighbourhood image",
    title: "UNCLE OTIS",
    address: "329 Spadina Ave",
    paragraph: "A longstanding menswear staple of Toronto, Uncle Otis stocks some of the most unique pieces you’ll find alongside everyday staples." +
        " Ask for some treats for their shop dog Ollie. ",
    linkTo: 'https://uncleotis.com/',
  },
];

const NeighbourhoodShoppingDesktop: NeighbourhoodCarouselDesktopType[] = [
  {
    imageSrc: NeighbourhoodShopping[0].imageSrc,
    imageAlt: NeighbourhoodShopping[0].imageAlt,
    imageTitle: NeighbourhoodShopping[0].imageTitle,
    title: NeighbourhoodShopping[0].title,
    address: NeighbourhoodShopping[0].address,
    paragraph: NeighbourhoodShopping[0].paragraph,
    linkTo: NeighbourhoodShopping[0].linkTo,

    imageSrc2: NeighbourhoodShopping[1].imageSrc,
    imageAlt2: NeighbourhoodShopping[1].imageAlt,
    imageTitle2: NeighbourhoodShopping[1].imageTitle,
    title2: NeighbourhoodShopping[1].title,
    address2: NeighbourhoodShopping[1].address,
    paragraph2: NeighbourhoodShopping[1].paragraph,
    linkTo2: NeighbourhoodShopping[1].linkTo,

    imageSrc3: NeighbourhoodShopping[2].imageSrc,
    imageAlt3: NeighbourhoodShopping[2].imageAlt,
    imageTitle3: NeighbourhoodShopping[2].imageTitle,
    title3: NeighbourhoodShopping[2].title,
    address3: NeighbourhoodShopping[2].address,
    paragraph3: NeighbourhoodShopping[2].paragraph,
    linkTo3: NeighbourhoodShopping[2].linkTo,

    imageSrc4: NeighbourhoodShopping[3].imageSrc,
    imageAlt4: NeighbourhoodShopping[3].imageAlt,
    imageTitle4: NeighbourhoodShopping[3].imageTitle,
    title4: NeighbourhoodShopping[3].title,
    address4: NeighbourhoodShopping[3].address,
    paragraph4: NeighbourhoodShopping[3].paragraph,
    linkTo4: NeighbourhoodShopping[3].linkTo,
  },
  {
    imageSrc: NeighbourhoodShopping[4].imageSrc,
    imageAlt: NeighbourhoodShopping[4].imageAlt,
    imageTitle: NeighbourhoodShopping[4].imageTitle,
    title: NeighbourhoodShopping[4].title,
    address: NeighbourhoodShopping[4].address,
    paragraph: NeighbourhoodShopping[4].paragraph,
    linkTo: NeighbourhoodShopping[4].linkTo,

    imageSrc2: NeighbourhoodShopping[5].imageSrc,
    imageAlt2: NeighbourhoodShopping[5].imageAlt,
    imageTitle2: NeighbourhoodShopping[5].imageTitle,
    title2: NeighbourhoodShopping[5].title,
    address2: NeighbourhoodShopping[5].address,
    paragraph2: NeighbourhoodShopping[5].paragraph,
    linkTo2: NeighbourhoodShopping[5].linkTo,

    imageSrc3: NeighbourhoodShopping[6].imageSrc,
    imageAlt3: NeighbourhoodShopping[6].imageAlt,
    imageTitle3: NeighbourhoodShopping[6].imageTitle,
    title3: NeighbourhoodShopping[6].title,
    address3: NeighbourhoodShopping[6].address,
    paragraph3: NeighbourhoodShopping[6].paragraph,
    linkTo3: NeighbourhoodShopping[6].linkTo,

    imageSrc4: NeighbourhoodShopping[7].imageSrc,
    imageAlt4: NeighbourhoodShopping[7].imageAlt,
    imageTitle4: NeighbourhoodShopping[7].imageTitle,
    title4: NeighbourhoodShopping[7].title,
    address4: NeighbourhoodShopping[7].address,
    paragraph4: NeighbourhoodShopping[7].paragraph,
    linkTo4: NeighbourhoodShopping[7].linkTo,
  },
  {
    imageSrc: NeighbourhoodShopping[8].imageSrc,
    imageAlt: NeighbourhoodShopping[8].imageAlt,
    imageTitle: NeighbourhoodShopping[8].imageTitle,
    title: NeighbourhoodShopping[8].title,
    address: NeighbourhoodShopping[8].address,
    paragraph: NeighbourhoodShopping[8].paragraph,
    linkTo: NeighbourhoodShopping[8].linkTo,

    imageSrc2: NeighbourhoodShopping[9].imageSrc,
    imageAlt2: NeighbourhoodShopping[9].imageAlt,
    imageTitle2: NeighbourhoodShopping[9].imageTitle,
    title2: NeighbourhoodShopping[9].title,
    address2: NeighbourhoodShopping[9].address,
    paragraph2: NeighbourhoodShopping[9].paragraph,
    linkTo2: NeighbourhoodShopping[9].linkTo,

    imageSrc3: NeighbourhoodShopping[10].imageSrc,
    imageAlt3: NeighbourhoodShopping[10].imageAlt,
    imageTitle3: NeighbourhoodShopping[10].imageTitle,
    title3: NeighbourhoodShopping[10].title,
    address3: NeighbourhoodShopping[10].address,
    paragraph3: NeighbourhoodShopping[10].paragraph,
    linkTo4: NeighbourhoodShopping[10].linkTo,
  }
];

const NeighbourhoodOthers: NeighbourhoodCarouselType[] = [
  {
    imageSrc: TOWN_BARBER ,
    imageAlt: "Town Barber Neighbourhood image",
    imageTitle: "Town barber Neighbourhood image",
    title: "Town Barber",
    address: "114 Dundas st w",
    paragraph: "The idyllic barber shop if you need a touchup while in town. Walls clad with skateboards and good vibes. ",
    linkTo: "https://townbarber.ca/",
  },
];

const NeighbourhoodOthersDesktop: NeighbourhoodCarouselDesktopType[] = [
  {
    imageSrc: NeighbourhoodOthers[0].imageSrc,
    imageAlt: NeighbourhoodOthers[0].imageAlt,
    imageTitle: NeighbourhoodOthers[0].imageTitle,
    title: NeighbourhoodOthers[0].title,
    address: NeighbourhoodOthers[0].address,
    paragraph: NeighbourhoodOthers[0].paragraph,
    linkTo: NeighbourhoodOthers[0].linkTo,
  },
];

// Google Maps Markers 
const NeighbourhoodMapMarkerFoods: NeighbourhoodMapMarker[] = [
  { lat: 43.6493815, lng: -79.4299844, title: "MILOU", category: 'Food' },
  { lat: 43.6496567, lng: -79.4287676, title: "IMANISHI", category: 'Food' },
  { lat: 43.6629906, lng: -79.4050819, title: "Piano - 88 Harbord St", category: 'Food' },
  { lat: 43.7044771, lng: -79.3905835, title: "Piano - 623 Mt Pleasant Rd", category: 'Food' },
  { lat: 43.6583004, lng: -79.4445205, title: "SUGO", category: 'Food' },
  { lat: 43.6508027, lng: -79.4150806, title: "LAMBO'S", category: 'Food' },
  { lat: 43.6496, lng: -79.425247, title: "HANMOTO", category: 'Food' },
  { lat: 43.6489337, lng: -79.4230028, title: "MANITA", category: 'Food' },
  { lat: 43.6508889, lng: -79.414572, title: "SAVING GRACE", category: 'Food' },
  { lat: 43.6517122, lng: -79.411688, title: "FOURTH MAN IN THE FIRE", category: 'Food' },
  { lat: 43.6563624, lng: -79.409031, title: "QUETZAL", category: 'Food' },
  { lat: 43.6535192, lng: -79.4298614, title: "GIULIETTA", category: 'Food' },
  { lat: 43.6646307, lng: -79.4129263, title: "MALLO", category: 'Food' },
];

const NeighbourhoodMapMarkerCocktails: NeighbourhoodMapMarker[] = [
  { lat: 43.6555537, lng: -79.4152397, title: "MRS. ROBINSON", category: 'Cocktails' },
  { lat: 43.6558249, lng: -79.4121005, title: "Bar Raval", category: 'Cocktails' },
  { lat: 43.6518446, lng: -79.4094942, title: "COLLECTIVE ARTS", category: 'Cocktails' },
  { lat: 43.6629906, lng: -79.4050819, title: "Piano - 88 Harbord St", category: 'Cocktails' },
  { lat: 43.7044771, lng: -79.3905835, title: "Piano - 623 Mt Pleasant Rd", category: 'Cocktails' },
  { lat: 43.6495605, lng: -79.4271042, title: "BAR MORDECHAI", category: 'Cocktails' },
  { lat: 43.6489337, lng: -79.4230028, title: "MANITA", category: 'Cocktails' },
];

const NeighbourhoodMapMarkerCoffees: NeighbourhoodMapMarker[] = [
  { lat: 43.6522916, lng: -79.4091001, title: "MILKY’S", category: 'Coffee' },
  { lat: 43.6467153, lng: -79.4217309, title: "PILOT COFFEE ROASTERS - 117 Ossington Ave", category: 'Coffee' },
  { lat: 43.6477014, lng: -79.3992128, title: "PILOT COFFEE ROASTERS - 439 Richmond St W", category: 'Coffee' },
  { lat: 43.6491883, lng: -79.3830704, title: "PILOT COFFEE ROASTERS - 100 King St W", category: 'Coffee' },
  { lat: 43.6452985, lng: -79.3829526, title: "PILOT COFFEE ROASTERS - 65 Front St W", category: 'Coffee' },
  { lat: 43.6594525, lng: -79.4226524, title: "POLIVIA’S GARDEN", category: 'Coffee' },
  { lat: 43.6602506, lng: -79.4176057, title: "SAM JAMES COFFEE BAR - 297 Harbord St", category: 'Coffee' },
  { lat: 43.6418076, lng: -79.434819, title: "SAM JAMES COFFEE BAR - 6 Brock Ave", category: 'Coffee' },
  { lat: 43.647381, lng: -79.4220444, title: "SAM JAMES COFFEE BAR - 141 Ossington Ave", category: 'Coffee' },
  { lat: 43.6480458, lng: -79.38626, title: "SAM JAMES COFFEE BAR - 150 King St W", category: 'Coffee' },
];

const NeighbourhoodMapMarkerShopping: NeighbourhoodMapMarker[] = [
  { lat: 43.6451992, lng: -79.4215286, title: "LOST & FOUND", category: 'Shopping' },
  { lat: 43.6464357, lng: -79.4104994, title: "The Latest Scoop - 700 Queen St W", category: 'Shopping' },
  { lat: 43.6465319, lng: -79.4217003, title: "The Latest Scoop - 115 Ossington Ave", category: 'Shopping' },
  { lat: 43.649458, lng: -79.4340837, title: "Blue Button Shop", category: 'Shopping' },
  { lat: 43.6443199, lng: -79.4209559, title: "SIZE?", category: 'Shopping' },
  { lat: 43.6549641, lng: -79.4229099, title: "MONOCLE", category: 'Shopping' },
  { lat: 43.6505567, lng: -79.4177392, title: "SUPERETTE SIP N SMOKE", category: 'Shopping' },
  { lat: 43.6448652, lng: -79.4168784, title: "FAWN", category: 'Shopping' },
  { lat: 43.651153, lng: -79.4145175, title: "MEJURI", category: 'Shopping' },
  { lat: 43.6658602, lng: -79.4090308, title: "BMV BOOKS", category: 'Shopping' },
  { lat: 43.6490854, lng: -79.3963062, title: "REIGNING CHAMP - 383 Queen St W", category: 'Shopping' },
  { lat: 43.6452202, lng: -79.4210064, title: "REIGNING CHAMP - 41 Ossington Ave", category: 'Shopping' },
  { lat: 43.654631, lng: -79.4004347, title: "UNCLE OTIS", category: 'Shopping' },
];

const NeighbourhoodMapMarkerOthers: NeighbourhoodMapMarker[] = [
  { lat: 43.649730, lng: -79.420200, title: "Town Barber - Dundas East Toronto", category: 'Others' },
  { lat: 43.643100, lng: -79.425140, title: "Town Barber - Queen St. W. Toronto", category: 'Others' },
];

export {
  mockBudgets, 

  NeighbourhoodMapMarkerFoods,
  NeighbourhoodMapMarkerCocktails,
  NeighbourhoodMapMarkerCoffees,
  NeighbourhoodMapMarkerShopping,
  NeighbourhoodMapMarkerOthers,

  NeighbourhoodFoods,
  NeighbourhoodFoodsDesktop,
  NeighbourhoodCocktails,
  NeighbourhoodCocktailsDesktop,
  NeighbourhoodCoffees,
  NeighbourhoodCoffeesDesktop,
  NeighbourhoodShopping,
  NeighbourhoodShoppingDesktop,
  NeighbourhoodOthers,
  NeighbourhoodOthersDesktop,
};
