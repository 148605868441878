import { collection } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

const useRooms = () => {
  const roomCollectionRef = collection(useFirestore(), 'rooms');

  const {
    data: rooms
  } = useFirestoreCollectionData(roomCollectionRef, {idField: 'id'});

  return {
    rooms,
    roomCollectionRef
  }
};

export default useRooms;
