import Button from "../../components/Global/Button/Button";
import "./BookingSpecial.scss";

type BookingSpecialProps = {
  title?: string;
  description?: string;
  total?: string;
  added?: boolean;
  addOnClick?: () => void;
  removeOnClick?: () => void;
};

const BookingSpecial = (props: BookingSpecialProps) => {
  return (
    <div className="booking-special">
      <div className="booking-special__info">
        <h2 className="booking-special__title">{props.title}</h2>
        <p className="booking-special__description">{props.description}</p>
        <h3 className="booking-special__total">{props.total}</h3>
      </div>
      {props.added && (
        <Button
          cname="booking-special__button"
          buttontype="outline"
          children="Remove"
          onClick={props.removeOnClick}
          linkTo=""
        ></Button>
      )}
      {!props.added && (
        <Button
          cname="booking-special__button"
          buttontype="primary--inverse"
          children={`Add ${props.title}`}
          onClick={props.addOnClick}
          linkTo=""
        ></Button>
      )}
    </div>
  );
};

export default BookingSpecial;
