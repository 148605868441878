import "./TextLink.scss";
import { Link } from "react-router-dom";

export type TextLinkProps = {
  text: string;
  linkTo: string;
  className: string;
  onClick?: (() => void) | ((e: any) => void);
};

const TextLink = ({
  text,
  linkTo,
  className,
  onClick,
  ...props
}: TextLinkProps) => {
  return (
    <Link to={linkTo} className={`text-link ${className}`} onClick={onClick}>
      {text}
    </Link>
  );
};

export default TextLink;
