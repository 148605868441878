import "./BookingItem.scss";
import ButtonArrow from "../Global/ButtonArrow/ButtonArrow";
import Button from "../Global/Button/Button";

import axios from "axios";

type BookingItemProps = {
  imgSrc?: string;
  imgAlt?: string;
  imgTitle?: string;
  name?: string;
  date?: string;
  linkTo?: string;
  linkText?: string;
  upcoming?: boolean;
  reservationId?: any;
};

const BookingItem = ({
  imgSrc,
  imgAlt,
  imgTitle,
  name,
  date,
  linkTo,
  linkText,
  upcoming,
  reservationId,
  ...props
}: BookingItemProps) => {
  const cancelBooking = async () => {
    var data = JSON.stringify({
      ReservationIds: [reservationId],
      ChargeCancellationFee: false,
      Notes: "Cancellation through Website",
    });

    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const apiBaseUrl = process.env.REACT_APP_FIREBASE_API_BASE;
    try {
      const response = await axios.post(
        `${apiBaseUrl}/reservations/cancel`,
        data,
        config
      );

      // console.log("your booking has been successfully cancelled.", response);

      // setTimeout(() => {}, 1500);
    } catch (err: any) {
      // console.log("cancel booking error", err.message);
      alert(err);
    }
  };

  return (
    <div className="booking-item">
      {imgSrc && (
        <div className="booking-item__image-container">
          <img
            className="booking-item__image"
            src={imgSrc}
            alt={imgAlt}
            title={imgTitle}
            loading="lazy"
          />
        </div>
      )}
      <div className="booking-item__info">
        {name && <h2 className="booking-item__name">{name}</h2>}
        {date && <p className="booking-item__date">{date}</p>}

        <div className="booking-item__buttons">
          {linkTo && (
            <ButtonArrow
              className="booking-item__buttons--details"
              buttontype="main"
              children="Booking Details"
              iconColor="#000000"
              linkTo={linkTo}
            />
          )}
          {upcoming && (
            <Button
              buttontype="primary--inverse"
              children="Cancel Booking"
              type="button"
              onClick={cancelBooking}
              linkTo=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingItem;
