// import "bootstrap/dist/css/bootstrap.min.css";

import "./EventCarousel.scss";
import { Carousel } from "react-bootstrap";
import { useState } from "react";
// import TheBigOne from "../../assets/images/live-local/the-big-one.png";
import Icon from "../Global/Icon/Icon";
import EventNew from "../EventNew/EventNew";
// import ourPeople from "../../assets/images/our-people.png";

type EventCarouselDetails = {
  imgTitle?: string;
  imgSrc?: string;
  imgAlt?: string;
  name?: string;
  date?: string;
  time?: string;
  budget?: string;
  desc?: string;
  textUs?: boolean;
  imgTitle2?: string;
  imgSrc2?: string;
  imgAlt2?: string;
  name2?: string;
  date2?: string;
  time2?: string;
  budget2?: string;
  desc2?: string;
  textUs2?: boolean;
};

type EventCarouselProps = {
  arrayOfObjectsSrc?: EventCarouselDetails[] | undefined;
};

export default function EventCarousel({
  arrayOfObjectsSrc,
  ...props
}: EventCarouselProps) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const directionButtons = (direction: string) => {
    return (
      <span
        aria-hidden="true"
        className={
          direction === "Next" ? "events-button-next" : "events-button-prev"
        }
      >
        {direction === "Next" ? (
          <Icon
            name="next-arrow"
            className="events-button-next-icon"
            color="black"
          />
        ) : (
          <Icon
            name="back-arrow"
            className="events-button-prev-icon"
            color="black"
          />
        )}
      </span>
    );
  };

  // const eventCarouselData = [
  //   {
  //     imgTitle: "Our People",
  //     imgSrc: TheBigOne,
  //     imgAlt: "The Big One",
  //     name: "Holiday Market",
  //     date: "1 Sept",
  //     time: "8pm",
  //     budget: "Free",
  //     desc:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel eros purus. Sed tristique commodo ultricies.",
  //     imgTitle2: "Our People",
  //     imgSrc2: ourPeople,
  //     imgAlt2: "The Big One",
  //     name2: "Comedy Night",
  //     date2: "1 Sept",
  //     time2: "8pm",
  //     budget2: "Free",
  //     desc2:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel eros purus. Sed tristique commodo ultricies.",
  //   },
  //   {
  //     imgTitle: "Our People",
  //     imgSrc: TheBigOne,
  //     imgAlt: "The Big One",
  //     name: "Holiday Market",
  //     date: "1 Sept",
  //     time: "8pm",
  //     budget: "Free",
  //     desc:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel eros purus. Sed tristique commodo ultricies.",
  //     imgTitle2: "Our People",
  //     imgSrc2: ourPeople,
  //     imgAlt2: "The Big One",
  //     name2: "Comedy Night",
  //     date2: "1 Sept",
  //     time2: "8pm",
  //     budget2: "Free",
  //     desc2:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel eros purus. Sed tristique commodo ultricies.",
  //   },
  // ];

  return (
    <div className="events-carousel-container">
      <Carousel
        //  ref={ref}
        interval={null}
        controls={true}
        indicators={false}
        nextLabel={""}
        prevLabel={""}
        activeIndex={index}
        onSelect={handleSelect}
        nextIcon={directionButtons("Next")}
        prevIcon={directionButtons("Previous")}
        className="events-carousel"
      >
        {arrayOfObjectsSrc?.map((item, index) => {
          return (
            <Carousel.Item key={index}>
              <div className="events-carousel-item mobile-only">
                <EventNew
                  imgTitle={item.imgTitle}
                  imgSrc={item.imgSrc}
                  imgAlt={item.imgAlt}
                  name={item.name}
                  date={item.date}
                  time={item.time}
                  budget={item.budget}
                  desc={item.desc}
                  textUs={item.textUs}
                />
              </div>
              <div className="events-carousel-item desktop-only">
                <EventNew
                  imgTitle={item.imgTitle}
                  imgSrc={item.imgSrc}
                  imgAlt={item.imgAlt}
                  name={item.name}
                  date={item.date}
                  time={item.time}
                  budget={item.budget}
                  desc={item.desc}
                  textUs={item.textUs}
                />
                <EventNew
                  imgTitle={item.imgTitle2}
                  imgSrc={item.imgSrc2}
                  imgAlt={item.imgAlt2}
                  name={item.name2}
                  date={item.date2}
                  time={item.time2}
                  budget={item.budget2}
                  desc={item.desc2}
                  textUs={item.textUs2}
                />
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}
