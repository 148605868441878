import './AnnexDiscoverCardCarousel.scss';

import DiscoverCard from '../../components/DiscoverCard/DiscoverCard';
import Icon from '../../components/Global/Icon/Icon';


import Champagne from '../../assets/images/the-big-one-room-page/champagne.jpg';
// import Pizza from '../../assets/images/the-big-one-room-page/pizza.png';
// import StreetcarTracks from '../../assets/images/the-big-one-room-page/streetcar-tracks.png';
import ManDoubleBass from '../../assets/images/the-big-one-room-page/man-double-bass.jpg';
// import FoodDrinks from '../../assets/images/the-big-one-room-page/food-drinks.jpg';
import GlassesOfWine from '../../assets/images/the-big-one-room-page/glasses-of-wine.jpg';
import TheNeighbourhood from '../../assets/images/the-big-one-room-page/the-neighbourhood.jpg';
// import DupontStation from '../../assets/images/the-big-one-room-page/dupont-station.jpg';


import {Carousel} from "react-bootstrap";
import {useState} from 'react';

export type AnnexDiscoverCardCarouselProps = {
	title?: string;
	infoHeight?: string;
}

const AnnexDiscoverCardCarousel = (props: AnnexDiscoverCardCarouselProps) => {

	const {title, infoHeight} = props;

	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex: number) => {
		setIndex(selectedIndex);
	};

	const directionButtons = (direction: string) => {
		return (
			<span
				aria-hidden="true"
				className={direction === "Next" ? "button-next-adcc" : "button-prev-adcc"}
			>
          {direction === "Next" ? (
	          <Icon name='next-arrow' className="button-next-icon-adcc" color='black'/>
          ) : (
	          <Icon name='back-arrow' className="button-prev-icon-adcc" color='black'/>
          )}
        </span>
		);
	};

	const annexDiscoverCardCarouselData = [
		{
			imageSrc: Champagne,
			imageAlt: "Champagne in an ice bucket",
			imageTitle: "Champagne",
			title: "Packages",
			paragraph: "Depending on your niche, we’ll take care of you like an old friend. We’ll have our favourite wines waiting for you upon arrival or a bowl with fresh water ready for your furry companion.",
			linkTo: "#",
			buttonText: "Coming Soon"
		},
		{
			imageSrc: GlassesOfWine,
			imageAlt: "Food & Drinks",
			imageTitle: "Food & Drinks",
			title: "Food & Drinks",
			paragraph: "Eat, get some work done, drink, unwind. At The Annex, the living quarters are just where you sleep. Our common spaces have chill vibes with the perfect amount of privacy to finish that screenplay you’ve been putting off.",
			linkTo: "/eat-and-drink",
			buttonText: "Show Me"
		},
		{
			imageSrc: TheNeighbourhood,
			imageAlt: "The Neighbourhood",
			imageTitle: "The Neighbourhood",
			title: "The Neighbourhood",
			paragraph: "Young and vibrant. Mythic and grounded. The Annex is an authentic blend of old and new. It’s where trends are born and ideas run free. Let’s call it, perfectly imperfect.",
			linkTo: "/neighbourhood",
			buttonText: "Show Me"
		},
		{
			imageSrc: ManDoubleBass,
			imageAlt: "Man playing a double bass",
			imageTitle: "Man playing a double bass",
			title: "Events",
			paragraph: "We always have something going on, that’s why we say we are more than just a hotel. Our spaces come to life with music, food, film, dance parties and more. Check this page.",
			linkTo: '/events',
			buttonText: "Show Me"
		}
	]

	return (
		<div className="annex-discover-card-carousel-container">
			<h4 className="annex-discover-card-carousel-title">{title}</h4>

			<Carousel
				//  ref={ref}
				interval={null}
				controls={true}
				indicators={false}
				nextLabel={""}
				prevLabel={""}
				activeIndex={index}
				onSelect={handleSelect}
				nextIcon={directionButtons("Next")}
				prevIcon={directionButtons("Previous")}
				className="adc-carousel mobile-only"
			>
				{annexDiscoverCardCarouselData.map((item, index) => {
					return (
						<Carousel.Item key={index} className='adc-carousel-item'>
							<DiscoverCard
								imageSrc={item.imageSrc}
								imageAlt={item.imageAlt}
								imageTitle={item.imageTitle}
								title={item.title}
								paragraph={item.paragraph}
								linkTo={item.linkTo}
								buttonText={item.buttonText}
								infoHeight={infoHeight}	
							/>
						</Carousel.Item>
					)
				})}
			</Carousel>
			<div className="annex-discover-card-list-desktop desktop-only">
				{annexDiscoverCardCarouselData.map((item, index) => {
					return (
						<div className="annex-discover-card-desktop" key={index}>
							<DiscoverCard
								imageSrc={item.imageSrc}
								imageAlt={item.imageAlt}
								imageTitle={item.imageTitle}
								title={item.title}
								paragraph={item.paragraph}
								linkTo={item.linkTo}
								buttonText={item.buttonText}
								infoHeight={infoHeight}	
							/>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default AnnexDiscoverCardCarousel;
