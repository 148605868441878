import './RoomTwoImageSection.scss';

export type RoomTwoImageSectionProps = {
    leftImgSrc?: string;
    leftImgAlt?: string;
    rightImgSrc?: string;
    rightImgAlt?: string;
}

const RoomTwoImageSection = (props: RoomTwoImageSectionProps) => {

    const { leftImgSrc, leftImgAlt, rightImgSrc, rightImgAlt } = props;

    return (
        <div className="two-image">
            <img src={leftImgSrc} alt={leftImgAlt} className="two-image__left-image" loading="lazy"/>
            <img src={rightImgSrc} alt={rightImgAlt} className="two-image__right-image" loading="lazy"/>
        </div>
    )
}

export default RoomTwoImageSection;
