export const mapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ffffff' }, { lightness: 17 }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }, { lightness: 18 }],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }, { lightness: 16 }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#dedede' }, { lightness: 21 }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
  },
  { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [{ color: '#fefefe' }, { lightness: 20 }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
  },
];

export const getUTCDate = (fullDate?: Date) => {
  let year: number;
  let month: number;
  let day: any;
  let hours: any;
  let minutes: any;
  let seconds: any;
  let milliseconds: any;
  let utc: string;
  let date = fullDate ? new Date(fullDate) : new Date();
  
  year = date.getFullYear();
  month = date.getMonth() + 1;
  day =  date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  //need to add 1 hour on for same day booking so MEWS doesn't think the start time is in the past
  // hours = date.getHours() < 10 ? date.getHours() + "0" : date.getHours();
  // hours = date.getHours() < 9 ? date.getHours()+1 + "0" : date.getHours()+1;
  // minutes = date.getMinutes() < 10 ? date.getMinutes() + "0" : date.getMinutes();
  // seconds = date.getSeconds() < 10 ? date.getSeconds() + "0" : date.getSeconds();
  // milliseconds = date.getMilliseconds() < 10 ? date.getMilliseconds() + "0" : date.getMilliseconds();
  hours = date.getHours() < 9 ? "0" + date.getHours()+1 : date.getHours()+1;
  minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  milliseconds = date.getMilliseconds() < 10 ? "0" + date.getMilliseconds() : date.getMilliseconds();
  // console.log("milli", date.getMilliseconds());
  // console.log("milli", date.getMilliseconds()-1);
  utc = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  return utc;
};

export const getUTCDateNext = (fullDate?: Date) => {
  let year: number;
  let month: number;
  let day: any;
  let hours: any;
  let minutes: any;
  let seconds: any;
  let milliseconds: any;
  let utc: string;
  let date = fullDate ? new Date(fullDate) : new Date();
  
  year = date.getFullYear();
  month = date.getMonth() + 1;
  day =  date.getDate() < 10 ? "0" + date.getDate() + 1 : date.getDate() + 1;
  // hours = date.getHours() < 10 ? date.getHours() + "0" : date.getHours();
  // minutes = date.getMinutes() < 10 ? date.getMinutes() + "0" : date.getMinutes();
  // seconds = date.getSeconds() < 10 ? date.getSeconds() + "0" : date.getSeconds();
  // milliseconds = date.getMilliseconds() < 10 ? date.getMilliseconds() + "0" : date.getMilliseconds();
  hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  milliseconds = date.getMilliseconds() < 10 ? "0" + date.getMilliseconds() : date.getMilliseconds();
  utc = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  return utc;
};
