import "./EventBookingModal.scss";

import { useState, useEffect, useRef } from "react";

import Button from "../Global/Button/Button";

import * as yup from "yup";
import { useFormik } from "formik";

import { addDoc } from "firebase/firestore";
import { useMailCollection } from "../../hooks/useMailCollection";
import ReCAPTCHA from "react-google-recaptcha";

export type CollaborateProps = {
  openEventsModal: boolean;
  setOpenEventsModal: (i: boolean) => void;
};

const Collaborate = ({
  openEventsModal,
  setOpenEventsModal,
  ...props
}: CollaborateProps) => {
  const { mailCollectionRef } = useMailCollection();

  const [formSuccess, setFormSuccess] = useState(false);

  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [verfied, setVerifed] = useState(false);

   // const CAPTCHA_SITE_KEY = `6Lc3rRYiAAAAALfHyHj2kruCbxzPgQhVaMh71MzE`; // version 3
   const CAPTCHA_SITE_KEY = `6LeNPh8iAAAAAPjauigd_W51w9avrbbxTRgMf0EM`; // version 2
   // const CAPTCHA_SITE_KEY = `6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI`; // for testing locally
  const reCaptchaRef = useRef(null);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const websiteRegExp =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  const collaborateSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Invalid phone number")
      .min(10, "Phone number must be at least 10 characters")
      .required("Phone number is required"),
    website: yup.string().matches(websiteRegExp, "Invalid URL"),
    role: yup.string().required("Your role is required"),
    howCollaborate: yup
      .string()
      .required("How would you like to collaborate is required"),
    recaptcha: yup.string().required("Please verify you are not a robot"),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    website: "",
    role: "",
    howCollaborate: "",
    recaptcha: "",
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema: collaborateSchema,
    onSubmit: async (values, { resetForm, setFieldError }) => {
      if (
        values.firstName &&
        values.lastName &&
        values.email &&
        values.phoneNumber &&
        values.website &&
        values.role &&
        values.howCollaborate &&
        values.recaptcha
      ) {
        try {
          await addDoc(mailCollectionRef, {
            //change to hello@theannex.com once Mack approves email formatting/details
            //change in firebase extension as well
            to: "events@theannex.com",
            message: {
              subject: "New 'Collaborate' request!",
              // change any of this text? any feedback from Mack?
              html: `${
                values.firstName + " " + values.lastName
              } would like to collaborate! The details of the collaboration request are as follows:
              <br>
              <br>
              First name: ${values.firstName}
              <br>
              Last name: ${values.lastName}
              <br>
              Email: ${values.email}
              <br>
              Phone number: ${values.phoneNumber}
              <br>
              Website: ${values.website}
              <br>
              Specify role: ${values.role}
              <br>
              How would you like to collaborate with us?: ${
                values.howCollaborate
              }
              <br>
              Verification: ${values.recaptcha}
  
              `,
            },
            replyTo: values.email,
          }).then(() => {
            setShowError(false);
            setErrorMessage("");
            setFormSuccess(true);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
            resetForm();
          });
        } catch (err: any) {
          console.error("err", err);
          // console.log("error message", err.message);
          setShowError(true);
          setErrorMessage("An unknown error has occurred. Please try again.");
        }
      }
    },
  });

  useEffect(() => {
    !openEventsModal && setTimeout(() => setFormSuccess(false), 600);
  });

  return (
    <div>
      {!formSuccess && (
        <>
          <h4 className="form-title">Your Details</h4>
          <form
            className="your-details-form"
            onSubmit={formikProps.handleSubmit}
          >
            <div className="form-two-columns-inputs-container">
              <div className="form-input-half-width">
                <div className="your-details-form-input ">
                  <label
                    htmlFor="first-name"
                    className={
                      formikProps.touched.firstName &&
                      formikProps.errors.firstName
                        ? "your-details-form-input__label your-details-form-input__label--error "
                        : "your-details-form-input__label"
                    }
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    name="firstName"
                    value={formikProps.values.firstName}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    className="your-details-form-input__input"
                  />
                </div>
                {formikProps.touched.firstName &&
                  formikProps.errors.firstName && (
                    <p className="form-error-message">
                      {formikProps.errors.firstName}
                    </p>
                  )}
              </div>
              <div className="form-input-half-width">
                <div className="your-details-form-input">
                  <label
                    htmlFor="last-name"
                    className={
                      formikProps.touched.lastName &&
                      formikProps.errors.lastName
                        ? "your-details-form-input__label your-details-form-input__label--error "
                        : "your-details-form-input__label"
                    }
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="last-name"
                    name="lastName"
                    value={formikProps.values.lastName}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    className="your-details-form-input__input"
                  />
                </div>
                {formikProps.touched.lastName &&
                  formikProps.errors.lastName && (
                    <p className="form-error-message">
                      {formikProps.errors.lastName}
                    </p>
                  )}
              </div>
            </div>
            <div className="form-two-columns-inputs-container">
              <div className="form-input-half-width">
                <div className="your-details-form-input">
                  <label
                    htmlFor="email"
                    className={
                      formikProps.touched.email && formikProps.errors.email
                        ? "your-details-form-input__label your-details-form-input__label--error "
                        : "your-details-form-input__label"
                    }
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    className="your-details-form-input__input"
                  />
                </div>
                {formikProps.touched.email && formikProps.errors.email && (
                  <p className="form-error-message">
                    {formikProps.errors.email}
                  </p>
                )}
              </div>
              <div className="form-input-half-width">
                <div className="your-details-form-input">
                  <label
                    htmlFor="phone-number"
                    className={
                      formikProps.touched.phoneNumber &&
                      formikProps.errors.phoneNumber
                        ? "your-details-form-input__label your-details-form-input__label--error "
                        : "your-details-form-input__label"
                    }
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone-number"
                    name="phoneNumber"
                    value={formikProps.values.phoneNumber}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    className="your-details-form-input__input"
                  />
                </div>
                {formikProps.touched.phoneNumber &&
                  formikProps.errors.phoneNumber && (
                    <p className="form-error-message">
                      {formikProps.errors.phoneNumber}
                    </p>
                  )}
              </div>
            </div>
            <div className="your-details-form-input">
              <label
                htmlFor="website"
                className={
                  formikProps.touched.website && formikProps.errors.website
                    ? "your-details-form-input__label your-details-form-input__label--error "
                    : "your-details-form-input__label"
                }
              >
                Website
              </label>
              <input
                // type="url"
                type="text"
                id="website"
                name="website"
                value={formikProps.values.website}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                className="your-details-form-input__input"
              />
            </div>
            {formikProps.touched.website && formikProps.errors.website && (
              <p className="form-error-message">{formikProps.errors.website}</p>
            )}
            <div className="your-details-form-input">
              <label
                htmlFor="role"
                className={
                  formikProps.touched.role && formikProps.errors.role
                    ? "your-details-form-input__label your-details-form-input__label--error "
                    : "your-details-form-input__label"
                }
              >
                Specify Role
              </label>
              <input
                type="text"
                id="role"
                name="role"
                value={formikProps.values.role}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                className="your-details-form-input__input"
              />
            </div>
            {formikProps.touched.role && formikProps.errors.role && (
              <p className="form-error-message">{formikProps.errors.role}</p>
            )}
            <div className="your-details-form-input your-details-form-input--text-area-collaborate">
              <label
                htmlFor="how-collaborate"
                className={
                  formikProps.touched.howCollaborate &&
                  formikProps.errors.howCollaborate
                    ? "your-details-form-input__label your-details-form-input__label--error "
                    : "your-details-form-input__label"
                }
              >
                How would you like to collaborate with us?
              </label>
              <textarea
                id="how-collaborate"
                name="howCollaborate"
                value={formikProps.values.howCollaborate}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                className="your-details-form-input__text-area"
              ></textarea>
            </div>
            {formikProps.touched.howCollaborate &&
              formikProps.errors.howCollaborate && (
                <p className="form-error-message">
                  {formikProps.errors.howCollaborate}
                </p>
              )}
            {showError && <p className="form-error-message">{errorMessage}</p>}
            <div className="event-details-captcha">
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                badge="inline"
                size="normal"
                theme="dark"
                ref={reCaptchaRef}
                sitekey={CAPTCHA_SITE_KEY}
                onBlur={formikProps.handleBlur}
                onChange={(value) => {
                  formikProps.setFieldValue("recaptcha", value);
                  setVerifed(true);
                }}
              />
            </div>
            <div className="event-details-submit">
              <Button
                buttontype="primary"
                children="Let's Party"
                cname={
                  !(formikProps.dirty && formikProps.isValid)
                    ? "disabled-btn"
                    : "event-details-submit-button"
                }
                type="submit"
                disabled={!(formikProps.dirty && formikProps.isValid && verfied)}
                linkTo=""
              />
            </div>
          </form>
        </>
      )}
      {formSuccess && (
        <div className="event-form-success">
          <h4 className="event-form-success__title">
            Your request has been sent!
          </h4>
          <p className="event-form-success__text">
            A member of The Annex team will get back to you soon to confirm the
            details of your collaboration request.
          </p>
          <div className="event-form-success__button-container">
            <Button
              buttontype="primary"
              children="Back to Events"
              onClick={() => {
                setOpenEventsModal(false);
                // setTimeout(() => setFormSuccess(false), 600);
              }}
              linkTo=""
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Collaborate;
