import { useUser, useFirestore, useFirestoreDocData } from "reactfire";
import { doc } from "firebase/firestore";

export const useUserData = () => {
  const { data: user } = useUser();

  const userRef = doc(useFirestore(), `customers/${user?.email}`);

  const { status, data: userData } = useFirestoreDocData(userRef);

  return {
    userData,
    userRef,
    status,
  };
};
