import './ImageGroup.scss';

type ImageGroupProps = {
  LeftColumnImgSrc?: string;
  LeftColumnImgAlt?: string;
  LeftColumnImgTitle?: string;
  RightColumnTopImgSrc?: string;
  RightColumnTopImgAlt?: string;
  RightColumnTopImgTitle?: string;
  RightColumnBottomLeftImgSrc?: string;
  RightColumnBottomLeftImgAlt?: string;
  RightColumnBottomLeftImgTitle?: string;
  RightColumnBottomRightImgSrc?: string;
  RightColumnBottomRightImgAlt?: string;
  RightColumnBottomRightImgTitle?: string;
}

const ImageGroup = (props: ImageGroupProps) => {
  const { 
    LeftColumnImgSrc, 
    LeftColumnImgAlt, 
    LeftColumnImgTitle, 
    RightColumnTopImgSrc, 
    RightColumnTopImgAlt, 
    RightColumnTopImgTitle, 
    RightColumnBottomLeftImgSrc, 
    RightColumnBottomLeftImgAlt, 
    RightColumnBottomLeftImgTitle, 
    RightColumnBottomRightImgSrc, 
    RightColumnBottomRightImgAlt, 
    RightColumnBottomRightImgTitle,
  } = props;
  
  return (
    <div className="annex-image-group">
      <div className="annex-image-group__left-column">
        <div className="annex-image-group__left-column-image-container">
          <img 
            className="annex-image-group__left-column-image"
            src={LeftColumnImgSrc} 
            alt={LeftColumnImgAlt} 
            title={LeftColumnImgTitle} 
            loading="lazy"
          />
        </div>
      </div>
      <div className="annex-image-group__right-column">
        <div className="annex-image-group__right-column-top-image-container">
          <img 
            className="annex-image-group__right-column-top-image"
            src={RightColumnTopImgSrc} 
            alt={RightColumnTopImgAlt} 
            title={RightColumnTopImgTitle}
            loading="lazy"
          />
        </div>
        <div className="annex-image-group__right-column-bottom-two-images">
          <div className="annex-image-group__right-column-bottom-left-image-container">
            <img 
              className="annex-image-group__right-column-bottom-left-image"
              src={RightColumnBottomLeftImgSrc} 
              alt={RightColumnBottomLeftImgAlt} 
              title={RightColumnBottomLeftImgTitle} 
              loading="lazy"
            />
          </div>
          <div className="annex-image-group__right-column-bottom-right-image-container">
            <img 
              className="annex-image-group__right-column-bottom-right-image"
              src={RightColumnBottomRightImgSrc} 
              alt={RightColumnBottomRightImgAlt} 
              title={RightColumnBottomRightImgTitle} 
              loading="lazy"
            />
          </div>

        </div>
      </div>
    </div>
  )
};

export default ImageGroup;
