import { collection, doc } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData, useFirestoreDocDataOnce } from "reactfire";

const useMews = () => {
  const mewsCollectionRef = collection(useFirestore(), 'mews');
  const mewsConfigDoc = doc(useFirestore(), "mews/config");
  const mewsPaymentDoc = doc(useFirestore(), "mews/payment");
  
  const {
    data: mews
  } = useFirestoreCollectionData(mewsCollectionRef, {idField: 'id'});

  const {
    data: config
  } = useFirestoreDocDataOnce(mewsConfigDoc, {idField: 'id'});

  const {
    data: payment
  } = useFirestoreDocDataOnce(mewsPaymentDoc, { idField: "id" });


  return {
    mews,
    mewsCollectionRef,
    mewsConfigDoc,
    config,
    mewsPaymentDoc,
    payment
  }
};

export default useMews;
