import GotQuestions from "../../components/GotQuestions/GotQuestions";
import "./FAQs.scss";
import FAQsAccordion from "./FAQsAccordion";
import MetaDecorator from "../../MetaDecorator";

const FAQs = () => {
  return (
    <>
      <MetaDecorator title="FAQs" description="Sample description" />
      <div className="faqs">
        <div className="faqs-header">
          <h1 className="faqs-header__title">FAQs</h1>
          <p className="faqs-header__body">You got questions, we got answers</p>
        </div>

        <h3 className="faqs-title">Room Details</h3>

        <div className="faqs__room-details">
          <FAQsAccordion
            title="When can I check in?"
            paragraph="Check in is 3pm. Check out is 11am. Don't fret, if you need to sleep off last night's party, late checkout is available for a small fee. Just let our host know."
          />
          <FAQsAccordion
            title="What if something comes up and I need to cancel?"
            paragraph="Shit happens. We get it. You can change or cancel your reservation until 3pm on the day prior to check-in."
          />
          <FAQsAccordion
            title="Is there an elevator?"
            paragraph="There is not. Keep that in mind if you tend to overpack or have an accessibility need (we’re happy to be your porter when you’re here)."
          />
          <FAQsAccordion
            title="What about room service?"
            paragraph="Oh ya. Text the Host to get a copy of our rotational menu and enjoy it in your room."
          />
          <FAQsAccordion
            title="Can I bring my dog?"
            paragraph="We are dog friendly! We kindly ask that your furry companions adhere to the same respect policies our human guests do; No noise complaints and don’t chew the furniture. We permit pups up to 25lbs. Pet guests are subject to a one time $50 + HST cleaning fee."
          />
          <FAQsAccordion
            title="Can I party in my room?"
            paragraph="You do you. Just as long as you don’t wake up the neighbours. We are always happy to host you and your friends in the commons or lobby bar."
          />
          <FAQsAccordion
            title="What about room service?"
            paragraph="Oh ya. Text the Host to get a copy of our rotational menu and enjoy it in your room."
          />
          <FAQsAccordion
            title="What’s the age restriction?"
            paragraph="We require our guests to be 21 years of age or older."
          />
        </div>

        <h3 className="faqs-title">Getting Around</h3>

        <div className="faqs__room-details">
          <FAQsAccordion
            title="Is there parking?"
            paragraph="There is a lot directly beside the hotel that charges a rate of $2 per half hour, with no limit, operated by Toronto’s Municipal parking. Your parking session can be monitored through their app for IOS and Android freeing you of frequent visits to the metre. For usage in the app, lot 288 is the space directly in our alley."
          />
          <FAQsAccordion
            title="What are my transportation options?"
            paragraph="Subway and buses are where it’s at. We are a short walk to the Bathurst or Spadina subway stations. Buses stop right outside our front door at Bloor & Brunswick. Billy Bishop airport is a 15 minute drive. Our alley also has a bike share hub."
          />
        </div>

        <h3 className="faqs-title">Amenities</h3>

        <div className="faqs__room-details">
          <FAQsAccordion
            title="Are there meeting spaces?"
            paragraph="What you do in your room is up to you! For larger gatherings, you can ask about reserving our ground floor spaces."
          />
          <FAQsAccordion
            title="Is there a gym?"
            paragraph="Not onsite, but our city is your playground. Take a jog through High Park, or along the lake on the Waterfront trail. Hit the beachside gym in February, and we’ll have a post workout drink waiting for you. "
          />
        </div>

        <h3 className="faqs-title">Join the Team</h3>

        <div className="faqs__room-details">
          <FAQsAccordion
            title="Can I work at the annex?"
            paragraph="We’d love to work with you. Check out our openings "
            linkTo="/careers"
            linkToText="here"
            punctuation="."
          />
        </div>
      </div>

      <div className="faqs-got-questions">
        <GotQuestions
          title="Get answers to your questions."
          children="Text Us"
          buttonHoverText="+1 647 694 9868"
          hasATag={true}
          linkTo=""
        />
      </div>
    </>
  );
};

export default FAQs;
