import "./EventBookingModal.scss";

import { useState, useEffect, useRef } from "react";

import OutsideClickHandler from "react-outside-click-handler";

import EventBookingAccordion from "../EventBookingAccordion/EventBookingAccordion";
import Icon from "../Global/Icon/Icon";
import Button from "../Global/Button/Button";

import {
  // yourDetailsArray,
  willThereBeFoodArray,
  occasionArray,
  // ourSpacesDescriptionsArray,
} from "./EventDataArrays";

import BookingCalendar from "../Global/BookingCalendar/BookingCalendar";

import * as yup from "yup";
import { useFormik } from "formik";
import moment from "moment";

import { addDoc } from "firebase/firestore";
import { useMailCollection } from "../../hooks/useMailCollection";
import ReCAPTCHA from "react-google-recaptcha";

export type BookOurSpaceProps = {
  openEventsModal: boolean;
  setOpenEventsModal: (i: boolean) => void;
  bookOnly: boolean;
  setBookOnly: (i: boolean) => void;
};

const BookOurSpace = ({
  openEventsModal,
  setOpenEventsModal,
  bookOnly,
  setBookOnly,
  ...props
}: BookOurSpaceProps) => {
  const { mailCollectionRef } = useMailCollection();

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [verfied, setVerifed] = useState(false);

  const handleCalendarOpen = (e: any) => {
    setCalendarOpen(!calendarOpen);
  };
  // function onChange(value: any) {
  //   console.log("Captcha value:", value);
  //   setVerifed(true);
  // }

  // const [value, onChange] = useState<Date[]>([new Date(), new Date()]);
  // const CAPTCHA_SITE_KEY = `6Lc3rRYiAAAAALfHyHj2kruCbxzPgQhVaMh71MzE`; // version 3
  const CAPTCHA_SITE_KEY = `6LeNPh8iAAAAAPjauigd_W51w9avrbbxTRgMf0EM`; // version 2
  // const CAPTCHA_SITE_KEY = `6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI`; // for testing locally
  const reCaptchaRef = useRef(null);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const bookOurSpaceSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Invalid phone number")
      .min(10, "Phone number must be at least 10 characters")
      .required("Phone number is required"),
    date: yup.date().required("Date is required"),
    numberOfGuests: yup
      .number()
      .min(2, "Must have more than one guest")
      .required("Number of guests is required"),
    startTime: yup
      .string()
      .required("Start time is required")
      .test(
        "is-greater",
        "Start time must be 9:00AM or later",
        function (value) {
          return moment(value, "HH:mm").isSameOrAfter(moment("9:00", "HH:mm"));
        }
      )
      .test("is-less", "Start time is too late", function (value) {
        return moment(value, "HH:mm").isSameOrBefore(moment("22:00", "HH:mm"));
      }),
    endTime: yup
      .string()
      .required("End time is required")
      .test("is-greater", "End time should be greater", function (value) {
        const { startTime } = this.parent;
        return moment(value, "HH:mm").isSameOrAfter(moment(startTime, "HH:mm"));
      })
      .test(
        "is-greater",
        "End time must be 10:00AM or later",
        function (value) {
          return moment(value, "HH:mm").isSameOrAfter(moment("10:00", "HH:mm"));
        }
      )
      .test("is-less", "End time is too late", function (value) {
        return moment(value, "HH:mm").isSameOrBefore(moment("23:59", "HH:mm"));
      }),
    food: yup.string().required("Will there be food is required"),
    occasion: yup.string().required("Occasion is required"),
    recaptcha: yup.string().required("Please verify you are not a robot"),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    date: new Date(),
    numberOfGuests: "",
    startTime: "",
    endTime: "",
    food: "",
    occasion: "",
    details: "",
    recaptcha: "",
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema: bookOurSpaceSchema,
    onSubmit: async (values, { resetForm }) => {
      //do we need to make additional event details a required field?
      if (
        values.firstName &&
        values.lastName &&
        values.email &&
        values.phoneNumber &&
        values.date &&
        values.numberOfGuests &&
        values.startTime &&
        values.endTime &&
        values.food &&
        values.occasion &&
        values.recaptcha
      ) {
        try {
          await addDoc(mailCollectionRef, {
            //change to hello@theannex.com once Mack approves email formatting/details
            //change in firebase extension as well
            to: "events@theannex.com",
            message: {
              subject: "New 'Book Our Space' request!",
              // change any of this text? any feedback from Mack?
              html: `${
                values.firstName + " " + values.lastName
              } would like to book our space! The details of the event booking request are as follows:
              <br>
              <br>
              First name: ${values.firstName}
              <br>
              Last name: ${values.lastName}
              <br>
              Email: ${values.email}
              <br>
              Phone number: ${values.phoneNumber}
              <br>
              Date: ${values.date.toDateString()}
              <br>
              Number of guests: ${values.numberOfGuests}
              <br>
              Start time: ${values.startTime}
              <br>
              End time: ${values.endTime}
              <br>
              Will there be food?: ${values.food}
              <br>
              What is the occasion?: ${values.occasion}
              <br>
              Additional event details: ${values.details}
              <br>
              Verification: ${values.recaptcha}
              `,
            },
            replyTo: values.email,
          }).then(() => {
            setShowError(false);
            setErrorMessage("");
            setFormSuccess(true);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
            resetForm();
          });
        } catch (err: any) {
          console.error("err", err);
          // console.log("error message", err.message);
          setShowError(true);
          setErrorMessage("An unknown error has occurred. Please try again.");
        }
      }
    },
  });

  const dateSelected = formikProps.values.date.toLocaleDateString("default", {
    month: "short",
    day: "numeric",
  });

  useEffect(() => {
    !openEventsModal && setTimeout(() => setFormSuccess(false), 600);
  });

  return (
    <div>
      {!formSuccess && (
        <>
          <h4 className="form-title">Your Details</h4>
          <form
            className="your-details-form"
            onSubmit={formikProps.handleSubmit}
          >
            <div className="form-two-columns-inputs-container">
              <div className="form-input-half-width">
                <div className="your-details-form-input">
                  <label
                    htmlFor="first-name"
                    className={
                      formikProps.touched.firstName &&
                      formikProps.errors.firstName
                        ? "your-details-form-input__label your-details-form-input__label--error "
                        : "your-details-form-input__label"
                    }
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    name="firstName"
                    value={formikProps.values.firstName}
                    onChange={formikProps.handleChange}
                    className="your-details-form-input__input"
                    onBlur={formikProps.handleBlur}
                  />
                </div>
                {formikProps.touched.firstName &&
                  formikProps.errors.firstName && (
                    <p className="form-error-message">
                      {formikProps.errors.firstName}
                    </p>
                  )}
              </div>
              <div className="form-input-half-width">
                <div className="your-details-form-input">
                  <label
                    htmlFor="last-name"
                    className={
                      formikProps.touched.lastName &&
                      formikProps.errors.lastName
                        ? "your-details-form-input__label your-details-form-input__label--error "
                        : "your-details-form-input__label"
                    }
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="last-name"
                    name="lastName"
                    value={formikProps.values.lastName}
                    onChange={formikProps.handleChange}
                    className="your-details-form-input__input"
                    onBlur={formikProps.handleBlur}
                  />
                </div>
                {formikProps.touched.lastName &&
                  formikProps.errors.lastName && (
                    <p className="form-error-message">
                      {formikProps.errors.lastName}
                    </p>
                  )}
              </div>
            </div>
            <div className="your-details-form-input">
              <label
                htmlFor="email"
                className={
                  formikProps.touched.email && formikProps.errors.email
                    ? "your-details-form-input__label your-details-form-input__label--error "
                    : "your-details-form-input__label"
                }
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formikProps.values.email}
                onChange={formikProps.handleChange}
                className="your-details-form-input__input"
                onBlur={formikProps.handleBlur}
              />
            </div>
            {formikProps.touched.email && formikProps.errors.email && (
              <p className="form-error-message">{formikProps.errors.email}</p>
            )}
            <div className="your-details-form-input">
              <label
                htmlFor="phone-number"
                className={
                  formikProps.touched.phoneNumber &&
                  formikProps.errors.phoneNumber
                    ? "your-details-form-input__label your-details-form-input__label--error "
                    : "your-details-form-input__label"
                }
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone-number"
                name="phoneNumber"
                value={formikProps.values.phoneNumber}
                onChange={formikProps.handleChange}
                className="your-details-form-input__input"
                onBlur={formikProps.handleBlur}
              />
            </div>
            {formikProps.touched.phoneNumber &&
              formikProps.errors.phoneNumber && (
                <p className="form-error-message">
                  {formikProps.errors.phoneNumber}
                </p>
              )}
            <h4 className="form-title">Event Details</h4>
            <div className="form-two-columns-inputs-container">
              <OutsideClickHandler
                onOutsideClick={() => setCalendarOpen(false)}
                display="contents"
              >
                <div
                  className={`event-accordion form-input-half-width white-border ${
                    calendarOpen && "focus-accordion"
                  }`}
                >
                  <div
                    className={
                      calendarOpen
                        ? "event-accordion-header event-accordion-header--active"
                        : "event-accordion-header"
                    }
                    onClick={handleCalendarOpen}
                  >
                    <div className="event-accordion-header-selector">
                      <p className="event-accordion-header-selector__title">
                        Date
                      </p>
                      <p className="event-accordion-header-selector__text">
                        {dateSelected}
                      </p>
                    </div>
                    <div className="event-accordion-header-icon">
                      <Icon name="calendar" className="event-calendar-icon" />
                    </div>
                  </div>
                  <BookingCalendar
                    value={[formikProps.values.date]}
                    onChange={(e: Date) => {
                      formikProps.setFieldValue("date", e);
                    }}
                    onBlur={formikProps.handleBlur}
                    name={"date"}
                    isActive={calendarOpen}
                    whiteText={true}
                    bookEvent={true}
                    cname="calendar-book-event"
                    validationProps={formikProps}
                    returnValue="start"
                  />

                  {formikProps.touched.date && formikProps.errors.date && (
                    <p className="form-error-message">
                      {formikProps.errors.date}
                    </p>
                  )}
                </div>
              </OutsideClickHandler>
              <div className="form-input-half-width">
                <div className="your-details-form-input">
                  <label
                    htmlFor="guests"
                    className={
                      formikProps.touched.numberOfGuests &&
                      formikProps.errors.numberOfGuests
                        ? "your-details-form-input__label your-details-form-input__label--error "
                        : "your-details-form-input__label"
                    }
                  >
                    Number of Guests
                  </label>
                  <input
                    type="text"
                    id="guests"
                    name="numberOfGuests"
                    value={formikProps.values.numberOfGuests}
                    onChange={formikProps.handleChange}
                    className="your-details-form-input__input"
                    onBlur={formikProps.handleBlur}
                  />
                </div>

                {formikProps.touched.numberOfGuests &&
                  formikProps.errors.numberOfGuests && (
                    <p className="form-error-message">
                      {formikProps.errors.numberOfGuests}
                    </p>
                  )}
              </div>
            </div>

            <div className="form-two-columns-inputs-container">
              <div className="form-input-half-width">
                <div className="your-details-form-input">
                  <label
                    htmlFor="start-time"
                    className={
                      formikProps.touched.startTime &&
                      formikProps.errors.startTime
                        ? "your-details-form-input__label your-details-form-input__label--error "
                        : "your-details-form-input__label"
                    }
                  >
                    Start Time
                  </label>
                  <input
                    type="time"
                    id="start-time"
                    name="startTime"
                    className="your-details-form-input__input your-details-form-input__input--time"
                    value={formikProps.values.startTime}
                    onChange={formikProps.handleChange}
                    // min="09:00"
                    // max="22:00"
                    onBlur={formikProps.handleBlur}
                  />
                </div>
                {formikProps.touched.startTime &&
                  formikProps.errors.startTime && (
                    <p className="form-error-message">
                      {formikProps.errors.startTime}
                    </p>
                  )}
              </div>

              <div className="form-input-half-width">
                <div className="your-details-form-input">
                  <label
                    htmlFor="end-time"
                    className={
                      formikProps.touched.endTime && formikProps.errors.endTime
                        ? "your-details-form-input__label your-details-form-input__label--error "
                        : "your-details-form-input__label"
                    }
                  >
                    End Time
                  </label>
                  <input
                    type="time"
                    id="end-time"
                    name="endTime"
                    className="your-details-form-input__input your-details-form-input__input--time"
                    value={formikProps.values.endTime}
                    onChange={formikProps.handleChange}
                    // min="09:00"
                    // max="22:00"
                    onBlur={formikProps.handleBlur}
                  />
                </div>
                {formikProps.touched.endTime && formikProps.errors.endTime && (
                  <p className="form-error-message">
                    {formikProps.errors.endTime}
                  </p>
                )}
              </div>
            </div>
            <div className="form-two-columns-inputs-container">
              <div className="form-input-half-width">
                <EventBookingAccordion
                  className="white-border"
                  headerTitle="Will there be food?"
                  labelHtmlFor="food"
                  labelClassName={
                    formikProps.touched.food && formikProps.errors.food
                      ? "your-details-form-input__label your-details-form-input__label--error "
                      : "your-details-form-input__label"
                  }
                  listDataSrc={willThereBeFoodArray}
                  onSelect={(value: string) => {
                    formikProps.setFieldValue("food", value);
                  }}
                  fieldInput={
                    <input
                      type="text"
                      name="food"
                      id="food"
                      value={formikProps.values.food}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      className="event-accordion-header-selector__text"
                      readOnly
                    />
                  }
                />
                {formikProps.touched.food && formikProps.errors.food && (
                  <p className="form-error-message">
                    {formikProps.errors.food}
                  </p>
                )}
              </div>
              <div className="form-input-half-width">
                <EventBookingAccordion
                  className="white-border"
                  headerTitle="What is the occasion?"
                  labelHtmlFor="occasion"
                  labelClassName={
                    formikProps.touched.occasion && formikProps.errors.occasion
                      ? "your-details-form-input__label your-details-form-input__label--error "
                      : "your-details-form-input__label"
                  }
                  listDataSrc={occasionArray}
                  onSelect={(value: string) => {
                    formikProps.setFieldValue("occasion", value);
                  }}
                  fieldInput={
                    <input
                      type="text"
                      name="occasion"
                      id="occasion"
                      value={formikProps.values.occasion}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      className="event-accordion-header-selector__text"
                      readOnly
                    />
                  }
                />
                {formikProps.touched.occasion &&
                  formikProps.errors.occasion && (
                    <p className="form-error-message">
                      {formikProps.errors.occasion}
                    </p>
                  )}
              </div>
            </div>
            <div className="your-details-form-input your-details-form-input--text-area">
              <label
                htmlFor="end-time"
                className="your-details-form-input__label"
              >
                Additional Event Details
              </label>
              <textarea
                id="details"
                name="details"
                value={formikProps.values.details}
                onChange={formikProps.handleChange}
                className="your-details-form-input__text-area"
                onBlur={formikProps.handleBlur}
              />
            </div>
            {showError && <p className="form-error-message">{errorMessage}</p>}
            <div className="event-details-captcha">
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                badge="inline"
                size="normal"
                theme="dark"
                ref={reCaptchaRef}
                sitekey={CAPTCHA_SITE_KEY}
                onBlur={formikProps.handleBlur}
                onChange={(value) => {
                  formikProps.setFieldValue("recaptcha", value);
                  setVerifed(true);
                }}
              />
            </div>

            <div className="event-details-submit">
              <Button
                buttontype="primary"
                children="Let's Party"
                cname={
                  !(formikProps.dirty && formikProps.isValid)
                    ? "disabled-btn"
                    : "event-details-submit-button"
                }
                type="submit"
                disabled={!(formikProps.dirty && formikProps.isValid && verfied)}
                linkTo=""
              />
            </div>
          </form>
        </>
      )}
      {formSuccess && (
        <div className="event-form-success">
          <h4 className="event-form-success__title">
            Your request has been sent!
          </h4>
          <p className="event-form-success__text">
            A member of The Annex team will get back to you soon to confirm the
            details of your booking request.
          </p>
          <div className="event-form-success__button-container">
            <Button
              buttontype="primary"
              children="Back to Events"
              onClick={() => {
                setOpenEventsModal(false);
                setBookOnly(false);
                // setTimeout(() => setFormSuccess(false), 600);
              }}
              linkTo=""
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BookOurSpace;
