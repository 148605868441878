import { IconsType } from "../../assets/icons";
import "./BookingRoom.scss";
import Button from "../../components/Global/Button/Button";
import Icon from "../../components/Global/Icon/Icon";
import ButtonArrow from "../../components/Global/ButtonArrow/ButtonArrow";

import RoomAmenitiesModal from "../../components/RoomAmenitiesModal/RoomAmenitiesModal";
import OutsideClickHandler from "react-outside-click-handler";
import { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Room } from "../../../shared/types/room";

type BookingRoomProps = {
  room: Room;
  onSelect: (room: Room) => void;
  selectedBedTypeIcon: IconsType;
  setSelectedBedTypeIcon: (i: IconsType) => void;
  selectedRoomTypeIcon: IconsType;
  setSelectedRoomTypeIcon: (i: IconsType) => void;
  selectedGuestCountIcon: IconsType;
  setSelectedGuestCountIcon: (i: IconsType) => void;
  setSelectedGuestCount: (i: string) => void;
  setSelectedRoomDescription: (i: string) => void;
  setSelectedRoomImage: (i: string) => void;
};

const BookingRoom = ({
  room,
  onSelect,
  selectedBedTypeIcon,
  setSelectedBedTypeIcon,
  selectedRoomTypeIcon,
  setSelectedRoomTypeIcon,
  selectedGuestCountIcon,
  setSelectedGuestCountIcon,
  setSelectedGuestCount,
  setSelectedRoomDescription,
  setSelectedRoomImage,
  ...props
}: BookingRoomProps) => {
  // const {room, onSelect} = props;
  const [bedTypeIcon, setBedTypeIcon] = useState<IconsType>("queen"); // TODO: set based on bed type
  const [roomTypeIcon, setRoomTypeIcon] = useState<IconsType>(">290sqft"); // TODO: set based on room type
  const [guestCountIcon, setGuestCountIcon] =
    useState<IconsType>("oneGuestPerson");
  const [showAmenities, setShowAmenities] = useState(false);
  const handleShowAmenities = () => {
    setShowAmenities(!showAmenities);
  };
  const handleCloseAmenities = () => {
    setShowAmenities(false);
  };

  //carousel items

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const directionButtons = (direction: string) => {
    return (
      <span
        aria-hidden="true"
        className={
          direction === "Next" ? "button-next-room" : "button-prev-room"
        }
      >
        {direction === "Next" ? (
          <Icon
            name="next-arrow"
            className="button-next-icon-room"
            color="black"
          />
        ) : (
          <Icon
            name="back-arrow"
            className="button-prev-icon-room"
            color="black"
          />
        )}
      </span>
    );
  };

  useEffect(() => {
    const generateBedType = () => {
      // queen, king
      setBedTypeIcon("queen");
    };

    const generateRoomType = () => {
      // "200sqft", "200-240sqft", ">290sqft", "330sqft"
      setRoomTypeIcon(">290sqft");
    };

    const generateGuestCountIcon = () => {
      if (room && room.bedCount) {
        const bedCount = parseInt(room.bedCount);

        if (bedCount === 1) {
          setGuestCountIcon("oneGuestPerson");
        }
        if (bedCount === 2) {
          setGuestCountIcon("twoGuestsPerson");
        }
        if (bedCount === 3) {
          setGuestCountIcon("threeGuestsPerson");
        }
        if (bedCount === 4) {
          setGuestCountIcon("fourGuestsPerson");
        }
      }
    };

    generateBedType();
    generateRoomType();
    generateGuestCountIcon();

    return () => {};
  }, [room, room.bedCount]);

  return (
    <div className="booking-room">
      <OutsideClickHandler onOutsideClick={handleCloseAmenities}>
        <RoomAmenitiesModal
          className="hide-modal"
          onClick={handleShowAmenities}
          showAmenities={showAmenities}
        />
      </OutsideClickHandler>
      <div className="booking-room__image-container">
        <Carousel
          //  ref={ref}
          interval={null}
          controls={true}
          indicators={false}
          nextLabel={""}
          prevLabel={""}
          activeIndex={index}
          onSelect={handleSelect}
          nextIcon={directionButtons("Next")}
          prevIcon={directionButtons("Previous")}
          className="hero-carousel-room"
        >
          {room.imageUrls?.map((imageUrl, index): any => {
            return (
              <Carousel.Item key={index} className="carousel-item-room">
                <img
                  className="carousel-item-room__image"
                  src={imageUrl}
                  alt={imageUrl}
                  loading="lazy"
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
        <h2 className="booking-room__title">{room.name}</h2>
      </div>

      <div className="booking-room__info">
        <div className="booking-room__total-button">
          <h2 className="booking-room__total">${room.price}</h2>
          <Button
            cname="booking-room__button"
            buttontype="primary--inverse"
            children="Select Room"
            onClick={() => {
              onSelect(room);
              // setSelectedBedTypeIcon(""); //revise once have proper Annex MEWS data
              // setSelectedRoomTypeIcon(""); //revise once have proper Annex MEWS data
              setSelectedGuestCountIcon(guestCountIcon);
              setSelectedGuestCount(room.bedCount); //revise once have proper Annex MEWS data
              setSelectedRoomDescription(room.description);
              setSelectedRoomImage(room.mainImageUrl);
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
            linkTo=""
          ></Button>
        </div>

        <div className="booking-room__room-info">
          <div className="booking-room__room-info-column">
            <Icon
              className="booking-room__room-info-icon booking-room__room-info-icon--bed"
              name={bedTypeIcon}
              color="#004C45"
            />
            <p className="booking-room__room-info-text">{room.bedCount}</p>
          </div>
          <div className="booking-room__room-info-column">
            <Icon
              className="booking-room__room-info-icon booking-room__room-info-icon--room"
              name={roomTypeIcon}
              color="#004C45"
            />
            <p className="booking-room__room-info-text">{room.type}</p>
          </div>
          <div className="booking-room__room-info-column">
            <Icon
              className="booking-room__room-info-icon booking-room__room-info-icon--guest"
              name={guestCountIcon}
              color="#004C45"
            />
            <p className="booking-room__room-info-text">{room.bedCount}</p>
          </div>
        </div>

        <ButtonArrow
          buttontype="main"
          children="See Amenities"
          // linkTo={props.linkTo}
          linkTo="#"
          onClick={handleShowAmenities}
        />
      </div>
    </div>
  );
};

export default BookingRoom;
