import { createContext } from "react";
import { Size } from "./hooks/useWindowSize";

export type AppContextProps = {
  size: Size;
  bookingAPI: any;
  openBookingEngine: (startDate?: Date, endDate?: Date) => any,
}

const AppContext = createContext<AppContextProps>({
  size: {
    width: undefined,
    height: undefined,
  },
  bookingAPI: null,
  openBookingEngine: () => {},
});

export default AppContext;