import MetaDecorator from "../../MetaDecorator";
import GotQuestions from "../../components/GotQuestions/GotQuestions";
import BlogItem from "./BlogItem";
import useBlogs from "../../hooks/useBlogs";

import "./Blog.scss";

const Blog = () => {
  const blogs = useBlogs().blogs;
  
  const convertDate = (date: any) => {
    const blogDate = date.toMillis();
    return `${new Date(blogDate).toDateString()}`;
  };

  return (
    <>
      <MetaDecorator title="Blog" description="Sample description" />
      <div className="blog">
        <h2 className="blog__title">The Lobby</h2>
        <div className="blog__items">
          {blogs && (
            blogs.map((blog, index) =>
            <a 
              href={`/the-lobby/${blog.id}`} 
              key={index}
            >
              <BlogItem
                title={blog.title}
                authorName={blog.authorName}
                // date={convertDate(blog.Date)}
                date=""
                header_image={blog.header_image}
                imgAlt={blog.header_image}
              />
            </a>
          ))}
        </div>
      </div>

      <div className="blog__got-questions">
        <GotQuestions
          title="Get answers to your questions."
          children="Send us a text message"
          buttonHoverText="+1 647 694 9868"
          hasATag={true}
        />
      </div>
    </>
  );
};

export default Blog;
