import { collection } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

const useProducts = () => {
  const productsCollectionRef = collection(useFirestore(), 'products');

  const {
    data: products
  } = useFirestoreCollectionData(productsCollectionRef, {idField: 'id'});

  return {
    products,
    productsCollectionRef
  }
};

export default useProducts;