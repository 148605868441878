import "./InfoImageCard.scss";
import Button from "../Button/Button";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export type InfoImageCardProps = {
  title?: string;
  name?: string;
  subName?: string;
  paragraph?: string;
  imageSrc?: string;
  imageAlt?: string;
  imageTitle?: string;
  mobileImageSrc?: string;
  mobileImageAlt?: string;
  mobileImageTitle?: string;
  buttonText?: string;
  buttonLinkTo?: string;
  linkChildren?: ReactNode;
};

const InfoImageCard = (props: InfoImageCardProps) => {
  return (
    <div
      className={`info-image-card ${
        props.title ? "info-image-card--has-title" : ""
      }`}
    >
      {props.imageSrc && (
        <div className="info-image-card__image-container">
          {props.imageSrc && (
            <img
              className="info-image-card__image desktop-only"
              src={props.imageSrc}
              alt={props.imageAlt}
              title={props.imageTitle}
              loading="lazy"
            />
          )}
          {props.mobileImageSrc && (
            <img
              className="info-image-card__image mobile-only"
              src={props.mobileImageSrc}
              alt={props.mobileImageAlt}
              title={props.mobileImageTitle}
              loading="lazy"
            />
          )}
          {props.linkChildren && <>{props.linkChildren}</>}
        </div>
      )}
      <div className="info-image-card__content">
        {props.title && (
          <h2 className="info-image-card__title">{props.title}</h2>
        )}
        <div className="info-image-card__info">
          {props.name && (
            <h2 className="info-image-card__name">{props.name}</h2>
          )}
          {props.subName && (
            <p className="info-image-card__sub-name">{props.subName}</p>
          )}
          {props.paragraph && (
            <p className="info-image-card__paragraph">{props.paragraph}</p>
          )}
        </div>
        <Link to={props.buttonLinkTo ? props.buttonLinkTo : "#"}>
          <Button
            buttontype="primary--inverse"
            children={props.buttonText}
            linkTo=""
          />
        </Link>
      </div>
    </div>
  );
};

export default InfoImageCard;
