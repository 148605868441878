import "./JoinCommunityForm.scss";

import { useState, useEffect } from "react";

import * as yup from "yup";
import { useFormik } from "formik";

import Button from "../Global/Button/Button";
import SendingAnimation from "../Global/SendingAnimation/SendingAnimation";
import { addFlodeskSubscriber } from "../../pages/Flodesk/flodeskApi";

type CustomFormProps = {
  status?: any;
  message?: any;
  title?: string;
  body?: string;
  showMailingListModal?: boolean;
  setShowMailingListModal: (i: boolean) => void;
  joinMailingList?: boolean;
  subscribeToMailingList?: boolean;
  subscribeToMailingListMobile?: boolean;
  children?: string;
};

const CustomForm = ({
  message,
  title,
  body,
  showMailingListModal,
  setShowMailingListModal,
  joinMailingList,
  subscribeToMailingList,
  subscribeToMailingListMobile,
  children,
  ...props
}: CustomFormProps) => {
  const [alreadySubscribed, setAlreadySubscribed] = useState<boolean>(false);

  const joinMailingListSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const initialValues = {
    email: "",
    firstName: '',
    lastName: ''
  };

  const [status, setStatus] = useState('')

  const formikProps = useFormik({
    initialValues,
    validationSchema: joinMailingListSchema,
    onSubmit: async (values, { resetForm, setFieldError }) => {
      setStatus(await addFlodeskSubscriber(values.email, values.firstName, values.lastName));
      return;
    },
  });

  useEffect(() => {
    if (status === "success") {
      setTimeout(() => {
        setShowMailingListModal(false);
        formikProps.resetForm();
      }, 3000);
    }
    if (
      status === "error" &&
      message
        .toLowerCase()
        .includes(`${formikProps.values.email} is already subscribed`)
    ) {
      setAlreadySubscribed(true);
      setTimeout(() => {
        setShowMailingListModal(false);
        formikProps.resetForm();
      }, 3000);
    } else if (status === "error") {
      formikProps.setFieldError("email", message);
      setAlreadySubscribed(false);
    }
  }, [status]);

  return (
    <form className="join-mailing-list" onSubmit={formikProps.handleSubmit}>
      <h4 className="join-mailing-list__title">{title}</h4>
      <p className="join-mailing-list__body">{body}</p>
      <div className="join-mailing-list-enter-email">
        <div className="annex-form-group-container input-button-spacing">
          <div className="annex-form-group">
            <label
              className={`annex-form__label`}
            >
              First Name
            </label>
            <input
              className="annex-form__input"
              id="firstName"
              name="firstName"
              type="text"
              value={formikProps.values.firstName}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
            />
          </div>
          <div className="annex-form-group">
            <label
              className={`annex-form__label`}
            >
              Last Name
            </label>
            <input
              className="annex-form__input"
              id="lastName"
              name="lastName"
              type="text"
              value={formikProps.values.lastName}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
            />
          </div>
          <div className="annex-form-group">
            <label
              className={`annex-form__label ${formikProps.touched.email && formikProps.errors.email
                ? "annex-form__label--error"
                : ""
                }`}
            >
              Email
            </label>
            <input
              className="annex-form__input"
              id="email"
              name="email"
              type="text"
              value={formikProps.values.email}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
            />
          </div>
          {formikProps.touched.email && formikProps.errors.email && (
            <p className="annex-form__error-message mailchimp-error">
              {formikProps.errors.email}
            </p>
          )}
          {/* {console.log("status", status)} */}
          {/* {console.log("message", message)} */}
          {status === "sending" && <SendingAnimation />}
          {status === "success" &&
            (subscribeToMailingList || subscribeToMailingListMobile) ? (
            <p className="annex-form__success-message">
              Thanks for subscribing!
            </p>
          ) : (
            status === "success" &&
            joinMailingList && (
              <p className="annex-form__success-message">
                Thanks for joining our mailing list!
              </p>
            )
          )}
          {alreadySubscribed &&
            (subscribeToMailingList || subscribeToMailingListMobile) ? (
            <p className="annex-form__success-message">
              It looks like you're already subscribed. Thanks for subscribing!
            </p>
          ) : (
            alreadySubscribed &&
            joinMailingList && (
              <p className="annex-form__success-message">
                It looks like you've already joined our mailing list, thank you!
              </p>
            )
          )}
        </div>
        <div className="join-mailing-list-enter-email__button">
          <Button
            buttontype="secondary"
            children={children}
            cname={
              !(formikProps.dirty && formikProps.isValid)
                ? "join-mailing-list-enter-email__button--disabled"
                : ""
            }
            type="submit"
            disabled={!(formikProps.dirty && formikProps.isValid)}
            linkTo=""
          />
        </div>
      </div>
    </form>
  );
};

type JoinCommunityFormType = {
  title?: string;
  body?: string;
  showMailingListModal?: boolean;
  setShowMailingListModal: (i: boolean) => void;
  joinMailingList?: boolean;
  subscribeToMailingList?: boolean;
  subscribeToMailingListMobile?: boolean;
  children?: string;
};

const JoinCommunityForm = ({
  title,
  body,
  showMailingListModal,
  setShowMailingListModal,
  joinMailingList,
  subscribeToMailingList,
  subscribeToMailingListMobile,
  children,
  ...props
}: JoinCommunityFormType) => {
  return (
    <div className="mc__form-container">
      {/*  <MailchimpSubscribe */}
      {/*  url={postUrl}
        render={({ subscribe, status, message }) => ( */}
      <CustomForm
        // status={status}
        // message={message}
        // onValidated={(formData: any) => subscribe(formData)}
        title={title}
        body={body}
        showMailingListModal={showMailingListModal}
        setShowMailingListModal={setShowMailingListModal}
        joinMailingList={joinMailingList}
        subscribeToMailingList={subscribeToMailingList}
        subscribeToMailingListMobile={subscribeToMailingListMobile}
        children={children}
      />
      {/*  )}
       /> */}
    </div>
  );
};

export default JoinCommunityForm;
