import "./Neighbourhood.scss";
import GotQuestions from "../../components/GotQuestions/GotQuestions";
// import AccordionBottomMenuMobile from "../../components/AccordionBottomMenuMobile/AccordionBottomMenuMobile";
// import BookingMenuAccordion from "../../components/BookingMenu/BookingMenuAccordion";
import { useEffect, useState } from "react";
import MetaDecorator from "../../MetaDecorator";
import LogoCircle from "../../assets/icons/annex-circle-icon.png";
import GreenCircleIcon from "../../assets/icons/green-circle-icon.png";
import NeighbourhoodCarousel from "./NeighbourhoodCarousel/NeighbourhoodCarousel";
import PageBanner from "../../components/Global/PageBanner/PageBanner";
import BookingMenuAccordion, {
  BookingMenuAccordionItem,
} from "../../components/BookingMenu/BookingMenuAccordion";
import {
  NeighbourhoodMapMarkerFoods,
  NeighbourhoodMapMarkerCocktails,
  NeighbourhoodMapMarkerCoffees,
  NeighbourhoodMapMarkerShopping,
  NeighbourhoodMapMarkerOthers,
  NeighbourhoodFoods,
  NeighbourhoodFoodsDesktop,
  NeighbourhoodCocktails,
  NeighbourhoodCocktailsDesktop,
  NeighbourhoodCoffees,
  NeighbourhoodCoffeesDesktop,
  NeighbourhoodShopping,
  NeighbourhoodShoppingDesktop,
  NeighbourhoodOthers,
  NeighbourhoodOthersDesktop,
} from "./neighbourhoodMockData";
import {
  NeighbourhoodCarouselDesktopType,
  NeighbourhoodCarouselType,
} from "../../models/NeighbourhoodCarousel";
import { NeighbourhoodMapMarker } from "../../models/NeighbourhoodMapMarker";
import { mapStyles } from "../../util/util";
declare var google: any;

const Neighbourhood = () => {
  // const [openMenu, setOpenMenu] = useState(false);
  const [neighbourhoodMarkers, setNeighbourhoodMarkers] = useState<
    NeighbourhoodMapMarker[]
  >([]);
  const [neighbourhoods, setNeighbourhoods] =
    useState<NeighbourhoodCarouselType[]>();
  const [neighbourhoodsDesktop, setNeighbourhoodsDesktop] =
    useState<NeighbourhoodCarouselDesktopType[]>();
  const [headerSubTitle, setHeaderSubTitle] = useState("-");
  const [heightState, setHeightState] = useState("0px");
  const [activeState, setActiveState] = useState("");
  const listOptions = ["Food", "Coffee", "Cocktails", "Shopping", "Others"];

  const initMap = () => {
    const theAnnexPosition: google.maps.LatLngLiteral = {
      lat: 43.6654713,
      lng: -79.4080313,
    };
    const mapOptions = {
      center: theAnnexPosition,
      zoom: 15,
      styles: mapStyles,
      mapTypeControl: false,
      streetViewControl: false,
    };
    const mapElement = document.getElementById(
      "neighbourhoodMap"
    ) as HTMLElement;
    const map: google.maps.Map = new google.maps.Map(mapElement, mapOptions);
    const infoWindow = new google.maps.InfoWindow();
    const annexMarker = new google.maps.Marker({
      position: theAnnexPosition,
      map: map,
      icon: LogoCircle,
      title: "The Annex Hotel",
    });

    annexMarker.addListener("click", () => {
      infoWindow.close();
      infoWindow.setContent(annexMarker.getTitle());
      infoWindow.open(annexMarker.getMap(), annexMarker);
    });

    neighbourhoodMarkers.forEach((neighbourhoodMarker) => {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          neighbourhoodMarker.lat,
          neighbourhoodMarker.lng
        ),
        map,
        title: neighbourhoodMarker.title,
        optimized: false,
        icon: GreenCircleIcon,
      });

      marker.addListener("click", () => {
        infoWindow.close();
        infoWindow.setContent(marker.getTitle());
        infoWindow.open(marker.getMap(), marker);
      });
    });
  };

  const selectOption = (e: any) => {
    setHeaderSubTitle(e);
    setHeightState("0px");
    setActiveState("");

    if (e === "Food") {
      setNeighbourhoodMarkers(NeighbourhoodMapMarkerFoods);
      setNeighbourhoods(NeighbourhoodFoods);
      setNeighbourhoodsDesktop(NeighbourhoodFoodsDesktop);
    } else if (e === "Cocktails") {
      setNeighbourhoodMarkers(NeighbourhoodMapMarkerCocktails);
      setNeighbourhoods(NeighbourhoodCocktails);
      setNeighbourhoodsDesktop(NeighbourhoodCocktailsDesktop);
    } else if (e === "Coffee") {
      setNeighbourhoodMarkers(NeighbourhoodMapMarkerCoffees);
      setNeighbourhoods(NeighbourhoodCoffees);
      setNeighbourhoodsDesktop(NeighbourhoodCoffeesDesktop);
    } else if (e === "Shopping") {
      setNeighbourhoodMarkers(NeighbourhoodMapMarkerShopping);
      setNeighbourhoods(NeighbourhoodShopping);
      setNeighbourhoodsDesktop(NeighbourhoodShoppingDesktop);
    } else if (e === "Others") {
      setNeighbourhoodMarkers(NeighbourhoodMapMarkerOthers);
      setNeighbourhoods(NeighbourhoodOthers);
      setNeighbourhoodsDesktop(NeighbourhoodOthersDesktop);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      initMap();
    }, 1000);
  });

  return (
    <>
      <MetaDecorator title="Neighbourhood" description="Sample description" />

      <div className="neighbourhood-container">
        <PageBanner
          title="Get to know the neighbours"
          type="Neighbourhood"
          children={<div id="neighbourhoodMap"></div>}
        />

        <div className="neighbourhood-container__Carousel">
          <NeighbourhoodCarousel
            items={neighbourhoods}
            itemsDesktop={neighbourhoodsDesktop}
            selectDropDownChildren={
              <div className="annex-form-group-container annex-form-group-container--neighbourhood">
                <BookingMenuAccordion
                  headerTitle={"I'm looking for ..."}
                  headerSubTitle={headerSubTitle}
                  containerClassName={"neighbourhood-dropdown-container"}
                  dropdownClassName={"neighbourhood-dropdown"}
                  heightState={heightState}
                  activeState={activeState}
                  itemsChildren={
                    <>
                      {listOptions.map((option, index) => (
                        <BookingMenuAccordionItem
                          key={index}
                          label={option}
                          onClick={() => selectOption(option)}
                        />
                      ))}
                    </>
                  }
                  mobileActive={true}
                />
              </div>
            }
          />
        </div>

        <div className=" neighbourhood-container__gotQuestion">
          <GotQuestions
            title="Get answers to your questions."
            children="Text Us"
            buttonHoverText="+1 647 694 9868"
            hasATag={true}
            linkTo=""
          />
        </div>

        {/* <AccordionBottomMenuMobile
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          menuTitle={"Refine my choices"}
          menuOptions={[
            <BookingMenuAccordion
              headerTitle={"I'm looking for"}
              headerSubTitle={"Some Exercise"}
              headerWidth={"mobile-menu"}
              listDataSrc={["Swimming Pool", "Internet Connection"]}
              mobileActive={openMenu}
              // bookRoom={true}
            />,
            <BookingMenuAccordion
              headerTitle={"I'm willing to walk"}
              headerSubTitle={"5-10- Minutes"}
              headerWidth={"mobile-menu"}
              listDataSrc={[
                "10-20- Minutes",
                "20-30- Minutes",
                "30-60- Minutes",
              ]}
              mobileActive={openMenu}
              // bookRoom={true}
            />,
            <BookingMenuAccordion
              headerTitle={"Tell us about yourself"}
              headerSubTitle={"Im feeling carefree"}
              headerWidth={"mobile-menu"}
              listDataSrc={["Im feeling great", "Im feeling Energized"]}
              mobileActive={openMenu}
              // bookRoom={true}
            />,
            <BookingMenuAccordion
              headerTitle={"How much?"}
              headerSubTitle={"$-$$"}
              headerWidth={"mobile-menu"}
              listDataSrc={["$5 - 10$", "$10 - 20$", "$20 - 30$"]}
              mobileActive={openMenu}
              // bookRoom={true}
            />,
            <BookingMenuAccordion
              headerTitle={"Whose going?"}
              headerSubTitle={"1-2 Guests"}
              headerWidth={"mobile-menu"}
              listDataSrc={["2 Guests", "2-3 Guests", "3-4 Guests"]}
              mobileActive={openMenu}
              // bookRoom={true}
            />,
          ]}
        /> */}
      </div>
    </>
  );
};
export default Neighbourhood;
