import { useEffect, useState } from "react";
import BookingItem from "../../../components/BookingItem/BookingItem";
import MetaDecorator from "../../../MetaDecorator";
import {
  mockUpcomingItems,
  mockPastItems,
  mockCancelledItems,
} from "../mockData";
import AuthTwoColumnLayout from "../AuthTwoColumnLayout";
import "./AuthMyBookings.scss";
import { useUserData } from "../../../hooks/useUserData";
import { DocumentReference, DocumentData } from "firebase/firestore";
import useUserReservations from "../../../hooks/useUserReservations";

type AuthMyBookingsViewProps = {
  userRef: DocumentReference<DocumentData>;
  userData: DocumentData;
};

const AuthMyBookingsView = (props: AuthMyBookingsViewProps) => {
  const {
    // userRef,
    userData,
  } = props;
  const reservations = useUserReservations(userData.email).reservations;

  const [upcomingTab, setUpcomingTab] = useState<boolean>(true);
  const [pastTab, setPastTab] = useState<boolean>(false);
  const [cancelledTab, setCancelledTab] = useState<boolean>(false);

  const [upcomingItems, setUpcomingItems] = useState<any[]>([]);
  const [pastItems, setPastItems] = useState<any[]>([]);
  const [cancelledItems, setCancelledItems] = useState<any[]>([]);

  const resetTabs = () => {
    setUpcomingTab(false);
    setPastTab(false);
    setCancelledTab(false);
  };

  //function to find out if the end date of the reservation is in the past
  //if the end date is today, it's considered in the past

  const isDateInPast = (firstDate: Date, secondDate: Date) => {
    if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setUpcomingItems(mockUpcomingItems);
    setPastItems(mockPastItems);
    setCancelledItems(mockCancelledItems);

    return () => {
      setUpcomingItems([]);
      setPastItems([]);
      setCancelledItems([]);
    };
  }, [upcomingItems, pastItems, cancelledItems]);

  return (
    <>
      <MetaDecorator
        title="My Bookings | Annex"
        description=" Sample description"
      />
      <AuthTwoColumnLayout
        rightColumnChildren={
          <>
            <div className="annex-auth-two-column-layout__right-column-container">
              <div className="annex-tabs annex-tabs--mobile-only">
                <button
                  className={`annex-tabs__tab ${
                    upcomingTab ? "annex-tabs__tab--active" : ""
                  }`}
                  onClick={() => [resetTabs(), setUpcomingTab(true)]}
                >
                  Upcoming
                </button>
                <button
                  className={`annex-tabs__tab ${
                    pastTab ? "annex-tabs__tab--active" : ""
                  }`}
                  onClick={() => [resetTabs(), setPastTab(true)]}
                >
                  Past
                </button>
                <button
                  className={`annex-tabs__tab ${
                    cancelledTab ? "annex-tabs__tab--active" : ""
                  }`}
                  onClick={() => [resetTabs(), setCancelledTab(true)]}
                >
                  Cancelled
                </button>
              </div>

              <h2 className="annex-auth-two-column-layout__title">
                My Bookings
              </h2>

              <div className="annex-tabs annex-tabs--desktop-only">
                <button
                  className={`annex-tabs__tab ${
                    upcomingTab ? "annex-tabs__tab--active" : ""
                  }`}
                  onClick={() => [resetTabs(), setUpcomingTab(true)]}
                >
                  Upcoming
                </button>
                <button
                  className={`annex-tabs__tab ${
                    pastTab ? "annex-tabs__tab--active" : ""
                  }`}
                  onClick={() => [resetTabs(), setPastTab(true)]}
                >
                  Past
                </button>
                <button
                  className={`annex-tabs__tab ${
                    cancelledTab ? "annex-tabs__tab--active" : ""
                  }`}
                  onClick={() => [resetTabs(), setCancelledTab(true)]}
                >
                  Cancelled
                </button>
              </div>

              {upcomingTab && (
                <div className="annex-tab-content">
                  {/* {console.log("my reservations", reservations)} */}
                  {reservations?.map((reservation, index: number) => {
                    const startDate = reservation.start.toDate().toDateString();
                    const endDate = reservation.end.toDate().toDateString();

                    const today = new Date();
                    const pastDate = new Date(endDate);

                    isDateInPast(pastDate, today);

                    return (
                      !isDateInPast(pastDate, today) &&
                      reservation.state === "Confirmed" && (
                        <BookingItem
                          key={index}
                          imgSrc={reservation.mainImageUrl}
                          imgAlt={reservation.name}
                          imgTitle={reservation.name}
                          name={reservation.name}
                          date={`${startDate} - ${endDate}`}
                          linkTo={"#"}
                          linkText={"Booking Details"}
                          upcoming={true}
                          reservationId={reservation.id}
                        />
                      )
                    );
                  })}
                </div>
              )}

              {pastTab && (
                <div className="annex-tab-content">
                  {reservations?.map((reservation, index: number) => {
                    const startDate = reservation.start.toDate().toDateString();
                    const endDate = reservation.end.toDate().toDateString();

                    const today = new Date();
                    const pastDate = new Date(endDate);

                    isDateInPast(pastDate, today);

                    return (
                      isDateInPast(pastDate, today) &&
                      reservation.state !== "Canceled" && (
                        <BookingItem
                          key={index}
                          imgSrc={reservation.mainImageUrl}
                          imgAlt={reservation.name}
                          imgTitle={reservation.name}
                          name={reservation.name}
                          date={`${startDate} - ${endDate}`}
                          linkTo={"#"}
                          linkText={"Booking Details"}
                        />
                      )
                    );
                  })}
                </div>
              )}

              {cancelledTab && (
                <div className="annex-tab-content">
                  {reservations.map((reservation, index: number) => {
                    const startDate = reservation.start.toDate().toDateString();
                    const endDate = reservation.end.toDate().toDateString();

                    return (
                      reservation.state === "Canceled" && (
                        <BookingItem
                          key={index}
                          imgSrc={reservation.mainImageUrl}
                          imgAlt={reservation.name}
                          imgTitle={reservation.name}
                          name={reservation.name}
                          date={`${startDate} - ${endDate}`}
                          // linkTo={"#"}
                          // linkText={"Booking Details"}
                        />
                      )
                    );
                  })}
                </div>
              )}
            </div>
          </>
        }
      />
    </>
  );
};

const AuthMyBookings = () => {
  const { userRef, userData } = useUserData();

  return (
    <>
      {userData && <AuthMyBookingsView userRef={userRef} userData={userData} />}
    </>
  );
};

export default AuthMyBookings;
