import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Global/Button/Button";
import "./AuthLogin.scss";

import * as yup from "yup";
import { useFormik } from "formik";

import MetaDecorator from "../../../MetaDecorator";
import {
  signInWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import { useAuth } from "reactfire";

const AuthLogin = () => {
  const provider = new GoogleAuthProvider();
  const auth = useAuth();
  const history = useHistory();
  const passwordRegExp =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;

  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [resetPasswordErrors, setResetPasswordErrors] = useState(false);
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] =
    useState("");

  const handleForgotPassword = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      const email = formikProps.values.email;
      const auth = getAuth();

      await sendPasswordResetEmail(auth, email)
        .then(() => {
          setResetEmailSent(true);
          setTimeout(() => setResetEmailSent(false), 5000);
        })
        .catch((error) => {
          // console.log(" login page error", error.message);

          if (!formikProps.values.email) {
            setResetPasswordErrors(true);
            setResetPasswordErrorMessage(
              "Email is required to reset your password"
            );
          }

          if (error.message === "Firebase: Error (auth/too-many-requests).") {
            formikProps.setFieldError(
              "password",
              "Too many requests, try again later"
            );
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const loginSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .matches(
        passwordRegExp,
        "Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit and one special character"
      )
      .required("Password is required"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { resetForm, setFieldError }) => {
      await signInWithEmailAndPassword(auth, values.email, values.password)
        .then(() => {
          history.push("/mybookings");
          resetForm();
          // console.log("auth", auth);
        })
        .catch((err) => {
          console.error(err);
          // console.log("error", err.message);
          //   if (!values.password) {
          //     setFieldError("password", "Password is required");
          //   }
          if (err.message === "Firebase: Error (auth/wrong-password).") {
            setFieldError("password", "Incorrect password");
          }

          if (err.message === "Firebase: Error (auth/user-not-found).") {
            setFieldError("email", "User not found");
          }
        });
    },
  });

  const signInGooglePopUp = async () => {
    const auth = getAuth();
    await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        // The signed-in user info.
        const user = result.user;
        // ...
        history.push("/mybookings");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        // console.log("error code", errorCode);
        const errorMessage = error.message;
        // console.log("error message", errorMessage);
        // The email of the user's account used.
        const email = error.email;
        // console.log("error email", email);
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // console.log("credential", credential);
        // ...
      });
  };

  //   const signInGoogleRedirect = () => {
  //     const authSignIn = getAuth();
  //     signInWithRedirect(authSignIn, provider);
  //     getRedirectResult(authSignIn)
  //       .then((result) => {
  //         // This gives you a Google Access Token. You can use it to access Google APIs.
  //         //   const credential = GoogleAuthProvider.credentialFromResult(result);
  //         //   const token = credential?.accessToken;
  //         console.log("result", result);

  //         // The signed-in user info.
  //         const user = result?.user;
  //         console.log("user", user);
  //         history.push("/mybookings");
  //       })
  //       .catch((error) => {
  //         // Handle Errors here.
  //         const errorCode = error.code;
  //         const errorMessage = error.message;
  //         console.log("error", error);
  //         console.log("error message", errorMessage);
  //         // The email of the user's account used.
  //         const email = error.email;
  //         // The AuthCredential type that was used.
  //         const credential = GoogleAuthProvider.credentialFromError(error);
  //         // ...
  //       });
  //     // const auth = getAuth();
  //     // await signInWithRedirect(auth, provider);
  //   };

  useEffect(() => {
    if (formikProps.values.email) {
      setResetPasswordErrors(false);
      setResetPasswordErrorMessage("");
    }
  }, [formikProps.values.email]);

  return (
    <>
      <MetaDecorator title="Login | Annex" description="Sample description" />
      <div className="auth-container">
        <h2 className="annex-form-title">Login to your account</h2>
        <form className="annex-form" onSubmit={formikProps.handleSubmit}>
          <div className="annex-form-group-container">
            <div className="annex-form-group">
              <label
                className={`annex-form__label ${
                  formikProps.touched.email && formikProps.errors.email
                    ? "annex-form__label--error"
                    : ""
                }`}
              >
                Email
              </label>
              <input
                className="annex-form__input"
                id="email"
                name="email"
                type="text"
                value={formikProps.values.email}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
              />
            </div>
            {formikProps.touched.email && formikProps.errors.email && (
              <p className="annex-form__error-message">
                {formikProps.errors.email}
              </p>
            )}
            {resetPasswordErrors && !formikProps.touched.email && (
              <p className="annex-form__error-message">
                {resetPasswordErrorMessage}
              </p>
            )}
          </div>
          <div className="annex-form-group-container">
            <div className="annex-form-group">
              <label
                className={`annex-form__label ${
                  formikProps.touched.password && formikProps.errors.password
                    ? "annex-form__label--error"
                    : ""
                }`}
              >
                Password
              </label>
              <input
                className="annex-form__input"
                id="password"
                name="password"
                type="password"
                value={formikProps.values.password}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
              />
            </div>
            <div className={`errors-forgot-password`}>
              <button
                className="forgot-password"
                onClick={handleForgotPassword}
                type="button"
              >
                Forgot your password?
              </button>
            </div>
            {formikProps.touched.password && formikProps.errors.password && (
              <p className="annex-form__error-message">
                {formikProps.errors.password}
              </p>
            )}
            {resetEmailSent && (
              <p className="annex-form__success-message">
                An email was sent to the provided email address to reset your
                password
              </p>
            )}
          </div>
          <div className="login-button-container">
            <Button
              buttontype="primary--inverse"
              children="Login"
              cname={`login-button
                ${
                  !(formikProps.dirty && formikProps.isValid)
                    ? "disabled-btn-black"
                    : ""
                }
              `}
              disabled={!(formikProps.dirty && formikProps.isValid)}
              type="submit"
              linkTo=""
            />
            <div className="sign-in-google-container">
              <button
                className="sign-in-google"
                onClick={signInGooglePopUp}
                type="button"
              ></button>
              {/* <button
                className="sign-in-google mobile-only"
                onClick={signInGoogleRedirect}
                type="button"
              ></button> */}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AuthLogin;
