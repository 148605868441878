type TermsProps = {
  title: string;
  descriptions: string;
};

export const terms: TermsProps[] = [
  {
    title: "1. Acceptance of the Terms of Use",
    descriptions: `These Terms of Use are entered into by and between you and Perfect Fill Hotel Group LTD (operating as Annex Hotels) (“COMPANY”, “WE”, “US” or “OUR”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use”), govern your access to and use of <a href='https://theannex.com' target="_blank">theannex.com</a>, including any content, functionality, and services offered on or through <a href='https://theannex.com' target="_blank">theannex.com</a> (the “WEBSITE“). Please read the Terms of Use carefully before you start to use the Website. By using the Website or by clicking to accept or agree to the Terms of Use, you accept and agree to be bound by these Terms of Use. If you do not want to agree to these Terms of Use, you must not access or use the Website.`,
  },
  {
    title: "2. Booking Terms",
    descriptions: `These Terms of Use are entered into by and between you and Perfect Fill Hotel Group LTD (operating as Annex Hotels) (“COMPANY”, “WE”, “US” or “OUR”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use”), govern your access to and use of <a href='https://theannex.com' target="_blank">theannex.com</a>, including any content, functionality, and services offered on or through.`,
  },
  {
    title: "3. Changes to these Terms",
    descriptions:
      "We reserve the right to change these Terms of Use at any time upon notice. We may give notice by posting the updated Terms " +
      "of Use on the Website or by any other reasonable means. You can review the most current version of these Terms of Use at any time at this website address. " +
      "The Terms of Use in effect at the time of your use of the Website apply. Updated Terms of Use are binding on you with respect to your use of the Website " +
      "on or after the date indicated in the updated Terms of Use. If you do not agree to the updated Terms of Use, you must stop using the Website. Your continued" +
      " use of the Website after the date of the updated Terms of Use will constitute your acceptance of the updated Terms of Use.",
  },
  {
    title: "4. Accessing the Website",
    descriptions:
      "We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, " +
      "in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period." +
      " From time to time, we may restrict access to some parts of the Website, or the entire Website, to certain users.",
  },
  {
    title: "5. Intellectual Property Rights",
    descriptions:
      "The Website and its entire contents, features, and functionality (including but not limited to all information, " +
      "software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by the Company, " +
      "its licensors, or other providers of such material and are protected by Canada and international copyright, trademark, patent, trade secret, " +
      "and other intellectual property or proprietary rights laws. You must not reproduce, distribute, modify, create derivative works of, " +
      "publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website without our prior written consent. " +
      "The Company name, the Company logo, and all related names, logos, product and service names, designs, and slogans are trademarks " +
      "of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. " +
      "All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners." +
      " Under no circumstances will you acquire any ownership rights or other interest in any such marks or content on this Website by or through your use of the Website.",
  },
  {
    title: "6. Permitted Uses",
    descriptions:
      "You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not (a) to use the Website " +
      "in any way that violates any applicable federal, state, local, or international law or regulation, (b) use the Website for any unauthorized," +
      " fraudulent, or malicious purpose, (c) to engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Website, or which," +
      " as determined by us, may harm the Company or users of the Website or expose them to liability, (d) use the Website in any manner that could disable, " +
      "overburden, damage, or impair the site, (e) use any robot, spider, or other automatic device, process, or means to access the Website for any purpose," +
      " including monitoring or copying any of the material on the Website, (f) to access systems, data or information not intended by us to be made " +
      "accessible to a user, (g) to obtain or attempt to obtain any materials or information through any means not intentionally made available by us; " +
      "or (f) to use the Website for any use other than the business purpose for which it was intended.",
  },
  {
    title: "7. Reliance on Information Posted",
    descriptions:
      "Mistakes happen and as a result we do not warrant the accuracy, completeness, or usefulness of any information on the Website. " +
      "Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any " +
      "reliance placed on such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents. " +
      "We may update the content on this Website from time to time, but its content is not necessarily complete or up-to-date. " +
      "Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material. " +
      "In the event a room rate is mistakenly listed at an incorrect price, or mistakenly described or mistakenly listed as available, " +
      "we reserve the right to refuse or cancel any booking you make in reliance on such mistake.  If you have already been charged and " +
      "made payment for such booking, we will issue a refund for the amount paid.",
  },
  {
    title: "8. No Offers",
    descriptions:
      "You may use the Website to book a stay at one of our hotels. All bookings may be subject to additional terms and conditions which " +
      "may be presented to you through the Website when you book your stay or when you check-in to one of our hotels. These additional terms and conditions" +
      " (referred to herein as the “Booking Terms”) are incorporated into these Terms of Use by reference.",
  },
  {
    title: "9. Links from the website",
    descriptions:
      "If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. " +
      "This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents " +
      "of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. " +
      "If you decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk and subject" +
      " to the terms and conditions of use for such websites.",
  },
  {
    title: "10. Disclaimer of Warranties",
    descriptions:
      "Your use of the website and its content are at your own risk. the website and its content are provided on an “as is” and “as available” basis, without any warranties of any kind, either express or implied. Neither the company nor any person associated with the company makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the website. Without limiting the foregoing, neither the company nor anyone associated with the company represents or warrants that the website or its content will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected, that our site or the server that makes it available are free of viruses or other harmful components, or that the website or its content will otherwise meet your needs or expectations. To the fullest extent provided by law, the company hereby disclaims all warranties of any kind, whether express or implied, statutory, or otherwise, including but not limited to any warranties of merchantability, non-infringement, and fitness for particular purpose.",
  },
  {
    title: "11. Limitation of Liability",
    descriptions:
      "To the fullest extent provided by law, in no event will the company, its affiliates, or their licensors, service providers, employees, agents, officers, or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your use, or inability to use, the website, any websites linked to it, or any content on the website or such other websites, including any direct, indirect, special, incidental, consequential, or punitive damages, and including but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract, or otherwise, even if foreseeable.",
  },
  {
    title: "12. Indemnification",
    descriptions:
      "You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, " +
      "and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from " +
      "and against any third-party claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) " +
      "arising out of or relating to (a) your violation of these Terms of Use or your use of the Website, or (b) your violation " +
      "of any other party’s rights or applicable law.",
  },
  {
    title: "13. Arbitration and Governing Law",
    descriptions:
      "These Terms of Use will be governed by and construed in accordance with the laws of the Province of Ontario, " +
      "without giving effect to its conflict of laws provisions. All disputes arising out of or in connection with these Terms of Use, " +
      "or in respect of any legal relationship associated with or derived from these Terms of Use, shall be finally resolved by arbitration, " +
      "under the Simplified Arbitration Rules of the ADR Institute of Canada, Inc.  The number of arbitrators shall be one.  " +
      "The seat of arbitration shall be Toronto, Ontario.  The language of the arbitration shall be English.",
  },
  {
    title: "14. Limitation on Time to File Claims",
    descriptions:
      "Any cause of action or claim you may have arising out of or relating to these terms of use or the website must be commenced within one year after the cause of action accrues, otherwise, the cause of action or claim is permanently barred.",
  },
];
