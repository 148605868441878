import "./Careers.scss";
import TwoColumnImage from "../../components/TwoColumnImage/TwoColumnImage";
import ImageTextColumn from "../../components/ImageTextColumn/ImageTextColumn";
import ourPeople from "../../assets/images/our-people.png";
import ourCulture from "../../assets/images/our-culture.png";
import girlWithBottle from "../../assets/images/girl-with-bottle.png";
import manWithCd from "../../assets/images/Man-with-cd.png";
import PeopleBW from "../../assets/images/people-bw.jpg";
import ManBarBW from "../../assets/images/man-bar-bw.jpg";
import GotQuestions from "../../components/GotQuestions/GotQuestions";
import careersAnnexImg from "../../assets/images/careers-annex.jpg";
import CurrentVacancies from "../../components/CurrentVacancies/CurrentVacancies";
import MetaDecorator from "../../MetaDecorator";
import PageBanner from "../../components/Global/PageBanner/PageBanner";

const Careers = () => {
  return (
    <>
      <MetaDecorator title="Careers" description="Sample description" />
      <div className="careers-container">
        <PageBanner
          title="Careers at The Annex"
          type="Careers"
          imgSrc={careersAnnexImg}
        />

        <div className="careers-container__people">
          <ImageTextColumn
            title={"Our People"}
            ImageSrc={ourPeople}
            imagePosition={"right"}
            ImageAlt={"Our people"}
            ImageTitle={"People"}
            paragraphChildren={
              <>
                <p className="annex-image-text-column-info__paragraph">
                  Annexed to Toronto in the late 19th century, the neighbourhood
                  has preserved its distinctive character ever since thanks to
                  activists and visionary urban planners.
                </p>
                <p className="annex-image-text-column-info__paragraph">
                  Centrally located between Yorkville and Koreatown, we’ve
                  partnered with the artists and shopkeepers who breathe life
                  into this unique neighbourhood.
                </p>
              </>
            }
          />
        </div>
        <div className="careers-container__culture">
          <ImageTextColumn
            title={"Our Culture"}
            ImageSrc={ourCulture}
            imagePosition={"left"}
            ImageAlt={"Our culture"}
            ImageTitle={"culture"}
            paragraphChildren={
              <>
                <p className="annex-image-text-column-info__paragraph">
                  Annexed to Toronto in the late 19th century, the neighbourhood
                  has preserved its distinctive character ever since thanks to
                  activists and visionary urban planners.
                </p>
                <p className="annex-image-text-column-info__paragraph">
                  Centrally located between Yorkville and Koreatown, we’ve
                  partnered with the artists and shopkeepers who breathe life
                  into this unique neighbourhood.
                </p>
              </>
            }
          />
        </div>

        <div className="careers-container__TwoColumnImage">
          <TwoColumnImage
            leftImgSrc={PeopleBW}
            leftImgAlt={"people in black and white"}
            leftImgTitle={"Girl Wine"}
            rightImgSrc={ManBarBW}
            rightImgAlt={"man at bar in black and white"}
            rightImgTitle={"CD Man"}
          />
        </div>
        <div className="careers-container__vacancies-container">
          <h2 className="careers-container__vacancies-Title">
            Current Vacancies
          </h2>
          {/*TODO CurrentVacancies needs to be Pulled  from API & map it if need it */}
          {/* <CurrentVacancies PositionTitle={"Position"} /> */}
          {/* <CurrentVacancies PositionTitle={"Position"} /> */}
          {/* <CurrentVacancies PositionTitle={"Position"} /> */}
        </div>
      </div>
      <div className="careers-got-questions">
        <GotQuestions
          title="Introduce yourself"
          children="Text Us"
          buttonHoverText="+1 647 694 9868"
          hasATag={true}
          linkTo=""
        />
      </div>
    </>
  );
};
export default Careers;
