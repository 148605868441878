import { ReactNode } from "react";
import './InfoImageGroup.scss';

export type InfoImageGroupProps = {
  leftImageSrc?: string;
  leftImageAlt?: string;
  leftImageTitle?: string;
  rightImageSrc?: string;
  rightImageAlt?: string;
  rightImageTitle?: string;
  cardChildren?: ReactNode;
  imageCardPosition?: 'top' | 'bottom';
  bottomTitle?: string;
}

const InfoImageGroup = (props: InfoImageGroupProps) => {
  return (
    <div className={`info-image-group info-image-group--image-card-position-${props.imageCardPosition}`}>
      <div className="info-image-group__left-column">
        {props.leftImageSrc &&
          <div className="info-image-group__left-column-image-container">
            <img 
              className="info-image-group__left-column-image"
              src={props.leftImageSrc} 
              alt={props.leftImageAlt} 
              title={props.leftImageTitle} 
              loading="lazy"
            />
          </div>}
      </div>
      <div className="info-image-group__right-column">
        {props.cardChildren && <>{props.cardChildren}</>}
        {props.rightImageSrc && 
          <div className="info-image-group__right-column-image-container">
            <img 
              className="info-image-group__right-column-image"
              src={props.rightImageSrc} 
              alt={props.rightImageAlt} 
              title={props.rightImageTitle} 
              loading="lazy"
            />
          </div>}
      </div>
      {props.bottomTitle && <h2 className="info-image-group__bottom-title">{props.bottomTitle}</h2>}
    </div>
  )
}

InfoImageGroup.defaultProps ={
  imageCardPosition: 'bottom'
}

export default InfoImageGroup;