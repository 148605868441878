import './RoomImagesSection.scss';
import ImageLoadAnimation from './TheBigOne/ImageLoadAnimation';

export type RoomImage = {
    src: string;
    alt: string;
    width: number | string;
    height: number | string;
    observe?: boolean;
}

export type RoomImagesSectionProps = {
    imgs: RoomImage[];
    onObserverImageInView: (inView: boolean) => void | any;
}


const RoomImagesSection = ({ onObserverImageInView, imgs, ...props}: RoomImagesSectionProps) => {
    const roomImages = imgs.map((img, idx) => ({...img, className: `room-images-section__${idx+1}`}))

    return (
        <div className='room-images-section'>
            {roomImages.map((image, index) => {
                return (
                    <ImageLoadAnimation
                        onInView={image.observe ? onObserverImageInView : undefined}
                        src={image.src}
                        alt={image.alt}
                        width={image.width}
                        height={image.height}
                        key={index}
                        className={image.className}
                    />
                )
            })}
        </div>
    )
}

export default RoomImagesSection;