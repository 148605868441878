import "./Rooms.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MetaDecorator from "../../MetaDecorator";
import useRooms from "../../hooks/useRooms";

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const updateMousePosition = (event: MouseEvent) => {
      setMousePosition({ x: event.pageX, y: event.pageY });
    };
    window.addEventListener("mousemove", updateMousePosition);

    return () => window.removeEventListener("mousemove", updateMousePosition);
  }, []);

  return mousePosition;
};

const Rooms = () => {
  const { x, y } = useMousePosition();
  const rooms = useRooms().rooms;

  // const roomsSorted = [rooms[0], rooms[1], rooms[3], rooms[2]];

  return (
    <>
      {rooms && (
        <div className="rooms">
          <MetaDecorator title="Annex Rooms" description="Sample description" />
          <h1 className="rooms__title"> Our Rooms</h1>
          {rooms.map((room, index) => (
            <Link
              key={index}
              to={`/rooms/${room.id}`}
              className={`rooms__link rooms__link--${index + 1}`}
            >
              {room.name}
            </Link>
          ))}
          <div
            className="rooms__images-container tablet-and-above"
            style={{ transform: `translate(${x}px, ${y}px)` }}
          >
            {rooms.map((room, index) => (
              <img
                key={index}
                src={room.mainImageUrl}
                alt={room.name}
                className={`rooms__image rooms__image--${index + 1}`}
                loading="lazy"
              />
            ))}
          </div>
          <div className="rooms__images-container mobile-below-tablet">
            {rooms.map((room, index) => (
              <img
                key={index}
                src={room.mainImageUrl}
                alt={room.name}
                className={`rooms__image rooms__image--${index + 1}`}
                loading="lazy"
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Rooms;
