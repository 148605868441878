import "./DiscoverCardCarousel.scss";

import DiscoverCard from "../../components/DiscoverCard/DiscoverCard";
import Icon from "../Global/Icon/Icon";

import {
  discoverCardCarouselData,
  musicCarouselData,
} from "./DiscoverCardCarouselData";

// import WineBar from '../../assets/images/live-local/wine-bar.jpg';
// import TheCommons from '../../assets/images/live-local/the-commons.jpg';
// // import TheBrunswickPatio from '../../assets/images/live-local/the-brunswick-patio.png';
// import AuntLucys from '../../assets/images/live-local/aunt-lucys.jpg';
// // import AnnexPlaceholder from '../../assets/images/annex-placeholder.png';
// import TheAnnexCafe from '../../assets/images/live-local/the-annex-cafe.jpg';

import { Carousel } from "react-bootstrap";
import { useState } from "react";

export type DiscoverCardCarouselProps = {
  title?: string;
  infoHeight?: string;
  experience?: boolean;
};

const DiscoverCardCarousel = ({
  title,
  infoHeight,
  experience,
  ...props
}: DiscoverCardCarouselProps) => {
  //   const { title, infoHeight, experience } = props;

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const directionButtons = (direction: string) => {
    return (
      <span
        aria-hidden="true"
        className={direction === "Next" ? "button-next-dcc" : "button-prev-dcc"}
      >
        {direction === "Next" ? (
          <Icon
            name="next-arrow"
            className="button-next-icon-dcc"
            color="black"
          />
        ) : (
          <Icon
            name="back-arrow"
            className="button-prev-icon-dcc"
            color="black"
          />
        )}
      </span>
    );
  };

  return (
    <>
      <h4 className="discover-card-carousel-title">{title}</h4>

      <Carousel
        //  ref={ref}
        interval={null}
        controls={true}
        indicators={false}
        nextLabel={""}
        prevLabel={""}
        activeIndex={index}
        onSelect={handleSelect}
        nextIcon={directionButtons("Next")}
        prevIcon={directionButtons("Previous")}
        className="dc-carousel mobile-only"
      >
        {(experience ? musicCarouselData : discoverCardCarouselData).map(
          (item, index) => {
            return (
              <Carousel.Item key={index} className="dc-carousel-item">
                <DiscoverCard
                  imageSrc={item.imageSrc}
                  imageAlt={item.imageAlt}
                  imageTitle={item.imageTitle}
                  title={item.title}
                  paragraph={item.paragraph}
                  buttonText={item.buttonText}
                  linkTo={item.linkTo}
                  infoHeight={infoHeight}
                  hash={item.hash}
                  // url={item.url}
                  experience={experience}
                />
              </Carousel.Item>
            );
          }
        )}
      </Carousel>
      <div className="discover-card-list-desktop desktop-only">
        {(experience ? musicCarouselData : discoverCardCarouselData).map(
          (item, index) => {
            return (
              <div className="discover-card-desktop" key={index}>
                <DiscoverCard
                  imageSrc={item.imageSrc}
                  imageAlt={item.imageAlt}
                  imageTitle={item.imageTitle}
                  title={item.title}
                  paragraph={item.paragraph}
                  buttonText={item.buttonText}
                  linkTo={item.linkTo}
                  infoHeight={infoHeight}
                  hash={item.hash}
                  // url={item.url}
                  experience={experience}
                />
              </div>
            );
          }
        )}
      </div>
    </>
  );
};

export default DiscoverCardCarousel;
