import './TwoColumnImage.scss';

export type TwoColumnImageProps = {
	leftImgSrc?: string;
	leftImgAlt?: string;
	leftImgTitle?: string;
	rightImgSrc?: string;
	rightImgAlt?: string;
	rightImgTitle?: string;
	title?: string;
	desc?: string;
}

const TwoColumnImage = (props: TwoColumnImageProps) => {
	return (
		<div className="two-column-image">
			<div className="two-column-image__left-column-image-container">
				<img
					className="two-column-image__left-column-image"
					src={props.leftImgSrc}
					alt={props.leftImgAlt}
					title={props.leftImgTitle}
					loading="lazy"
				/>
				<div className="two-column-image__left-content-bottom">
					{props.title && <h2 className="two-column-image__title">{props.title}</h2>}
					{props.desc && <p className="two-column-image__desc">{props.desc}</p>}
				</div>
			</div>
			<div className="two-column-image__right-column-image-container">
				<img
					className="two-column-image__right-column-image"
					src={props.rightImgSrc}
					alt={props.rightImgAlt}
					title={props.rightImgTitle}
					loading="lazy"
				/>
			</div>
		</div>
	)
}

export default TwoColumnImage;