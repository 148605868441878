import "./PrivacyPolicy.scss";
import React from "react";
import MetaDecorator from "../../MetaDecorator";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <MetaDecorator title="Privacy Policy" description="Sample description" />
      <div className="privacy">
        <h1 className="privacy__title">Privacy Policy</h1>
        <h2 className="privacy__subTitle">personal information we collect</h2>
        <div className="privacy_text_container">
          <p className="privacy_text_container--text">
            Perfect Fill Hotel Group (operating as Annex Hotels) (“COMPANY”,
            “ANNEX HOTELS”, “WE”, “US” or “OUR”) takes steps intended to meet
            privacy principles and requirements with respect to personal
            information under applicable Canadian privacy legislation. The
            purpose of this statement is to inform our customers and other
            individuals we deal with (“you” or “your”) how we collect, use,
            disclose and protect your personal information. Personal information
            is information about an identifiable individual, as more
            particularly described under applicable privacy legislation. This
            statement applies to our collection, use and disclosure of personal
            information in Canada. This statement does not apply to information
            about our employees or to information that is not personal
            information.
          </p>
          <p className="privacy_text_container--text">
            The Company and its agents and representatives collect personal
            information in a number of circumstances in the course of operating
            and managing a hotel and hospitality business. Personal information
            we collect includes:
          </p>
          <ul className="privacy_text_container--text--list">
            <li>
              name, address, telephone number, and other contact information;
            </li>
            <li>transaction history, purchase history, request history;</li>
            <li>customer profiles, customer preferences;</li>
            <li>
              such other information we may collect with your consent or as
              permitted or required by law;
            </li>
          </ul>
          <p className="privacy_text_container--text">
            At Annex Hotels we stress the importance of privacy and are
            committed to earning the trust of our guests by adopting high
            standards for the protection of personal information.
          </p>
          <p className="privacy_text_container--text">
            Our policy outlines the type of personal information we collect and
            receive, the circumstances in which we collect or receive personal
            information, the policies and procedures we have established
            outlining its use and storage, and for sharing certain types of
            personal information in certain limited circumstances, the
            procedures you should follow if you have any questions or requests
            in respect of your personal information or our policies and
            procedures and the person to whom such questions or requests should
            be directed, and the means by which to communicate with that person.
          </p>
          <h3 className="privacy_text_container_title">
            How you may provide us with your personal information
          </h3>
          <ul className="privacy_text_container--text--list">
            <li>By making a reservation or by using Annex Hotels services.</li>
            <li>
              By providing the information to us by participating in a marketing
              initiative.
            </li>
            <li>
              By providing the information to us by participating in a marketing
              initiative.
            </li>
            <li>By visiting our website.</li>
          </ul>
          <p className="privacy_text_container--text">
            Below we will outline the type of information normally collected in
            each of these circumstances, the reasons for doing so, and how we
            will use it and store it.
          </p>
          <h3 className="privacy_text_container_title">Making a Reservation</h3>
          <p className="privacy_text_container--text">
            When making a reservation or using Annex Hotels services it is
            necessary to have information in order to identify you, contact you
            and to process your purchase and requests. This information usually
            includes your name, address, phone number, email address, credit
            card number and expiration date, and language preference. It may
            also include, if you choose to share that information with us, your
            preferences regarding the delivery of your service such as type of
            room, type of bed, and the like. You may wish to have your purchase
            of Annex Hotels' services recognized in a third party's loyalty
            program with which Annex Hotels has a relationship. To do so will
            require you providing us certain verification information such as
            your membership number in the third-party program. We also offer
            special discounts from time to time that may have qualifying
            criteria. To assess your eligibility and process your reservation
            correctly, we may need to collect additional information at the time
            of booking such as your date of birth.
          </p>
          <p className="privacy_text_container--text">
            Annex Hotels is a management company that manages hotels for
            third-party owners. Your personal information is shared with the
            third-party owner of the hotel where you intend to stay and those of
            its employees involved in delivering the service to you to the
            extent necessary for them to do so and to process the transaction.
            All hotels that are managed by Annex Hotels are required to agree to
            abide by the terms of this Privacy Protection Policy.
          </p>
          <p className="privacy_text_container--text">
            From time to time, we offer packages or other services that involve
            third parties. If you are partaking of such a package or service,
            your personal information will be shared with that third party to
            the extent necessary to provide that service and process the
            purchase or request.
          </p>
          <p className="privacy_text_container--text">
            Annex Hotels may use the information you provide to send you offers
            and information about Annex Hotels services and those of select
            third parties. Your information will not be given to these third
            parties but rather the third party's offer will be provided to Annex
            Hotels which will manage the distribution.
          </p>
          <p className="privacy_text_container--text">
            If you do not wish to receive such offers and information you may
            unsubscribe or opt-out by sending an email to{" "}
            <a href="mailto:hello@theannex.com">hello@theannex.com</a>, using
            the subject line "Unsubscribe", so indicating your request with
            sufficient personal identifiers so we can appropriately act on your
            request. All our marketing communication of this type will also
            contain instructions for unsubscribing.
          </p>
          <h3 className="privacy_text_container_title">
            Information provided to us in communications regarding service
            delivery such as comment cards
          </h3>
          <p className="privacy_text_container--text">
            Information provided to us in such a fashion will be used solely for
            the purpose of recording your comments, communicating with you in
            respect of them, reviewing them with the subject hotel and hotel
            personnel, for the purposes of recognizing employees for excellence
            of service delivery and for developing improvements in service
            delivery.
          </p>
          <h3 className="privacy_text_container_title">
            Annex Hotels offers you the ability to recieve targeted, timely
            notification of time sensitive offers
          </h3>
          <p className="privacy_text_container--text">
            To sign up for this service we require you to provide us with your
            email address, first name, last name, language preference, and
            country of residence. You may unsubscribe at any time using the link
            provided on every email message and you will no longer receive email
            offers and promotions for the specified subscription or website, as
            the case may be. You may at any time in the future opt-in to receive
            email offers.
          </p>
          <h3 className="privacy_text_container_title">
            By visiting one of our websites
          </h3>
          <p className="privacy_text_container--text">
            We use "cookies" on our websites. Cookies are pieces of information
            that an Internet site transfers to your hard drive for
            record-keeping purposes. The use of cookies is an industry standard
            -- you'll find them almost everywhere on the Internet. Annex uses
            cookies to recognize visitors when they return to our sites. Once we
            know it's you, we can customize your online visit.
          </p>
          <p className="privacy_text_container--text">
            We may also evaluate our content and services and tailor our
            websites, for visitors, based on other information we collect, such
            as IP addresses, which are numbers assigned to your computer
            whenever you use the Internet, pixel tags (or clear gifs), and the
            type of Internet browser or operating system you are using. This
            information is collected in the aggregate, but we may tie it to your
            personal information through cookie use as described above.
          </p>
          <p className="privacy_text_container--text">
            We use Google Analytics to measure how visitors interact with
            content on our websites. We also use Facebook Custom Audiences to
            ask Facebook to show you ads that are customized based on your
            interaction with our websites or our Facebook applications and to
            measure how you interact with those ads. All of these services use
            cookies and similar technologies, such as web beacons, tags and
            embedded scripts, to record information about your interaction with
            our websites. Additional information on how these services use such
            technologies can be found on Google’s website and Facebook’s
            website. The Google Analytics security and privacy principles
            summarize how Google protects information collected through Google
            Analytics. Facebook’s Data Use Policy describes how Facebook
            protects information collected through Facebook Custom Audiences.
          </p>
          <p className="privacy_text_container--text">
            If you do not wish to have data relating to your visits to our
            websites collected through Google Analytics, you may opt-out by
            installing the Google Analytics opt-out browser add-on. You may
            opt-out of Facebook Custom Audiences by visiting Facebook’s opt-out
            page.
          </p>
          <h3 className="privacy_text_container_title">
            Why we collect personal information
          </h3>
          <ol className="privacy_text_container--text--list same">
            <li>
              To establish and maintain a responsible commercial relationship
              with you and to provide ongoing service.
            </li>
            <li>
              To understand your needs and preferences.We maintain a record of
              the products and services you receive from us and we may ask for
              additional information so that we can serve you better. For
              example, we may record your preference for type of room.
            </li>
            <li>
              To develop, enhance, market or provide products and services. For
              example, we look at our guests' use of our services so that we can
              better understand how to improve our services.
            </li>
            <li>
              To manage and develop our business and operations.For example we
              analyze guest patterns of usage of our hotels and services to help
              us manage them efficiently and plan for future growth.
            </li>
            <li>To meet legal and regulatory requirements.</li>
          </ol>
          <h3 className="privacy_text_container_title">
            When do we disclose personal information
          </h3>
          <p className="privacy_text_container--text">
            We disclose personal information in these circumstances:
          </p>
          <ol className="privacy_text_container--text--list same">
            <li>
              We may disclose a guest's personal information to a person who, in
              the reasonable judgment of Annex Hotels, is seeking the
              information as an agent of the guest - for example, a travel agent
              who is booking a reservation on behalf of the guest.
            </li>
            <li>
              Personal information will be shared with the Annex hotel for which
              your reservation has been made so that they may provide the
              services you have reserved.
            </li>
            <li>
              Personal information may be shared with other Annex hotels so that
              they may better provide services to you in the event you wish to
              travel to that Annex hotel in the future.
            </li>
            <li>
              Personal information will be shared with a third party involved in
              supplying the guest with the services they have purchased to the
              extent necessary to effect the supply and the processing of the
              transaction.
            </li>
            <li>
              Personal information may be shared with a third party retained by
              Annex Hotels to perform functions on its behalf such as
              reservations handling, data processing or storage, guest surveys
              or research.
            </li>
            <li>
              Personal information may be shared with an agent retained by Annex
              Hotels to evaluate a customer's credit worthiness or in order to
              collect a customer's account.
            </li>
            <li>
              Personal information may be shared with a public authority or an
              agent of public authority if in the reasonable judgment of Annex
              Hotels it appears that there is an imminent danger to life or
              property which could be avoided or minimized by disclosure of the
              information, or which disclosure is compelled by legal authority.
            </li>
          </ol>
          <p className="privacy_text_container--text">
            Any such disclosure of a guest's personal information by Annex
            Hotels to a third party will be made only on a confidential basis
            conditioned upon the information being used only for the purpose for
            which it has been disclosed.
          </p>
          <h3 className="privacy_text_container_title">Principles</h3>
          <ol className="privacy_text_container--text--list same">
            <li>
              Annex Hotels will not collect, use or disclose your personal
              information for any other purpose than those identified above,
              except with your consent.
            </li>
            <li>
              Annex Hotels will protect your personal information with
              appropriate security safeguards.
            </li>
            <li>
              Annex Hotels will take appropriate steps to protect the
              confidentiality of your personal information when dealing with
              third parties.
            </li>
            <li>
              Annex Hotels will strive to keep your personal information as
              accurate and up to date as is necessary for the purposes
              identified above.
            </li>
            <li>
              Annex Hotels will honour your request to access your personal
              information in as timely fashion as is reasonably possible.
            </li>
            <li>
              You are always free to refuse to provide personal information to
              us.
            </li>
          </ol>
          <p className="privacy_text_container--text">
            You may also withdraw your consent with respect to the use of your
            personal information for marketing purposes at any time, subject to
            legal or contractual restrictions and reasonable notice, by emailing
            us at hello@theannex.com, using 'Unsubscribe' as the subject line,
            and providing us sufficient personal identifiers so we can act
            effectively on your request.s
          </p>
          <p className="privacy_text_container--text">
            However, in either case, this may limit our ability to serve you.
          </p>
          <p className="privacy_text_container--text">
            If you have questions or concerns about our privacy practices or
            wish to make a request in respect of your personal information
            please contact our Privacy Officer at
            <a href="mailto:privacy@theannex.com">privacy@theannex.com.</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
