import { ReactNode, useEffect, useCallback } from 'react';
import './BookingTwoColumnLayout.scss';
import Logo from '../../assets/icons/annex-icon.png';
import { mapStyles } from '../../util/util';
declare var google: any;

type BookingTwoColumnLayoutProps = {
  className?: string;
  leftColumnClassName?: string;
  leftColumnChildren?: ReactNode;
  rightColumnClassName?: string;
  rightColumnChildren?: ReactNode;
  showLocationMap?: boolean;
  showCallToActionBanner?: boolean;
};

const BookingTwoColumnLayout = (props: BookingTwoColumnLayoutProps) => {
  const initMap = useCallback(() => {
    if (props.showLocationMap) {
      const theAnnexPosition: google.maps.LatLngLiteral = {lat: 43.6654713, lng: -79.4080313};
      const mapOptions = {
        center: theAnnexPosition,
        zoom: 10,
        styles: mapStyles,
        mapTypeControl: false,
        streetViewControl: false
      };
      const mapElement = document.getElementById("bookingTwoColumnLayoutMap") as HTMLElement;
      const map: google.maps.Map = new google.maps.Map(mapElement, mapOptions);
      const infoWindow = new google.maps.InfoWindow();
      const annexMarker = new google.maps.Marker({
        position: theAnnexPosition, 
        map: map,
        icon: Logo,
        title: 'The Annex Hotel'
      });
      annexMarker.addListener('click', () => {
        infoWindow.close();
        infoWindow.setContent(annexMarker.getTitle());
        infoWindow.open(annexMarker.getMap(), annexMarker);
      });
    }
  }, [props.showLocationMap]);

  useEffect(() => {
    setTimeout(() => {
      initMap();
    }, 1000);
    return () => {}
  }, [initMap]);
  
  return (
    <>
      <div className={`booking-two-column-layout ${props.className ? props.className : ''}`}>
        <div className={`booking-two-column-layout__left-column ${props.leftColumnClassName ? props.leftColumnClassName : ''}`}>{props.leftColumnChildren}</div>
        <div className={`booking-two-column-layout__right-column ${props.rightColumnClassName ? props.rightColumnClassName : ''}`}>{props.rightColumnChildren}</div>
      </div>
    </>
  )
}

export default BookingTwoColumnLayout;
