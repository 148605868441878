import './EventBookingAccordion.scss';

import { useState, useRef, ReactNode } from 'react';

import Icon from '../Global/Icon/Icon';

export type AnnexSpacesDetails = {
    title?: string;
    body?: string;
}

export type EventBookingAccordionProps = {
    className?: string;
    headerClassName?: string;
    headerTitle?: string;
    headerSubTitle?: string;
    headerSubTitleClassName?: string;
    listDataSrc?: Array<string> | undefined;
    arrayOfObjectsSrc?: AnnexSpacesDetails[] | undefined;
    dropdownClassName?: string;
    dropdownInnerContainerClassName?: string;
    dropdownHeadingTextClassName?: string;
    dropdownTextClassName?: string;
    onSelect: (value:string) => void;
    labelHtmlFor?: string;
    labelClassName?: string;
    fieldInput?: ReactNode;
}

const EventBookingAccordion = ({className, headerClassName, headerTitle, headerSubTitle, headerSubTitleClassName, listDataSrc, arrayOfObjectsSrc, dropdownClassName, dropdownInnerContainerClassName, dropdownHeadingTextClassName, dropdownTextClassName, labelHtmlFor, labelClassName, onSelect, fieldInput, ...props}: EventBookingAccordionProps) =>  {


    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [subtitle, setSubtitle] = useState("");
    // const [dropdownActive, setDropdownActive] = useState(false);

    const handleSubtitle = (e: any) => {
        setSubtitle(e.target.id);
        onSelect(e.target.id);
        setHeightState("0px");
        setActiveState("");
    } 

    const content = useRef<HTMLDivElement>(null!);

    function toggleAccordion(e: any) {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
          );
        //   setDropdownActive(
        //       setActive === "active" ? false : true
        //   );
    };

    const arrayOfObjects = arrayOfObjectsSrc?.map((object, index) => ({...object, key:{index}})) 

    return (
        <div className={`event-accordion ${className}`}>
            <div className={headerClassName ?  headerClassName : "event-accordion-header"} onClick={(e) => toggleAccordion(e)} tabIndex={0} onBlur={() => { setHeightState("0px");  setActiveState("");}}>
                
                <div className={`event-accordion-header-selector ${setActive}`}>
                    <label className={`${labelClassName}`} htmlFor={labelHtmlFor} >{headerTitle}</label>
                    <p className={headerSubTitleClassName ? headerSubTitleClassName : "event-accordion-header-selector__text"} >{fieldInput ? fieldInput : (headerSubTitle || subtitle)}
                    </p>
                    
                </div> 
                <div className="event-accordion-header-icon">
                    <Icon name="dropdown-arrow" className={setActive === "active" ? "event-accordion-header-icon__icon rotate-icon" : "event-accordion-header-icon__icon"} />     
                </div>         
            </div>
            <div className={dropdownClassName ? dropdownClassName : `event-accordion-dropdown`} ref={content} style={{ maxHeight: `${setHeight}` }}>
                
                {listDataSrc?.map((item, index) => {
                    return (                                 
                        <label className={dropdownTextClassName ? dropdownTextClassName : "event-accordion-dropdown__text"} onClick={handleSubtitle} id={item} key={index}>{item}</label>
                    )
                })} 
                {arrayOfObjects?.map((item, index) => {
                    return (
                        <div className={dropdownInnerContainerClassName} key={index}>
                            <h4 className={dropdownHeadingTextClassName}>{item.title}</h4>
                            <p className={dropdownTextClassName}>{item.body}</p>
                        </div>
                    )

                })}
            </div>
        </div>
    )
}


export default EventBookingAccordion;
