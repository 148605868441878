import { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Global/Button/Button";
import MetaDecorator from "../../../MetaDecorator";

import AuthTwoColumnLayout from "../AuthTwoColumnLayout";
import "./AuthLoginSecurity.scss";

import * as yup from "yup";
import { useFormik } from "formik";

import { setDoc } from "firebase/firestore";

import { useUserData } from "../../../hooks/useUserData";

const AuthLoginSecurity = () => {
  const { userRef, userData } = useUserData();
  // console.log("user data", userData);
  // const [currentPassword, setCurrentPassword] = useState('');
  // const [currentPasswordError, setCurrentPasswordError] = useState<boolean>(false);

  // const [newPassword, setNewPassword] = useState('');
  // const [newPasswordError, setNewPasswordError] = useState<boolean>(false);

  // const [confirmPassword, setConfirmtPassword] = useState('');
  // const [confirmPasswordError, setConfirmtPasswordError] = useState<boolean>(false);

  const [formSuccess, setFormSuccess] = useState(false);

  const history = useHistory();

  // const updatePassword = (e: any) => {
  //   e.preventDefault();

  //   // if (!currentPassword) setCurrentPasswordError(true);
  //   // if (!newPassword) setNewPasswordError(true);
  //   // if (!confirmPassword) setConfirmtPasswordError(true);

  //   // if (currentPassword) setCurrentPasswordError(false);
  //   // if (newPassword) setNewPasswordError(false);
  //   // if (confirmPassword) setConfirmtPasswordError(false);

  //   // if (currentPassword && newPassword && confirmPassword) {

  //   // }

  //   // if (formikProps.values.currentPassword && !formikProps.errors.currentPassword && formikProps.values.newPassword && !formikProps.errors.newPassword && formikProps.values.confirmPassword && !formikProps.errors.confirmPassword) {
  // 	// }
  // }

  const passwordRegExp =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;

  const newPasswordSchema = yup.object().shape({
    //will need to add validation to verify current password matches what's saved on the server once an initial password is created.
    currentPassword: yup
      .string()
      .matches(
        passwordRegExp,
        "Current password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit and one special character"
      )
      .required("Current password is required"),
    newPassword: yup
      .string()
      .matches(
        passwordRegExp,
        "New password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit and one special character"
      )
      .required("New password is required"),
    confirmPassword: yup
      .string()
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.newPassword === value;
      })
      .required("Confirm password is required"),
  });

  const initialValues = {
    currentPassword: "", //will need to come from server once initial password is created?
    newPassword: "",
    confirmPassword: "",
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema: newPasswordSchema,
    onSubmit: async (values, { resetForm, setFieldError }) => {
      if (values.currentPassword === userData.password) {
        await setDoc(
          userRef,
          {
            password: values.confirmPassword,
          },
          { merge: true }
        )
          .then(() => {
            setFormSuccess(true);
            setTimeout(() => {
              history.push("/mybookings");
              setFormSuccess(false);
              resetForm();
            }, 4000);
          })
          .catch((err) => {
            console.error(err);
          });
      }
      if (values.currentPassword !== userData.password) {
        setFieldError("currentPassword", "Incorrect password");
      }
    },
  });

  return (
    <>
      <MetaDecorator
        title="Login & Security | Annex"
        description=" Sample description"
      />
      <AuthTwoColumnLayout
        rightColumnChildren={
          <>
            <div className="annex-auth-two-column-layout__right-column-container">
              <h2 className="annex-auth-two-column-layout__title">
                Login & Security
              </h2>
              <form className="annex-form" onSubmit={formikProps.handleSubmit}>
                <fieldset>Password</fieldset>

                <div className="annex-form-group-container">
                  <div className="annex-form-group">
                    <label
                      className={`annex-form__label ${
                        formikProps.touched.currentPassword &&
                        formikProps.errors.currentPassword
                          ? "annex-form__label--error"
                          : ""
                      }`}
                    >
                      Current Password
                    </label>
                    <input
                      className="annex-form__input"
                      id="currentPassword"
                      name="currentPassword"
                      type="password"
                      // value={currentPassword}
                      // onChange={(e) => setCurrentPassword(e.target.value)}
                      value={formikProps.values.currentPassword}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                  </div>
                  {formikProps.touched.currentPassword &&
                    formikProps.errors.currentPassword && (
                      <p className="annex-form__error-message">
                        {formikProps.errors.currentPassword}
                      </p>
                    )}
                </div>

                <div className="annex-form-group-container">
                  <div className="annex-form-group">
                    <label
                      className={`annex-form__label ${
                        formikProps.touched.newPassword &&
                        formikProps.errors.newPassword
                          ? "annex-form__label--error"
                          : ""
                      }`}
                    >
                      New Password
                    </label>
                    <input
                      className="annex-form__input"
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      // value={newPassword}
                      // onChange={(e) => setNewPassword(e.target.value)}
                      value={formikProps.values.newPassword}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                  </div>
                  {formikProps.touched.newPassword &&
                    formikProps.errors.newPassword && (
                      <p className="annex-form__error-message">
                        {formikProps.errors.newPassword}
                      </p>
                    )}
                </div>

                <div className="annex-form-group-container">
                  <div className="annex-form-group">
                    <label
                      className={`annex-form__label ${
                        formikProps.touched.confirmPassword &&
                        formikProps.errors.confirmPassword
                          ? "annex-form__label--error"
                          : ""
                      }`}
                    >
                      Confirm Password
                    </label>
                    <input
                      className="annex-form__input"
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      // value={confirmPassword}
                      // onChange={(e) => setConfirmtPassword(e.target.value)}
                      value={formikProps.values.confirmPassword}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                  </div>
                  {formikProps.touched.confirmPassword &&
                    formikProps.errors.confirmPassword && (
                      <p className="annex-form__error-message">
                        {formikProps.errors.confirmPassword}
                      </p>
                    )}
                </div>

                <Button
                  buttontype="primary--inverse"
                  children="Update"
                  cname={
                    !(formikProps.dirty && formikProps.isValid)
                      ? "disabled-btn-black"
                      : ""
                  }
                  disabled={!(formikProps.dirty && formikProps.isValid)}
                  type="submit"
                  linkTo=""
                />
                {formSuccess && (
                  <p className="annex-form__success-message">
                    You've successfully updated your password and will be
                    re-directed shortly!
                  </p>
                )}
              </form>
            </div>
          </>
        }
      />
    </>
  );
};

export default AuthLoginSecurity;
