// import "bootstrap/dist/css/bootstrap.min.css";

import "./NeighbourhoodCarousel.scss";

import { Carousel } from "react-bootstrap";
import { ReactNode, useState } from "react";

import DiscoverCard from "../../../components/DiscoverCard/DiscoverCard";

import Icon from "../../../components/Global/Icon/Icon";

type NeighbourhoodCarouselProps = {
  items?: any[];
  itemsDesktop?: any[];
  selectDropDownChildren?: ReactNode;
}

const NeighbourhoodCarousel = (props: NeighbourhoodCarouselProps) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const directionButtons = (direction: string) => {
    return (
      <span
        aria-hidden="true"
        className={
          direction === "Next"
            ? "button-next-neighbourhood"
            : "button-prev-neighbourhood"
        }
      >
        {direction === "Next" ? (
          <Icon
            name="next-arrow"
            className="button-next-icon-neighbourhood"
            color="black"
          />
        ) : (
          <Icon
            name="back-arrow"
            className="button-prev-icon-neighbourhood"
            color="black"
          />
        )}
      </span>
    );
  };

  return (
    <>
      {props.selectDropDownChildren && <>{props.selectDropDownChildren}</>}
      <div className="neighbourhood-carousel-container">
        {
          props.items && 
          <Carousel
            //  ref={ref}
            interval={null}
            controls={true}
            indicators={false}
            nextLabel={""}
            prevLabel={""}
            activeIndex={index}
            onSelect={handleSelect}
            nextIcon={directionButtons("Next")}
            prevIcon={directionButtons("Previous")}
            className="neighbourhood-carousel mobile-only"
          >
            {props.items.map((item, index) => {
              return (
                <Carousel.Item
                  key={index}
                  className="neighbourhood-carousel-item"
                >
                  <div className="neighbourhood-carousel-item-wrapper">
                    <DiscoverCard
                      imageSrc={item.imageSrc}
                      imageAlt={item.imageAlt}
                      imageTitle={item.imageTitle}
                      title={item.title}
                      address={item.address}
                      paragraph={item.paragraph}
                      linkTo={item.linkTo}
                      url={item.url}
                      isExternal={true}
                    />
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        }
        {
          props.itemsDesktop && 
          <Carousel
            //  ref={ref}
            interval={null}
            controls={true}
            indicators={false}
            nextLabel={""}
            prevLabel={""}
            activeIndex={index}
            onSelect={handleSelect}
            nextIcon={directionButtons("Next")}
            prevIcon={directionButtons("Previous")}
            className="neighbourhood-carousel desktop-only"
          >
            {props.itemsDesktop.map((item, index) => {
              return (
                <Carousel.Item
                  key={index}
                  className="neighbourhood-carousel-item"
                >
                  <div className="neighbourhood-discover-cards">
                    {
                      item.imageSrc &&
                      <DiscoverCard
                        imageSrc={item.imageSrc}
                        imageAlt={item.imageAlt}
                        imageTitle={item.imageTitle}
                        title={item.title}
                        address={item.address}
                        paragraph={item.paragraph}
                        linkTo={item.linkTo}
                        url={item.url}
                        isExternal={true}
                      />
                    }
                    {
                      item.imageSrc2 &&
                      <DiscoverCard
                        imageSrc={item.imageSrc2}
                        imageAlt={item.imageAlt2}
                        imageTitle={item.imageTitle2}
                        title={item.title2}
                        address={item.address2}
                        paragraph={item.paragraph2}
                        linkTo={item.linkTo2}
                        url={item.url2}
                        isExternal={true}
                      />
                    }
                    {
                      item.imageSrc3 &&
                      <DiscoverCard
                        imageSrc={item.imageSrc3}
                        imageAlt={item.imageAlt3}
                        imageTitle={item.imageTitle3}
                        title={item.title3}
                        address={item.address3}
                        paragraph={item.paragraph3}
                        linkTo={item.linkTo3}
                        url={item.url3}
                        isExternal={true}
                      />
                    }
                    {
                      item.imageSrc4 &&
                      <DiscoverCard
                        imageSrc={item.imageSrc4}
                        imageAlt={item.imageAlt4}
                        imageTitle={item.imageTitle4}
                        title={item.title4}
                        address={item.address4}
                        paragraph={item.paragraph4}
                        linkTo={item.linkTo4}
                        url={item.url4}
                        isExternal={true}
                      />
                    }
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        }
      </div>
    </>
  );
}

export default NeighbourhoodCarousel;