import { collection } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

const useBlogs = () => {
  const blogCollectionRef = collection(useFirestore(), 'blogs');

  const {
    data: blogs,
  } = useFirestoreCollectionData(blogCollectionRef, { idField: 'id' });

  return {
    blogs,
    blogCollectionRef
  }
};

export default useBlogs;
