import { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Global/Button/Button";
import MetaDecorator from "../../../MetaDecorator";
import AuthTwoColumnLayout from "../AuthTwoColumnLayout";

import { DocumentData, DocumentReference, setDoc } from "firebase/firestore";
import { useAuth } from "reactfire";

import { useUserData } from "../../../hooks/useUserData";
import * as yup from "yup";
import { useFormik } from "formik";

import axios from "axios";

import "./AuthAccount.scss";

type AuthAccountViewProps = {
  userRef: DocumentReference<DocumentData>;
  userData: DocumentData;
};

const AuthAccountView = (props: AuthAccountViewProps) => {
  const { userRef, userData } = props;

  const signOut = (auth: any) =>
    auth.signOut().then(() => console.log("signed out"));
  const authSignOut = useAuth();

  const [formSuccess, setFormSuccess] = useState(false);

  const history = useHistory();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const accountSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("First name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Invalid phone number")
      .min(10, "Phone number must be at least 10 characters")
      .required("Phone number is required"),
  });

  const initialValues = {
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    email: userData?.email || "",
    phoneNumber: userData?.phoneNumber || "",
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema: accountSchema,
    onSubmit: async (values, { resetForm }) => {
      await setDoc(userRef, values, { merge: true })
        .then(() => {
          setFormSuccess(true);
          setTimeout(() => {
            history.push("/mybookings");
            setFormSuccess(false);
            resetForm();
          }, 4000);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  });

  return (
    <>
      <MetaDecorator title="Account | Annex" description="Sample description" />
      <AuthTwoColumnLayout
        rightColumnChildren={
          <>
            <div className="annex-auth-two-column-layout__right-column-container">
              <h2 className="annex-auth-two-column-layout__title">Account</h2>
              <form className="annex-form" onSubmit={formikProps.handleSubmit}>
                <fieldset>Personal Information</fieldset>

                <div className="annex-form-group-container">
                  <div className="annex-form-group">
                    <label
                      className={`annex-form__label ${
                        formikProps.touched.firstName &&
                        formikProps.errors.firstName
                          ? "annex-form__label--error"
                          : ""
                      }`}
                    >
                      First Name
                    </label>
                    <input
                      className="annex-form__input"
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={formikProps.values.firstName}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                  </div>
                  {formikProps.touched.firstName &&
                    formikProps.errors.firstName && (
                      <p className="annex-form__error-message">
                        {formikProps.errors.firstName}
                      </p>
                    )}
                </div>

                <div className="annex-form-group-container">
                  <div className="annex-form-group">
                    <label
                      className={`annex-form__label ${
                        formikProps.touched.lastName &&
                        formikProps.errors.lastName
                          ? "annex-form__label--error"
                          : ""
                      }`}
                    >
                      Last Name
                    </label>
                    <input
                      className="annex-form__input"
                      id="lastName"
                      name="lastName"
                      type="text"
                      value={formikProps.values.lastName}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                  </div>
                  {formikProps.touched.lastName &&
                    formikProps.errors.lastName && (
                      <p className="annex-form__error-message">
                        {formikProps.errors.lastName}
                      </p>
                    )}
                </div>

                <div className="annex-form-group-container">
                  <div className="annex-form-group">
                    <label
                      className={`annex-form__label ${
                        formikProps.touched.email && formikProps.errors.email
                          ? "annex-form__label--error"
                          : ""
                      }`}
                    >
                      Email
                    </label>
                    <input
                      className="annex-form__input"
                      id="email"
                      name="email"
                      type="email"
                      value={formikProps.values.email}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                  </div>
                  {formikProps.touched.email && formikProps.errors.email && (
                    <p className="annex-form__error-message">
                      {formikProps.errors.email}
                    </p>
                  )}
                </div>

                <div className="annex-form-group-container">
                  <div className="annex-form-group">
                    <label
                      className={`annex-form__label ${
                        formikProps.touched.phoneNumber &&
                        formikProps.errors.phoneNumber
                          ? "annex-form__label--error"
                          : ""
                      }`}
                    >
                      Phone Number
                    </label>
                    <input
                      className="annex-form__input"
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                      value={formikProps.values.phoneNumber}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                  </div>
                  {formikProps.touched.phoneNumber &&
                    formikProps.errors.phoneNumber && (
                      <p className="annex-form__error-message">
                        {formikProps.errors.phoneNumber}
                      </p>
                    )}
                </div>

                <Button
                  buttontype="primary--inverse"
                  children="Update"
                  cname={
                    !(formikProps.dirty && formikProps.isValid)
                      ? "disabled-btn-black"
                      : ""
                  }
                  disabled={!(formikProps.dirty && formikProps.isValid)}
                  type="submit"
                  linkTo=""
                />
                {formSuccess && (
                  <p className="annex-form__success-message">
                    You've successfully updated your personal information and
                    will be re-directed shortly!
                  </p>
                )}
              </form>
              <div className="logout">
                <Button
                  buttontype="primary--inverse"
                  children="Logout"
                  type="button"
                  onClick={() => {
                    signOut(authSignOut);
                    history.push("/");
                  }}
                  linkTo=""
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

const AuthAccount = () => {
  const { userRef, userData } = useUserData();

  return (
    <>{userData && <AuthAccountView userRef={userRef} userData={userData} />}</>
  );
};

export default AuthAccount;
