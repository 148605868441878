import "./Header.scss";
import Button from "../Global/Button/Button";
import NavMenu from "../NavMenu/NavMenu";
import Icon from "../Global/Icon/Icon";

import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";

import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { useAuth } from 'reactfire';

import AppContext from "../../context";

export default function Header() {
  const [loggedIn, setLoggedin] = useState(false);
  const [openNavMenu, setOpenNavMenu] = useState(false);

  // const signOut = (auth: any) => auth.signOut().then(() => console.log('signed out'));

  const handleOpenNavMenu = () => {
    setOpenNavMenu(!openNavMenu);
  };

  const location = useLocation();
  const darkTheme =
    location.pathname === "/rooms" ||
    location.pathname === "/camera-roll" ||
    location.pathname === "/contact-us";

  const auth = getAuth();

  // const authSignInOut = useAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedin(true);
        // const uid = user.uid;
      } else {
        setLoggedin(false);
      }
    });
  }, [auth, loggedIn]);

  const { openBookingEngine } = useContext(AppContext);
  const openBooking = () => {
    // const dates = date.split(" – ");
    // const startDate = moment(dates[0]).toDate();
    // const endDate = moment(dates[1]).toDate();
    // const startDate = moment(startUtc).toDate();
    // const endDate = moment(endUtc).toDate();
    openBookingEngine();
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => setOpenNavMenu(false)}
      display="contents"
    >
      <div
        className={`header ${
          !openNavMenu && darkTheme ? "header header--dark" : ""
        }`}
      >
        <Link to="#" className="icon-container" onClick={handleOpenNavMenu}>
          {!openNavMenu && (
            <Icon
              name="annex-icon"
              color={!openNavMenu && darkTheme ? "#FFFFFF" : "#000000"}
              className="icon-container__icon desktop-only"
            />
          )}
          <div className="hamburger mobile-only" onClick={handleOpenNavMenu}>
            <div
              className={
                !openNavMenu
                  ? `hamburger__top ${darkTheme && "hamburger__top--dark"}`
                  : `hamburger__top hamburger__top--rotate`
              }
            ></div>

            <div
              className={
                !openNavMenu
                  ? `hamburger__middle ${
                      darkTheme && "hamburger__middle--dark"
                    }`
                  : `hamburger__middle hamburger__middle--remove`
              }
            ></div>
            <div
              className={
                !openNavMenu
                  ? `hamburger__bottom ${
                      darkTheme && "hamburger__bottom--dark"
                    }`
                  : `hamburger__bottom hamburger__bottom--rotate`
              }
            ></div>
          </div>
          {openNavMenu && (
            <Icon
              name="menu-close"
              color="#000000"
              className="icon-container__close desktop-only"
              size={27}
            />
          )}

          {!openNavMenu && (
            <p
              className={`icon-container__text desktop-only ${
                !openNavMenu && darkTheme && "icon-container__text--dark"
              }`}
            >
              Menu
            </p>
          )}
        </Link>
        <Link
          to="/"
          className="logo-container"
          onClick={() => {
            setOpenNavMenu(false);
          }}
        >
          {/* <img src={AnnexLogo} alt="Annex Logo" className='desktop-only'/> */}
          <Icon
            name="annex-logo"
            color={darkTheme ? "#FFFFFF" : "#000000"}
            className="annex-logo desktop-only"
          />
          {!openNavMenu && (
            <Icon
              name="annex-icon"
              color={darkTheme ? "#FFFFFF" : "#000000"}
              className="icon-container__icon mobile-only"
            />
          )}
        </Link>
        {!openNavMenu && (
          <Link
            // to="/booking-stay"
            to="#"
            onClick={openBooking}
            className="header__link desktop-only"
          >
            <Button
              buttontype={darkTheme ? "primary" : "primary--inverse"}
              children="Book Now"
              cname="book-now-button"
              linkTo=""
            />
          </Link>
        )}
        <Link
          // to="/booking-stay"
          to="#"
          onClick={openBooking}
          className={
            openNavMenu
              ? "header__link book-now-icon mobile-only hide-disable"
              : "header__link book-now-icon mobile-only"
          }
        >
          <Icon
            name="bell"
            color={darkTheme ? "#FFFFFF" : "#000000"}
            size={32}
          />
        </Link>
        {openNavMenu && !loggedIn && (
          <div className="join-login">
            {/* Commenting out join/login/my account buttons for now until after launch - starting here */}

            {/* <Link
              to="/create"
              className={`join-login__link join-login__link--divider`}
              onClick={() => {
                setOpenNavMenu(false);
              }}
            >
              Join
            </Link>
            <Link
              to="/login"
              className={`join-login__link`}
              onClick={() => {
                setOpenNavMenu(false);
              }}
            >
              Login
            </Link> */}

            {/* Commenting out join/login/my account buttons for now until after launch - ending here */}
          </div>
        )}
        {openNavMenu && loggedIn && (
          <div className="my-account">
            <Link
              to="/mybookings"
              className={`my-account__link`}
              onClick={() => {
                setOpenNavMenu(false);
              }}
            >
              My Account
            </Link>
          </div>
        )}

        <NavMenu isActive={openNavMenu} onClick={handleOpenNavMenu} />
      </div>
    </OutsideClickHandler>
  );
}
