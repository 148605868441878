import "./EventNew.scss";

import Button from "../Global/Button/Button";

export type EventNewProps = {
  imgSrc?: string;
  imgAlt?: string;
  imgTitle?: string;
  name?: string;
  desc?: string;
  date?: string;
  time?: string;
  budget?: string;
  className?: string;
  textUs?: boolean;

  // imgSrc2?: string;
  // imgAlt2?: string;
  // imgTitle2?: string;
  // name2?: string;
  // desc2?: string;
  // date2?: string;
  // time2?: string;
  // budget2?: string;

  // imgSrc3?: string;
  // imgAlt3?: string;
  // imgTitle3?: string;
  // name3?: string;
  // desc3?: string;
  // date3?: string;
  // time3?: string;
  // budget3?: string;
};

const EventNew = (props: EventNewProps) => {
  return (
    <div className={`event-new ${props.className}`}>
      <div className="event-new__image-container">
        <div className="events-carousel-mask"></div>
        <img
          className="event-new__image"
          src={props.imgSrc}
          alt={props.imgAlt}
          title={props.imgTitle}
          loading="lazy"
        />
      </div>
      <div className="event-new__content-container">
        {props.name && <h2 className="event-new__title">{props.name}</h2>}
        <div className="event-new__date-container">
          <div className="event-new__date-time-container">
            {props.date && (
              <p className="event-new__date">
                {props.date} | {props.time}
              </p>
            )}
          </div>
          {props.budget && <p className="event-new__budget">{props.budget}</p>}
        </div>
        {props.desc && <p className="event-new__desc">{props.desc}</p>}
        {props.textUs && (
          <div className="events-text-us-container">
            <Button
              buttontype="secondary"
              children="Text Us"
              buttonHoverText="+1 647 694 9868"
              hasATag={true}
              cname="rollover-button"
              linkTo=""
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EventNew;
