import "./LoadingAnimation.scss";
import React from 'react'
import Icon from '../Icon/Icon';

type LoadingAnimationProps = {
    color?: string;
}

const LoadingAnimation = ({color, ...props}: LoadingAnimationProps) => {
    return (
        <div className="loading-animation">
            <Icon name="annex-icon" className="loading-animation__icon"/>
            <p className="loading-animation__text">Loading...</p>
        </div>
    )
}

export default LoadingAnimation;
