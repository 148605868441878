import './TextLinkIcon.scss';
import Icon from '../Icon/Icon';
import { Link } from 'react-router-dom';

type TextLinkIconProps = {
  linkTo: string;
  text: string;
  iconName: string;
  iconSize: number;
}

const TextLinkIcon = (props: TextLinkIconProps) => {
  return (
    <Link 
      to={props.linkTo}
      className='text-link-icon' 
    >
      {props.text}
      <Icon 
        className="text-link-icon__icon" 
        name="next-arrow" 
        size={props.iconSize} 
      />
    </Link>
  )
}

TextLinkIcon.defaultProps = {
  linkTo: '/',
  text: 'Text Link Icon',
  iconName: 'next-arrow',
  iconSize: '20',
}

export default TextLinkIcon;
