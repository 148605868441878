// import TheBigOne from "../../assets/images/live-local/the-big-one.png";
// import ourPeople from "../../assets/images/our-people.png";

import WineNight from "../../assets/images/events-page/wine-night.jpg";
import RunningClub from "../../assets/images/events-page/running_club.jpg";

export const MockBudget = ["All", "Free", "Paid"];

export const MockType = ["All", "Art", "Music", "Food"];

export const eventCarouselDataDesktop = [
  {
    imgTitle: "Wine Night",
    imgSrc: WineNight,
    imgAlt: "Wine Night",
    name: "Wine Night",
    date: "Every Thursday",
    time: "4 - 11",
    budget: "",
    desc: "Every Thursday night in the Wine Bar our wine list is 40% off so you can enjoy our newest wines without hurting the wallet. Weekend starts on Thursday. First come first served.",
    textUs: false,
    imgTitle2: "Running People",
    imgSrc2: RunningClub,
    imgAlt2: "Running People",
    name2: "Slowpokes Run Club",
    date2: "Every Saturday | 10am",
    time2: "Rain or Shine",
    budget2: "",
    desc2:
      "A 5k loop designed to get the cobwebs off from Friday night's festivities. All paces are welcome.",
    textUs2: true,
  },
  {
    imgTitle: "Wine Night",
    imgSrc: WineNight,
    imgAlt: "Wine Night",
    name: "Wine Night",
    date: "Every Thursday",
    time: "4 - 11",
    budget: "",
    desc: "Every Thursday night in the Wine Bar our wine list is 40% off so you can enjoy our newest wines without hurting the wallet. Weekend starts on Thursday. First come first served.",
    textUs: false,
    imgTitle2: "Running People",
    imgSrc2: RunningClub,
    imgAlt2: "Running People",
    name2: "Slowpokes Run Club",
    date2: "Every Saturday | 10am",
    time2: "Rain or Shine",
    budget2: "",
    desc2:
      "A 5k loop designed to get the cobwebs off from Friday night's festivities. All paces are welcome.",
    textUs2: true,
  },
];

export const eventCarouselDataMobile = [
  {
    imgTitle: "Wine Night",
    imgSrc: WineNight,
    imgAlt: "Wine Night",
    name: "Wine Night",
    date: "Every Thursday",
    time: "4 - 11",
    budget: "",
    desc: "Every Thursday night in the Wine Bar our wine list is 40% off so you can enjoy our newest wines without hurting the wallet. Weekend starts on Thursday. First come first served.",
    textUs: false,
  },
  {
    imgTitle: "Running People",
    imgSrc: RunningClub,
    imgAlt: "Running People",
    name: "Slowpokes Run Club",
    date: "Every Saturday | 10am",
    time: "Rain or Shine",
    budget: "",
    desc: "A 5k loop designed to get the cobwebs off from Friday night's festivities. All paces are welcome.",
    textUs: true,
  },
];
