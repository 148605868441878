// import ManCloseUp from '../../assets/images/live-local/man-close-up.png';
// import ManSkateboarding from '../../assets/images/live-local/man-skateboarding.png';
// import ManWriting from '../../assets/images/live-local/man-writing.png';
// import AuntLucys from '../../assets/images/live-local/aunt-lucys.png';
// import Commons from '../..//assets/images/live-local/commons.png';
// import HeadboardWithArtwork from '../../assets/images/live-local/headboard-with-artwork.png';
import BlurredDude from '../../assets/images/live-local/blurred-dude.jpg';
import LadyOnStool from '../../assets/images/live-local/lady-on-stool.jpg';
import Mural from '../../assets/images/live-local/mural.jpg';

const ourGuestsItems: any[] = [
  {
    leftImageSrc: BlurredDude,
    leftImageAlt: 'Man out of focus',
    leftImageTitle: 'Man out of focus',
    imageCardPosition: "top",
    rightImageSrc: Mural,
    rightImageAlt: 'Man in front of mural',
    rightImageTitle: 'Man in front of mural',
    imageSrc: LadyOnStool,
    imageAlt: 'Lady on stool',
    imageTitle: 'Lady on stool',
    mobileImageSrc: LadyOnStool,
    mobileImageAlt: 'Lady on stool',
    mobileImageTitle: 'Lady on stool',
    title: 'Friendly Faces',
    name: "The who's who",
    subName: 'From The Annex, to the world',
    paragraph: 'A look at people who make The Annex what it is. Catch them staying here on the regular or enjoying a martini in the Wine Bar.',
    buttonText: 'Coming Soon',
    bottomTitle: 'Friendly Faces'
},
// {
//     leftImageSrc: AuntLucys,
//     leftImageAlt: 'Woman eating burger',
//     leftImageTitle: 'Woman eating burger',
//     imageCardPosition: 'top',
//     rightImageSrc: HeadboardWithArtwork,
//     rightImageAlt: 'Headboard with artwork',
//     rightImageTitle: 'Headboard with artwork',
//     imageSrc: Commons,
//     imageAlt: 'Record player and records',
//     imageTitle: 'Record player and records',
//     mobileImageSrc: Commons,
//     mobileImageAlt: 'Record player and records',
//     mobileImageTitle: 'Record player and records',
//     title: 'Finer Things',
//     name: 'Influencer Name',
//     subName: 'The Annex, Toronto',
//     paragraph: 'Creative Entrepreneur & the founder of Burgers & Beets Entertainment.',
//     buttonText: 'View Story',
//     bottomTitle: 'Finer Things'
// },
];

export { ourGuestsItems };
