import "./AccordionBottomMenuMobile.scss";
import { useState, useRef } from "react";

import OutsideClickHandler from "react-outside-click-handler";

import DropDownArrow from "../../assets/icons/dropdown-arrow.svg";

export type AccordionBottomMenuMobileProps = {
  // mobileActive?: boolean;
  className?: string;
  menuTitle: string;
  menuOptions: JSX.Element[];
  openMenu: boolean;
  setOpenMenu: (i: boolean) => void;
  onClearFilter?: () => void;
};

const AccordionBottomMenuMobile = ({
  className,
  menuTitle,
  menuOptions,
  openMenu,
  setOpenMenu,
  ...props
}: AccordionBottomMenuMobileProps) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
    setOpenMenu(!openMenu);
  };

  const content = useRef<HTMLDivElement>(null!);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      // setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
      setActive === "active" ? "0px" : `1000px`
    );
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setMenuOpen(false);
        setActiveState("");
        setOpenMenu(false);
      }}
    >
      <div
        className={`${className} room-booking-accordion-header-mobile-container mobile-only`}
      >
        <div className="room-booking-header-dropdown-container">
          <div
            className="accordion-dropdown-book-room"
            ref={content}
            style={{ maxHeight: `${setHeight}` }}
          >
            {/* <div className="clear-filters-button-container">
                            <button className="clear-filters-button">Clear Filters</button>
                        </div> */}
            {/* <RoomBookingMenu mobileActive={menuOpen}/> */}
            <div
              className={
                menuOpen
                  ? `${className} room-booking-menu room-booking-menu--mobile-active`
                  : `${className} room-booking-menu`
              }
            >
              <div className="clear-filters-button-container">
                <button 
                  className="clear-filters-button" 
                  onClick={props.onClearFilter}
                  >
                    Clear Filters
                  </button>
              </div>
              {menuOptions?.map((option, index) => {
                return <div key={index}>{option}</div>;
              })}
            </div>
          </div>
          <div
            className="room-booking-accordion-header-mobile"
            onClick={() => {
              toggleAccordion();
              handleMenuOpen();
            }}
          >
            <div
              className={`room-booking-accordion-header-selector-mobile ${setActive}`}
            >
              <p className="room-booking-accordion-header-selector-mobile__text">
                {menuTitle}
              </p>
              <img
                src={DropDownArrow}
                alt="chevron"
                className={
                  setActive === "active"
                    ? "room-booking-accordion-header-selector-mobile__icon rotate-icon"
                    : "room-booking-accordion-header-selector-mobile__icon"
                }
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default AccordionBottomMenuMobile;
