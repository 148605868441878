import { ReactNode } from "react";
import './PageBanner.scss';

type PageBannerProps = {
  imgSrc?: string;
  imgAlt?: string;
  imgTitle?: string;
  title?: string;
  children?: ReactNode;
  type: 'Careers' | 'Events' | 'FoodAndDrinks' | 'Neighbourhood' | 'Blog' ;
  showHeroMask?: boolean;
}

const PageBanner = (props: PageBannerProps) => {
  return (
    <div className={`annex-page-banner annex-page-banner--type-${props.type}`}>
      {props.showHeroMask && <div className="events-hero-mask"></div>}                                                                                                       
      <div className="annex-page-banner__image-container">
        {props.children && <>{props.children}</>}
        {props.imgSrc && <img className="annex-page-banner__image" src={props.imgSrc} alt={props.imgAlt} title={props.imgTitle} />}
      </div>
      {props.title && <h2 className="annex-page-banner__title">{props.title}</h2>}
    </div>


  )
}

export default PageBanner;
