import "./RoomHeroSection.scss";

import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

export type RoomHeroSectionProps = {
  imgSrc?: string;
  title?: string;
  description?: string;
};

const RoomHeroSection = ({
  imgSrc,
  title,
  description,
  ...props
}: RoomHeroSectionProps) => {
  const [changePosition, setChangePosition] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (!inView) {
      setChangePosition(true);
    } else setChangePosition(false);
  }, [inView, changePosition, setChangePosition]);

  return (
    <div className="room-hero-section">
      <div className="room-hero-container">
        <div className="room-hero-container__image-gradient-mask"></div>
        <img
          src={imgSrc}
          alt="Hero room"
          ref={ref}
          className="room-hero-container__image"
          loading="lazy"
        />
        <motion.h1
          layout
          className={
            changePosition
              ? "room-hero-container__title--change-position"
              : "room-hero-container__title"
          }
        >
          {title}
        </motion.h1>
      </div>
      <p className="room-hero-container__description">{description}</p>
    </div>
  );
};

export default RoomHeroSection;
