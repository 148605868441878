import "./DiscoverCard.scss";
import ButtonArrow from "../Global/ButtonArrow/ButtonArrow";
import Icon from "../Global/Icon/Icon";

import SpotifyIcon from "../../assets/icons/spotify.svg";

type DiscoverCardProps = {
  imageSrc?: string;
  imageAlt?: string;
  imageTitle?: string;
  infoHeight?: string;
  title?: string;
  address?: string;
  paragraph?: string;
  buttonText?: string;
  linkTo?: string;
  url?: string;
  onPrev?: () => void;
  onNext?: () => void;
  isExternal?: boolean;
  hash?: boolean;
  experience?: boolean;
  instagram?: boolean;
};

const DiscoverCard = (props: DiscoverCardProps) => {
  return (
    <div
      className="annex-discover-card"
      onClick={props.instagram ? () => window.open(props.url) : () => {}}
    >
      <div className="annex-discover-card__inner">
        {props.imageSrc && (
          <div className="annex-discover-card__image-container">
            {props.instagram && (
              <Icon
                name="instagram-white"
                color="#FFFFFF"
                className="annex-discover-card__instagram-icon"
              ></Icon>
            )}
            <img
              className={`${
                props.experience
                  ? "annex-discover-card__music-image"
                  : "annex-discover-card__image"
              }`}
              src={props.imageSrc}
              alt={props.imageAlt}
              title={props.imageTitle}
              loading="lazy"
            />
          </div>
        )}
        <div
          className="annex-discover-card__info"
          style={{ height: props.infoHeight }}
        >
          <div className="annex-discover-card__info-inner">
            {/* <div className="annex-discover-card__carousel-arrows">
            <Icon className="annex-discover-card__carousel-arrow annex-discover-card__carousel-arrow--previous" name="back-arrow" size={21} />
            <Icon className="annex-discover-card__carousel-arrow annex-discover-card__carousel-arrow--next" name="next-arrow" size={21} />
            </div> */}
            <div className="annex-discover-card__info-title-paragraph">
              {props.title && (
                <h2 className="annex-discover-card__title">{props.title}</h2>
              )}
              {props.address && (
                <h2 className="annex-discover-card__address">
                  {props.address}
                </h2>
              )}
              {props.paragraph && (
                <p className="annex-discover-card__paragraph">
                  {props.paragraph}
                </p>
              )}
            </div>
            {props.linkTo &&
              (props.experience ? (
                <img
                  src={SpotifyIcon}
                  alt="Spotify link"
                  style={{ width: "35px", height: "35px", cursor: "pointer" }}
                  onClick={props.url ? () => window.open(props.url) : () => {}}
                />
              ) : (
                <ButtonArrow
                  buttontype="main"
                  children={props.buttonText}
                  iconColor="#000000"
                  linkTo={props.linkTo ? props.linkTo : "#"}
                  onClick={props.url ? () => window.open(props.url) : () => {}}
                  isExternal={props.isExternal}
                  hash={props.hash}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

DiscoverCard.defaultProps = {
  buttonText: "Discover",
};

export default DiscoverCard;
