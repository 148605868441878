import "./Footer.scss";

import AnnexIcon from "../../assets/icons/annex-icon.svg";
import Button from "../Global/Button/Button";

import OutsideClickHandler from "react-outside-click-handler";
import JoinMailingListModal from "../JoinMailingListModal/JoinMailingListModal";

import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  const landingPage = location.pathname === "/";
  const roomsPage = location.pathname === "/rooms";
  const momentsPage = location.pathname === "/camera-roll";
  const contactUsPage = location.pathname === "/contact-us";

  const [showMailingListModal, setShowMailingListModal] = useState(false);

  const handleShowMailingListModal = () => {
    setShowMailingListModal(!showMailingListModal);
  };

  const handleCloseMailingListModal = () => {
    setShowMailingListModal(false);
  };

  return (
    <div
      className={
        landingPage || roomsPage || momentsPage || contactUsPage
          ? "hide-footer"
          : "footer-container desktop-only"
      }
    >
      <div className="footer">
        <OutsideClickHandler onOutsideClick={handleCloseMailingListModal}>
          <JoinMailingListModal
            className="hide-modal"
            title={"Subscribe"}
            body={
              "Provide your email, and we will provide you with our deepest, darkest secrets. And some discounts. Probably some events too."
            }
            onClick={handleShowMailingListModal}
            showMailingListModal={showMailingListModal}
            setShowMailingListModal={setShowMailingListModal}
            children={"Subscribe"}
            joinMailingList={false}
            subscribeToMailingList={true}
          />
        </OutsideClickHandler>
        <img
          src={AnnexIcon}
          alt="Annex Icon"
          className="footer__icon"
          loading="lazy"
        />
        <div className="footer__body">
          <div className="footer-links-container">
            <div className="footer-links">
              <Link to="/terms-and-conditions" className="footer-links__text">
                Terms & Conditions
              </Link>
              <Link to="/faqs" className="footer-links__text">
                FAQs
              </Link>
              <Link to="/contact-us" className="footer-links__text">
                Contact Us
              </Link>
            </div>
            <div className="footer-links">
              <Link to="#" className="footer-links__text">
                Powered by The Annex
              </Link>
              <Link to="/careers" className="footer-links__text">
                Careers
              </Link>
            </div>
            <div className="footer-contact">
              <p className="footer-contact__text">+1 647 694 9868</p>
              <p className="footer-contact__text">296 Brunswick, Toronto</p>
              <p className="footer-contact__text">M5S 2M7</p>
            </div>
          </div>
          <div className="subscribe">
            {/* hide the subscribe for the newsletter */}
            {/* <p className="subscribe__title">Subscribe</p>
            <p className="subscribe__text">
              Sign up to learn about events, news and updates from Annex and the
              experience
            </p> */}
            {/* <Button
              buttontype="primary--inverse"
              children="Subscribe"
              cname="subscribe__button"
              onClick={handleShowMailingListModal}
              linkTo=""
            /> */}
            <p className="subscribe__text">
              &copy; The Annex. All Rights Reserved.
              <Link to="/privacy-policy" className="p-policy-link">
                Privacy Policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
