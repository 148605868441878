interface SubscriberData {
  email: string;
  first_name?: string;
  last_name?: string;
  optin_timestamp: string;
  segments: { id: string; name: string }[];
}
const authorizationHeader = `${process.env.REACT_APP_FLODESK_API}`;
export const addFlodeskSubscriber = async (
  email: string,
  firstName?: string,
  lastName?: string
) => {
  const baseUrl = 'https://api.flodesk.com/v1/subscribers';
  const subscriberData: SubscriberData = {
    email,
    first_name: firstName || '',
    last_name: lastName || '',
    optin_timestamp: new Date().toISOString(),
    // segments do not update in this request for some reason, so
    // another request below is needed
    segments: [
      {
        id: '650340818e277eb6b8f0ab64',
        name: 'TEST',
      },
    ],
  };

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorizationHeader || '',
    },
    body: JSON.stringify(subscriberData),
    redirect: 'follow',
  };

  try {
    // Create new subscriber
    const response = await fetch(baseUrl, requestOptions);
    //const result =
    await response.text();
    //console.log(result);
  } catch (error) {
    console.log('error', error);
    return 'error';
  }

  // Add created subscriber to specific segment (in this case "TEST")
  const rawTmp = JSON.stringify({ segment_ids: ['66325a8ba5eccea8a448e638'] });
  requestOptions.body = rawTmp;

  try {
    const response = await fetch(
      `${baseUrl}/${email}/segments`,
      requestOptions
    );
    //const result =
    await response.text();
    return 'success';
    //console.log(result);
  } catch (error) {
    console.log('error', error);
    return 'error';
  }
};
