import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

export type ButtonProps = {
  buttontype?:
    | "primary"
    | "primary--inverse"
    | "secondary"
    | "secondary--inverse"
    | "outline"
    | "outline--inverse";
  children?: ReactNode | string;
  buttonHoverText?: string;
  cname?: string;
  isActive?: boolean;
  disabled?: boolean;
  hasATag?: boolean;
  hasLinkTag?: boolean;
  linkTo?: string;
  onClick?: (() => void) | ((e: any) => void);
  type?: "button" | "submit" | "reset";
};

const Button = (props: ButtonProps) => {
  const [buttonTitle, setButtonTitle] = useState(props.children);

  if (props.hasATag) {
    return (
      <a
        href={
          props.buttonHoverText
            ? `sms:${props.buttonHoverText}`
            : "javascript:void(0);"
        }
        className={`button-link-wrapper ${props.buttonHoverText && "sms-text"}`}
      >
        <button
          disabled={props.disabled}
          onClick={props.onClick}
          className={[
            "button",
            `button-${props.buttontype}`,
            props.cname,
            `${props.isActive ? "active" : ""}`,
          ].join(" ")}
          type={props.type}
          onMouseEnter={
            props.buttonHoverText
              ? () => setButtonTitle(props.buttonHoverText)
              : () => {}
          }
          onMouseLeave={() => setButtonTitle(props.children)}
        >
          {buttonTitle}
        </button>
      </a>
    );
  }

  // props.hasLinkTag && props.linkTo
  if (props.hasLinkTag) {
    return (
      <Link to={props.linkTo || ''}>
        <button
          disabled={props.disabled}
          onClick={props.onClick}
          className={[
            "button",
            `button-${props.buttontype}`,
            props.cname,
            `${props.isActive ? "active" : ""}`,
          ].join(" ")}
          type={props.type}
        >
          {props.children}
        </button>
      </Link>
    );
  }

  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={[
        "button",
        `button-${props.buttontype}`,
        props.cname,
        `${props.isActive ? "active" : ""}`,
      ].join(" ")}
      type={props.type}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  buttontype: "primary",
  children: "Button",
  type: "button",
};

export default Button;
