export const NavMenuItems = [
  {
    name: "Our Rooms",
    link: "/rooms",
  },
  {
    name: "Eat and Drink",
    link: "/eat-and-drink",
  },
  {
    name: "The Neighbourhood",
    link: "/neighbourhood",
  },
  {
    name: "Events",
    link: "/events",
  },
  {
    name: "Camera Roll",
    link: "/camera-roll",
  },
];
