import { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import AuthSideMenuDesktopList, { AuthSideMenuDesktopListItem } from './AuthSideMenuDesktopList';
import AuthSideMenuMobileList, {
  AuthSideMenuMobileListItem,
} from "./AuthSideMenuMobileList";
import OutsideClickHandler from "react-outside-click-handler";
import "./AuthMenuMobile.scss";
import "./AuthTwoColumnLayout.scss";

const AuthMenuMobile = () => {
  const [currentListValue, setCurrentListValue] = useState("");
  const [isSelectOpen, setSelectOpen] = useState<boolean>(false);
  // const [booking, setBooking] = useState<boolean>(false);
  // const [specialOffers, setSpecialOffers] = useState<boolean>(false);
  // const [account, setAccount] = useState<boolean>(false);
  // const [loginSecurity, setLoginSecurity] = useState<boolean>(false);
  // const [paymentInformation, setPaymentInformation] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const myBookingsPage = location.pathname === "/mybookings";
  const specialOffersPage = location.pathname === "/specialoffers";
  const accountPage = location.pathname === "/account";
  const loginSecurityPage = location.pathname === "/loginsecurity";
  const paymentInformationPage = location.pathname === "/paymentinformation";

  const displayAuthMenuMobile = useCallback(() => {
    if (
      myBookingsPage ||
      specialOffersPage ||
      accountPage ||
      loginSecurityPage ||
      paymentInformationPage
    ) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  }, [myBookingsPage, specialOffersPage, accountPage, loginSecurityPage, paymentInformationPage, setIsHidden]);

  const toggleSelect = () => {
    let newValue = isSelectOpen;
    newValue = !newValue;
    setSelectOpen(newValue);
  };

  const closeMenu = () => {
    setSelectOpen(false);
  };

  // const resetMenus = () => {
  //   // setBooking(false);
  //   // setSpecialOffers(false);
  //   // setAccount(false);
  //   // setLoginSecurity(false);
  //   // setPaymentInformation(false);
  // };

  const checkCurrentPage = useCallback(() => {
    // resetMenus();

    if (window.location.pathname === "/mybookings") {
      setCurrentListValue("My Bookings");
      // setBooking(true);
    }

    if (window.location.pathname === "/specialoffers") {
      setCurrentListValue("My Special Offers");
      // setSpecialOffers(true);
    }

    if (window.location.pathname === "/account") {
      setCurrentListValue("My Account");
      // setAccount(true);
    }

    if (window.location.pathname === "/loginsecurity") {
      setCurrentListValue("My Login & Security");
      // setLoginSecurity(true);
    }

    if (window.location.pathname === "/paymentinformation") {
      setCurrentListValue("My Payments Information");
      // setPaymentInformation(true);
    }
  }, []);

  const redirectPage = (url: string) => {
    history.push(url);
  };

  useEffect(() => {
    checkCurrentPage();
    displayAuthMenuMobile();

    return () => {};
  }, [
    myBookingsPage,
    specialOffersPage,
    accountPage,
    loginSecurityPage,
    paymentInformationPage,
    checkCurrentPage,
    displayAuthMenuMobile
  ]);

  return (
    <div className={`auth-menu-mobile ${isHidden && "hide-auth-menu-mobile"}`}>
      <OutsideClickHandler onOutsideClick={closeMenu}>
        <AuthSideMenuMobileList
          label={currentListValue}
          isSelectOpen={isSelectOpen}
          onClick={toggleSelect}
        >
          <AuthSideMenuMobileListItem
            label="My Bookings"
            onClick={() => [toggleSelect(), redirectPage("/mybookings")]}
          ></AuthSideMenuMobileListItem>
          <AuthSideMenuMobileListItem
            label="Special Offers"
            onClick={() => [toggleSelect(), redirectPage("/specialoffers")]}
          ></AuthSideMenuMobileListItem>
          <AuthSideMenuMobileListItem
            label="Account"
            onClick={() => [toggleSelect(), redirectPage("/account")]}
          ></AuthSideMenuMobileListItem>
          <AuthSideMenuMobileListItem
            label="Login & Security"
            onClick={() => [toggleSelect(), redirectPage("/loginsecurity")]}
          ></AuthSideMenuMobileListItem>
          <AuthSideMenuMobileListItem
            label="Payment Information"
            onClick={() => [
              toggleSelect(),
              redirectPage("/paymentinformation"),
            ]}
          ></AuthSideMenuMobileListItem>
        </AuthSideMenuMobileList>
      </OutsideClickHandler>
    </div>
  );
};

export default AuthMenuMobile;
