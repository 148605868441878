// import RollOverButton from '../Global/RollOverButton/RollOverButton';
import Button from "../Global/Button/Button";
import "./GotQuestions.scss";

export type GotQuestionsProps = {
  title?: string;
  children?: string;
  buttonHoverText?: string;
  hasATag?: boolean;
  hasLinkTag?: boolean;
  linkTo?: string;
  onClick?: (() => void) | ((e: any) => void);
  experience?: boolean;
};

const GotQuestions = (props: GotQuestionsProps) => {
  return (
    <div
      className={`${
        props.experience
          ? "annex-got-questions--experience"
          : "annex-got-questions"
      } `}
    >
      <div className="annex-got-questions__questions-container">
        <h2 className="annex-got-questions__questions-title">{props.title}</h2>
      </div>
      <div className="annex-got-questions__questions-button">
        <Button
          buttontype="secondary"
          children={props.children}
          buttonHoverText={props.buttonHoverText}
          cname="rollover-button"
          hasATag={props.hasATag}
          hasLinkTag={props.hasLinkTag}
          linkTo={props.linkTo}
          onClick={props.onClick}
        />
        {/* <RollOverButton buttontype="secondary" buttonTitle={props.children}
				                buttonNumberText={props.buttonHoverText}/> */}
      </div>
    </div>
  );
};

export default GotQuestions;
