import "./SendingAnimation.scss";
import React from 'react';

type SendingAnimationProps = {
    color?: string;
}

const SendingAnimation = ({color, ...props}: SendingAnimationProps) => {
    return (
        <div className="sending-animation">
            <p className="sending-animation__text">Sending...</p>
        </div>
    )
}

export default SendingAnimation;
