import "./BlogBanner.scss";

export type BlogBannerProps = {
  title: string;
  image?: string;
  authorName: string;
  date: string;
};

const BlogBanner = (props: BlogBannerProps) => {
  return (
    <>
      <div className="blog-banner">
        {props.title && (<h2 className="blog-banner__title">{props.title}</h2>)}
        {props.image && (
          <div className="blog-banner__image-container">
            <img
              className="blog-banner__image"
              src={props.image}
              alt="blog-banner"
            />
          </div>
        )}
        <div className="blog-banner__author-name-date">
          <h3 className="blog-banner__author-name">{props.authorName}</h3>
          <h3 className="blog-banner__date">{props.date}</h3>
        </div>
      </div>
    </>
  );
}

export default BlogBanner;
