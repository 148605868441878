import './RoomFeatureBar.scss';

import Icon from '../../components/Global/Icon/Icon';
import { IconsType } from '../../assets/icons';

export type RoomFeatureBarProps = {
    guestsIconName: IconsType;
    guestIconNumber?: number;
    guestsText?: string;
    sqftIconName: IconsType;
    sqftIconNumber?: number;
    sqftText?: string;
    bedIconName: IconsType;
    bedIconNumber?: string;
    bedText?: string;
}

const RoomFeatureBar = (props: RoomFeatureBarProps) => {

    const { guestsIconName, guestIconNumber, guestsText, sqftIconName, sqftIconNumber, sqftText, bedIconName, bedIconNumber, bedText } = props;


    return (
        <div className='feature-bar'>
            <div className="feature-bar-section feature-bar-section--border">
                <Icon 
                    name={guestsIconName} 
                    className={`feature-bar-section__icon feature-bar-section__icon--${guestIconNumber}`}
                    color='#004C45'
                />
                <p className="feature-bar-section__text">{guestsText}</p>
            </div>
            <div className="feature-bar-section feature-bar-section--border">
                <Icon 
                    name={sqftIconName} 
                    className={`feature-bar-section__icon feature-bar-section__icon--${sqftIconNumber}`}
                    color='#004C45'
                />
                <p className="feature-bar-section__text">{sqftText}</p>
            </div>
            <div className="feature-bar-section">
                <Icon 
                    name={bedIconName} 
                    className={`feature-bar-section__icon feature-bar-section__icon--${bedIconNumber}`}
                    color='#004C45'
                />
                <p className="feature-bar-section__text">{bedText}</p>
            </div>
            
        </div>
    )
}

export default RoomFeatureBar;