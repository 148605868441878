import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

type propsMetaDecorator = {
  title?: string;
  description?: string;
  currentUrl?: string;
  hostname?: string;
  imageAlt?: string;
  imageUrl?: string;
};
const MetaDecorator = (props: propsMetaDecorator) => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <title>{props.title}</title>
        <meta property="og:title" content={props.title} />
        <meta name="description" content={props.description} />
        <meta property="og:description" content={props.description} />
        <meta property="url" content={props.currentUrl} />
        <meta property="og:image" content={props.imageUrl} />
        <meta name="og:image:alt" content={props.imageAlt} />
        <meta
          property="og:url"
          content={
            props.hostname + window.location.pathname + window.location.search
          }
        />
      </Helmet>
    </HelmetProvider>
  );
};

export default MetaDecorator;
