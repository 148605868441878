import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/Global/Icon/Icon";
import "./FAQsAccordion.scss";

import OutsideClickHandler from "react-outside-click-handler";

type FAQsAccordionProps = {
  title?: string;
  paragraph?: string;
  linkTo?: string;
  linkToText?: string;
  punctuation?: string;
};

const FAQsAccordion = (props: FAQsAccordionProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");

  const content = useRef<HTMLDivElement>(null!);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setOpen(!isOpen);
  }

  const closeAccordion = () => {
    setOpen(false);
    setHeightState("0px");
    setActiveState("");
  };

  return (
    <div id="faqs-accordion" className="faqs-accordion">
      <OutsideClickHandler onOutsideClick={closeAccordion}>
        <button className="faqs-accordion__header" onClick={toggleAccordion}>
          <h4 className="faqs-accordion__title">{props.title}</h4>
          {isOpen ? (
            <Icon className="faqs-accordion__icon" name="minus" size={21} />
          ) : (
            <Icon className="faqs-accordion__icon" name="add" size={21} />
          )}
        </button>
        <div
          className={`faqs-accordion__content`}
          ref={content}
          style={{ maxHeight: `${setHeight}` }}
        >
          <p className="faqs-accordion__paragraph">
            {props.paragraph}
            {props.linkTo && (
              <Link to={props.linkTo} className="faqs-accordion__link">
                {props.linkToText}
              </Link>
            )}
            {props.punctuation && props.punctuation}
          </p>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default FAQsAccordion;
