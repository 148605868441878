import "./Experience.scss";
import { useEffect, useState, useContext } from "react";
// import BookingMenuMobile from "../../components/BookingMenuMobile/BookingMenuMobile";
import HeroCarousel from "../../components/HeroCarousel/HeroCarousel";
// import DiscoverCardCarousel from "../../components/DiscoverCardCarousel/DiscoverCardCarousel";
import TwoColumnImage from "../../components/TwoColumnImage/TwoColumnImage";
import InfoImageGroupCarousel from "../../components/Global/InfoImageGroupCarousel/InfoImageGroupCarousel";
import InfoImageGroup from "../../components/Global/InfoImageGroup/InfoImageGroup";
import InfoImageCard from "../../components/Global/InfoImageCard/InfoImageCard";
import InstagramCarousel from "../../components/InstagramCarousel/InstagramCarousel";
import CallToActionBanner from "../../components/CallToActionBanner/CallToActionBanner";
// import LocationMap from "../../components/LocationMap/LocationMap";
import TextLinkIcon from "../../components/Global/TextLinkIcon/TextLinkIcon";
import GotQuestions from "../../components/GotQuestions/GotQuestions";

// import DeskWithRecordPlayer from '../../assets/images/live-local/desk-with-record-player.png';
// import HeadboardWithArtwork from '../../assets/images/live-local/headboard-with-artwork.png';
// import DownTheHall from '../../assets/images/live-local/down-the-hall.png';
import RoomWithBlurredPerson from "../../assets/images/live-local/room-with-blurred-person.jpg";
import PersonOnPhone from "../../assets/images/live-local/person-on-phone.jpg";
// import ManAtComputer from '../../assets/images/live-local/man-at-computer.png';
// import RoomAudioSystem from '../../assets/images/live-local/room-audio-system.png';
import JamesBrown from "../../assets/images/live-local/james-brown.jpg";
import Bed from "../../assets/images/live-local/bed.jpg";
import DeskWithIpad from "../../assets/images/live-local/desk-ipad.jpg";
import { ourGuestsItems } from "./ExperienceMockData";
import {
  InstagramImages,
  InstagramImagesDesktop,
} from "../../components/InstagramCarousel/InstagramCarouselMockData";
import MetaDecorator from "../../MetaDecorator";
import Logo from "../../assets/icons/annex-icon.png";
import { mapStyles } from "../../util/util";

// import moment from "moment";
import AppContext from "../../context";
import BlogRecentPostCarousel from "../../components/BlogRecentPostCarousel/BlogRecentPostCarousel";
import useBlogs from "../../hooks/useBlogs";

declare var google: any;

export default function Experience() {
  const initMap = () => {
    const theAnnexPosition: google.maps.LatLngLiteral = {
      lat: 43.6654713,
      lng: -79.4080313,
    };
    const mapOptions = {
      center: theAnnexPosition,
      zoom: 10,
      styles: mapStyles,
      mapTypeControl: false,
      streetViewControl: false,
    };
    const mapElement = document.getElementById("myMap") as HTMLElement;
    const map: google.maps.Map = new google.maps.Map(mapElement, mapOptions);
    const infoWindow = new google.maps.InfoWindow();
    const annexMarker = new google.maps.Marker({
      position: theAnnexPosition,
      map: map,
      icon: Logo,
      title: "The Annex Hotel",
    });
    annexMarker.addListener("click", () => {
      infoWindow.close();
      infoWindow.setContent(annexMarker.getTitle());
      infoWindow.open(annexMarker.getMap(), annexMarker);
    });
  };
  const blogs = useBlogs().blogs;
  const [ourGuests, setOurGuests] = useState<any[]>([]);

  useEffect(() => {
    setOurGuests(ourGuestsItems);
    setTimeout(() => {
      initMap();
    }, 1000);

    return () => {
      setOurGuests([]);
    };
  }, [ourGuests]);

  const { openBookingEngine } = useContext(AppContext);
  const openBooking = () => {
    // const dates = date.split(" – ");
    // const startDate = moment(dates[0]).toDate();
    // const endDate = moment(dates[1]).toDate();
    // const startDate = moment(value[0]).toDate();
    // const endDate = moment(value[1]).toDate();
    openBookingEngine();
  };

  return (
    <>
      <MetaDecorator title="Experience" description="Sample description" />
      {/* <BookingMenuMobile /> */}
      <HeroCarousel experience={true} />
      <div className="experience-container">
        <InfoImageGroupCarousel
          title={"Friendly Faces"}
          items={ourGuests}
          experience={true}
        />
        {/* <GotQuestions
          experience={true}
          hasLinkTag={true}
          linkTo="/live-local"
          title="Stay where this is all happening"
          children="Book Now"
        /> */}
        <GotQuestions
          experience={true}
          hasLinkTag={false}
          linkTo="#"
          title="Stay where this is all happening"
          children="Book Now"
          onClick={openBooking}
        />
        <BlogRecentPostCarousel 
          title="Catch up in The Lobby" 
          infoHeight={"185px"}
          items={blogs}
        />
        {/* <DiscoverCardCarousel
          title={"Music from The Annex"}
          infoHeight={"240px"}
          experience={true}
        /> */}
        <div className="live-local-two-column-image">
          <TwoColumnImage
            leftImgSrc={PersonOnPhone}
            leftImgAlt={"Person on their phone"}
            leftImgTitle={"Person on their phone"}
            rightImgSrc={RoomWithBlurredPerson}
            rightImgAlt={"Room with blurred person"}
            rightImgTitle={"Room with blurred person"}
            title={"Hospitality driven by technology."}
            desc={
              "Your stay at The Annex happens entirely from the palm of your hand. Mobile check in is done entirely prior to arrival. That means no lines at the front desk. And when you need a cheeky cocktail or to find out where the best tacos are in the area… just text us."
            }
          />
        </div>
        <h4 className="experience-section-title">Connect with us</h4>
        <InstagramCarousel
          items={InstagramImages}
          itemsDesktop={InstagramImagesDesktop}
          experience={true}
        />
        <h4 className="experience-section-title">Our Rooms</h4>
        <InfoImageGroup
          leftImageSrc={JamesBrown}
          leftImageAlt={"Desk with record player and James Brown record"}
          leftImageTitle={"Desk with record player and James Brown record"}
          rightImageSrc={Bed}
          rightImageAlt={"Bed"}
          rightImageTitle={"Bed"}
          imageCardPosition="top"
          bottomTitle="Stay with us"
          cardChildren={
            <InfoImageCard
              imageSrc={DeskWithIpad}
              imageAlt={"Desk with iPad"}
              imageTitle={"Desk with iPad"}
              mobileImageSrc={JamesBrown}
              mobileImageAlt={"Desk with record player and James Brown record"}
              mobileImageTitle={
                "Desk with record player and James Brown record"
              }
              linkChildren={
                <TextLinkIcon
                  linkTo="/rooms"
                  text="View All Rooms"
                  iconSize={21}
                />
              }
              name={"Live like a local"}
              subName={"The Annex, Toronto"}
              paragraph={
                "A warm and authentic Toronto experience while enjoying comfort that makes a house a home. See the city from a different angle – from the inside."
              }
              buttonText={"All Rooms"}
              buttonLinkTo={"/rooms"}
              // title="Stay with us"
            />
          }
        />
      </div>

      {/* <LocationMap mapChildren={<div id="myMap"></div>} /> */}
      <CallToActionBanner
        title={"Join the Community"}
        body={
          "Annex is an unrivalled local community experience for creative, lifestyle, and original hospitality experiences. Join the mailing list for first access to new content, discounts and events."
        }
        buttonText={"Join"}
        hasMailingListModal={true}
      />
    </>
  );
}
