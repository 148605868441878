import "./Moments.scss";
import { useState } from "react";

import BigLaugh from "../../assets/images/moments-page/big-laugh.jpg";
import DanceFloor from "../../assets/images/moments-page/dance-floor.jpg";
import MakingEntrance from "../../assets/images/moments-page/Making-Entrance.jpg";
import BestBouquet from "../../assets/images/moments-page/Best-Bouquet.jpg";
import CoffeeClass from "../../assets/images/moments-page/Coffee-Class.jpg";
import PatioMoment from "../../assets/images/moments-page/Patio-Moment.jpg";
import BeProductive from "../../assets/images/moments-page/Be-Productive.jpg";
import DrinkingTime from "../../assets/images/moments-page/DriningTime.jpg";
import PatioSeason from "../../assets/images/moments-page/Patio-Season.jpg";
import Morning from "../../assets/images/moments-page/Morning-Read.jpg";
import Artist from "../../assets/images/moments-page/Artist.jpg";
import WineBarVibe from "../../assets/images/moments-page/Wine-Bar-Vibe.jpg";
import Tonight from "../../assets/images/moments-page/B-Tonight.jpg";
import BestBirthday from "../../assets/images/moments-page/Best-Birthday.jpg";
import SweetDay from "../../assets/images/moments-page/Sweet-Day.jpg";
import Empower from "../../assets/images/moments-page/empower-girl.jpg";
import Market from "../../assets/images/moments-page/market.jpg";
import ArtFaceTear from "../../assets/images/moments-page/art-face-tear.jpg";
import ManSinging from "../../assets/images/moments-page/man-singing.jpg";
import PersonHoldingDog from "../../assets/images/moments-page/person-holding-dog.jpg";
import Succulents from "../../assets/images/moments-page/succulents.jpg";
import LadyBW from "../../assets/images/moments-page/lady-bw.jpg";
import DayDreaming from "../../assets/images/moments-page/day-dreaming.jpg";
import Breakfast from "../../assets/images/moments-page/breakfast.jpg";
import HotelRoom from "../../assets/images/moments-page/hotel-room.jpg";

import MetaDecorator from "../../MetaDecorator";

//need to duplicate images in array, hence there being double the amount of array entries to actual images

const momentsImages = [
  {
    image: BigLaugh,
    alt: "Women Having Fun",
    caption: "A big laugh - AK",
  },
  {
    image: DanceFloor,
    alt: "Cowboy on the dancefloor",
    caption: "Cowboy on the dancefloor",
  },
  {
    image: MakingEntrance,
    alt: " Making an entrance",
    caption: "Always making an entrance",
  },
  {
    image: BestBouquet,
    alt: " Best bouquet ever",
    caption: "Best bouquet ever.",
  },
  {
    image: CoffeeClass,
    alt: "Coffee Class",
    caption: "Coffee class was the best - LN",
  },
  {
    image: PatioMoment,
    alt: "patio moment",
    caption: "A perfect fall patio moment.",
  },
  {
    image: BeProductive,
    alt: "Be Productive",
    caption: "Trying to be productive… - GP",
  },
  {
    image: DrinkingTime,
    alt: "Time for a drink",
    caption: "Time for a drink…",
  },
  {
    image: PatioSeason,
    alt: "Patio Season",
    caption: "Patio season is the best season - EC",
  },
  {
    image: Morning,
    alt: "a morning read",
    caption: "A morning read - AL",
  },
  {
    image: Artist,
    alt: "Become an artist",
    caption: "Became an artist - MW",
  },
  {
    image: WineBarVibe,
    alt: "The wine bar",
    caption: "The wine bar is a vibe - AD",
  },
  {
    image: Tonight,
    alt: "Brianna",
    caption: "Brianna killed it tonight - JM",
  },
  {
    image: BestBirthday,
    alt: "",
    caption: "Best birthday ever - Mickey",
  },
  {
    image: SweetDay,
    alt: "Sweet Day",
    caption: "Needed a sweat today - MJ",
  },
  {
    image: Empower,
    alt: "empower tomorrow’s",
    caption: "A night to empower tomorrow’s entrepreneurs.",
  },
  {
    image: Market,
    alt: "Market",
    caption: "New Friends - AG",
  },
  {
    image: ManSinging,
    alt: "Man singing",
    caption: "good music, good night - KM",
  },
  {
    image: PersonHoldingDog,
    alt: "Person holding a dog",
    caption: "Our furry friends need staycations too… - SS",
  },
  {
    image: Succulents,
    alt: "Succulents",
    caption: "Cute thing at the fall market - LS",
  },
  {
    image: LadyBW,
    alt: "Lady in black and white",
    caption: " A moment of glamour - SA",
  },
  {
    image: DayDreaming,
    alt: "Person lying on bed",
    caption: "Daydreaming - NS",
  },
  {
    image: Breakfast,
    alt: "Coffee and croissant",
    caption: "a perfect morning - AH",
  },
  {
    image: HotelRoom,
    alt: "Hotel room",
    caption: "the one where I turn 27 - SA",
  },
  {
    image: BigLaugh,
    alt: "Women Having Fun",
    caption: "A big laugh - AK",
  },
  {
    image: DanceFloor,
    alt: "Cowboy on the dancefloor",
    caption: "Cowboy on the dancefloor",
  },
  {
    image: MakingEntrance,
    alt: " Making an entrance",
    caption: "Always making an entrance",
  },
  {
    image: BestBouquet,
    alt: " Best bouquet ever",
    caption: "Best bouquet ever.",
  },
  {
    image: CoffeeClass,
    alt: "Coffee Class",
    caption: "Coffee class was the best - LN",
  },
  {
    image: PatioMoment,
    alt: "patio moment",
    caption: "A perfect fall patio moment.",
  },
  {
    image: BeProductive,
    alt: "Be Productive",
    caption: "Trying to be productive… - GP",
  },
  {
    image: DrinkingTime,
    alt: "Time for a drink",
    caption: "Time for a drink…",
  },
  {
    image: PatioSeason,
    alt: "Patio Season",
    caption: "Patio season is the best season - EC",
  },
  {
    image: Morning,
    alt: "a morning read",
    caption: "A morning read - AL",
  },
  {
    image: Artist,
    alt: "Become an artist",
    caption: "Became an artist - MW",
  },
  {
    image: WineBarVibe,
    alt: "The wine bar",
    caption: "The wine bar is a vibe - AD",
  },
  {
    image: Tonight,
    alt: "Brianna",
    caption: "Brianna killed it tonight - JM",
  },
  {
    image: BestBirthday,
    alt: "",
    caption: "Best birthday ever -  Mickey",
  },
  {
    image: SweetDay,
    alt: "Sweet Day",
    caption: "Needed a sweat today - MJ",
  },
  {
    image: Empower,
    alt: "empower tomorrow’s",
    caption: "A night to empower tomorrow’s entrepreneurs.",
  },
  {
    image: Market,
    alt: "Market",
    caption: "New Friends - AG",
  },
  {
    image: ManSinging,
    alt: "Man singing",
    caption: "good music, good night - KM",
  },
  {
    image: PersonHoldingDog,
    alt: "Person holding a dog",
    caption: "Our furry friends need staycations too… - SS",
  },
  {
    image: Succulents,
    alt: "Succulents",
    caption: "Cute thing at the fall market - LS",
  },
  {
    image: LadyBW,
    alt: "Lady in black and white",
    caption: " A moment of glamour - SA",
  },
  {
    image: DayDreaming,
    alt: "Person lying on bed",
    caption: "Daydreaming - NS",
  },
  {
    image: Breakfast,
    alt: "Coffee and croissant",
    caption: "a perfect morning - AH",
  },
  {
    image: HotelRoom,
    alt: "Hotel room",
    caption: "the one where I turn 27 - SA",
  },
];

const Moments = () => {
  const [pause, setPause] = useState(false);

  const pauseAnimation = () => {
    setPause(true);
  };
  const unpauseAnimation = () => {
    setPause(false);
  };

  return (
    <div className="moments">
      <MetaDecorator
        title="Moments at the Annex"
        description="Sample description"
      />
      <div className="moments-title">
        <h1
          className={`moments-title__text ${
            pause && "moments-title__text--pause"
          }`}
        >
          Camera
        </h1>
        <h1
          className={`moments-title__text ${
            pause && "moments-title__text--pause"
          }`}
        >
          Roll
        </h1>
      </div>
      <div
        className={`moments-imgs-container ${
          pause &&
          "moments-imgs-container--pause moments-imgs-container--elevate"
        }`}
      >
        {momentsImages.map((image, index) => {
          return (
            <div
              className={`moments-img--${index + 1} moments-img--text-align`}
              onMouseEnter={pauseAnimation}
              onMouseLeave={unpauseAnimation}
              key={index}
              style={{ backgroundImage: `url(${image.image})` }}
            >
              <div className="camera-roll-images-mask"></div>
              <p
                className={`moments-img__text ${
                  pause && "moments-img__text--pause"
                }`}
              >
                {image.caption}
              </p>
            </div>
          );
        })}
      </div>
      <a className="share-moment" href="mailto:hello@theannex.com">
        Share a <br></br> pic
      </a>
    </div>
  );
};

export default Moments;
