import "./EventBookingModal.scss";

import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import EventBookingAccordion from "../EventBookingAccordion/EventBookingAccordion";
import Icon from "../Global/Icon/Icon";

import BookOurSpace from "./BookOurSpace";
import Collaborate from "./Collaborate";

import {
  // yourDetailsArray,
  // willThereBeFoodArray,
  // occasionArray,
  ourSpacesDescriptionsArray,
} from "./EventDataArrays";

export type EventBookingModalProps = {
  showEventsModal?: boolean;
  openEventsModal: boolean;
  setOpenEventsModal: (i: boolean) => void;
  bookOnly: boolean;
  setBookOnly: (i: boolean) => void;
};

const showModal = {
  hidden: {
    opacity: 0,
    top: "-100%",
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    top: 0,
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
};

const EventBookingModal = ({
  showEventsModal,
  openEventsModal,
  setOpenEventsModal,
  bookOnly,
  setBookOnly,
  ...props
}: EventBookingModalProps) => {
  const [book, setBook] = useState(true);
  const [collaborate, setCollaborate] = useState(false);

  const handleBook = () => {
    setBook(true);
    setCollaborate(false);
  };
  const handleCollaborate = () => {
    setBook(false);
    setCollaborate(true);
  };

  // const [calendarOpen, setCalendarOpen] = useState(false);
  // const handleCalendarOpen = (e: any) => {
  //     setCalendarOpen(!calendarOpen)
  // }

  // const [value, onChange] = useState<Date[]>([new Date(), new Date()]);

  // const startDay = value[0].toLocaleDateString('default', {month: 'short', day: 'numeric'});
  // const endDay = value[1].toLocaleDateString('default', {month: 'short', day: 'numeric'});

  return (
    <AnimatePresence exitBeforeEnter>
      {showEventsModal && (
        <motion.div
          className="event-booking-modal-container"
          variants={showModal}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Icon
            name="menu-close"
            size={21}
            className="event-booking-modal-close"
            onClick={() => {
              setOpenEventsModal(false);
            }}
          ></Icon>
          <div className="event-booking-modal-inner-container">
            <div className="event-booking-modal">
              <div className="event-booking-modal-left">
                <div className="event-booking-modal-left__title-container">
                  <h1 className="event-booking-modal-left__title-top">
                    Event Booking
                  </h1>
                  {/* <h1 className="event-booking-modal-left__title-bottom">Booking</h1> */}
                </div>
                <EventBookingAccordion
                  onSelect={() => {}}
                  className="our-spaces-mobile"
                  headerSubTitle="Learn about our spaces"
                  headerClassName="our-spaces-header"
                  headerSubTitleClassName="our-spaces-header__text"
                  dropdownClassName="our-spaces-header-dropdown"
                  dropdownInnerContainerClassName="our-spaces-header-dropdown__inner-container"
                  dropdownHeadingTextClassName="our-spaces-header-dropdown__heading-text"
                  dropdownTextClassName="our-spaces-header-dropdown__text"
                  arrayOfObjectsSrc={ourSpacesDescriptionsArray}
                />
                {ourSpacesDescriptionsArray.map((item, index) => {
                  return (
                    <div className="our-spaces" key={index}>
                      <h4 className="our-spaces__title">{item.title}</h4>
                      <p className="our-spaces__body">{item.body}</p>
                    </div>
                  );
                })}
              </div>
              <div className="event-booking-modal-right">
                <div className="book-collaborate">
                  <button
                    className={
                      book
                        ? "book-collaborate__book button-active"
                        : "book-collaborate__book"
                    }
                    onClick={handleBook}
                  >
                    Book our space
                  </button>
                  {/* <div className="book-collaborate__divider"></div> */}
                  {!bookOnly && (
                    <button
                      className={
                        collaborate
                          ? "book-collaborate__collaborate button-active"
                          : "book-collaborate__collaborate"
                      }
                      onClick={handleCollaborate}
                    >
                      Collaborate
                    </button>
                  )}
                </div>
                {book && (
                  <BookOurSpace
                    openEventsModal={openEventsModal}
                    setOpenEventsModal={setOpenEventsModal}
                    bookOnly={bookOnly}
                    setBookOnly={setBookOnly}
                  />
                )}
                {collaborate && (
                  <Collaborate
                    openEventsModal={openEventsModal}
                    setOpenEventsModal={setOpenEventsModal}
                  />
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default EventBookingModal;
