import MetaDecorator from '../../../MetaDecorator';
import AuthTwoColumnLayout from '../AuthTwoColumnLayout';
import './AuthSpecialOffers.scss';
import useVouchers from '../../../hooks/useVouchers';

const AuthSpecialOffers = () => {
  const vouchers = useVouchers().vouchers;

  return (
    <>
      <MetaDecorator title="Special Offers | Annex" description=" Sample description"/>
      <AuthTwoColumnLayout 
        rightColumnChildren={<>
          <div className="annex-auth-two-column-layout__right-column-container">
            <h2 className="annex-auth-two-column-layout__title">Special Offers</h2>
            <ul>
              {vouchers && vouchers.map((voucher, index) => <li key={voucher.id}>{voucher.name}</li>)}
            </ul>
          </div>
        </>}
      />
    </>
  )
};

export default AuthSpecialOffers;
