import "./LandingPage.scss";
import { Link } from "react-router-dom";
// import AnnexIconHero from '../../assets/images/landing-pages/annex-icon-hero.png';
import MetaDecorator from "../../MetaDecorator";

export default function LandingPage() {
  return (
    <div className="landing-container">
      <MetaDecorator
        title="Discover the Annex"
        description="A New Kind of Hotel - Enjoy the comforts that make a house a home. Come live like a local."
      />
      <div className="landing-hero">
        <div className="landing-hero__mask"></div>
        {/* <img src={AnnexIconHero} alt="Annex Icon Hero" className="landing-hero__icon" loading="lazy"/>     */}
        <h1 className="landing-hero__title">Welcome to The Annex</h1>
      </div>
      <div className="experience">
        <Link to="/experience" className="experience__button">
          Experience
        </Link>
        <div className="experience__image">
          <div className="experience-image-mask"></div>
          {/* <img src={AnnexIconHero} alt="Annex Icon Hero" className="landing-hero__icon" loading="lazy" /> */}
          <div className="experience-title">
            <h1 className="experience-title__text-1">Live,</h1>
            <h1 className="experience-title__text-2">don't sleep</h1>
          </div>
        </div>
      </div>
      <div className="experience-live-local-divider mobile-only"></div>
      <div className="live-local">
        <Link to="/live-local" className="live-local__button">
          Stay
        </Link>
        <div className="live-local__image">
          <div className="live-local-image-mask"></div>
          {/* <img src={AnnexIconHero} alt="Annex Icon Hero" className="landing-hero__icon icon-enhance" loading="lazy"/> */}
          <div className="live-local-title">
            <h1 className="live-local-title--1">Live like</h1>
            <h1 className="live-local-title--2">a local</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
