import "./BookingSummary.scss";
import BookSummaryImage from "../../assets/images/booking-summary.jpg";
import { Room } from "../../../shared/types/room";

type BookingSummaryProps = {
  room: Room;
};

const BookingSummary = (props: BookingSummaryProps) => {
  const { room } = props;
  return (
    <div className="annex-booking-summary">
      <h2 className="annex-booking-summary__title">Booking Summary</h2>
      <div className="annex-booking-summary__img-text-container">
        <img
          className="annex-booking-summary__img"
          src={BookSummaryImage}
          alt={room.name}
          title={room.name}
          loading="lazy"
        />
        <h3 className="annex-booking-summary__room-name">{room.name}</h3>
      </div>
      <div className="annex-booking-summary__booking-total-container">
        <p className="annex-booking-summary__booking-total">Booking Total</p>
        <p className="annex-booking-summary__booking-total-cost">{`$${room.price}`}</p>
      </div>
    </div>
  );
};

export default BookingSummary;
