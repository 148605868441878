import { ReactNode, useEffect, useState } from "react";
import Button from "../Global/Button/Button";
import Icon from "../Global/Icon/Icon";
import "./LocationMap.scss";
import { useHistory } from "react-router-dom";

type LocationMapProps = {
  mapChildren?: ReactNode;
};

const LocationMap = (props: LocationMapProps) => {
  const history = useHistory();
  const handleClick = () => history.push("/neighbourhood");
  const [celsius, setCelsius] = useState<number>(0);
  const [fahrenheit, setFahrenheit] = useState<number>(0);

  useEffect(() => {
    const URL = `https://api.openweathermap.org/data/2.5/weather?`;
    const API_KEY = process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY; // TODO: Get Will to purchase a key

    fetch(`${URL}q=Toronto&units=metric&appid=${API_KEY}`)
      .then((response) => response.json())
      .then((data) => {
        if (data?.main?.temp) {
          setCelsius(Math.round(Number(data.main.temp)));
          setFahrenheit(Math.round(Number(data.main.temp) * 1.8 + 32));
        }
      })
      .catch((err) => {
        console.error("Getting weather data failed", err);
      });
  }, []);

  return (
    <div className="annex-location-map">
      {props.mapChildren && (
        <div className="annex-location-map__map-container">
          {props.mapChildren}
        </div>
      )}
      <div className="annex-location-map__info">
        <Icon className="annex-location-map__icon" name="location" size={20} />
        <h2 className="annex-location-map__location-text">Location</h2>
        <p className="annex-location-map__location-area-text">
          North America
          <br />
          Toronto
        </p>
        <p className="annex-location-map__location-address-text">
          296 Brunswick, Toronto
          <br />
          M5S 2M7
        </p>
        <Button
          buttontype="primary--inverse"
          children="Discover the City"
          onClick={handleClick}
          linkTo=""
        />
        <p className="annex-location-map__Temerature-text">Temperature Today</p>
        <p className="annex-location-map__Temerature-celsius-fahrenheit-text">
          {celsius} °C / {fahrenheit} °F
        </p>
      </div>
    </div>
  );
};

export default LocationMap;
