import "./BookCalendarGuests.scss";

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../Global/Icon/Icon";
import BookingCalendar from "../Global/BookingCalendar/BookingCalendar";
import Counter from "../Global/Counter/Counter";
import Button from "../Global/Button/Button";

import { motion, AnimatePresence } from "framer-motion";

// import { NumberOfGuests } from "../BookingMenu/BookingMenuMockData";

import { doc } from "@firebase/firestore";
import {
  useFirestore,
  //   useFirestoreDocOnce,
  useFirestoreDocDataOnce,
} from "reactfire";
import { getUTCDate, getUTCDateNext } from "../../util/util";

import axios from "axios";
import Loader from "react-loader-spinner";

export type BookCalendarGuestsProps = {
  openMenuCalendar: boolean;
  setOpenMenuCalendar: (i: boolean) => void;
  openMenuGuests: boolean;
  setOpenMenuGuests: (i: boolean) => void;
  // setNumberOfGuests: (i: number) => void;
  setRooms: any;
  date: any;
  setDate: any;
  guests: number;
  setGuests: (i: number) => void;
  isLoading: boolean;
  setIsLoading: (i: boolean) => void;
  startUtcFromParams: any;
  endUtcFromParams: any;
  numberOfGuestsFromParams: number;
  idFromParams: any;
};

const BookCalendarGuests = ({
  openMenuCalendar,
  setOpenMenuCalendar,
  openMenuGuests,
  setOpenMenuGuests,
  setRooms,
  date,
  setDate,
  guests,
  setGuests,
  isLoading,
  setIsLoading,
  startUtcFromParams,
  endUtcFromParams,
  numberOfGuestsFromParams,
  idFromParams,
  ...props
}: BookCalendarGuestsProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [guestSelect, setGuestSelect] = useState<boolean>(false);
  //   const [guestNumber, setGuestNumber] = useState<number>(1);
  // const [value, onChange] = useState<Date[]>([new Date(), new Date()]);

  const history = useHistory();

  const fullStartUTC = getUTCDate();
  const [startUtc, setStartUtc] = useState(
    startUtcFromParams ? startUtcFromParams : fullStartUTC
  );

  const fullEndUTC = getUTCDateNext();
  const [endUtc, setEndUtc] = useState(
    endUtcFromParams ? endUtcFromParams : fullEndUTC
  );

  const configRef = doc(useFirestore(), "mews/config");
  const { data: configure } = useFirestoreDocDataOnce(configRef);
  const [numberOfGuests, setNumberOfGuests] = useState(
    numberOfGuestsFromParams ? numberOfGuestsFromParams : 1
  );
  const id = configure?.roomIds;

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [value, setValue] = useState<Date[]>(
    startUtcFromParams
      ? [new Date(startUtcFromParams), new Date(endUtcFromParams)]
      : [today, tomorrow]
  );

  const [availability, setAvailability] = useState<number>(0);
  //   const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChange = (nextValue?: any) => {
    setValue(nextValue);
    // need .toISOString() to convert dates to format readable by MEWS
    const currentDay = new Date();
    const currentDayByOneHour = currentDay.setHours(
      currentDay.getHours() + 1 - 5
    );
    const currentYearMonthDay = currentDay.toISOString().split("T")[0];
    const currentDayFormated = new Date(currentDayByOneHour).toISOString();

    const checkInDate = new Date(
      //15 - 5 is for 3pm - 5 hours. -5 hours to compensate for UTC being 5 hours ahead of EST
      //TODO:
      //need to confirm with Annex people if 3pm is correct check in time
      new Date(nextValue[0]).setHours(15 - 5)
    ).toISOString();
    const checkOutDate = new Date(
      //11 - 5 is for 11am - 5 hours. -5 hours to compensate for UTC being 5 hours ahead of EST
      //The additional -1 is because the time was being set an hour ahead of 11am for some reason
      //TODO:
      //need to confirm with Annex people if 11am is correct check out time
      new Date(nextValue[1]).setHours(11 - 5 - 1)
    ).toISOString();
    // console.log(
    //   "first day",
    //   new Date(new Date(nextValue[0]).setHours(15 - 5)).toISOString()
    // );
    // console.log(
    //   "last day",
    //   new Date(new Date(nextValue[1]).setHours(11 - 5 - 1)).toISOString()
    // );

    if (nextValue[0].toISOString().includes(currentYearMonthDay)) {
      setStartUtc(currentDayFormated);
    } else {
      // setStartUtc(nextValue[0].toISOString());
      setStartUtc(checkInDate);
    }
    // setEndUtc(nextValue[1].toISOString());
    setEndUtc(checkOutDate);
    // setDate(
    //   `${new Date(nextValue[0].toISOString()).toDateString()} – ${new Date(
    //     nextValue[1].toISOString()
    //   ).toDateString()}`
    // );
    setDate(
      `${new Date(checkInDate).toDateString()} – ${new Date(
        checkOutDate
      ).toDateString()}`
    );
  };

  const handleCalendarOpen = (e: any) => {
    setCalendarOpen(!calendarOpen);
    setGuestSelect(!guestSelect);
  };
  const handleGuestSelect = (e: any) => {
    setCalendarOpen(false);
    setGuestSelect(true);
  };
  const handleMenuClose = () => {
    setMenuOpen(false);
    setCalendarOpen(false);
    setGuestSelect(false);
    setOpenMenuCalendar(false);
    setOpenMenuGuests(false);
    // setGuestNumber(1);
  };

  useEffect(() => {
    setMenuOpen(openMenuCalendar || openMenuGuests);
    setCalendarOpen(openMenuCalendar);
    setGuestSelect(openMenuGuests);
    // if (!openMenuCalendar && !openMenuGuests) {
    //     setGuestNumber(1);
    // }
  }, [
    openMenuCalendar,
    openMenuGuests,
    setOpenMenuCalendar,
    setOpenMenuGuests,
  ]);

  //load room availability
  // useEffect(() => {
  // Copied this from Booking Stay

  const loadAvailability = async () => {
    // console.log("loading from book calendar guest");
    // console.log("start utc", startUtc);
    // console.log("end utc", endUtc);
    // console.log("number of guests", numberOfGuests);

    var data = JSON.stringify({
      StartUtc: startUtc,
      EndUtc: endUtc,
      AdultCount: numberOfGuests,
      // CategoryIds: config.roomIds.split(","),
      CategoryIds: idFromParams?.split(","),
    });
    // console.log("start utc", startUtc);
    // console.log("end utc", endUtc);
    // console.log("number of guests", numberOfGuests);
    // console.log("availability data", data);

    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    setIsLoading(true);

    let roomAvailability = 0;
    const apiBaseUrl = process.env.REACT_APP_FIREBASE_API_BASE;
    await axios
      .post(`${apiBaseUrl}/hotels/getAvailability`, data, config)
      .then((response) => {
        setAvailability(response.data.length);
        roomAvailability = response.data.length;
        setRooms(response.data);
        setIsLoading(false);
        if (roomAvailability !== 0) {
          history.push(
            `/booking-stay/${[id]}/${numberOfGuests}/${startUtc}/${endUtc}`
          );
        }
        // console.log("availability booking guest calendar", response);
      })
      .catch((err) => {
        console.error("loadAvailability", err);
        setIsLoading(false);
      });
  };
  //   if (
  //     startUtc !== startUtcFromParams ||
  //     endUtc !== endUtcFromParams ||
  //     numberOfGuests !== numberOfGuestsFromParams
  //   ) {
  //     loadAvailability();
  //   }
  // }, [startUtc, endUtc, numberOfGuests]);

  //   const startDay = value[0].toLocaleDateString('default', {month: 'short', day: 'numeric'});
  //   const endDay = value[1].toLocaleDateString('default', {month: 'short', day: 'numeric'});

  const showMenu = {
    hidden: {
      left: "-100%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    visible: {
      left: "0px",
      transition: {
        type: "spring",
        duration: 0.7,
      },
    },
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {menuOpen && (
        <motion.div
          layout
          className="book-calendar-guests"
          variants={showMenu}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <div className={`book-calendar-guests-accordion`}>
            <div
              className={
                calendarOpen
                  ? "book-calendar-guests-accordion-header book-calendar-guests-accordion-header--active"
                  : "book-calendar-guests-accordion-header"
              }
            >
              <div className="book-calendar-guests-icon-container">
                <Icon
                  name="calendar"
                  color={
                    calendarOpen ? "rgb(0, 76, 69)" : "rgb(0, 76, 69, 0.2)"
                  }
                  className="book-calendar-icon"
                  onClick={handleCalendarOpen}
                />
                <div className="book-guests-icon" onClick={handleGuestSelect}>
                  <Icon
                    name="oneGuestPerson"
                    color={
                      guestSelect ? "rgb(0, 76, 69)" : "rgb(0, 76, 69, 0.2)"
                    }
                    className="book-guests-icon__icon"
                  />
                  {guests === 2 && (
                    <Icon
                      name="oneGuestPerson"
                      color={
                        guestSelect ? "rgb(0, 76, 69)" : "rgb(0, 76, 69, 0.2)"
                      }
                      className="book-guests-icon__icon"
                    />
                  )}
                  {guests === 3 && (
                    <>
                      <Icon
                        name="oneGuestPerson"
                        color={
                          guestSelect ? "rgb(0, 76, 69)" : "rgb(0, 76, 69, 0.2)"
                        }
                        className="book-guests-icon__icon"
                      />
                      <Icon
                        name="oneGuestPerson"
                        color={
                          guestSelect ? "rgb(0, 76, 69)" : "rgb(0, 76, 69, 0.2)"
                        }
                        className="book-guests-icon__icon"
                      />
                    </>
                  )}
                  {guests === 4 && (
                    <>
                      <Icon
                        name="oneGuestPerson"
                        color={
                          guestSelect ? "rgb(0, 76, 69)" : "rgb(0, 76, 69, 0.2)"
                        }
                        className="book-guests-icon__icon"
                      />
                      <Icon
                        name="oneGuestPerson"
                        color={
                          guestSelect ? "rgb(0, 76, 69)" : "rgb(0, 76, 69, 0.2)"
                        }
                        className="book-guests-icon__icon"
                      />
                      <Icon
                        name="oneGuestPerson"
                        color={
                          guestSelect ? "rgb(0, 76, 69)" : "rgb(0, 76, 69, 0.2)"
                        }
                        className="book-guests-icon__icon"
                      />
                    </>
                  )}
                </div>
              </div>
              {guestSelect && (
                <Counter
                  //   number={guestNumber}
                  //   setGuestNumber={setGuestNumber}
                  setNumberOfGuests={setNumberOfGuests}
                  guests={guests}
                  setGuests={setGuests}
                />
              )}

              {/* <Icon
                  name="menu-close"
                  className="book-close-icon"
                  onClick={handleMenuClose}
                /> */}
              <Button
                buttontype="outline"
                children={
                  isLoading ? (
                    <div className="loader-container">
                      <Loader
                        type="Oval"
                        color="#000000"
                        visible={true}
                        height={18}
                        width={18}
                      />
                    </div>
                  ) : (
                    "Go"
                  )
                }
                cname="go-button"
                linkTo=""
                onClick={loadAvailability}
              />
            </div>
            <BookingCalendar
              value={value}
              onChange={onChange}
              isActive={calendarOpen}
              // bookRoom={true}
              cname="calendar-book-this-room"
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BookCalendarGuests;
