import "./TermsAndConditions.scss";
import { terms } from "./TermList";
import MetaDecorator from "../../MetaDecorator";

const TermsAndConditions = () => {
  return (
    <div className="terms-conditions-container">
      <MetaDecorator
        title="Terms and Conditions"
        description="Sample description"
      />
      <div className="terms">
        <h1 className="terms__title">
          Terms and
          <br /> Conditions
        </h1>
        <h3 className="terms__subTitle">last modified: may 18, 2023</h3>
        <div className="terms_text_container">
          <p className="terms_text_container--text">
            PLEASE READ THE FOLLOWING TERMS OF USE CLOSELY BEFORE USING OUR
            WEBSITE. BY ACCESSING, BROWSING, AND USING THIS WEBSITE, YOU
            ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO COMPLY WITH
            THESE TERMS OF USE. IF YOU DO NOT AGREE WITH ANY PART OF THESE TERMS
            OF USE, YOU MUST NOT USE THIS WEBSITE.
          </p>
          {terms.map((term, index) => (
            <div key={index}>
              <ul className="terms_text_container_title">
                <li>{term.title}</li>
              </ul>
              <p
                className="terms_text_container--text"
                dangerouslySetInnerHTML={{ __html: term.descriptions }}
              ></p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
