import './GuestQuote.scss';

export type GuestQuoteProps = {
    text: string;
    // name: string;
}

const GuestQuote = (props: GuestQuoteProps) => {

    const { text } = props;

    return (
        <div className="guest-quote">
            <p className="guest-quote__text">{text}</p>
            {/* <p className="guest-quote__name">{name}</p> */}
        </div>
    )
}

export default GuestQuote;
