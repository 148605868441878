import BlogImageLoadAnimation from "./BlogImageLoadAnimation";
import "./BlogItem.scss";
// import { Timestamp } from "firebase/firestore";

export type BlogItemProps = {
  header_image: string;
  imgAlt?: string;
  title: string;
  authorName: string;
  date: any;
};

const BlogItem = (props: BlogItemProps) => {
  const { header_image, imgAlt, title, authorName, date } = props;

  return (
    <div className={`blog-item`}>
      {header_image && (
        <div className="blog-item__image-container">
          <img 
            className="blog-item__image blog-item__image--mobile-only" 
            src={header_image}
            alt={imgAlt} 
          />
          <BlogImageLoadAnimation 
            onInView={() => true}
            src={header_image}
            alt={imgAlt}
            width={'100%'}
            height={'100%'}
            className="blog-item__image blog-item__image--tablet-desktop-only"
          />
        </div>
      )}
      {title && (<h3 className="blog-item__title">{title}</h3>)}
      <div className="blog-item__author-date">
        {/* <span className="blog-item__author">{authorName ? authorName : 'N/A'}</span> | <span className="blog-item__date">{date ? date : 'N/A'}</span> */}
        <span className="blog-item__author">{authorName ? authorName : 'N/A'}</span> 
      </div> 
    </div>
  );
}

export default BlogItem;
