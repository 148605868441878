// import "bootstrap/dist/css/bootstrap.min.css";

import "./HeroCarousel.scss";

import { Carousel } from "react-bootstrap";
import { useState, useContext } from "react";

import BookingMenu from "../../components/BookingMenu/BookingMenu";
import TextLink from "../Global/TextLink/TextLink";

import {
  liveLocalHeroCarousel,
  experienceHeroCarousel,
} from "./HeroCarouselData";

import Icon from "../Global/Icon/Icon";

import AppContext from "../../context";

type HeroCarouselProps = {
  experience?: boolean;
};

export default function HeroCarousel({
  experience,
  ...props
}: HeroCarouselProps) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const directionButtons = (direction: string) => {
    return (
      <span
        aria-hidden="true"
        className={direction === "Next" ? "button-next" : "button-prev"}
      >
        {direction === "Next" ? (
          <Icon name="next-arrow" className="button-next-icon" color="black" />
        ) : (
          <Icon name="back-arrow" className="button-prev-icon" color="black" />
        )}
      </span>
    );
  };

  const { openBookingEngine } = useContext(AppContext);
  const openBooking = () => {
    // const dates = date.split(" – ");
    // const startDate = moment(dates[0]).toDate();
    // const endDate = moment(dates[1]).toDate();
    // const startDate = moment(startUtc).toDate();
    // const endDate = moment(endUtc).toDate();
    openBookingEngine();
  };

  return (
    <div
      className={`hero-carousel-container ${
        experience && "hero-carousel-container--experience"
      }`}
    >
      <Carousel
        //  ref={ref}
        interval={null}
        controls={experience ? false : true}
        indicators={false}
        nextLabel={""}
        prevLabel={""}
        activeIndex={index}
        onSelect={handleSelect}
        nextIcon={!experience && directionButtons("Next")}
        prevIcon={!experience && directionButtons("Previous")}
        className="hero-carousel"
      >
        {(experience ? experienceHeroCarousel : liveLocalHeroCarousel).map(
          (item, index) => {
            return (
              <Carousel.Item key={index} className="carousel-item">
                <div className="hero-carousel-item-wrapper">
                  <div
                    className={`${
                      experience ? "image-gradient-mask" : "image-mask"
                    }`}
                  ></div>
                  <img
                    src={item.image}
                    alt={item.alt}
                    className="hero-carousel__image"
                    loading="lazy"
                  />
                  <div
                    className={`hero-captions ${
                      experience && "hero-captions--experience"
                    }`}
                  >
                    {!experience && (
                      <p className="hero-captions__text">{item.previous}</p>
                    )}
                    <h1
                      className={`hero-captions__title ${
                        experience && "hero-captions__title--experience"
                      }`}
                    >
                      {item.name}
                    </h1>
                    {!experience && (
                      <div className="hero-captions-link-container">
                        <p className="hero-captions__description desktop-only">
                          {item.description}
                        </p>

                        <TextLink
                          // linkTo="/live-local"
                          linkTo="#"
                          onClick={openBooking}
                          text="BOOK NOW"
                          className="hero-captions-link-container__book-now"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Carousel.Item>
            );
          }
        )}
      </Carousel>
      {!experience && (
        <BookingMenu verticalPosition={250} className="desktop-only" />
      )}
      {!experience && (
        <h1 className="live-local-hero-title desktop-only">Live Local</h1>
      )}
      {experience && (
        <h1 className="experience-hero-title desktop-only">Experience</h1>
      )}
    </div>
  );
}
