// import { useEffect, useState } from "react";
import "./Counter.scss";

import Icon from "../Icon/Icon";

export type CounterProps = {
  // number: number;
  // setGuestNumber: (i: number) => void;
  setNumberOfGuests: (i: number) => void;
  guests: number;
  setGuests: (i: number) => void;
};

const Counter = ({
  setNumberOfGuests,
  guests,
  setGuests,
  ...props
}: CounterProps) => {
  // const [count, setCounter] = useState<number>(guests);
  // const [displayedCount, setDisplayedCount] = useState<string>('1');

  // useEffect(() => {
  //  setGuestNumber(count);
  //  setNumberOfGuests(count);
  //  setGuests(count);
  //   // if (count < 10) {
  //   //   setDisplayedCount('0'+count)
  //   // } else {
  //   //   setDisplayedCount(`${count}`)
  //   // }
  // }, [count, setGuestNumber, setNumberOfGuests, setGuests])

  const increment = (): any => {
    if (guests === 4) {
      // setCounter(4);
      // setGuestNumber(4);
      setNumberOfGuests(4);
      setGuests(4);
    } else {
      // setCounter(guests + 1);
      // setGuestNumber(guests + 1);
      setNumberOfGuests(guests + 1);
      setGuests(guests + 1);
    }
  };

  const decrement = (): any => {
    if (guests === 1) {
      // setCounter(1)
      // setGuestNumber(1);
      setNumberOfGuests(1);
      setGuests(1);
    } else {
      // setCounter(guests - 1);
      // setGuestNumber(guests - 1);
      setNumberOfGuests(guests - 1);
      setGuests(guests - 1);
    }
  };

  return (
    <div className="counter">
      <Icon name="minus" onClick={decrement} className="counter__minus" />
      <p className="counter__number">{guests}</p>
      <Icon name="add" onClick={increment} className="counter__plus" />
    </div>
  );
};

export default Counter;
