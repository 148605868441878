import "./NeighbourhoodNew.scss";

import ButtonArrow from "../../components/Global/ButtonArrow/ButtonArrow";

export type NeighbourhoodNewProps = {
  imgSrc?: string;
  imgAlt?: string;
  imgTitle?: string;
  name?: string;
  desc?: string;
  date?: string;
  time?: string;
  budget?: string;
  address?: string;
  onClick?: (() => void) | ((e: any) => void);
};

const NeighbourhoodNew = (props: NeighbourhoodNewProps) => {
  return (
    <div className="neighbourhood-new">
      <div className="neighbourhood-new__container">
        <div className="neighbourhood-new__image-text-content">
          <div className="neighbourhood-new__image-container">
            <img
              className="neighbourhood-new__image"
              src={props.imgSrc}
              alt={props.imgAlt}
              title={props.imgTitle}
              loading="lazy"
            />
          </div>

          <div className="neighbourhood-new__content">
            <div>
            <p className="neighbourhood-new__date">{props.date} | {props.time}</p>
            <p className="neighbourhood-new__budget">{props.budget}</p>
            <p className="neighbourhood-new__address">{props.address}</p>
            </div>
            {/* <div className="neighbourhood-new__title-button-link desktop-only"> */}
            <div className="neighbourhood-new__title-button-link tablet-and-above">
              <h2 className="neighbourhood-new__title">{props.name}</h2>
              {/* <ButtonArrow
                buttontype="main"
                children="More Information"
                iconColor="#000000"
                linkTo="#"
                onClick={props.onClick}
              /> */}
            </div>
            <ButtonArrow
            // className="desktop-only"
            className="tablet-and-above"
                buttontype="main"
                children="More Information"
                iconColor="#000000"
                linkTo="#"
                onClick={props.onClick}
              />
        
          </div>
        </div>

        {/* <div className="neighbourhood-new__title-button-link mobile-only"> */}
        <div className="neighbourhood-new__title-button-link mobile-below-tablet">
          <h2 className="neighbourhood-new__title">{props.name}</h2>
          <ButtonArrow
            buttontype="main"
            children="More Information"
            iconColor="#000000"
            linkTo="#"
            onClick={props.onClick}
          />
        </div>

      </div>
    </div>
  );
};

export default NeighbourhoodNew;
