import { useContext, useEffect, useState } from "react";
import Button from "../../../components/Global/Button/Button";
import "./BookingStay.scss";
import BookingStepOneImage from "../../../assets/images/booking-step-one.jpg";
// import BookingRoomImage from "../../../assets/images/booking-room.jpg";
import MetaDecorator from "../../../MetaDecorator";
import BookingRoom from "../BookingRoom";
import BookingTwoColumnLayout from "../BookingTwoColumnLayout";
import BookingStepTwoImage from "../../../assets/images/booking-step-two.jpg";
import BookCalendarGuests from "../../../components/BookCalendarGuests/BookCalendarGuests";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../../../components/Global/Icon/Icon";
import BookingRoomInfo from "../BookingRoomInfo";
import BookingSpecial from "../BookingSpecial";
import LocationMap from "../../../components/LocationMap/LocationMap";
import CallToActionBanner from "../../../components/CallToActionBanner/CallToActionBanner";
import Logo from "../../../assets/icons/annex-icon.png";
import BookingCheckout from "../BookingCheckout/BookingCheckout";
import BookingComplete from "../BookingComplete/BookingComplete";

import LoadingAnimation from "../../../components/Global/LoadingAnimation/LoadingAnimation";

import axios from "axios";
import { useParams } from "react-router-dom";
import { Room } from "../../../../shared/types/room";
// import { Product } from "../../../../shared/types/product";
import useProducts from "../../../hooks/useProducts";
import { DocumentData } from "firebase/firestore";
import { mapStyles } from "../../../util/util";
import { number } from "yup/lib/locale";

import { IconsType } from "../../../assets/icons";
import moment from "moment";
import AppContext from "../../../context";

declare var google: any;

const BookingStay = () => {
  const [guestData, setGuestData] = useState({
    uid: "",
    email: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
  }); //may delete later

  const { id, startUtc, endUtc, numberOfGuests } = useParams<any>();

  const products = useProducts().products;
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [date, setDate] = useState(
    startUtc
      ? `${new Date(startUtc).toDateString()} – ${new Date(
          endUtc
        ).toDateString()}`
      : // `${new Date(today).toDateString()} – ${new Date(
        //     tomorrow
        //   ).toDateString()}`
        "No dates selected"
  );

  const { openBookingEngine } = useContext(AppContext);
  const openBooking = () => {
    const dates = date.split(" – ");
    const startDate = moment(dates[0]).toDate();
    const endDate = moment(dates[1]).toDate();
    openBookingEngine(startDate, endDate);
  };

  const [guests, setGuests] = useState(Number(numberOfGuests) || 1);
  const [totalCost, setTotalCost] = useState("0");
  const [rooms, setRooms] = useState<Room[]>();
  const [selectedRoom, setSelectedRoom] = useState<Room>();

  const [roomSelection, setRoomSelection] = useState<boolean>(true);
  const [makeItSpecial, setMakeItSpecial] = useState<boolean>(false);

  const [showCheckout, setShowCheckout] = useState<boolean>(false);
  const [showCheckOutComplete, setShowCheckoutComplete] =
    useState<boolean>(false);
  const [showLocationMap, setShowLocationMap] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedBedTypeIcon, setSelectedBedTypeIcon] =
    useState<IconsType>("queen"); // TODO: set based on bed type
  const [selectedRoomTypeIcon, setSelectedRoomTypeIcon] =
    useState<IconsType>(">290sqft"); // TODO: set based on room type
  const [selectedGuestCountIcon, setSelectedGuestCountIcon] =
    useState<IconsType>("oneGuestPerson");
  const [selectedGuestCount, setSelectedGuestCount] = useState<string>("1");
  const [selectedRoomDescription, setSelectedRoomDescription] =
    useState<string>("");
  const [selectedRoomImage, setSelectedRoomImage] = useState<string>("");

  const handelSelectRoom = (room: Room) => {
    setSelectedRoom(room);
    room.price && setTotalCost(room.price);
    setRoomSelection(false);
    setMakeItSpecial(true);
  };

  const changeRoom = () => {
    setRoomSelection(true);
    setMakeItSpecial(false);
  };

  const addLateCheckoutItem = (item: DocumentData) => {
    // console.log("Add Late Checkout Item", item);
  };

  const removeLateCheckoutItem = (item: DocumentData) => {
    // console.log("Remove Late Checkout Item", item);
  };

  const completeReservation = () => {
    setMakeItSpecial(false);
    setShowCheckout(true);
    setShowLocationMap(true);
  };

  const handelCheckoutComplete = () => {
    setShowCheckout(false);
    setShowCheckoutComplete(true);
    setShowLocationMap(true);
  };

  const [openMenuCalendar, setOpenMenuCalendar] = useState(false);
  const [openMenuGuests, setOpenMenuGuests] = useState(false);

  const handleOpenMenuCalendar = () => {
    setOpenMenuCalendar(true);
  };

  const handleOpenMenuGuests = () => {
    setOpenMenuGuests(true);
  };

  const handleMenuClose = () => {
    setOpenMenuCalendar(false);
    setOpenMenuGuests(false);
  };

  const initMap = () => {
    const theAnnexPosition: google.maps.LatLngLiteral = {
      lat: 43.6654713,
      lng: -79.4080313,
    };
    const mapOptions = {
      center: theAnnexPosition,
      zoom: 10,
      styles: mapStyles,
      mapTypeControl: false,
      streetViewControl: false,
    };
    const mapElement = document.getElementById(
      "bookingTwoColumnLayoutMap"
    ) as HTMLElement;
    const map: google.maps.Map = new google.maps.Map(mapElement, mapOptions);
    const infoWindow = new google.maps.InfoWindow();
    const annexMarker = new google.maps.Marker({
      position: theAnnexPosition,
      map: map,
      icon: Logo,
      title: "The Annex Hotel",
    });
    annexMarker.addListener("click", () => {
      infoWindow.close();
      infoWindow.setContent(annexMarker.getTitle());
      infoWindow.open(annexMarker.getMap(), annexMarker);
    });
  };

  useEffect(() => {
    //TODO: Abstract is away
    const loadAvailability = async () => {
      var data = JSON.stringify({
        StartUtc: startUtc,
        EndUtc: endUtc,
        AdultCount: numberOfGuests,
        CategoryIds: id?.split(","),
      });

      var config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      setIsLoading(true);
      const apiBaseUrl = process.env.REACT_APP_FIREBASE_API_BASE;
      await axios
        .post(`${apiBaseUrl}/hotels/getAvailability`, data, config)
        .then((response) => {
          setRooms(response.data);
          setIsLoading(false);
          console.log("availability booking stay", response.data);
        })
        .catch((err) => {
          console.error("loadAvailability", err);
        });
    };

    // if (!rooms) {
    //   loadAvailability();
    // }
    if (startUtc && endUtc && numberOfGuests) {
      loadAvailability();
    }

    setCheckIn("3:00PM");
    setCheckOut("11:00AM");
    setTimeout(() => {
      initMap();
    }, 1000);

    return () => {};
    // }, [rooms, startUtc, endUtc, numberOfGuests, id]);
  }, []);

  return (
    <>
      <MetaDecorator title="Booking Stay" description="Sample description" />

      <BookingTwoColumnLayout
        leftColumnChildren={
          <>
            {/* {
							roomSelection &&  */}
            <div
              className={`edit-accordion ${
                roomSelection && "edit-accordion--active"
              }`}
            >
              <div className="booking-select-room">
                <div className="booking-summary-total">
                  <h2 className="booking-two-column-layout__title">
                    Tell us about your stay
                  </h2>
                  {/* will this work? - start */}
                  <OutsideClickHandler onOutsideClick={handleMenuClose}>
                    <div className="booking-tell-us__icons">
                      <div className="booking-tell-us__icon-container">
                        <Icon
                          className="booking-tell-us__icon"
                          name="calendar"
                          onClick={handleOpenMenuCalendar}
                        />
                      </div>
                      <div className="booking-tell-us__icon-container">
                        <Icon
                          className="booking-tell-us__icon"
                          name="twoGuestsPerson"
                          color="#004C45"
                          onClick={handleOpenMenuGuests}
                        />
                      </div>
                      <BookCalendarGuests
                        openMenuCalendar={openMenuCalendar}
                        setOpenMenuCalendar={setOpenMenuCalendar}
                        openMenuGuests={openMenuGuests}
                        setOpenMenuGuests={setOpenMenuGuests}
                        setRooms={setRooms}
                        date={date}
                        setDate={setDate}
                        guests={guests}
                        setGuests={setGuests}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        startUtcFromParams={startUtc}
                        endUtcFromParams={endUtc}
                        numberOfGuestsFromParams={numberOfGuests}
                        idFromParams={id}
                      />
                    </div>
                  </OutsideClickHandler>
                  {/* will this work - end */}
                  <div className="booking-summary-total__row-checkin">
                    <div className="booking-summary-total__column-checkin">
                      <h3 className="booking-summary-total__title">Check-in</h3>
                      <p className="booking-summary-total__text">
                        After {checkIn}
                      </p>
                    </div>
                    <div className="booking-summary-total__column-checkin">
                      <h3 className="booking-summary-total__title">
                        Check-out
                      </h3>
                      <p className="booking-summary-total__text">
                        Before {checkOut}
                      </p>
                    </div>
                  </div>

                  <div
                    className="booking-summary-total__row"
                    onClick={handleOpenMenuCalendar}
                  >
                    <div className="booking-summary-total__column">
                      <h3 className="booking-summary-total__title">Date</h3>
                      <p className="booking-summary-total__text">{date}</p>
                    </div>
                  </div>

                  <div
                    className="booking-summary-total__row"
                    onClick={handleOpenMenuGuests}
                  >
                    <div className="booking-summary-total__column">
                      <h3 className="booking-summary-total__title">Guests</h3>
                      <p className="booking-summary-total__text">{guests}</p>
                    </div>
                  </div>

                  <div className="booking-summary-total__row-total-cost">
                    <div className="booking-summary-total__column-total-cost">
                      <h3 className="booking-summary-total__total-cost">
                        Total
                      </h3>
                      <h3 className="booking-summary-total__total-cost">
                        ${totalCost}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`edit-accordion ${
                roomSelection && "edit-accordion--active"
              }`}
            >
              <div className="booking-checkout-content booking-checkout-content--pr-0">
                <h3 className="booking-checkout__alt-title">
                  What room would you like?
                </h3>
                <div
                  className="booking-selecting-room"
                  style={{
                    height: !startUtc || isLoading ? "100px" : "1000px",
                  }}
                >
                  {!isLoading ? (
                    rooms?.map((room, index: number) => (
                      <BookingRoom
                        key={index}
                        room={room}
                        onSelect={handelSelectRoom}
                        selectedBedTypeIcon={selectedBedTypeIcon}
                        setSelectedBedTypeIcon={setSelectedBedTypeIcon}
                        selectedRoomTypeIcon={selectedRoomTypeIcon}
                        setSelectedRoomTypeIcon={setSelectedRoomTypeIcon}
                        selectedGuestCountIcon={selectedGuestCountIcon}
                        setSelectedGuestCount={setSelectedGuestCount}
                        setSelectedGuestCountIcon={setSelectedGuestCountIcon}
                        setSelectedRoomDescription={setSelectedRoomDescription}
                        setSelectedRoomImage={setSelectedRoomImage}
                      />
                    ))
                  ) : (
                    <LoadingAnimation color="#000000" />
                  )}
                </div>
              </div>
            </div>

            <div
              className={`edit-accordion ${
                roomSelection && "edit-accordion--active"
              }`}
            >
              <div className="booking-checkout-list">
                <h3 className="booking-checkout-list__label">
                  Make it special
                </h3>
              </div>

              <div className="booking-two-column-button">
                <div className="booking-two-column-button__button-container">
                  <Button
                    cname="booking-two-column-button__button distributor-open"
                    buttontype="secondary--inverse"
                    children="Complete Reservation"
                    // onClick={openBooking}
                    onClick={completeReservation}
                    linkTo=""
                  ></Button>
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* } */}

            {/* {
							makeItSpecial &&  */}

            <div
              className={`edit-accordion ${
                makeItSpecial && "edit-accordion--active"
              }`}
            >
              <div className="booking-select-special">
                {/* {makeItSpecial &&  */}
                <div className="booking-tell-us">
                  <h2 className="booking-two-column-layout__title">
                    Tell us about your stay
                  </h2>
                  {/* <OutsideClickHandler onOutsideClick={handleMenuClose}>
                        <div className="booking-tell-us__icons">
                          <Icon
                            className="booking-tell-us__icon"
                            name="calendar"
                            color="#004C45"
                            onClick={handleOpenMenuCalendar}
                          />
                          <Icon
                            className="booking-tell-us__icon"
                            name="twoGuestsPerson"
                            color="#004C45"
                            onClick={handleOpenMenuGuests}
                          />
                          <BookCalendarGuests
                            openMenuCalendar={openMenuCalendar}
                            setOpenMenuCalendar={setOpenMenuCalendar}
                            openMenuGuests={openMenuGuests}
                            setOpenMenuGuests={setOpenMenuGuests}
                            // setNumberOfGuests={setNumberOfGuests}
                          />
                        </div>
                      </OutsideClickHandler> */}
                  <div className="booking-tell-us__total-cost">
                    <h3 className="booking-tell-us__total-cost-text">Total</h3>
                    <h3 className="booking-tell-us__total-cost-text">
                      ${totalCost}
                    </h3>
                  </div>
                </div>
              </div>
              {/* } */}
              <>
                {selectedRoom && (
                  <div
                    className={`edit-accordion ${
                      makeItSpecial && "edit-accordion--active"
                    }`}
                  >
                    <div className="booking-checkout-list border-bottom">
                      <h3 className="booking-checkout-list__label">
                        {selectedRoom.name}
                      </h3>
                      <button
                        className="booking-checkout-list__button"
                        onClick={changeRoom}
                      >
                        Change
                      </button>
                    </div>
                  </div>
                )}
              </>

              <div
                className={`edit-accordion ${
                  makeItSpecial && "edit-accordion--active"
                }`}
              >
                <div className="booking-checkout-content border-top-none">
                  <h3 className="booking-checkout__alt-title">
                    Make it special
                  </h3>
                  <div className="booking-selecting-make-it-special">
                    {products &&
                      products.map((product, index) => (
                        <BookingSpecial
                          key={index}
                          title={product.name}
                          description={product.description}
                          total={product.price}
                          added={false}
                          addOnClick={() => addLateCheckoutItem(product)}
                          removeOnClick={() => removeLateCheckoutItem(product)}
                        />
                      ))}
                  </div>
                </div>

                <div className="booking-two-column-button">
                  <div className="booking-two-column-button__button-container">
                    <Button
                      cname="booking-two-column-button__button distributor-open"
                      buttontype="secondary"
                      children="Complete Reservation"
                      // onClick={openBooking}
                      onClick={() => {
                        completeReservation();
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                      type="submit"
                      linkTo=""
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
            {/* } */}
          </>
        }
        rightColumnChildren={
          <>
            {/* {
							roomSelection &&  */}
            <div
              className={`edit-accordion ${
                roomSelection && "edit-accordion--active"
              }`}
            >
              <img
                className="booking-two-column-layout__image"
                src={BookingStepOneImage}
                alt="Room"
                title="Room"
              />
            </div>
            {/* } */}

            {/* {
							makeItSpecial &&  */}
            <div
              className={`edit-accordion ${
                makeItSpecial && "edit-accordion--active"
              }`}
            >
              <BookingRoomInfo
                imgSrc={selectedRoomImage}
                imgAlt="Room" //revise once have access to Annex MEWS
                imgTitle="Room" //revise once have access to Annex MEWS
                title={selectedRoomDescription} //revise once have access to Annex MEWS
                bedSizeIcon="queen" //revise once have access to Annex MEWS
                bedSize="Queen Bed" //revise once have access to Annex MEWS
                roomSizeIcon=">290sqft" //revise once have access to Annex MEWS
                roomSize="Extra Large Room" //revise once have access to Annex MEWS
                guestIcon={selectedGuestCountIcon}
                guest={`Sleeps ${selectedGuestCount}`}
              />
            </div>
            {/* } */}
          </>
        }
      />

      {showCheckout && selectedRoom && (
        <BookingCheckout
          room={selectedRoom}
          onCheckoutComplete={handelCheckoutComplete}
          guestData={guestData}
          setGuestData={setGuestData}
        />
      )}

      {showCheckOutComplete && (
        <>
          <div className="booking-complete-scaffold"></div>
          <BookingComplete
            showCheckOutComplete={showCheckOutComplete}
            roomName={selectedRoom?.name}
            guestData={guestData}
          />
        </>
      )}

      {showLocationMap && (
        <LocationMap mapChildren={<div id="bookingTwoColumnLayoutMap"></div>} />
      )}

      <CallToActionBanner
        title="Got a question? Get an answer."
        body="Get in touch with one of our concierge staff members and they’d be happy to answer any questions or help make special arrangements for you so everything is ready for your arrival. "
        buttonText="Send us a message"
        buttonHoverText="+1 647 694 9868"
        buttonClassName="sms-button"
        hasATag={true}
      />
    </>
  );
};

export default BookingStay;
