import "./BlogDetail.scss";
import MetaDecorator from "../../MetaDecorator";
import BlogBanner from "./BlogBanner";
import BlogRecentPostCarousel from "../../components/BlogRecentPostCarousel/BlogRecentPostCarousel";
import useBlogs from "../../hooks/useBlogs";
import { useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import { doc } from "firebase/firestore";

const BlogDetail = () => {
	const { id } = useParams<{ id: string }>();
	const blogRef = doc(useFirestore(), `blogs/${id}`);
	const { data: blog } = useFirestoreDocDataOnce(blogRef, { idField: "id" });
	const blogs = useBlogs().blogs;
  
  const convertDate = (date: any) => {
    const blogDate = date?.toMillis();
    return `${new Date(blogDate).toDateString()}`;
  };

  return (
    <>
      <MetaDecorator title="Blog details" description="Sample description"/>

			<BlogBanner
				title={blog?.title}
				authorName={blog?.authorName}
				// date={convertDate(blog?.Date)}
        date=""
				image={blog?.header_image}
			/>

      <div className="blog-detail">
        <div 
          className="blog-detail__content" 
          dangerouslySetInnerHTML={{__html: `${(blog?.content[0]?.value || "")}`}}
        ></div>
      </div>
      
      <BlogRecentPostCarousel
        title="Keep Reading"
        infoHeight={"185px"}
        items={blogs}
      />
    </>
  );
}

export default BlogDetail;
