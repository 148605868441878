import "./BookingMenu.scss";

import { useState, useContext } from "react";
import AppContext from "../../context";
import moment from "moment";

// import { Link } from "react-router-dom";
import BookingCalendar from "../Global/BookingCalendar/BookingCalendar";
import OutsideClickHandler from "react-outside-click-handler";

import DropDownArrow from "../../assets/icons/dropdown-arrow.svg";
import Icon from "../Global/Icon/Icon";

import BookingMenuAccordion, {
  BookingMenuAccordionItem,
} from "./BookingMenuAccordion";

import { MockCities, NumberOfGuests } from "./BookingMenuMockData";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import { useHistory } from "react-router-dom";
import { getUTCDate, getUTCDateNext } from "../../util/util";
import axios from "axios";

export type BookingMenuProps = {
  verticalPosition?: number;
  className?: string;
  mobileActive?: boolean;
};

const BookingMenu = (props: BookingMenuProps) => {
  const history = useHistory();

  const { verticalPosition, className, mobileActive } = props;
  const [setActive, setActiveState] = useState("");

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [cityHeaderSubTitle, setCityHeaderSubTitle] = useState(MockCities[0]);
  const [heightState, setHeightState] = useState("0px");
  const [guestHeaderSubTitle, setGuestHeaderSubTitle] = useState(
    NumberOfGuests[0]
  );

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [value, setValue] = useState<Date[]>([today, tomorrow]);

  const [numberOfGuests, setNumberOfGuests] = useState(1);

  const fullStartUTC = getUTCDate();
  const [startUtc, setStartUtc] = useState<any>(fullStartUTC);

  const fullEndUTC = getUTCDateNext();
  const [endUtc, setEndUtc] = useState<any>(fullEndUTC);

  const configRef = doc(useFirestore(), "mews/config");
  const { data: config } = useFirestoreDocDataOnce(configRef);

  const startDay = value[0].toLocaleDateString("default", {
    month: "short",
    day: "numeric",
  });
  const endDay = value[1].toLocaleDateString("default", {
    month: "short",
    day: "numeric",
  });

  const [availability, setAvailability] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /*
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion-header-selector__icon");

  const content = useRef<HTMLDivElement>(null!);
  function toggleAccordion() {
      setActiveState(setActive === "" ? "active" : "");
      setHeightState(
          setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
          setActive === "active" ? "accordion-header-selector__icon" : "accordion-header-selector__icon rotate-icon"
        );
    };
  */

  const handleCalendarOpen = (e: any) => {
    setCalendarOpen(!calendarOpen);
  };

  const selectCity = (e: any) => {
    setCityHeaderSubTitle(e);
    setHeightState("0px");
    setActiveState("");
  };

  const selectNumberOfGuests = (e: any) => {
    setGuestHeaderSubTitle(e);
    setHeightState("0px");
    setActiveState("");
    setNumberOfGuests(e);
    // loadAvailability();
  };

  const onChange = (nextValue?: any) => {
    setValue(nextValue);
    // need .toISOString() to convert dates to format readable by MEWS
    const currentDay = new Date();
    const currentDayByOneHour = currentDay.setHours(
      currentDay.getHours() + 1 - 5
    );
    const currentYearMonthDay = currentDay.toISOString().split("T")[0];
    const currentDayFormated = new Date(currentDayByOneHour).toISOString();

    const checkInDate = new Date(
      //15 - 5 is for 3pm - 5 hours. -5 hours to compensate for UTC being 5 hours ahead of EST
      //TODO:
      //need to confirm with Annex people if 3pm is correct check in time
      new Date(nextValue[0]).setHours(15 - 5)
    ).toISOString();
    const checkOutDate = new Date(
      //11 - 5 is for 11am - 5 hours. -5 hours to compensate for UTC being 5 hours ahead of EST
      //The additional -1 is because the time was being set an hour ahead of 11am for some reason
      //TODO:
      //need to confirm with Annex people if 11am is correct check out time
      new Date(nextValue[1]).setHours(11 - 5 - 1)
    ).toISOString();
    // console.log(
    //   "first day",
    //   new Date(new Date(nextValue[0]).setHours(15 - 5)).toISOString()
    // );
    // console.log(
    //   "last day",
    //   new Date(new Date(nextValue[1]).setHours(11 - 5 - 1)).toISOString()
    // );

    if (nextValue[0].toISOString().includes(currentYearMonthDay)) {
      setStartUtc(currentDayFormated);
    } else {
      // setStartUtc(nextValue[0].toISOString());
      setStartUtc(checkInDate);
    }
    // setEndUtc(nextValue[1].toISOString());
    setEndUtc(checkOutDate);

    // loadAvailability();
  };

  const startBooking = () => {
    history.push(
      `/booking-stay/${[
        config.roomIds,
      ]}/${numberOfGuests}/${startUtc}/${endUtc}`
    );
  };

  const loadAvailability = async () => {
    var data = JSON.stringify({
      StartUtc: startUtc,
      EndUtc: endUtc,
      AdultCount: numberOfGuests,
      // CategoryIds: config.roomIds.split(","),
    });

    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    setIsLoading(true);

    const apiBaseUrl = process.env.REACT_APP_FIREBASE_API_BASE;
    await axios
      .post(`${apiBaseUrl}/hotels/getAvailability`, data, config)
      .then((response) => {
        setAvailability(response.data.length);
        setIsLoading(false);
        // console.log("availability booking menu", response);
      })
      .catch((err) => {
        setIsLoading(false);
        setAvailability(0);
        console.error("loadAvailability", err);
      });
  };

  {
    /* Commenting out use effect with loadAvailability function for now until after launch - starting here */
  }

  // useEffect(() => {
  //   loadAvailability();
  // }, [startUtc, endUtc, numberOfGuests]);

  {
    /* Commenting out use effect with loadAvailability function for now until after launch - ending here */
  }

  const { openBookingEngine } = useContext(AppContext);
  const openBooking = () => {
    // const dates = date.split(" – ");
    // const startDate = moment(dates[0]).toDate();
    // const endDate = moment(dates[1]).toDate();
    const startDate = moment(value[0]).toDate();
    const endDate = moment(value[1]).toDate();
    openBookingEngine(startDate, endDate);
  };

  return (
    <div
      className={
        mobileActive
          ? `${className} booking-menu booking-menu--mobile-active`
          : `${className} booking-menu`
      }
      style={{ top: `${verticalPosition}px` }}
    >
      <BookingMenuAccordion
        headerTitle={"Location"}
        headerSubTitle={cityHeaderSubTitle}
        headerWidth={"location"}
        heightState={heightState}
        activeState={setActive}
        itemsChildren={
          <>
            {MockCities.map((MockCity, index) => (
              <BookingMenuAccordionItem
                key={index}
                label={MockCity}
                onClick={() => selectCity(MockCity)}
              />
            ))}
          </>
        }
        mobileActive={mobileActive}
        dropdown={false}
      />
      <OutsideClickHandler onOutsideClick={() => setCalendarOpen(false)}>
        <div className="select-dates accordion-border-right">
          <div className="accordion accordion--dates">
            <div
              className={
                mobileActive
                  ? "accordion-header accordion-header--mobile-active"
                  : "accordion-header"
              }
            >
              <p className="accordion-header__title">Select Dates</p>
              <div
                className="accordion-header-selector open-booking-menu"
                onClick={handleCalendarOpen}
              >
                <p className="accordion-header-selector__text">{`${startDay} - ${endDay}`}</p>
                <img
                  src={DropDownArrow}
                  alt="chevron"
                  className={
                    calendarOpen
                      ? "accordion-header-selector__icon rotate-chevron"
                      : "accordion-header-selector__icon"
                  }
                  loading="lazy"
                />
              </div>
            </div>
            <BookingCalendar
              value={value}
              onChange={onChange}
              isActive={calendarOpen}
              cname="calendar"
            />
          </div>
        </div>
      </OutsideClickHandler>

      {/* Commenting out No. of Guests accordion for now until after launch - starting here */}

      {/* <BookingMenuAccordion
        headerTitle={"No. of Guests"}
        headerSubTitle={guestHeaderSubTitle}
        headerWidth={"guests"}
        mobileActive={mobileActive}
        heightState={heightState}
        activeState={setActive}
        itemsChildren={
          <>
            {NumberOfGuests.map((guest, index) => (
              <BookingMenuAccordionItem
                key={index}
                label={guest.toString()}
                onClick={() => selectNumberOfGuests(guest)}
              />
            ))}
          </>
        }
      /> */}

      {/* Commenting out No. of Guests accordion for now until after launch - ending here */}
      <>
        {/* {config && ( */}

        {/* <Link
            to={`/booking-stay/${[config.roomIds]}/${numberOfGuests}/${startUtc}/${endUtc}`}
            className="accordion accordion--available"
          > */}
        <a
          className="accordion accordion--available"
          // onClick={startBooking}
          onClick={openBooking}
        >
          <div
            className={
              mobileActive
                ? "accordion-header accordion-header--mobile-active"
                : "accordion-header"
            }
          >
            {/* Commenting out Available label and number of available rooms for now until after launch - starting here */}

            {/* <p className="accordion-header__title">Available</p>
              <div className={`accordion-header-selector ${setActive}`}>
                {isLoading ? (
                  <div style={{ marginBottom: "-3px" }}>
                    <Loader
                      type="Bars"
                      visible={true}
                      height={25}
                      width={25}
                      // color="#000000"
                      color="#004C45"
                    />
                  </div>
                ) : (
                  <p className="accordion-header-selector__text">
                    {availability}
                  </p>
                )} */}
            <p className="accordion-header__title">Book Now</p>
            {/* <p className="accordion-header__temp-title">Book Now</p> */}
            <div className={`accordion-header-selector ${setActive}`}>
              {/* {isLoading ? ( */}
              {/* <div style={{ marginBottom: "-3px" }}>
                    <Loader
                      type="Bars"
                      visible={true}
                      height={25}
                      width={25}
                      // color="#000000"
                      color="#004C45"
                    />
                  </div> */}
              {/* ) : ( */}
              <p className="accordion-header-selector__temp-text">
                {/* {availability} */}x
              </p>
              {/* )} */}

              {/* Commenting out Available label and number of available rooms for now until after launch - ending here */}

              <div className="right-arrow-size">
                <Icon
                  name="next-arrow"
                  color="#000000"
                  className="right-arrow-style"
                />
              </div>
            </div>
          </div>
        </a>
        {/* </Link> */}

        {/* )} */}
      </>
    </div>
  );
};

export default BookingMenu;
