import { useEffect, useState, useRef, useCallback } from "react";
import Button from "../../../components/Global/Button/Button";
import Loader from "react-loader-spinner";
import BookingSummary from "../BookingSummary";
import BookingTwoColumnLayout from "../BookingTwoColumnLayout";
import "./BookingCheckout.scss";
import axios from "axios";
import { useParams } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  getAuth,
  signInAnonymously,
  // onAuthStateChanged,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  // EmailAuthProvider,
  // linkWithCredential
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import { collection, setDoc, doc } from "firebase/firestore";
import { useAuth, useFirestore } from "reactfire";
import { useUserData } from "../../../hooks/useUserData";
import { Room } from "../../../../shared/types/room";
import useMews from "../../../hooks/useMews";
// import { valueScaleCorrection } from "framer-motion/types/render/dom/projection/scale-correction";
// import { Container } from "react-bootstrap";
type BookingCheckoutProps = {
  room: Room;
  onCheckoutComplete: () => void;
  guestData?: {
    uid: any;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
  };
  setGuestData?: any;
};

let secureFields: any;

const BookingCheckout = ({
  room,
  onCheckoutComplete,
  guestData,
  setGuestData,
  ...props
}: BookingCheckoutProps) => {
  const provider = new GoogleAuthProvider();

  const [isLoading, setIsLoading] = useState(false);
  const { startUtc, endUtc, numberOfGuests } = useParams<any>();
  // console.log("number of guests booking checkout", numberOfGuests);

  // TODO: we should show and hide certain elements beast on auth state.
  const { userData, userRef } = useUserData();

  // console.log("user data", userData);

  // const ref = doc(useFirestore(), `mews/payment`);
  // const { data: payment } = useFirestoreDocDataOnce(ref, { idField: "id" });
  const payment = useMews().payment;

  const [continueAsUser, setContinueAsUser] = useState<boolean>(false);
  const [continueAsGuest, setContinueAsGuest] = useState<boolean>(false);

  const [showCheckingOut, setShowCheckingOut] = useState<boolean>(false);
  const [showCheckingOutForm, setShowCheckingOutForm] =
    // useState<boolean>(false);
    useState<boolean>(true);
  const [showEditCheckingOut, setShowEditCheckingOut] =
    useState<boolean>(false);

  const [showHowCanWeReachYou, setShowHowCanWeReachYou] =
    useState<boolean>(true);
  const [showHowCanWeReachYouForm, setShowHowCanWeReachYouForm] =
    useState<boolean>(false);
  const [showEditHowCanWeReachYou, setShowEditHowCanWeReachYou] =
    useState<boolean>(false);

  const [showPaymentInformation, setShowPaymentInformation] =
    useState<boolean>(true);
  const [showPaymentInformationForm, setShowPaymentInformationForm] =
    useState<boolean>(false);
  const [
    showEditPaymentInformationComplete,
    setShowPaymentInformationComplete,
  ] = useState<boolean>(false);

  const [showEverythingGood, setShowEverythingGood] = useState<boolean>(true);
  const [showEverythingGoodForm, setShowEverythingGoodForm] =
    useState<boolean>(false);
  const [showEditEverythingGoodComplete, setEditEverythingGoodComplete] =
    useState<boolean>(false);

  const [lastFourDigits, setLastFourDigits] = useState<string>("");
  const [startPaymentProcessing, setStartPaymentProcessing] =
    useState<boolean>(false);
  const [obfuscatedNumber, setObfuscatedNumber] = useState("");

  // const [email, setEmail] = useState("");
  // const [emailError, setEmailError] = useState<boolean>(false);

  // const [password, setPassword] = useState("");
  // const [passwordError, setPasswordError] = useState<boolean>(false);

  // const [firstName, setFirstName] = useState("");
  // const [firstNameError, setFirstNameError] = useState<boolean>(false);

  // const [lastName, setLastName] = useState("");
  // const [lastNameError, setLastNameError] = useState<boolean>(false);

  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);

  // const [creditCardNumber, setCreditCardNumber] = useState("");
  // const [creditCardNumberError, setCreditCardNumberError] =
  //   useState<boolean>(false);

  // const [expiryMonthYear, setExpiryMonthYear] = useState("");
  // const [expiryMonthYearError, setExpiryMonthYearError] =
  //   useState<boolean>(false);

  // const [cvc, setCVC] = useState("");
  // const [cvcError, setCVCError] = useState<boolean>(false);

  // const [promoCode, setPromoCode] = useState("");
  // const [promoCodeError, setPromoCodeError] = useState<boolean>(false);

  // const defaultState = () => {
  //   // setShowCheckingOut(false);
  //   // setShowCheckingOutForm(true);
  //   setShowEditCheckingOut(false);
  // };

  const db = useFirestore();
  const customersCollection = collection(db, "customers");
  const auth = useAuth();
  const authFirebase = getAuth();

  const signOut = (auth: any) =>
    auth.signOut().then(() => console.log("signed out"));

  const createUser = async () => {
    const { values, errors } = formikSignInOrCreateProps;

    await createUserWithEmailAndPassword(
      auth,
      values.email,
      values.password
    ).then((response) => {
      // console.log(" create user response", response);

      const user = response.user;

      // const credential = EmailAuthProvider.credential(values.email, values.password);
      // linkWithCredential(user, credential) //not working, says it's already linked - need to test if reservations made as a guest are being added to account when created with email and password - are the accounts in fact linked??
      //   .then((usercred) => {
      //     const user = usercred.user;
      //     console.log("Anonymous account successfully upgraded", user);
      //     if (
      //       values.email &&
      //       !errors.email &&
      //       values.password &&
      //       !errors.password
      //     ) {
      //       setContinueAsUser(true);
      //       setContinueAsGuest(false);
      //       setShowCheckingOut(true);
      //       setShowEditCheckingOut(true);
      //       setShowCheckingOutForm(false);
      //       setShowHowCanWeReachYou(false);
      //       setShowHowCanWeReachYouForm(true);
      //       setTimeout(() => {
      //         reachYouRef.current?.scrollIntoView({
      //           behavior: "smooth",
      //         });
      //       }, 1500);

      //     }
      //   }).catch((error) => {
      //     console.log("Error upgrading anonymous account", error);
      //   });

      if (user) {
        const userData = {
          uid: user.uid,
          email: values.email,
        };
        setDoc(doc(customersCollection, userData.email), userData, {
          merge: true,
        })
          .then(() => {
            if (
              values.email &&
              !errors.email &&
              values.password &&
              !errors.password
            ) {
              setContinueAsUser(true);
              setContinueAsGuest(false);
              setShowCheckingOut(true);
              setShowEditCheckingOut(true);
              setShowCheckingOutForm(false);
              setShowHowCanWeReachYou(false);
              setShowHowCanWeReachYouForm(true);
              setTimeout(() => {
                reachYouRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }, 1500);
            }
          })
          .catch((err) => {
            console.error(err);
            // console.log("create user error", err.message);
          });
      }
    });
  };

  const signInAndContinue = async (e: any) => {
    e.preventDefault();
    signOut(auth); //is this right? check if user is signed in before calling sign out?

    const { values, errors, setFieldError } = formikSignInOrCreateProps;
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then(() => {
        if (
          values.email &&
          !errors.email &&
          values.password &&
          !errors.password
        ) {
          setContinueAsUser(true);
          setContinueAsGuest(false);
          // setShowCheckingOut(true);
          // setShowEditCheckingOut(true);
          setShowCheckingOutForm(false);
          setShowHowCanWeReachYou(false);
          setShowHowCanWeReachYouForm(true);

          setTimeout(() => {
            reachYouRef.current?.scrollIntoView({
              behavior: "smooth",
            });
          }, 1500);
        }
      })

      .catch((err) => {
        console.error(err);
        // console.log("error", err.message);

        if (err.message === "Firebase: Error (auth/wrong-password).") {
          setFieldError("password", "Incorrect password");
        }

        if (err.message === "Firebase: Error (auth/user-not-found).") {
          createUser();
        }
      });
  };

  const checkOutAsGuest = (e: any) => {
    e.preventDefault();

    const { values, errors } = formikCheckoutAsGuestProps;

    if (
      values.firstName &&
      !errors.firstName &&
      values.lastName &&
      !errors.lastName
    ) {
      setContinueAsGuest(true);
      setContinueAsUser(false);
      setShowCheckingOut(true);
      setShowCheckingOutForm(false);
      setShowEditCheckingOut(true);
      setShowHowCanWeReachYou(false);
      setShowHowCanWeReachYouForm(true);

      setTimeout(() => {
        reachYouRef.current?.scrollIntoView({
          behavior: "smooth",
        });
      }, 1500);
    }
  };

  const userHowWeReachYou = async (e: any) => {
    e.preventDefault();

    const { values, errors, setFieldError } = formikSignInOrCreateProps;

    if (
      values.firstName &&
      !errors.firstName &&
      values.lastName &&
      !errors.lastName &&
      values.email &&
      !errors.email &&
      values.phoneNumber &&
      !errors.phoneNumber
    ) {
      setShowHowCanWeReachYou(true);
      setShowHowCanWeReachYouForm(false);
      setShowEditHowCanWeReachYou(true);
      setShowPaymentInformation(false);
      setShowPaymentInformationForm(true);
      await setDoc(
        userRef,
        {
          // email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
        },
        { merge: true }
      );

      //from onClick below - start

      setTimeout(() => {
        paymentRef.current?.scrollIntoView({
          behavior: "smooth",
        });
        secureFields = new (window as any).SecureFields();
        secureFields.initTokenize(
          payment.mechantId,
          {
            cardNumber: {
              placeholderElementId: "card-number-placeholder",
              inputType: "tel",
            },
            cvv: {
              placeholderElementId: "cvv-placeholder",
              inputType: "tel",
            },
          },
          {
            styles: styles,
            // focus: "cardNumber"
          }
        );
        secureFields.on("change", function (event: any) {
          // put a red border around the fields if they are not valid
          secureFields.setStyle("cardNumber.invalid", "color: red");
          secureFields.setStyle("cvv.invalid", "color: red");
          // console.log("on change event", event);

          if (!event.fields.cardNumber.valid) {
            setFieldError("creditCardNumber", "Invalid credit card number");
          } else setFieldError("creditCardNumber", "");

          if (!event.fields.cvv.valid) {
            setFieldError("cvc", "Invalid CVC");
          } else setFieldError("cvc", "");
        });
        secureFields.on("validate", function (event: any) {
          // put a red border around the fields if they are not valid
          secureFields.setStyle("cardNumber.invalid", "color: red");
          secureFields.setStyle("cvv.invalid", "color: red");

          if (!event.fields.cardNumber.valid) {
            setFieldError("creditCardNumber", "Invalid credit card number");
          } else setFieldError("creditCardNumber", "");

          if (!event.fields.cvv.valid) {
            setFieldError("cvc", "Invalid CVC");
          } else setFieldError("cvc", "");
        });
      }, 1500);

      //from onClick below - end
    }
  };

  const guestHowWeReachYou = (e: any) => {
    e.preventDefault();
    signOut(auth); //keep this or check if user is logged in before calling this?

    const { values, errors, setFieldError } = formikCheckoutAsGuestProps;

    if (
      values.firstName &&
      !errors.firstName &&
      values.lastName &&
      !errors.lastName &&
      values.email &&
      !errors.email &&
      values.phoneNumber &&
      !errors.phoneNumber
    ) {
      //check if user already exists in firebase start

      fetchSignInMethodsForEmail(authFirebase, values.email).then((result) => {
        if (result[0] === "password") {
          setFieldError(
            "email",
            "It looks like you already have an account with us! Sign in to your account to continue with your booking."
          );
        } else {
          //sign in anonymously  start

          signInAnonymously(authFirebase)
            .then((response) => {
              // console.log("anonymous sign in response", response);
              // Signed in..
              // onAuthStateChanged(authFirebase, (user) => {
              const user = response.user;
              if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const userData = {
                  uid: user.uid,
                  email: values.email,
                  phoneNumber: values.phoneNumber,
                  firstName: values.firstName,
                  lastName: values.lastName,
                };
                setGuestData(userData);

                setDoc(doc(customersCollection, userData.email), userData)
                  .then(() => {
                    setShowHowCanWeReachYou(true);
                    setShowHowCanWeReachYouForm(false);
                    setShowEditHowCanWeReachYou(true);
                    setShowPaymentInformation(false);
                    setShowPaymentInformationForm(true);

                    //from onClick below start

                    setTimeout(() => {
                      paymentRef.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                      secureFields = new (window as any).SecureFields();
                      secureFields.initTokenize(
                        payment.mechantId,
                        {
                          cardNumber: {
                            placeholderElementId: "card-number-placeholder",
                            inputType: "tel",
                          },
                          cvv: {
                            placeholderElementId: "cvv-placeholder",
                            inputType: "tel",
                          },
                        },
                        {
                          styles: styles,
                          // focus: "cardNumber"
                        }
                      );
                      secureFields.on("change", function (event: any) {
                        // put a red border around the fields if they are not valid
                        secureFields.setStyle(
                          "cardNumber.invalid",
                          "color: red"
                        );
                        secureFields.setStyle("cvv.invalid", "color: red");
                      });
                      secureFields.on("validate", function (event: any) {
                        // put a red border around the fields if they are not valid
                        secureFields.setStyle(
                          "cardNumber.invalid",
                          "color: red"
                        );
                        secureFields.setStyle("cvv.invalid", "color: red");
                      });
                    }, 1500);

                    //from onClick below end
                  })
                  .catch((err) => {
                    console.error(err);
                    // console.log("add user data error", err.message);
                  });
                // ...
              } else {
                console.log("user is signed out");
              }
              // });
            })
            .catch((error) => {
              // const errorCode = error.code;
              const errorMessage = error.message;
              // console.log("error message", errorMessage);
              // ...
            });

          //sign in anonymously end
        }
      });
    }
  };

  const submitPaymentInformationUser = (e: any) => {
    e.preventDefault();

    secureFields.getCardInfo();

    secureFields.on("cardInfo", function (event: any) {
      // console.log("event", event);
      setObfuscatedNumber(event.maskedCard);
    });

    // secureFields.submit();

    // setIsLoading(true);

    // secureFields.on("success", async function (resp: any) {
    //   // console.log("obfuscatedNumber", obfuscatedNumber);
    //   // debugger;
    //   //This should be the last step in the transation. TODO: fill in dynamic fields
    //   if (resp.transactionId) {
    //     const customer = {
    //       Email: userData.email,
    //       FirstName: userData.firstName,
    //       LastName: userData.lastName,
    //       Telephone: userData.phoneNumber,
    //     };
    //     console.log("obfuscated number", obfuscatedNumber);
    //     var data = JSON.stringify({
    //       Customer: customer,
    //       Reservations: [
    //         {
    //           RoomCategoryId: room.id,
    //           StartUtc: startUtc,
    //           EndUtc: endUtc,
    //           VoucherCode: `${formikSignInOrCreateProps.values.promoCode}`,
    //           RateId: room.rateId,
    //           AdultCount: numberOfGuests,
    //           ChildCount: 0,
    //           PersonCount: numberOfGuests,
    //         },
    //       ],
    //       CreditCardData: {
    //         PaymentGatewayData: resp.transactionId,
    //         // ObfuscatedNumber: "424242********4242",
    //         ObfuscatedNumber: obfuscatedNumber, // TODO we need a way to get the cc card number. Is it in the resp??
    //         //TODO: pull this from form
    //         // Expiration: "2030-10",
    //         Expiration: `20${formikSignInOrCreateProps.values.expiryMonthYear
    //           .split("/")
    //           .reverse()
    //           .join("-")}`,
    //         HolderName: formikSignInOrCreateProps.values.fullNameCC,
    //       },
    //     });

    //     var config = {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     };

    //     const apiBaseUrl = process.env.REACT_APP_FIREBASE_API_BASE;
    //     try {
    //       const response = await axios.post(
    //         `${apiBaseUrl}/reservationGroups/create`,
    //         data,
    //         config
    //       );
    //     } catch (err: any) {
    //       console.log("posting error", err.message);
    //       alert(err);
    //     }
    //     setShowPaymentInformation(true);
    //     setShowPaymentInformationForm(false);
    //     setShowPaymentInformationComplete(true);
    //     setShowEverythingGood(false);
    //     setShowEverythingGoodForm(true);

    //     console.log("your form has been submitted successfully!");

    //     setTimeout(() => {
    //       everythingGoodRef.current?.scrollIntoView({
    //         behavior: "smooth",
    //       });
    //     }, 1500);
    //     setIsLoading(false);
    //     setStartPaymentProcessing(false);
    //     secureFields.getCardInfo();
    //     secureFields.on("cardInfo", function (event: any) {
    //       setLastFourDigits(event.maskedCard.substr(-4));
    //     });
    //   }
    // });

    // secureFields.on("error", function (error: any) {
    //   debugger;
    //   // TODO: Proper error message for payment form

    //   console.log("error message", error.message);
    //   alert(error);
    // });
  };

  const submitPaymentInformationGuest = (e: any) => {
    e.preventDefault();

    const { values } = formikCheckoutAsGuestProps;

    setIsLoading(true);
    secureFields.submit();

    secureFields.on("success", async function (resp: any) {
      // debugger;
      //This should be the last step in the transation. TODO: fill in dynamic fields
      if (resp.transactionId) {
        // console.log(
        //   "customer",
        //   values.email,
        //   values.firstName,
        //   values.lastName,
        //   values.phoneNumber
        // );
        const customer = {
          Email: values.email,
          FirstName: values.firstName,
          LastName: values.lastName,
          Telephone: values.phoneNumber,
        };

        var data = JSON.stringify({
          Customer: customer,
          Reservations: [
            {
              RoomCategoryId: room.id,
              StartUtc: startUtc,
              EndUtc: endUtc,
              VoucherCode: `${values.promoCode}`,
              RateId: room.rateId,
              AdultCount: numberOfGuests,
              ChildCount: 0,
              PersonCount: numberOfGuests,
            },
          ],
          CreditCardData: {
            PaymentGatewayData: resp.transactionId,
            ObfuscatedNumber: "41111********1111", // TODO we need a way to get the cc card number. Is it in the resp??
            Expiration: `20${formikSignInOrCreateProps.values.expiryMonthYear
              .split("/")
              .reverse()
              .join("-")}`,
          },
        });

        var config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const apiBaseUrl = process.env.REACT_APP_FIREBASE_API_BASE;
        try {
          const response = await axios.post(
            `${apiBaseUrl}/reservationGroups/create`,
            data,
            config
          );
          setShowPaymentInformation(true);
          setShowPaymentInformationForm(false);
          setShowPaymentInformationComplete(true);
          setShowEverythingGood(false);
          setShowEverythingGoodForm(true);

          // console.log("your form has been submitted successfully!");

          setTimeout(() => {
            everythingGoodRef.current?.scrollIntoView({
              behavior: "smooth",
            });
          }, 1500);
          setIsLoading(false);
          secureFields.getCardInfo();
          secureFields.on("cardInfo", function (event: any) {
            setLastFourDigits(event.maskedCard.substr(-4));
          });
        } catch (err: any) {
          // console.log("posting error", err.message);
          alert(err);
        }
      }
    });

    secureFields.on("error", function (error: any) {
      debugger;
      // TODO: Proper error message for payment form

      // console.log("error message", error.message);
      alert(error);
    });
  };

  // const bookingComplete = () => {
  //   setShowEverythingGood(true);
  //   setShowEverythingGoodForm(false);
  //   setEditEverythingGoodComplete(true);
  // };

  const editCheckoutItem = (title: string) => {
    setShowCheckingOut(true);
    setShowCheckingOutForm(false);
    setShowHowCanWeReachYou(true);
    setShowHowCanWeReachYouForm(false);
    setShowPaymentInformation(true);
    setShowPaymentInformationForm(false);
    setShowEverythingGood(true);
    setShowEverythingGoodForm(false);

    if (title === "checkout") {
      setShowCheckingOut(false);
      setShowCheckingOutForm(true);

      if (continueAsUser && !continueAsGuest) {
        setContinueAsUser(true);
        setContinueAsGuest(false);
      }

      if (!continueAsUser && continueAsGuest) {
        setContinueAsUser(false);
        setContinueAsGuest(true);
      }
    }

    if (title === "reach") {
      setShowHowCanWeReachYou(false);
      setShowHowCanWeReachYouForm(true);

      if (continueAsUser && !continueAsGuest) {
        setContinueAsUser(true);
        setContinueAsGuest(false);
      }

      if (!continueAsUser && continueAsGuest) {
        setContinueAsUser(false);
        setContinueAsGuest(true);
      }
    }

    if (title === "payment") {
      setShowPaymentInformation(false);
      setShowPaymentInformationForm(true);
    }

    if (title === "everything") {
      setShowEverythingGood(false);
      setShowEverythingGoodForm(true);
    }
  };

  // const getPaymentUrl = (production: boolean) =>
  //   production
  //     ? // TODO: environment variables
  //       "https://pay.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js"
  //     : "https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js";

  const getPaymentUrl = useCallback((production: boolean) => {
    // TODO: environment variables
    if (production) {
      return "https://pay.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js";
    } else {
      return "https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js";
    }
  }, []);

  useEffect(() => {
    // defaultState();

    //initalize payment scripts
    const scriptSource = getPaymentUrl(false);

    if (document.querySelector('script[src="' + scriptSource + '"]')) {
      return;
    }

    const script = document.createElement("script");
    script.src = scriptSource;
    document.body.appendChild(script);

    return () => {};
  }, [getPaymentUrl, userData]); //keep or remove userData ?

  useEffect(() => {
    if (userData?.email) {
      setShowCheckingOutForm(false);
      // userData?.firstName &&
      // userData?.lastName &&
      // userData?.email &&
      // userData?.phoneNumber &&
      setShowHowCanWeReachYouForm(true);
      setContinueAsUser(true);
      setContinueAsGuest(false);
      // setShowCheckingOut(true);
      // setShowEditCheckingOut(true);

      setShowHowCanWeReachYou(false);

      // setShowPaymentInformationForm(true);
    }
  }, []);

  useEffect(() => {
    const submitAndSavePaymentInformationUser = () => {
      secureFields.submit();

      setIsLoading(true);

      secureFields.on("success", async function (resp: any) {
        // console.log("obfuscatedNumber", obfuscatedNumber);
        // debugger;
        //This should be the last step in the transation. TODO: fill in dynamic fields
        if (resp.transactionId) {
          const customer = {
            Email: userData.email,
            FirstName: userData.firstName,
            LastName: userData.lastName,
            Telephone: userData.phoneNumber,
          };
          // console.log("obfuscated number", obfuscatedNumber);
          var data = JSON.stringify({
            Customer: customer,
            Reservations: [
              {
                RoomCategoryId: room.id,
                StartUtc: startUtc,
                EndUtc: endUtc,
                VoucherCode: formikSignInOrCreateProps.values.promoCode,
                RateId: room.rateId,
                AdultCount: numberOfGuests,
                ChildCount: 0,
                PersonCount: numberOfGuests,
              },
            ],
            CreditCardData: {
              PaymentGatewayData: resp.transactionId,
              // StorageData: resp.transactionId,
              // ObfuscatedNumber: "424242********4242",
              ObfuscatedNumber: obfuscatedNumber, // TODO we need a way to get the cc card number. Is it in the resp??
              //TODO: pull this from form
              // Expiration: "2030-10",
              Expiration: `20${formikSignInOrCreateProps.values.expiryMonthYear
                .split("/")
                .reverse()
                .join("-")}`,
              HolderName: formikSignInOrCreateProps.values.fullNameCC,
            },
          });
          // console.log("data for reso", data);
          var config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          const apiBaseUrl = process.env.REACT_APP_FIREBASE_API_BASE;
          try {
            const response = await axios.post(
              `${apiBaseUrl}/reservationGroups/create`,
              data,
              config
            );
          } catch (err: any) {
            // console.log("posting error", err.message);
            alert(err);
          }
          setShowPaymentInformation(true);
          setShowPaymentInformationForm(false);
          setShowPaymentInformationComplete(true);
          setShowEverythingGood(false);
          setShowEverythingGoodForm(true);

          // console.log("your form has been submitted successfully!");

          setTimeout(() => {
            everythingGoodRef.current?.scrollIntoView({
              behavior: "smooth",
            });
          }, 1500);
          setIsLoading(false);
          setStartPaymentProcessing(false);
          secureFields.getCardInfo();
          secureFields.on("cardInfo", function (event: any) {
            setLastFourDigits(event.maskedCard.substr(-4));
          });
        }
      });

      secureFields.on("error", function (error: any) {
        debugger;
        // TODO: Proper error message for payment form

        // console.log("error message", error.message);
        alert(error);
      });
    };
    if (obfuscatedNumber) {
      submitAndSavePaymentInformationUser();
    }
  }, [obfuscatedNumber]);

  //sign in or create account validation

  // const currentMonthNumber = new Date().getMonth() + 1;
  const currentMonthNumber = ("0" + new Date().getMonth() + 1).slice(-2);
  const currentMonth = currentMonthNumber.toString();
  const currentYearNumber = new Date().getFullYear();
  const currentYear = currentYearNumber.toString().substr(-2);

  const passwordRegExp =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const creditCardRegExp = /^[0-9]{16}$/;

  const expiryMonthYearRegExp = /([0-9]{2})\/([0-9]{2})/;

  const fullNameCCRegExp = /^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/;

  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [resetPasswordErrors, setResetPasswordErrors] = useState(false);
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] =
    useState("");

  const handleForgotPassword = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      const email = formikSignInOrCreateProps.values.email;
      const auth = getAuth();

      await sendPasswordResetEmail(auth, email)
        .then(() => {
          setResetEmailSent(true);
          setTimeout(() => setResetEmailSent(false), 5000);
        })
        .catch((error) => {
          // console.log("handle forgot password error", error.message);

          if (!formikSignInOrCreateProps.values.email) {
            setResetPasswordErrors(true);
            setResetPasswordErrorMessage(
              "Email is required to reset your password"
            );
          }
          if (error.message === "Firebase: Error (auth/too-many-requests).") {
            formikSignInOrCreateProps.setFieldError(
              "password",
              "Too many requests, try again later"
            );
          }
        });
    } catch (error) {
      console.log("error", error);
      if (!formikSignInOrCreateProps.values.email) {
        formikSignInOrCreateProps.setFieldError(
          "email",
          "Email is required to reset your password"
        );
      }
    }
  };

  const signInOrCreateSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .matches(
        passwordRegExp,
        "Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit and one special character"
      )
      .required("Password is required"),
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Invalid phone number")
      .min(10, "Phone number must be at least 10 characters")
      .required("Phone number is required"),
    fullNameCC: yup
      .string()
      .matches(
        fullNameCCRegExp,
        "Enter your full name as it appears on your credit card"
      )
      .required("Full name is required"),
    // creditCardNumber: yup
    //   .string()
    //   .matches(creditCardRegExp, "Credit card number must be 16 digits")
    //   .required("Card number is required"),
    expiryMonthYear: yup
      .string()
      .typeError("Not a valid expiration date. Example: MM/YY")
      .max(5, "Not a valid expiration date. Example: MM/YY")
      .matches(
        expiryMonthYearRegExp,
        "Not a valid expiration date. Example: MM/YY"
      )
      .test(
        "test-credit-card-expiration-date",
        "Invalid. Expiration date has passed.",
        (expiryMonthYear) => {
          if (!expiryMonthYear) {
            return false;
          }

          const [expMonth, expYear] = expiryMonthYear.split("/");

          if (Number(expYear) < Number(currentYear)) {
            return false;
          } else if (
            Number(expMonth) < Number(currentMonth) &&
            Number(expYear) <= Number(currentYear)
          ) {
            return false;
          }

          return true;
        }
      )
      .test(
        "test-credit-card-expiration-date",
        "Invalid expiration month",
        (expiryMonthYear) => {
          if (!expiryMonthYear) {
            return false;
          }
          // const today = new Date().getFullYear().toString().substr(-2);

          const [expMonth] = expiryMonthYear.split("/");

          if (Number(expMonth) > 12) {
            return false;
          }

          return true;
        }
      )
      .required("Expiration date is required"),
    // cvc: yup
    //   .string()
    //   .min(3, "CVC must be 3 or 4 digits")
    //   .max(4, "CVC must be 3 or 4 digits ")
    //   .required("CVC is required"),
    promoCode: yup.string(),
  });

  const initialSignInOrCreateValues = {
    email: userData?.email || "",
    password: "",
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    phoneNumber: userData?.phoneNumber || "",
    fullNameCC: userData?.fullNameCC || "",
    creditCardNumber: "",
    expiryMonthYear:
      userData?.expiryMonthYear || currentMonth + "/" + currentYear,
    cvc: "",
    promoCode: "",
  };

  const formikSignInOrCreateProps = useFormik({
    initialValues: initialSignInOrCreateValues,
    validationSchema: signInOrCreateSchema,
    onSubmit: (values, { resetForm }) => {
      onCheckoutComplete();
      resetForm();
    },
  });

  //continue as guest validation

  const checkoutAsGuestSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Invalid phone number")
      .min(10, "Phone number must be at least 10 characters")
      .required("Phone number is required"),
    fullNameCC: yup
      .string()
      .matches(
        fullNameCCRegExp,
        "Enter your full name as it appears on your credit card"
      )
      .required("Full name is required"),
    // creditCardNumber: yup
    //   .string()
    //   .matches(creditCardRegExp, "Credit card number must be 16 digits")
    //   .required("Card number is required"),
    expiryMonthYear: yup
      .string()
      .typeError("Not a valid expiration date. Example: MM/YY")
      .max(5, "Not a valid expiration date. Example: MM/YY")
      .matches(
        expiryMonthYearRegExp,
        "Not a valid expiration date. Example: MM/YY"
      )
      .test(
        "test-credit-card-expiration-date",
        "Invalid. Expiration date has passed.",
        (expiryMonthYear) => {
          if (!expiryMonthYear) {
            return false;
          }

          const [expMonth, expYear] = expiryMonthYear.split("/");

          if (Number(expYear) < Number(currentYear)) {
            return false;
          } else if (
            Number(expMonth) < Number(currentMonth) &&
            Number(expYear) <= Number(currentYear)
          ) {
            return false;
          }

          return true;
        }
      )
      .test(
        "test-credit-card-expiration-date",
        "Invalid expiration month",
        (expiryMonthYear) => {
          if (!expiryMonthYear) {
            return false;
          }
          // const today = new Date().getFullYear().toString().substr(-2);

          const [expMonth] = expiryMonthYear.split("/");

          if (Number(expMonth) > 12) {
            return false;
          }

          return true;
        }
      )
      .required("Expiration date is required"),
    // cvc: yup
    //   .string()
    //   .min(3, "CVC must be 3 or 4 digits")
    //   .max(4, "CVC must be 3 or 4 digits ")
    //   .required("CVC is required"),
    promoCode: yup.string(),
  });

  const initialCheckoutAsGuestValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    fullNameCC: "",
    creditCardNumber: "",
    expiryMonthYear: currentMonth + "/" + currentYear,
    cvc: "",
    promoCode: "",
  };

  const formikCheckoutAsGuestProps = useFormik({
    initialValues: initialCheckoutAsGuestValues,
    validationSchema: checkoutAsGuestSchema,
    onSubmit: (values, { resetForm }) => {
      onCheckoutComplete();
      resetForm();
    },
  });

  const signInOrCreateRef = useRef<HTMLInputElement>(null);
  const guestRef = useRef<HTMLInputElement>(null);
  const reachYouRef = useRef<HTMLInputElement>(null);
  const paymentRef = useRef<HTMLInputElement>(null);
  const everythingGoodRef = useRef<HTMLInputElement>(null);

  var styles = {
    // set style to all elements, JSON accepted too
    // "*": "height: 16px;",
    // setting the font-family to both fields
    "*": "height: 74px; font-size: 16px; font-weight: normal; caret-color: #004C45; border: 1px solid #000000; padding: 18px 28px 0px;",
    // or with JSON
    //'*': {
    //    border: '2px solid black',
    //    padding: '.65em .5em'
    //    backgroundColor: 'blue',  // background-color
    //    backgroundImage: 'none',  // background-image
    //    boxSizing: 'border-box',  // box-sizing
    //    WebkitBoxShadow: 'none',  // -webkit-box-shadow
    //    WebkitAppearance: 'none'  // -webkit-appearance
    //}
    // ::hover on all elements
    "*::hover":
      "-webkit-box-shadow: none; -ms-box-shadow: none; -moz-appearance: none; ",
    // set style to one of the elements
    // cardNumber: "font-size: 16px;",
    // cvv: "font-size: 16px;",
    // setting style based on CSS classes (see 'Toggled classes')
    // "cardNumber.valid:hover": "background-color: green;",
    // "cardNumber.invalid:hover": "background-color: red;",
    // pseudoselectors
    "*:focus": "border: 2px solid #004C45",
    // '*:hover': 'border: 1px solid #66AFE9',
    "*::placeholder": "color: #999999",
    "*:-ms-input-placeholder": "color: #999999", // thanks MS :(
  };

  const signInGooglePopUp = async () => {
    const auth = getAuth();
    await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        // The signed-in user info.
        const user = result.user;
        // ...

        setContinueAsUser(true);
        setContinueAsGuest(false);
        // setShowCheckingOut(true);
        // setShowEditCheckingOut(true);
        setShowCheckingOutForm(false);
        setShowHowCanWeReachYou(false);
        setShowHowCanWeReachYouForm(true);

        setTimeout(() => {
          reachYouRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        }, 1500);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        // console.log("error code", errorCode);
        const errorMessage = error.message;
        // console.log("error message", errorMessage);
        // The email of the user's account used.
        const email = error.email;
        // console.log("error email", email);
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // console.log("credential", credential);
        // ...
      });
  };

  // const signInGoogleRedirect = () => {
  //   const authSignIn = getAuth();
  //   signInWithRedirect(authSignIn, provider);
  //   getRedirectResult(authSignIn)
  //     .then((result) => {
  //       // This gives you a Google Access Token. You can use it to access Google APIs.
  //       //   const credential = GoogleAuthProvider.credentialFromResult(result);
  //       //   const token = credential?.accessToken;
  //       console.log("result", result);

  //       // The signed-in user info.
  //       const user = result?.user;
  //       console.log("user", user);

  //       setContinueAsUser(true);
  //       setContinueAsGuest(false);
  //       // setShowCheckingOut(true);
  //       // setShowEditCheckingOut(true);
  //       setShowCheckingOutForm(false);
  //       setShowHowCanWeReachYou(false);
  //       setShowHowCanWeReachYouForm(true);

  //       setTimeout(() => {
  //         reachYouRef.current?.scrollIntoView({
  //           behavior: "smooth",
  //         });
  //       }, 1500);
  //     })
  //     .catch((error) => {
  //       // Handle Errors here.
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       console.log("error", error);
  //       console.log("error message", errorMessage);
  //       // The email of the user's account used.
  //       const email = error.email;
  //       // The AuthCredential type that was used.
  //       const credential = GoogleAuthProvider.credentialFromError(error);
  //       // ...
  //     });
  //   // const auth = getAuth();
  //   // await signInWithRedirect(auth, provider);
  // };

  useEffect(() => {
    if (formikSignInOrCreateProps.values.email) {
      setResetPasswordErrors(false);
      setResetPasswordErrorMessage("");
    }
  }, [formikSignInOrCreateProps.values.email]);

  return (
    <BookingTwoColumnLayout
      className="booking-checkout-layout"
      leftColumnChildren={
        <div className="booking-checkout">
          <h2 className="booking-two-column-layout__title">Checkout</h2>

          {/* {
						showCheckingOut &&
						//has border top */}
          <div
            className={`edit-accordion ${
              continueAsGuest && showCheckingOut && "edit-accordion--active"
            }`}
          >
            <div className="booking-checkout-list">
              <h3 className="booking-checkout-list__label">{`Checkout as ${
                continueAsUser ? "User" : "Guest"
              }`}</h3>
              {showEditCheckingOut && (
                <button
                  className="booking-checkout-list__button"
                  onClick={() => {
                    editCheckoutItem("checkout");
                    setTimeout(() => {
                      signInOrCreateRef.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 1500);
                  }}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
          {/* } */}

          {/* {
						showCheckingOutForm &&
						//has border top */}
          {/* <div
            className={`edit-accordion ${
              showCheckingOutForm && "edit-accordion--active"
            }`}
          > */}
          <div
            className={`edit-accordion ${
              // !continueAsUser && "edit-accordion--active"
              showCheckingOutForm && "edit-accordion--active"
            }`}
          >
            <div className="booking-checkout-content">
              <div
                className="booking-checkout-content__user-form"
                ref={signInOrCreateRef}
              >
                <h3 className="booking-checkout__alt-title">
                  Sign In or Create an account
                </h3>
                <form className="annex-form" onSubmit={signInAndContinue}>
                  <div className="annex-form__two-column">
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikSignInOrCreateProps.touched.email &&
                            formikSignInOrCreateProps.errors.email
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          Email Address
                        </label>
                        <input
                          className="annex-form__input"
                          id="email"
                          name="email"
                          type="text"
                          value={formikSignInOrCreateProps.values.email}
                          onChange={formikSignInOrCreateProps.handleChange}
                          onBlur={formikSignInOrCreateProps.handleBlur}
                        />
                      </div>
                      {formikSignInOrCreateProps.touched.email &&
                        formikSignInOrCreateProps.errors.email && (
                          <p className="annex-form__error-message">
                            {formikSignInOrCreateProps.errors.email}
                          </p>
                        )}
                      {resetPasswordErrors &&
                        !formikSignInOrCreateProps.touched.email && (
                          <p className="annex-form__error-message">
                            {resetPasswordErrorMessage}
                          </p>
                        )}
                    </div>
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikSignInOrCreateProps.touched.password &&
                            formikSignInOrCreateProps.errors.password
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          Password
                        </label>
                        <input
                          className="annex-form__input"
                          id="password"
                          name="password"
                          type="password"
                          value={formikSignInOrCreateProps.values.password}
                          onChange={formikSignInOrCreateProps.handleChange}
                          onBlur={formikSignInOrCreateProps.handleBlur}
                        />
                      </div>
                      <div className={`errors-forgot-password`}>
                        <button
                          className="forgot-password"
                          onClick={handleForgotPassword}
                          type="button"
                        >
                          Forgot your password?
                        </button>
                      </div>
                      {formikSignInOrCreateProps.touched.password &&
                        formikSignInOrCreateProps.errors.password && (
                          <p className="annex-form__error-message">
                            {formikSignInOrCreateProps.errors.password}
                          </p>
                        )}
                      {resetEmailSent && (
                        <p className="annex-form__success-message">
                          An email was sent to the provided email address to
                          reset your password
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="sign-in-buttons-container">
                    <div className="annex-form__button-container annex-form__button-container--text-right">
                      <Button
                        buttontype="primary--inverse"
                        children="Sign In and Continue"
                        cname={`sign-in-button
                          ${
                            !(
                              formikSignInOrCreateProps.values.email &&
                              !formikSignInOrCreateProps.errors.email &&
                              formikSignInOrCreateProps.values.password &&
                              !formikSignInOrCreateProps.errors.password
                            )
                              ? "disabled-btn-black"
                              : ""
                          }`}
                        type="submit"
                        disabled={
                          !(
                            formikSignInOrCreateProps.values.email &&
                            !formikSignInOrCreateProps.errors.email &&
                            formikSignInOrCreateProps.values.password &&
                            !formikSignInOrCreateProps.errors.password
                          )
                        }
                        linkTo=""
                      />
                    </div>
                    <div className="google-button-container">
                      <button
                        className="sign-in-google"
                        onClick={signInGooglePopUp}
                        type="button"
                      ></button>
                      {/* <button
                        className="sign-in-google mobile-only"
                        onClick={signInGoogleRedirect}
                        type="button"
                      ></button> */}
                    </div>
                  </div>
                </form>
              </div>

              <div
                className="booking-checkout-content__guest-form"
                ref={guestRef}
              >
                <h3 className="booking-checkout__alt-title">
                  Continue as guest?
                </h3>
                <form
                  className="annex-form"
                  onSubmit={checkOutAsGuest}
                  // onSubmit={formikCheckoutAsGuestProps.handleSubmit}
                >
                  <div className="annex-form__two-column">
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikCheckoutAsGuestProps.touched.firstName &&
                            formikCheckoutAsGuestProps.errors.firstName
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          First Name
                        </label>
                        <input
                          className="annex-form__input"
                          id="firstName"
                          name="firstName"
                          type="text"
                          value={formikCheckoutAsGuestProps.values.firstName}
                          onChange={formikCheckoutAsGuestProps.handleChange}
                          onBlur={formikCheckoutAsGuestProps.handleBlur}
                        />
                      </div>
                      {formikCheckoutAsGuestProps.touched.firstName &&
                        formikCheckoutAsGuestProps.errors.firstName && (
                          <p className="annex-form__error-message">
                            {formikCheckoutAsGuestProps.errors.firstName}
                          </p>
                        )}
                    </div>
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikCheckoutAsGuestProps.touched.lastName &&
                            formikCheckoutAsGuestProps.errors.lastName
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          Last Name
                        </label>
                        <input
                          className="annex-form__input"
                          id="lastName"
                          name="lastName"
                          type="text"
                          value={formikCheckoutAsGuestProps.values.lastName}
                          onChange={formikCheckoutAsGuestProps.handleChange}
                          onBlur={formikCheckoutAsGuestProps.handleBlur}
                        />
                      </div>
                      {formikCheckoutAsGuestProps.touched.lastName &&
                        formikCheckoutAsGuestProps.errors.lastName && (
                          <p className="annex-form__error-message">
                            {formikCheckoutAsGuestProps.errors.lastName}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="annex-form__button-container annex-form__button-container--text-right">
                    <Button
                      buttontype="primary--inverse"
                      children="Checkout as Guest"
                      cname={
                        !(
                          formikCheckoutAsGuestProps.values.firstName &&
                          !formikCheckoutAsGuestProps.errors.firstName &&
                          formikCheckoutAsGuestProps.values.lastName &&
                          !formikCheckoutAsGuestProps.errors.lastName
                        )
                          ? "disabled-btn-black"
                          : ""
                      }
                      type="submit"
                      disabled={
                        !(
                          formikCheckoutAsGuestProps.values.firstName &&
                          !formikCheckoutAsGuestProps.errors.firstName &&
                          formikCheckoutAsGuestProps.values.lastName &&
                          !formikCheckoutAsGuestProps.errors.lastName
                        )
                      }
                      linkTo=""
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* } */}

          {/* {
						showHowCanWeReachYou && */}
          <div
            className={`edit-accordion ${
              showHowCanWeReachYou && "edit-accordion--active"
            }`}
          >
            <div className="booking-checkout-list">
              <h3 className="booking-checkout-list__label">
                How can we reach you?
              </h3>
              {showEditHowCanWeReachYou && (
                <button
                  className="booking-checkout-list__button"
                  onClick={() => {
                    editCheckoutItem("reach");
                    setTimeout(() => {
                      reachYouRef.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 1500);
                  }}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
          {/* } */}

          {/* {
						showHowCanWeReachYouForm && */}
          <div
            className={`edit-accordion ${
              showHowCanWeReachYouForm && "edit-accordion--active"
            }`}
          >
            <div className="booking-checkout-content" ref={reachYouRef}>
              <h3 className="booking-checkout__alt-title">
                How can we reach you?
              </h3>
              {continueAsUser && (
                <form className="annex-form" onSubmit={userHowWeReachYou}>
                  <div className="annex-form__two-column">
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikSignInOrCreateProps.touched.firstName &&
                            formikSignInOrCreateProps.errors.firstName
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          First Name
                        </label>
                        <input
                          className="annex-form__input"
                          id="firstName"
                          name="firstName"
                          type="text"
                          value={formikSignInOrCreateProps.values.firstName}
                          onChange={formikSignInOrCreateProps.handleChange}
                          onBlur={formikSignInOrCreateProps.handleBlur}
                        />
                      </div>
                      {formikSignInOrCreateProps.touched.firstName &&
                        formikSignInOrCreateProps.errors.firstName && (
                          <p className="annex-form__error-message">
                            {formikSignInOrCreateProps.errors.firstName}
                          </p>
                        )}
                    </div>
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikSignInOrCreateProps.touched.lastName &&
                            formikSignInOrCreateProps.errors.lastName
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          Last Name
                        </label>
                        <input
                          className="annex-form__input"
                          id="lastName"
                          name="lastName"
                          type="text"
                          value={formikSignInOrCreateProps.values.lastName}
                          onChange={formikSignInOrCreateProps.handleChange}
                          onBlur={formikSignInOrCreateProps.handleBlur}
                        />
                      </div>
                      {formikSignInOrCreateProps.touched.lastName &&
                        formikSignInOrCreateProps.errors.lastName && (
                          <p className="annex-form__error-message">
                            {formikSignInOrCreateProps.errors.lastName}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="annex-form__two-column">
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikSignInOrCreateProps.touched.email &&
                            formikSignInOrCreateProps.errors.email
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          Email:
                        </label>
                        <input
                          className="annex-form__input"
                          id="email"
                          name="email"
                          type="text"
                          value={formikSignInOrCreateProps.values.email}
                          onChange={formikSignInOrCreateProps.handleChange}
                          onBlur={formikSignInOrCreateProps.handleBlur}
                        />
                      </div>
                      {formikSignInOrCreateProps.touched.email &&
                        formikSignInOrCreateProps.errors.email && (
                          <p className="annex-form__error-message">
                            {formikSignInOrCreateProps.errors.email}
                          </p>
                        )}
                    </div>
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikSignInOrCreateProps.touched.phoneNumber &&
                            formikSignInOrCreateProps.errors.phoneNumber
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          Phone
                        </label>
                        <input
                          className="annex-form__input"
                          id="phoneNumber"
                          name="phoneNumber"
                          type="text"
                          value={formikSignInOrCreateProps.values.phoneNumber}
                          onChange={formikSignInOrCreateProps.handleChange}
                          onBlur={formikSignInOrCreateProps.handleBlur}
                        />
                      </div>
                      {formikSignInOrCreateProps.touched.phoneNumber &&
                        formikSignInOrCreateProps.errors.phoneNumber && (
                          <p className="annex-form__error-message">
                            {formikSignInOrCreateProps.errors.phoneNumber}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="annex-form__button-container annex-form__button-container--text-right">
                    <Button
                      buttontype="primary--inverse"
                      children="Next"
                      cname={
                        !(
                          formikSignInOrCreateProps.values.email &&
                          !formikSignInOrCreateProps.errors.email &&
                          formikSignInOrCreateProps.values.phoneNumber &&
                          !formikSignInOrCreateProps.errors.phoneNumber
                        )
                          ? "disabled-btn-black"
                          : ""
                      }
                      type="submit"
                      linkTo=""
                    />
                  </div>
                </form>
              )}

              {continueAsGuest && (
                <form className="annex-form" onSubmit={guestHowWeReachYou}>
                  <div className="annex-form__two-column">
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikCheckoutAsGuestProps.touched.firstName &&
                            formikCheckoutAsGuestProps.errors.firstName
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          First Name
                        </label>
                        <input
                          className="annex-form__input"
                          id="firstName"
                          name="firstName"
                          type="text"
                          value={formikCheckoutAsGuestProps.values.firstName}
                          onChange={formikCheckoutAsGuestProps.handleChange}
                          onBlur={formikCheckoutAsGuestProps.handleBlur}
                        />
                      </div>
                      {formikCheckoutAsGuestProps.touched.firstName &&
                        formikCheckoutAsGuestProps.errors.firstName && (
                          <p className="annex-form__error-message">
                            {formikCheckoutAsGuestProps.errors.firstName}
                          </p>
                        )}
                    </div>
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikCheckoutAsGuestProps.touched.lastName &&
                            formikCheckoutAsGuestProps.errors.lastName
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          Last Name
                        </label>
                        <input
                          className="annex-form__input"
                          id="lastName"
                          name="lastName"
                          type="text"
                          value={formikCheckoutAsGuestProps.values.lastName}
                          onChange={formikCheckoutAsGuestProps.handleChange}
                          onBlur={formikCheckoutAsGuestProps.handleBlur}
                        />
                      </div>
                      {formikCheckoutAsGuestProps.touched.lastName &&
                        formikCheckoutAsGuestProps.errors.lastName && (
                          <p className="annex-form__error-message">
                            {formikCheckoutAsGuestProps.errors.lastName}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="annex-form__two-column">
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikCheckoutAsGuestProps.touched.email &&
                            formikCheckoutAsGuestProps.errors.email
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          Email
                        </label>
                        <input
                          className="annex-form__input"
                          id="email"
                          name="email"
                          type="text"
                          value={formikCheckoutAsGuestProps.values.email}
                          onChange={formikCheckoutAsGuestProps.handleChange}
                          onBlur={formikCheckoutAsGuestProps.handleBlur}
                        />
                      </div>
                      {formikCheckoutAsGuestProps.touched.email &&
                        formikCheckoutAsGuestProps.errors.email && (
                          <p className="annex-form__error-message">
                            {formikCheckoutAsGuestProps.errors.email}
                          </p>
                        )}
                    </div>
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikCheckoutAsGuestProps.touched.phoneNumber &&
                            formikCheckoutAsGuestProps.errors.phoneNumber
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          Phone
                        </label>
                        <input
                          className="annex-form__input"
                          id="phoneNumber"
                          name="phoneNumber"
                          type="text"
                          value={formikCheckoutAsGuestProps.values.phoneNumber}
                          onChange={formikCheckoutAsGuestProps.handleChange}
                          onBlur={formikCheckoutAsGuestProps.handleBlur}
                        />
                      </div>
                      {formikCheckoutAsGuestProps.touched.phoneNumber &&
                        formikCheckoutAsGuestProps.errors.phoneNumber && (
                          <p className="annex-form__error-message">
                            {formikCheckoutAsGuestProps.errors.phoneNumber}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="annex-form__button-container annex-form__button-container--text-right">
                    <Button
                      buttontype="primary--inverse"
                      children="Next"
                      cname={
                        !(
                          formikCheckoutAsGuestProps.values.firstName &&
                          !formikCheckoutAsGuestProps.errors.firstName &&
                          formikCheckoutAsGuestProps.values.lastName &&
                          !formikCheckoutAsGuestProps.errors.lastName &&
                          formikCheckoutAsGuestProps.values.email &&
                          !formikCheckoutAsGuestProps.errors.email &&
                          formikCheckoutAsGuestProps.values.phoneNumber &&
                          !formikCheckoutAsGuestProps.errors.phoneNumber
                        )
                          ? "disabled-btn-black"
                          : ""
                      }
                      type="submit"
                      linkTo=""
                    />
                  </div>
                </form>
              )}
            </div>
          </div>

          <div
            className={`edit-accordion ${
              showPaymentInformation && "edit-accordion--active"
            }`}
          >
            <div className="booking-checkout-list">
              <h3 className="booking-checkout-list__label">
                What's your payment information?
              </h3>
              {/* {showEditPaymentInformationComplete && (
                <button
                  className="booking-checkout-list__button"
                  // onClick={() => editCheckoutItem("payment")}
                  onClick={() => {
                    editCheckoutItem("payment");
                    setTimeout(() => {
                      paymentRef.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 1500);
                  }}
                  
                >
                  Edit
                </button>
              )} */}
            </div>
          </div>

          <div
            className={`edit-accordion ${
              showPaymentInformationForm && "edit-accordion--active"
            }`}
          >
            <div className="booking-checkout-content" ref={paymentRef}>
              <h3 className="booking-checkout__alt-title">
                What's your payment information?
              </h3>
              <a
                href="https://docs.pci-proxy.com/test-card-data"
                target="_blank"
                rel="noreferrer"
              >
                Test data here
              </a>
              {continueAsUser && (
                <form
                  className="annex-form-payment"
                  onSubmit={submitPaymentInformationUser}
                >
                  <div className="annex-form-group-container">
                    <div className="annex-form-group">
                      <label
                        className={`annex-form__label ${
                          formikSignInOrCreateProps.touched.fullNameCC &&
                          formikSignInOrCreateProps.errors.fullNameCC
                            ? "annex-form__label--error"
                            : ""
                        }`}
                      >
                        Name On Credit Card
                      </label>
                      <input
                        className="annex-form__input"
                        id="fullNameCC"
                        name="fullNameCC"
                        type="text"
                        value={formikSignInOrCreateProps.values.fullNameCC}
                        onChange={formikSignInOrCreateProps.handleChange}
                        onBlur={formikSignInOrCreateProps.handleBlur}
                      />
                    </div>
                    {formikSignInOrCreateProps.touched.fullNameCC &&
                      formikSignInOrCreateProps.errors.fullNameCC && (
                        <p className="annex-form__error-message">
                          {formikSignInOrCreateProps.errors.fullNameCC}
                        </p>
                      )}
                  </div>
                  <div className="annex-form-group-container-payment">
                    <div className="annex-form-group-payment">
                      <label
                        htmlFor="card-number-placeholder"
                        className={`annex-form-payment__label ${
                          formikSignInOrCreateProps.touched.creditCardNumber &&
                          formikSignInOrCreateProps.errors.creditCardNumber
                            ? "annex-form-payment__label--error"
                            : ""
                        }`}
                      >
                        Credit Card Number
                      </label>
                      <div
                        id="card-number-placeholder"
                        className="annex-form-payment__input"
                        style={{
                          height: "74px",
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          width: "100%",
                        }}
                      ></div>
                      {/* <input
                        className="annex-form__input"
                        id="creditCardNumber"
                        name="creditCardNumber"
                        type="text"
                        value={
                          formikSignInOrCreateProps.values.creditCardNumber
                        }
                        onChange={formikSignInOrCreateProps.handleChange}
                        onBlur={formikSignInOrCreateProps.handleBlur}
                      /> */}
                      <input
                        className="hidden-input"
                        id="creditCardNumber"
                        name="creditCardNumber"
                        // type="text"
                        // value={
                        //   formikSignInOrCreateProps.values.creditCardNumber
                        // }
                        // onChange={formikSignInOrCreateProps.handleChange}
                        // onBlur={formikSignInOrCreateProps.handleBlur}
                      />
                    </div>
                    {/* {formikSignInOrCreateProps.touched.creditCardNumber &&
                      formikSignInOrCreateProps.errors.creditCardNumber && (
                        <p className="annex-form__error-message">
                          {formikSignInOrCreateProps.errors.creditCardNumber}
                        </p>
                      )} */}
                    {formikSignInOrCreateProps.errors.creditCardNumber && (
                      <p className="annex-form__error-message">
                        {formikSignInOrCreateProps.errors.creditCardNumber}
                      </p>
                    )}
                  </div>
                  <div className="annex-form__two-column">
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikSignInOrCreateProps.touched.expiryMonthYear &&
                            formikSignInOrCreateProps.errors.expiryMonthYear
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          MM/YY
                        </label>
                        <input
                          className="annex-form__input"
                          id="expiryMonthYear"
                          name="expiryMonthYear"
                          type="text"
                          value={
                            formikSignInOrCreateProps.values.expiryMonthYear
                          }
                          onChange={formikSignInOrCreateProps.handleChange}
                          onBlur={formikSignInOrCreateProps.handleBlur}
                        />
                      </div>
                      {formikSignInOrCreateProps.touched.expiryMonthYear &&
                        formikSignInOrCreateProps.errors.expiryMonthYear && (
                          <p className="annex-form__error-message">
                            {formikSignInOrCreateProps.errors.expiryMonthYear}
                          </p>
                        )}
                    </div>
                    <div className="annex-form-group-container-payment">
                      <div className="annex-form-group-payment">
                        <label
                          htmlFor="cvv-placeholder"
                          className={`annex-form-payment__label ${
                            formikSignInOrCreateProps.touched.cvc &&
                            formikSignInOrCreateProps.errors.cvc
                              ? "annex-form-payment__label--error"
                              : ""
                          }`}
                        >
                          CVC
                        </label>
                        {/* <input
                          className="annex-form__input"
                          id="cvc"
                          name="cvc"
                          type="text"
                          value={formikSignInOrCreateProps.values.cvc}
                          onChange={formikSignInOrCreateProps.handleChange}
                          onBlur={formikSignInOrCreateProps.handleBlur}
                        /> */}
                        <div
                          id="cvv-placeholder"
                          className="annex-form-payment__input"
                          style={{
                            height: "74px",
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            width: "100%",
                          }}
                        ></div>
                        <input
                          className="hidden-input"
                          id="cvc"
                          name="cvc"
                          // type="text"
                          // value={formikSignInOrCreateProps.values.cvc}
                          // onChange={formikSignInOrCreateProps.handleChange}
                          // onBlur={formikSignInOrCreateProps.handleBlur}
                        />
                      </div>
                      {/* {formikSignInOrCreateProps.touched.cvc &&
                        formikSignInOrCreateProps.errors.cvc && (
                          <p className="annex-form__error-message">
                            {formikSignInOrCreateProps.errors.cvc}
                          </p>
                        )} */}
                      {formikSignInOrCreateProps.errors.cvc && (
                        <p className="annex-form__error-message">
                          {formikSignInOrCreateProps.errors.cvc}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="annex-form-group-container">
                    <div className="annex-form-group">
                      <label
                        className={`annex-form__label ${
                          formikSignInOrCreateProps.errors.promoCode
                            ? "annex-form__label--error"
                            : ""
                        }`}
                      >
                        Promo Code
                      </label>
                      <input
                        className="annex-form__input"
                        id="promoCode"
                        name="promoCode"
                        type="text"
                        value={formikSignInOrCreateProps.values.promoCode}
                        onChange={formikSignInOrCreateProps.handleChange}
                        onBlur={formikSignInOrCreateProps.handleBlur}
                      />
                    </div>
                    {formikSignInOrCreateProps.errors.promoCode && (
                      <p className="annex-form__error-message">
                        Error Message/Helper Text
                      </p>
                    )}
                  </div>

                  <div className="annex-form__button-container annex-form__button-container--text-right">
                    <Button
                      buttontype="primary--inverse"
                      children={
                        isLoading ? (
                          <div style={{ marginBottom: "-4px" }}>
                            <Loader
                              type="Bars"
                              visible={true}
                              height={25}
                              width={25}
                              color="#004C45"
                            />
                          </div>
                        ) : (
                          "Confirm Booking"
                        )
                      }
                      cname="confirm-booking-button"
                      // cname={
                      //   !(
                      //     formikSignInOrCreateProps.values.creditCardNumber &&
                      //     !formikSignInOrCreateProps.errors.creditCardNumber &&
                      //     formikSignInOrCreateProps.values.expiryMonthYear &&
                      //     !formikSignInOrCreateProps.errors.expiryMonthYear &&
                      //     formikSignInOrCreateProps.values.cvc &&
                      //     !formikSignInOrCreateProps.errors.cvc
                      //   )
                      //     ? "disabled-btn-black"
                      //     : ""
                      // }
                      type="submit"
                      linkTo=""
                    />
                  </div>
                </form>
              )}
              {continueAsGuest && (
                <form
                  className="annex-form"
                  onSubmit={submitPaymentInformationGuest}
                >
                  <div className="annex-form-group-container">
                    <div className="annex-form-group">
                      <label
                        className={`annex-form__label ${
                          formikSignInOrCreateProps.touched.fullNameCC &&
                          formikSignInOrCreateProps.errors.fullNameCC
                            ? "annex-form__label--error"
                            : ""
                        }`}
                      >
                        Name On Credit Card
                      </label>
                      <input
                        className="annex-form__input"
                        id="fullNameCC"
                        name="fullNameCC"
                        type="text"
                        value={formikSignInOrCreateProps.values.fullNameCC}
                        onChange={formikSignInOrCreateProps.handleChange}
                        onBlur={formikSignInOrCreateProps.handleBlur}
                      />
                    </div>
                    {formikSignInOrCreateProps.touched.fullNameCC &&
                      formikSignInOrCreateProps.errors.fullNameCC && (
                        <p className="annex-form__error-message">
                          {formikSignInOrCreateProps.errors.fullNameCC}
                        </p>
                      )}
                  </div>
                  <div className="annex-form-group-container-payment">
                    <div className="annex-form-group-payment">
                      <label
                        htmlFor="card-number-placeholder"
                        className={`annex-form-payment__label ${
                          formikCheckoutAsGuestProps.touched.creditCardNumber &&
                          formikCheckoutAsGuestProps.errors.creditCardNumber
                            ? "annex-form-payment__label--error"
                            : ""
                        }`}
                      >
                        Credit Card Number
                      </label>

                      {/* TODO - Style here https://docs.pci-proxy.com/collect-and-store-cards/capture-iframes/initialization-and-styling */}

                      <div
                        id="card-number-placeholder"
                        className="annex-form-payment__input"
                        style={{
                          height: "74px",
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          width: "100%",
                        }}
                      ></div>
                      {/* <input
                        className="annex-form__input"
                        id="creditCardNumber"
                        name="creditCardNumber"
                        type="text"
                        value={
                          formikCheckoutAsGuestProps.values.creditCardNumber
                        }
                        onChange={formikCheckoutAsGuestProps.handleChange}
                        onBlur={formikCheckoutAsGuestProps.handleBlur}
                      /> */}
                    </div>
                    {/* {formikCheckoutAsGuestProps.touched.creditCardNumber &&
                      formikCheckoutAsGuestProps.errors.creditCardNumber && (
                        <p className="annex-form__error-message">
                          {formikCheckoutAsGuestProps.errors.creditCardNumber}
                        </p>
                      )} */}
                  </div>
                  <div className="annex-form__two-column">
                    <div className="annex-form-group-container">
                      <div className="annex-form-group">
                        <label
                          className={`annex-form__label ${
                            formikCheckoutAsGuestProps.touched
                              .expiryMonthYear &&
                            formikCheckoutAsGuestProps.errors.expiryMonthYear
                              ? "annex-form__label--error"
                              : ""
                          }`}
                        >
                          MM/YY
                        </label>
                        <input
                          className="annex-form__input"
                          id="expiryMonthYear"
                          name="expiryMonthYear"
                          type="text"
                          value={
                            formikCheckoutAsGuestProps.values.expiryMonthYear
                          }
                          onChange={formikCheckoutAsGuestProps.handleChange}
                          onBlur={formikCheckoutAsGuestProps.handleBlur}
                        />
                      </div>
                      {formikCheckoutAsGuestProps.touched.expiryMonthYear &&
                        formikCheckoutAsGuestProps.errors.expiryMonthYear && (
                          <p className="annex-form__error-message">
                            {formikCheckoutAsGuestProps.errors.expiryMonthYear}
                          </p>
                        )}
                    </div>
                    <div className="annex-form-group-container-payment">
                      <div className="annex-form-group-payment">
                        <label
                          htmlFor="cvv-placeholder"
                          className={`annex-form-payment__label ${
                            formikCheckoutAsGuestProps.touched.cvc &&
                            formikCheckoutAsGuestProps.errors.cvc
                              ? "annex-form-payment__label--error"
                              : ""
                          }`}
                        >
                          CVC
                        </label>
                        <div
                          id="cvv-placeholder"
                          className="annex-form-payment__input"
                          style={{
                            height: "74px",
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            width: "100%",
                          }}
                        ></div>
                        {/* <input
                          className="annex-form__input"
                          id="cvc"
                          name="cvc"
                          type="text"
                          value={formikCheckoutAsGuestProps.values.cvc}
                          onChange={formikCheckoutAsGuestProps.handleChange}
                          onBlur={formikCheckoutAsGuestProps.handleBlur}
                        /> */}
                      </div>
                      {/* {formikCheckoutAsGuestProps.touched.cvc &&
                        formikCheckoutAsGuestProps.errors.cvc && (
                          <p className="annex-form__error-message">
                            {formikCheckoutAsGuestProps.errors.cvc}
                          </p>
                        )} */}
                    </div>
                  </div>
                  <div className="annex-form-group-container">
                    <div className="annex-form-group">
                      <label
                        className={`annex-form__label ${
                          formikCheckoutAsGuestProps.errors.promoCode
                            ? "annex-form__label--error"
                            : ""
                        }`}
                      >
                        Promo Code
                      </label>
                      <input
                        className="annex-form__input"
                        id="promoCode"
                        name="promoCode"
                        type="text"
                        value={formikCheckoutAsGuestProps.values.promoCode}
                        onChange={formikCheckoutAsGuestProps.handleChange}
                        onBlur={formikCheckoutAsGuestProps.handleBlur}
                      />
                    </div>
                    {formikCheckoutAsGuestProps.errors.promoCode && (
                      <p className="annex-form__error-message">
                        Error Message/Helper Text
                      </p>
                    )}
                  </div>
                  <div className="annex-form__button-container annex-form__button-container--text-right">
                    <Button
                      buttontype="primary--inverse"
                      children={
                        isLoading ? (
                          <div style={{ marginBottom: "-4px" }}>
                            <Loader
                              type="Bars"
                              visible={true}
                              height={25}
                              width={25}
                              color="#004C45"
                            />
                          </div>
                        ) : (
                          "Confirm Booking"
                        )
                      }
                      cname="confirm-booking-button"
                      // cname={
                      //   !(
                      //     formikCheckoutAsGuestProps.values.creditCardNumber &&
                      //     !formikCheckoutAsGuestProps.errors.creditCardNumber &&
                      //     formikCheckoutAsGuestProps.values.expiryMonthYear &&
                      //     !formikCheckoutAsGuestProps.errors.expiryMonthYear &&
                      //     formikCheckoutAsGuestProps.values.cvc &&
                      //     !formikCheckoutAsGuestProps.errors.cvc
                      //   )
                      //     ? "disabled-btn-black"
                      //     : ""
                      // }
                      type="submit"
                      linkTo=""
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
          {/* } */}

          {/* {
						showEverythingGood &&  */}
          <div
            className={`edit-accordion ${
              showEverythingGood && "edit-accordion--active"
            }`}
          >
            <div className="booking-checkout-list border-bottom">
              <h3 className="booking-checkout-list__label">
                Everything look good?
              </h3>
              {showEditEverythingGoodComplete && (
                <button
                  className="booking-checkout-list__button"
                  onClick={() => editCheckoutItem("everything")}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
          {/* } */}

          {/* {
						showEverythingGoodForm &&  */}

          <div
            className={`edit-accordion ${
              showEverythingGoodForm && "edit-accordion--active"
            }`}
          >
            <div
              className="booking-checkout-content border-bottom"
              ref={everythingGoodRef}
            >
              <h3 className="booking-checkout__alt-title">
                Everything look good?
              </h3>

              <div className="booking-checkout-info">
                <h4 className="booking-checkout-info__label">
                  Checkout out as
                </h4>
                <p className="booking-checkout-info__text">
                  {/* {continueAsUser ? "User" : "Guest"} */}
                  {continueAsUser
                    ? `${
                        formikSignInOrCreateProps.values.firstName +
                        " " +
                        formikSignInOrCreateProps.values.lastName
                      }`
                    : "Guest"}
                </p>

                <h4 className="booking-checkout-info__label">Contact</h4>
                {continueAsGuest && (
                  <p className="booking-checkout-info__text">{`${formikCheckoutAsGuestProps.values.email} | ${formikCheckoutAsGuestProps.values.phoneNumber}`}</p>
                )}
                {continueAsUser && (
                  <p className="booking-checkout-info__text">{`${formikSignInOrCreateProps.values.email} | ${formikSignInOrCreateProps.values.phoneNumber}`}</p>
                )}

                <h4 className="booking-checkout-info__label">
                  Payment Information
                </h4>
                {continueAsGuest && (
                  <p className="booking-checkout-info__text">{`xxxx xxxx xxxx ${lastFourDigits}`}</p>
                )}
                {continueAsUser && (
                  <p className="booking-checkout-info__text">{`xxxx xxxx xxxx ${lastFourDigits}`}</p>
                )}
              </div>

              <div className="annex-form__button-container annex-form__button-container--text-right">
                <Button
                  buttontype="secondary"
                  children="Complete Booking"
                  onClick={() => {
                    // props.onCheckoutCompleteClick();
                    onCheckoutComplete();
                    formikSignInOrCreateProps.handleSubmit();
                    formikCheckoutAsGuestProps.handleSubmit();
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                  type="submit"
                  linkTo=""
                />
              </div>
            </div>
          </div>
          {/* } */}
        </div>
      }
      rightColumnChildren={room && <BookingSummary room={room} />}
    />
  );
};

export default BookingCheckout;
