import './BookingMenu.scss';
import { useState, useRef, ReactNode } from 'react';

import DropDownArrow from '../../assets/icons/dropdown-arrow.svg';

export type BookingMenuAccordionProps = {
    containerClassName?: string;
    dropdownClassName?: string;
    headerTitle?: string;
    headerSubTitle?: string | number;
    headerWidth?: string;
    listDataSrc?: Array<string> | undefined;
    mobileActive?: boolean;
    bookRoom?: boolean;

    dropdown?: boolean;
    itemsChildren?: ReactNode;
    heightState?: string;
    activeState?: string;
}

export type BookingMenuAccordionItemProps = {
    onClick?: () => void;
    label: string;
}

const BookingMenuAccordionItem = (props: BookingMenuAccordionItemProps) => {
    return (
        <p 
            className="accordion-dropdown__text" 
            onClick={props.onClick} 
        >
            {props.label}
        </p>
    )
}

const BookingMenuAccordion = (props: BookingMenuAccordionProps) =>  {
    const { containerClassName, dropdownClassName, headerTitle, headerSubTitle, headerWidth, mobileActive, bookRoom, dropdown, itemsChildren } = props;
    const [setActive, setActiveState] = useState(props.activeState || "");
    const [setHeight, setHeightState] = useState(props.heightState ||  "0px");
    const content = useRef<HTMLDivElement>(null!);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
          );
    };

    return (
        <form className={`accordion accordion--${headerWidth} ${containerClassName}`}>
            <div className={mobileActive ? 'accordion-header accordion-header--mobile-active' : 'accordion-header'} onClick={dropdown ? toggleAccordion : ()=>{}} tabIndex={0} onBlur={() => { setHeightState("0px");  setActiveState("");}}>
                <p className="accordion-header__title">{headerTitle}</p>
                <div className={`accordion-header-selector ${setActive}`}>
                    <p className="accordion-header-selector__text">{headerSubTitle}</p>
                    {dropdown && <img src={DropDownArrow} alt="chevron" className={setActive === "active" ? "accordion-header-selector__icon rotate-icon" : "accordion-header-selector__icon"} loading="lazy" />}
                </div>               
            </div>
           {dropdown && <div className={`${dropdownClassName} accordion-dropdown--${headerWidth} ${bookRoom ? 'accordion-dropdown-book-room' : 'accordion-dropdown'}`} ref={content} style={{ maxHeight: `${setHeight}` }}>
                {itemsChildren && <>{itemsChildren}</>}
            </div>}
        </form>
    )
}

BookingMenuAccordion.defaultProps = {
    dropdown: true,
}

export { BookingMenuAccordionItem };
export default BookingMenuAccordion;
