import WineBar from "../../assets/images/live-local/wine-bar.jpg";
// import TheCommons from "../../assets/images/live-local/the-commons.jpg";
import lobby_image_1 from "../../assets/images/live-local/lobby_first_image.jpg";
import AuntLucys from "../../assets/images/live-local/aunt-lucys.jpg";
import TheAnnexCafe from "../../assets/images/live-local/the-annex-cafe.jpg";

import Boombox from "../../assets/images/experience/boombox.png";
import Confetti from "../../assets/images/experience/confetti.png";
import RedVista from "../../assets/images/experience/red-vista.png";
import NeonHallway from "../../assets/images/experience/neon-hallway.png";

export const discoverCardCarouselData = [
  {
    imageSrc: WineBar,
    imageAlt: "The Wine bar",
    imageTitle: "The Wine bar",
    title: "The Wine Bar",
    paragraph:
      "The Wine Bar is a cozy, intimate room that warmly invites you in for a drink and great conversation. Take your jacket off and stay a while.",
    linkTo: "/eat-and-drink#wine-bar",
    hash: true,
  },
  {
    imageSrc: TheAnnexCafe,
    imageAlt: "Annex Cafe",
    imageTitle: "Annex Cafe",
    title: "Annex Cafe",
    paragraph:
      "The Annex Cafe is the only way to start your day. We’ve tapped the best in the neighbourhood for their baked goods and locally roasted coffee. Enjoy a complimentary coffee and nosh every morning of your stay.",
    linkTo: "/eat-and-drink#cafe",
    hash: true,
  },
  {
    imageSrc: lobby_image_1,
    imageAlt: "Lobby",
    imageTitle: "Lobby",
    title: "Lobby",
    paragraph: "The communal hang. We're open during the day so you can finally get that inbox down to zero. In the evening catch up with friends over a cocktail while some of our favourite talent fills the space with sound.",
    linkTo: "/events",
    buttonText: "Discover",
    hash: true,
  },
  // {
  //   imageSrc: AuntLucys,
  //   imageAlt: "Aunt Lucy’s",
  //   imageTitle: "Aunt Lucy’s",
  //   title: "Aunt Lucy’s",
  //   paragraph:
  //     "Ghanian inspired smashburgers, chicken sandwiches and more. Named after the founder’s grandmother, Aunty Lucy’s is the ideal late night meal. Pairs well with wine and good times.",
  //   linkTo: "/eat-and-drink#aunty-lucys",
  //   url: "",
  //   hash: true,
  // },
];

export const musicCarouselData = [
  {
    imageSrc: Boombox,
    imageAlt: "Boombox",
    imageTitle: "Boombox",
    title: "Featured Favs",
    paragraph: "Our favourite home grown artists…",
    linkTo: "#",
    url: "https://open.spotify.com/album/6W4MCaXvhyiD2n26kv4e2R",
    hash: false,
  },
  {
    imageSrc: Confetti,
    imageAlt: "Confetti",
    imageTitle: "Confetti",
    title: "Party Hits",
    paragraph:
      "Our biggest mix of pop and dance hits to get you in the groove…",
    linkTo: "#",
    url: "https://open.spotify.com/playlist/37i9dQZF1DXa2PvUpywmrr",
    hash: false,
  },
  {
    imageSrc: RedVista,
    imageAlt: "Red Vista",
    imageTitle: "Red Vista",
    title: "lofi beats",
    paragraph: "Beats to relax, study, and focus…",
    linkTo: "#",
    url: "https://open.spotify.com/playlist/37i9dQZF1DWWQRwui0ExPn",
    buttonText: "",
    hash: false,
  },
  {
    imageSrc: NeonHallway,
    imageAlt: "Neon Hallway",
    imageTitle: "Neon Hallway",
    title: "Playlist Name",
    paragraph: "I can’t think of anything else to put for a fake playlist…",
    linkTo: "#",
    url: "https://open.spotify.com/playlist/37i9dQZF1DWXRqgorJj26U",
    hash: false,
  },
];
