import Burger from "../../assets/images/experience/burger.jpg";
import Charcuterie from "../../assets/images/experience/charcuterie.png";
import Patio from "../../assets/images/experience/patio.png";
import PourDrink from "../../assets/images/experience/pour-drink.png";
import PouringMilk from "../../assets/images/experience/pouring-milk.jpg";
import OpenWine from "../../assets/images/experience/open-wine.jpg";
import DeskPaper from "../../assets/images/experience/desk-paper.jpg";
import Comics from "../../assets/images/experience/comics.png";
import DogBed from "../../assets/images/experience/dog-bed.jpg";
import Cheers from "../../assets/images/experience/cheers.jpg";
import PourWine from "../../assets/images/experience/pour-wine.jpg";
import LookInsideRoom from "../../assets/images/experience/look-inside-room.jpg";

import {
  InstagramCarouselDesktopType,
  InstagramCarouselType,
} from "../../models/InstagramCarousel";

// Carousels
const InstagramImages: InstagramCarouselType[] = [
  {
    imageSrc: DeskPaper,
    imageAlt: "Desk with papers",
    imageTitle: "Desk with papers",
    url: "https://www.instagram.com/annexhotels/?hl=en",
  },
  {
    imageSrc: Comics,
    imageAlt: "Annex Comics",
    imageTitle: "Annex Comincs",
    url: "https://www.instagram.com/annexhotels/?hl=en",
  },
  {
    imageSrc: DogBed,
    imageAlt: "Dog on the bed",
    imageTitle: "Dog on the bed",
    url: "https://www.instagram.com/annexhotels/?hl=en",
  },
  {
    imageSrc: Cheers,
    imageAlt: "Cheers",
    imageTitle: "Cheers",
    url: "https://www.instagram.com/annexhotels/?hl=en",
  },
  {
    imageSrc: PourWine,
    imageAlt: "Pour Wine",
    imageTitle: "Pour Wine",
    url: "https://www.instagram.com/annexhotels/?hl=en",
  },
  {
    imageSrc: LookInsideRoom,
    imageAlt: "Look inside room",
    imageTitle: "Look inside room",
    url: "https://www.instagram.com/annexhotels/?hl=en",
  },
];

const InstagramImagesDesktop: InstagramCarouselDesktopType[] = [
  {
    imageSrc: InstagramImages[0].imageSrc,
    imageAlt: InstagramImages[0].imageAlt,
    imageTitle: InstagramImages[0].imageTitle,
    url: InstagramImages[0].url,

    imageSrc2: InstagramImages[1].imageSrc,
    imageAlt2: InstagramImages[1].imageAlt,
    imageTitle2: InstagramImages[1].imageTitle,
    url2: InstagramImages[1].url,

    imageSrc3: InstagramImages[2].imageSrc,
    imageAlt3: InstagramImages[2].imageAlt,
    imageTitle3: InstagramImages[2].imageTitle,
    url3: InstagramImages[2].url,
  },
  {
    imageSrc: InstagramImages[3].imageSrc,
    imageAlt: InstagramImages[3].imageAlt,
    imageTitle: InstagramImages[3].imageTitle,
    url: InstagramImages[3].url,

    imageSrc2: InstagramImages[4].imageSrc,
    imageAlt2: InstagramImages[4].imageAlt,
    imageTitle2: InstagramImages[4].imageTitle,
    url2: InstagramImages[4].url,

    imageSrc3: InstagramImages[5].imageSrc,
    imageAlt3: InstagramImages[5].imageAlt,
    imageTitle3: InstagramImages[5].imageTitle,
    url3: InstagramImages[5].url,
  },
];

export { InstagramImages, InstagramImagesDesktop };
