import { ReactNode, useState } from "react";
import Button from "../Global/Button/Button";
import "./RestaurantInfo.scss";
import ButtonArrow from "../Global/ButtonArrow/ButtonArrow";
import Icon from "../Global/Icon/Icon";
import { Carousel } from "react-bootstrap";

type RestaurantInfoImageDetails = {
  imgSrc?: string;
  imgAlt?: string;
  imgTitle?: string;
};

type RestaurantInfoProps = {
  id?: string;
  imgSrc?: string;
  imgAlt?: string;
  imgTitle?: string;
  imgDetails?: RestaurantInfoImageDetails[] | undefined;
  restaurantName?: string;
  paragraphChildren?: ReactNode;
  hoursChildren?: ReactNode;
  viewMenuLinkTo?: string;
  viewMenuSecondLinkTo?: string;
  menuUrl?: string;
  onBookATableClick?: () => void;
  message?: string;
  isBookingFull?: boolean;
  textUs?: boolean;
};

const RestaurantInfo = (props: RestaurantInfoProps) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const directionButtons = (direction: string) => {
    return (
      <span
        aria-hidden="true"
        className={
          direction === "Next"
            ? "button-next-restaurant"
            : "button-prev-restaurant"
        }
      >
        {direction === "Next" ? (
          <Icon
            name="next-arrow"
            className="button-next-icon-restaurant"
            color="black"
          />
        ) : (
          <Icon
            name="back-arrow"
            className="button-prev-icon-restaurant"
            color="black"
          />
        )}
      </span>
    );
  };

  return (
    <div className="annex-restaurant-info" id={props.id}>
      {props.imgDetails && (
        <div className="annex-restaurant-info__image-container">
          {/* <img
            className="annex-restaurant-info__image"
            src={props.imgSrc}
            alt={props.imgAlt}
            title={props.imgTitle}
            loading="lazy"
          /> */}
          {/* <div className="hero-carousel-container"> */}
          <Carousel
            //  ref={ref}
            interval={null}
            controls={true}
            indicators={false}
            nextLabel={""}
            prevLabel={""}
            activeIndex={index}
            onSelect={handleSelect}
            nextIcon={directionButtons("Next")}
            prevIcon={directionButtons("Previous")}
            className="restaurant-carousel"
          >
            {props.imgDetails?.map((item, index) => {
              return (
                <Carousel.Item key={index} className="carousel-item">
                  <div className="restaurant-images-item-wrapper">
                    {/* <div> */}
                    <img
                      className="annex-restaurant-info__image"
                      src={item.imgSrc}
                      alt={item.imgAlt}
                      title={item.imgTitle}
                      loading="lazy"
                    />
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
          {/* </div> */}
        </div>
      )}
      <div className="annex-restaurant-info__info">
        <div className="annex-restaurant-info__info-container">
          {props.restaurantName && (
            <h2 className="annex-restaurant-info__name">
              {props.restaurantName}
            </h2>
          )}
          {props.paragraphChildren && <>{props.paragraphChildren}</>}
          {props.viewMenuLinkTo && (
            <ButtonArrow
              target="_blank"
              buttontype="main"
              children={
                props.id === "wine-bar"
                  ? "Book the space"
                  : props.id === "Two"
                  ? "View Raff's Pizza Menu"
                  : "View Menu"
              }
              iconColor="#000000"
              linkTo={props.viewMenuLinkTo}
              onClick={
                props.menuUrl ? () => window.open(props.menuUrl) : () => {}
              }
            />
          )}
          {props.viewMenuSecondLinkTo && (
            <ButtonArrow
              target="_blank"
              buttontype="main"
              children="View drink menu"
              iconColor="#000000"
              linkTo={props.viewMenuSecondLinkTo}
              onClick={
                props.menuUrl ? () => window.open(props.menuUrl) : () => {}
              }
            />
          )}
          {props.id !== "wine-bar" && (
            <p className="annex-restaurant-info__hours">Hours</p>
          )}
          {props.hoursChildren && <>{props.hoursChildren}</>}
          {!props.isBookingFull && props.message ? (
            <Button
              buttontype="primary--inverse"
              children="Book a Table"
              onClick={props.onBookATableClick}
              linkTo=""
            ></Button>
          ) : null}
          {props.isBookingFull && props.message ? (
            <p className="annex-restaurant-info__message">{props.message}</p>
          ) : null}
          {props.textUs && (
            <div className="annex-restaurant-info__text-us">
              <Button
                buttontype="secondary"
                children="Text us to reserve"
                buttonHoverText="+1 647 694 9868"
                hasATag={true}
                cname="rollover-button"
                linkTo=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RestaurantInfo;
