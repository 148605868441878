import { ReactNode } from "react"
import './AuthSideMenuDesktopList.scss';

type AuthSideMenuDesktopListProps = {
  children?: ReactNode;
}

type AuthSideMenuDesktopListItemProps = {
  label?: string;
  isActive?: boolean;
  onClick?: () => void;
}

const AuthSideMenuDesktopList = (props: AuthSideMenuDesktopListProps) => {
  return (
    <div className="annex-auth-side-menu-desktop-list">
      {props.children}
    </div>
  )
}

const AuthSideMenuDesktopListItem = (props: AuthSideMenuDesktopListItemProps) => {
  return (
    <button className={`annex-auth-side-menu-desktop-list__item ${props.isActive ? 'annex-auth-side-menu-desktop-list__item--active' : ''}`} onClick={props.onClick}>
      <p className="annex-auth-side-menu-desktop-list__text">{props.label}</p>
    </button>
  )
}

export { AuthSideMenuDesktopListItem };
export default AuthSideMenuDesktopList;
