import { Splide, SplideSlide } from '@splidejs/react-splide';
import DiscoverCard from '../../components/DiscoverCard/DiscoverCard';
import Icon from '../../components/Global/Icon/Icon';
import '@splidejs/splide/dist/css/splide.min.css';
import './BlogRecentPostCarousel.scss';

export type BlogRecentPostCarouselProps = {
	title?: string;
	infoHeight: string;
	items: any[];
};

// REWORK CAROUSEL (WILL BE USED FOR ALL CAROUSELS BUT NEED TO CREATE NEW COMPONENTS (FOLLOW THIS CAROUSEL STRUCTURE))
const BlogRecentPostCarousel = (props: BlogRecentPostCarouselProps) => {
	const {title, infoHeight, items} = props;

	return (
		<div className="blog-recent-post-carousel">
			{title && (<h4 className="blog-recent-post-carousel__title">{title}</h4>)}
			{items && items.length > 0 ? (
				<div className="blog-recent-post-carousel__slider">
					<Splide
						options={{
							type: 'slide',
							perPage: 4,
							pagination: false,
							breakpoints: {
								1365: {
									perPage: 1,
								}
							}
						}}
						onArrowsMounted={(splide, prev, next) => {console.log(splide, prev, next)}}
						renderControls={() => (
							<div className="splide__arrows">
								<button className="splide__arrow splide__arrow--prev">
									<Icon name='next-arrow' className="" color='black'/>
								</button>
								<button className="splide__arrow splide__arrow--next">
									<Icon name='next-arrow' className="" color='black'/>
								</button>
							</div>
						)}
					>
						{items.map((item, index) => 
							<SplideSlide key={index}>
								<DiscoverCard
									imageSrc={item.header_image}
									imageAlt={item.imageAlt}
									imageTitle={item.imageTitle}
									title={item.title}
									linkTo={`/the-lobby/${item.id}`}
									buttonText={'Read'}
									infoHeight={infoHeight}
								/>
							</SplideSlide>
						)}
					</Splide>
				</div>
			) : <div className="blog-recent-post-carousel__no-items">
				 		<p className="blog-recent-post-carousel__no-items-text">No items available here.</p>
					</div>
			}
		</div>
	)
}

export default BlogRecentPostCarousel;
