import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../../components/Global/Button/Button";
import "./AuthCreate.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import MetaDecorator from "../../../MetaDecorator";
import { collection, setDoc, doc } from "firebase/firestore";
import { useAuth, useFirestore } from "reactfire";
import { createUserWithEmailAndPassword } from "firebase/auth";

const AuthCreate = () => {
  const db = useFirestore();
  const customersCollection = collection(db, "customers");
  const auth = useAuth();
  const [formSuccess, setFormSuccess] = useState(false);
  const history = useHistory();
  const passwordRegExp =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;

  const creatAccountSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .matches(
        passwordRegExp,
        "Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit and one special character"
      )
      .required("Password is required"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema: creatAccountSchema,
    onSubmit: async (values, { resetForm, setFieldError }) => {
      //   alert(JSON.stringify(values, null, 2));
      // setFormSuccess(true);

      // const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
      // const user = userCredential.user;

      await createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((response) => {
          // console.log("response", response);

          const user = response.user;
          if (user) {
            const userData = {
              uid: user.uid,
              email: values.email,
            };
            setDoc(doc(customersCollection, userData.email), userData)
              .then(() => {
                setFormSuccess(true);
                setTimeout(() => {
                  history.push("/mybookings");
                  setFormSuccess(false);
                  resetForm();
                }, 4000);
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
          // console.log("error", err.message);
          if (err.message === "Firebase: Error (auth/email-already-in-use).") {
            setFieldError("email", "Email address already in use");
          }
        });

      // if (user) {
      // 	const userData = {
      // 		uid: user.uid,
      // 		email: values.email,
      // 		password: values.password
      // 	};
      // 	await setDoc(doc(usersCollection, user.uid), userData)
      // 		.then(() => {
      // 			setTimeout(() => {
      // 				history.push('/mybookings');
      // 				setFormSuccess(false);
      // 				resetForm();
      // 			}, 4000);
      // 		})
      // 		.catch(err => {
      // 			console.error(err);
      // 			// console.log("error", err.message);
      // 			// if(err.message === "Firebase: Error (auth/email-already-in-use).") {
      // 			// 	setFieldError("email", "Email address already in use")
      // 			// }
      // 		});
      // }
    },
  });

  return (
    <>
      <MetaDecorator
        title="Create Account | Annex"
        description="Sample description"
      />
      <div className="auth-container">
        <h2 className="annex-form-title">Create your account</h2>
        <form className="annex-form" onSubmit={formikProps.handleSubmit}>
          <div className="annex-form-group-container">
            <div className="annex-form-group">
              <label
                className={`annex-form__label ${
                  formikProps.touched.email && formikProps.errors.email
                    ? "annex-form__label--error"
                    : ""
                }`}
              >
                Email
              </label>
              <input
                className="annex-form__input"
                id="email"
                name="email"
                type="text"
                value={formikProps.values.email}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
              />
            </div>
            {formikProps.touched.email && formikProps.errors.email && (
              <p className="annex-form__error-message">
                {formikProps.errors.email}
              </p>
            )}
          </div>

          <div className="annex-form-group-container">
            <div className="annex-form-group">
              <label
                className={`annex-form__label ${
                  formikProps.touched.password && formikProps.errors.password
                    ? "annex-form__label--error"
                    : ""
                }`}
              >
                Password
              </label>
              <input
                className="annex-form__input"
                id="password"
                name="password"
                type="password"
                value={formikProps.values.password}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
              />
            </div>
            {formikProps.touched.password && formikProps.errors.password && (
              <p className="annex-form__error-message">
                {formikProps.errors.password}
              </p>
            )}
          </div>

          <div className="annex-form-group-container">
            <div className="">
              <label className="annex-form__checkbox">
                <input
                  className="annex-form__checkbox-input"
                  id="recieveUpdates"
                  type="checkbox"
                />
                You agree to recieve updates and offers from The Annex Hotel.
                You may opt out anytime.
              </label>
            </div>
          </div>

          <div className="annex-form-group-container">
            <p className="annex-form__paragraph">
              By creating an account, you agree to the{" "}
              <Link to="/terms-and-conditions">Terms and Conditions</Link> and
              acknowledge our <Link to="/privacy-policy">Privacy Policy</Link>.
            </p>
          </div>

          <Button
            buttontype="primary--inverse"
            children="Create Account"
            cname={
              !(formikProps.dirty && formikProps.isValid)
                ? "disabled-btn-black"
                : ""
            }
            disabled={!(formikProps.dirty && formikProps.isValid)}
            type="submit"
            linkTo=""
          />
          {formSuccess && (
            <p className="annex-form__success-message">
              You've created your account and will be re-directed shortly!
            </p>
          )}
        </form>
      </div>
    </>
  );
};

export default AuthCreate;
