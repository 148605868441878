import TheLoftOne from "../../assets/images/live-local/the-loft-one.jpg";
import TheBigOne from "../../assets/images/live-local/the-big-one.jpg";
import TheCozyOne from "../../assets/images/live-local/the-cozy-one.jpg";
import TheJustRightOne from "../../assets/images/the-just-right-one-room-page/desk-bed.jpg";
import DanielsRoom from "../../assets/images/daniels-room-page/bed-desk-window.jpg";
import TheDouble from "../../assets/images/the-double-room-page/hero-the-double.jpg";

import DanceParty from "../../assets/images/landing-pages/experience-bg-image.jpg";

export const liveLocalHeroCarousel = [
  {
    image: TheLoftOne,
    alt: "Large Room",
    previous: "DREAM IN STYLE WITH CITY VIEWS",
    name: "Large",
    description: "1 king bed and all of the vibes. ",
  },
  {
    image: TheJustRightOne,
    alt: "Medium Room",
    previous: "CITY LIVING",
    name: "Medium",
    description: "Perfect is the word. 1 queen bed and plenty of light. ",
  },
  // {
  //   image: DanielsRoom,
  //   alt: "Daniel’s Room",
  //   previous: "GET INSPIRED IN",
  //   name: "Daniel’s Room",
  //   description: "Daniel Caesar’s personal retreat at The Annex. ",
  // },
  {
    image: TheDouble,
    alt: "Extra Large Room",
    previous: "RECONNECT",
    name: "Extra Large",
    description: "2 Queen beds for twice the fun. ",
  },
  // {
  //   image: TheBigOne,
  //   alt: "The Big One",
  //   previous: "MORE SPACE IN",
  //   name: "The Big One",
  //   description: "1 king bed for sprawling. ",
  // },
  {
    image: TheCozyOne,
    alt: "Small Room",
    previous: "PURPOSEFUL INTIMACY",
    name: "Small",
    description: "1 comfy queen bed. ",
  },
];

export const experienceHeroCarousel = [
  {
    image: DanceParty,
    alt: "Dance Party",
    previous: "",
    name: "Find yourself here",
    description: "",
  },
];
