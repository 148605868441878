import "./ButtonArrow.scss";
import {ReactNode} from "react";
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import Icon from "../Icon/Icon";

export type ButtonArrowProps = {
	buttontype: string;
	children: ReactNode | string;
	className?: string;
	isActive?: boolean;
	iconColor?: string;
	linkTo: string;
	width?: number;
	onClick?: (() => void) | ((e: any) => void);
	target?: string;
	rel?: string;
	download?: boolean;
	isExternal?: boolean;
	hash?: boolean;
}

const ButtonArrow = (props: ButtonArrowProps) => {
	if (props.isExternal) {
		return (
			<a href={props.linkTo} className="button-arrow-link" target={props.target} rel={props.rel} download={props.download}>
				<button
					onClick={props.onClick}
					className={['button-arrow', `button-arrow--${props.buttontype}`, props.className, `${props.isActive ? ('active') : ('')}`].join(' ')}
					style={{width: `${props.width}px`}}
					type="button"
				>
					{props.children}
					<Icon name="next-arrow" className={`button-arrow__icon button-arrow__icon--${props.buttontype}`}/>
				</button>
			</a>
		)
	}

	if (props.hash) {
		return (
			<HashLink smooth to={props.linkTo} className="button-arrow-link" target={props.target} rel={props.rel} download={props.download}>
				<button
					onClick={props.onClick}
					className={['button-arrow', `button-arrow--${props.buttontype}`, props.className, `${props.isActive ? ('active') : ('')}`].join(' ')}
					style={{width: `${props.width}px`}}
					type="button"
				>
					{props.children}
					<Icon name="next-arrow" className={`button-arrow__icon button-arrow__icon--${props.buttontype}`}/>
				</button>
			</HashLink>
		)
	}

	return (
		<Link to={props.linkTo} className="button-arrow-link" target={props.target} rel={props.rel} download={props.download}>
			<button
				onClick={props.onClick}
				className={['button-arrow', `button-arrow--${props.buttontype}`, props.className, `${props.isActive ? ('active') : ('')}`].join(' ')}
				style={{width: `${props.width}px`}}
				type="button"
			>
				{props.children}
				<Icon name="next-arrow" className={`button-arrow__icon button-arrow__icon--${props.buttontype}`}/>
			</button>
		</Link>
	)
}

export default ButtonArrow;