import React, {useEffect, useState} from 'react';
import {useInView} from 'react-intersection-observer';
import {motion} from 'framer-motion';
import './ImageLoadAnimation.scss';

export type ImageObserverProps = React.HTMLProps<HTMLImageElement> & {
	width: number | string;
	height: number | string;
	onInView?: (inView: boolean) => void | any;
	triggerOnce?: true;
};

const outerVariant: any = {
	visible: {
		y: 0,
		transition: {
			type: 'spring',
			stiffness: 50,
		},
	},
	hidden: {
		y: 50,
		transition: {
			type: 'spring',
			stiffness: 50,
		},
	},
};

const previewVariant: any = {
	hidden: {
		y: 0,
		transition: {
			delay: 0,
			type: 'tween',
			bounce: 0,
			//   duration: 0.7,
			duration: 1.1,
		},
	},
	visible: {
		y: -900,
		transition: {
			delay: 0.4,
			type: 'tween',
			bounce: 0,
			//   duration: 0.7,
			duration: 1.1,
		},
	},
};
const previewVariantWhite: any = {
	hidden: {
		y: 0,
		transition: {
			delay: 0.3,
			type: 'spring',
			bounce: 0,
			//   duration: 0.9,
			duration: 0.5,
		},
	},
	visible: {
		y: -900,
		transition: {
			//   delay: 0.7,
			delay: 0.2,
			type: 'tween',
			bounce: 0,
			//   duration: 0.9,
			duration: 0.5,
		},
	},
};

const imageVariant = {
	visible: {
		opacity: 1,
		y: 0,
		transition: {
			delay: 0.3,
			type: 'spring',
			bounce: 0,
			//   duration: 1.1,
			duration: 0.9,
		},
	},
	hidden: {
		delay: 0,
		// opacity: 0,
		opacity: 1,
		y: 150,
		transition: {
			type: 'spring',
			bounce: 0,
			//   duration: 1.1,
			duration: 1.2,
		},
	},
};

const ImageLoadAnimation = ({
	                            onInView,
	                            triggerOnce = true,
	                            width,
	                            height,
	                            ...props
                            }: ImageObserverProps) => {
	//const { scrollYProgress } = useViewportScroll();
	//const scale = useTransform(scrollYProgress, [0.06848404255319149, 0.4553191489361702], [1.1, 1]);
	const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
	const [finishedAnimating, setFinishedAnimating] = useState<boolean>(false);
	const [, setMouseIn] = useState<boolean>(false);
	const [image, setImage] = useState<any>(null);

	const onFinishedAnimation = () => {
		setTimeout(() => {
			setFinishedAnimating(true);
		}, 1200);
	}

	const {ref, inView} = useInView({
		triggerOnce: triggerOnce,
		// threshold: 0.4,
		threshold: 0.1,
	});

	useEffect(() => {
		if (inView) {
			setShouldAnimate(true);
		}
		if (inView && onInView) {
			onInView(inView || false);
		}
	}, [inView, onInView]);

	useEffect(() => {
		const innerAsync = async () => {
			try {
				if (props.src) {
					const response = await fetch(props.src);
					if (response.ok) {
						const blob = await response.blob();
						const objectURL = URL.createObjectURL(blob);
						setImage(objectURL);
					} else {
						setImage(props.src);
					}
				}
			} catch (e) {
				setImage(props.src);
			}
		};
		innerAsync();
	}, [props.src]);

	return (
		<div
			className={props.className}
			onMouseEnter={() => setMouseIn(true)}
			onMouseLeave={() => setMouseIn(false)}
		>
			<motion.div
				className="image-loader"
				variants={outerVariant}
				initial="hidden"
				animate={shouldAnimate ? 'visible' : 'hidden'}
				style={{width, height}}
				ref={ref}
			>
				<motion.div className="image-loader__preview-white" variants={previewVariantWhite}/>
				<motion.div className="image-loader__preview-green" variants={previewVariant}/>
				<motion.div
					{...(props as any)}
					variants={imageVariant}
					onAnimationStart={() => setFinishedAnimating(false)}
					onAnimationComplete={onFinishedAnimation}
					className={`image-loader__image ${finishedAnimating ? 'finished' : ''}`}
					style={{
						backgroundImage: `url(${image})`,
						backgroundPosition: 'center',
						backgroundSize: 'cover',
						//scale: mouseIn ? scale.get() + 0.1 : scale,
					}}
					whileHover={{scale: 1.05}}
					transition={{type: "tween"}}
				/>
			</motion.div>
		</div>
	);
};

export default ImageLoadAnimation;
