import './BookingMenuMobile.scss';
import { useState } from 'react';

import BookingMenu from '../BookingMenu/BookingMenu';
import OutsideClickHandler from 'react-outside-click-handler';

export default function BookingMenuMobile() {

    const [menuOpen, setMenuOpen] = useState(false);
    const handleMenuOpen = () => {
        setMenuOpen(!menuOpen);
    }

    return (
        <OutsideClickHandler onOutsideClick={()=> setMenuOpen(false)}>
            <div className='booking-menu-mobile-container mobile-only'>
                <div className={`${menuOpen ? 'booking-menu-mobile booking-menu-mobile--active' : 'booking-menu-mobile'}`}>
                    <button className="booking-menu-mobile__button" onClick={handleMenuOpen}>{`${menuOpen ? 'Cancel' : 'Book Now'}`}</button>
                    <div className={`${menuOpen ? 'menu-container menu-container--active' : 'menu-container'}`}>    
                        <BookingMenu className='mobile-only' mobileActive={menuOpen}/>    
                    </div>
                </div>    
            </div>
        </OutsideClickHandler>
    )
}
