import "./CallToActionBanner.scss";
import { useState } from "react";
import Button from "../Global/Button/Button";
import OutsideClickHandler from "react-outside-click-handler";
import JoinMailingListModal from "../JoinMailingListModal/JoinMailingListModal";

export type CallToActionBannerProps = {
  title?: string;
  body?: string;
  buttonText?: string;
  buttonHoverText?: string;
  buttonClassName?: string;
  hasATag?: boolean;
  hasMailingListModal?: boolean;
  onClick?: (() => void) | ((e: any) => void);
};

const CallToActionBanner = ({
  title,
  body,
  buttonText,
  buttonHoverText,
  buttonClassName,
  hasATag,
  hasMailingListModal,
  onClick,
  ...props
}: CallToActionBannerProps) => {
  // const {title, body, buttonText} = props;

  const [showMailingListModal, setShowMailingListModal] = useState(false);

  const handleShowMailingListModal = () => {
    setShowMailingListModal(!showMailingListModal);
  };

  const handleCloseMailingListModal = () => {
    setShowMailingListModal(false);
  };

  return (
    <div className="call-to-action-container">
      <OutsideClickHandler onOutsideClick={handleCloseMailingListModal}>
        <JoinMailingListModal
          className="hide-modal"
          title={"Join the Community"}
          body={
            "Annex is an unrivalled local community experience for creative, lifestyle, and original hospitality experiences. Join our mailing list for first access to new content, discounts and events. You may opt out at any time."
          }
          onClick={handleShowMailingListModal}
          showMailingListModal={showMailingListModal}
          setShowMailingListModal={setShowMailingListModal}
          children={"Join the Community"}
          joinMailingList={true}
        />
      </OutsideClickHandler>
      <div className="call-to-action">
        <h4 className="call-to-action__title">{title}</h4>
        <p className="call-to-action__text">{body}</p>
        <Button
          buttontype="primary"
          children={buttonText}
          onClick={hasMailingListModal ? handleShowMailingListModal : onClick}
          buttonHoverText={buttonHoverText}
          cname={buttonClassName}
          hasATag={hasATag}
          linkTo=""
        />
      </div>
    </div>
  );
};

export default CallToActionBanner;
