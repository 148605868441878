import BookingTwoColumnLayout from "../BookingTwoColumnLayout";
import "./BookingComplete.scss";
// import BookingCheckoutCompleteImage from "../../../assets/images/book-checkout-complete.jpg";
import BookingCheckoutCompleteImage from "../../../assets/images/wine-glass-cheers.jpg";
import { useState } from "react";

import { motion, AnimatePresence } from "framer-motion";

import { useUserData } from "../../../hooks/useUserData";

import { useParams } from "react-router-dom";

type BookingCompleteProps = {
  fullName?: string;
  email?: string;
  phoneNumber?: string;
  roomName?: string;
  date?: string;
  guest?: string;
  showCheckOutComplete?: boolean;
  guestData?: {
    uid: any;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
  };
};

const BookingComplete = ({
  roomName,
  showCheckOutComplete,
  guestData,
  ...props
}: BookingCompleteProps) => {
  // const { fullName, email, phoneNumber, room, date, guest } = props;
  const {
    // id,
    startUtc,
    endUtc,
    numberOfGuests,
  } = useParams<any>();
  const {
    userData,
    // userRef
  } = useUserData();

  const [fullName] = useState(
    userData
      ? `${userData?.firstName + " " + userData?.lastName}`
      : `${guestData?.firstName + " " + guestData?.lastName}`
  );
  const [email] = useState(userData ? userData?.email : guestData?.email);
  const [phoneNumber] = useState(
    userData ? userData?.phoneNumber : guestData?.phoneNumber
  );
  const [room] = useState(roomName);
  // const [date] = useState("Fri, June 25th, 2021 – Sun, June 27th, 2021");
  const [date] = useState(
    `${new Date(startUtc).toDateString()} – ${new Date(endUtc).toDateString()}`
  );

  // const [guests] = useState(numberOfGuests);

  const showBookingComplete = {
    hidden: {
      // opacity: 0,
      left: "-100%",
      transition: {
        type: "tween",
        duration: 1,
        delay: 0,
        ease: "easeInOut",
      },
    },
    visible: {
      // opacity: 1,
      left: 0,
      transition: {
        type: "tween",
        duration: 1,
        delay: 0,
        ease: "easeInOut",
      },
    },
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {showCheckOutComplete && (
        <motion.div
          variants={showBookingComplete}
          initial="hidden"
          animate="visible"
          className="booking-checkout-complete-animation-container"
        >
          <BookingTwoColumnLayout
            leftColumnChildren={
              <>
                <div className="booking-checkout-complete-header">
                  <h2 className="booking-checkout-complete-header__title">
                    You're booked. Best decision you've made today.
                  </h2>
                  <p className="booking-checkout-complete-header__paragraph">
                    {`We have recieved your booking and it is being arranged. You
                    will get an email confirmation at ${
                      userData ? userData?.email : guestData?.email
                    }`}
                  </p>
                </div>

                <div className="booking-checkout-complete-summary">
                  <h3 className="booking-checkout-complete-summary__title-bold">
                    Booking Summary
                  </h3>
                  <p className="booking-checkout-complete-summary__text">
                    {fullName}
                  </p>
                  <p className="booking-checkout-complete-summary__text">
                    {email} | {phoneNumber}
                  </p>
                </div>

                <div className="booking-checkout-complete-room-date">
                  <p className="booking-checkout-complete-room-date__text">
                    {room}
                  </p>
                  <p className="booking-checkout-complete-room-date__text">
                    {date}
                  </p>
                  <p className="booking-checkout-complete-room-date__text">
                    {/* {`${guests+" Adults"}`} */}
                    {`${numberOfGuests + " Adults"}`}
                    {/* {console.log("number of guests", numberOfGuests)} */}
                  </p>
                </div>
              </>
            }
            rightColumnChildren={
              <>
                <img
                  className="booking-two-column-layout__image"
                  src={BookingCheckoutCompleteImage}
                  alt="People cheering in crowds"
                  title="People cheering in crowds"
                  loading="lazy"
                />
                {/* <div
                  style={{
                    width: "100%",
                    height: 0,
                    paddingBottom: "100%",
                    position: "relative",
                  }}
                >
                  <iframe
                    src="https://giphy.com/embed/XF4FH1c1WZRXltNcjM"
                    width="100%"
                    height="100%"
                    style={{ position: "absolute" }}
                    frameBorder="0"
                    className="giphy-embed"
                    allowFullScreen
                  ></iframe>
                </div> */}
                {/* <div
                  style={{
                    width: "100%",
                    height: 0,
                    paddingBottom: "100%",
                    position: "relative",
                  }}
                >
                  <iframe
                    src="https://giphy.com/embed/b67fkus7qUjgJ0P2k9"
                    width="100%"
                    height="100%"
                    style={{ position: "absolute" }}
                    frameBorder="0"
                    className="giphy-embed"
                    allowFullScreen
                  ></iframe>
                </div> */}
                {/* <p>
                  <a href="https://giphy.com/gifs/kochstrasse-hannover-agencylife-agenturleben-b67fkus7qUjgJ0P2k9">
                    via GIPHY
                  </a>
                </p> */}
              </>
            }
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BookingComplete;
